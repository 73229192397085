import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function getAuditCategory() {
    try {
        let url = endPoints.AUDIT_QUESTIONS.GET_CATEGORY;
        return HTTPClient.get(url, null);   
    }
    catch (error) {
        console.error("Error in 'auditActions.js --> getAuditCategory()':" + error);
    }
}

function getAuditTaskType() {
    try {
        let url = endPoints.NOTE_TYPE.LIST_VIEW;
        return HTTPClient.get(url, null);   
    }
    catch (error) {
        console.error("Error in 'auditActions.js --> getAuditTaskType()':" + error);
    }
}

function setAuditId(data){
    return {
        type: actions.SET_AUDIT_ID,
        payload: data
    }
}

function updateAuditAnswerIds(data){
    return {
        type: actions.UPDATE_AUDIT_ANSWER_IDS,
        payload: data
    }
}

function updateAuditAnswers(data){
    return {
        type: actions.UPDATE_AUDIT_ANSWERS,
        payload: data
    }
}

function updateAuditPhotos(data){
    return {
        type: actions.UPDATE_AUDIT_PHOTOS,
        payload: data
    }
}

function updateAuditCategory(data) {
    return {
        type: actions.AUDIT_CATEGORY_UPDATE,
        payload: data
    }
}
function updateAuditCategorySelectedValue(data) {
    return {
        type: actions.AUDIT_CATEGORY_SELECTED_VALUE_UPDATE,
        payload: data
    }
}
function updateAuditCategoryData(data){
    return {
        type: actions.UPDATE_AUDIT_CATEGORY_DATA,
        payload: data
    }
}

function updateAuditTask(data){
    return {
        type: actions.UPDATE_AUDIT_TASK,
        payload: data
    }
}

function updateAuditWeaknessData(data){
    return {
        type: actions.UPDATE_AUDIT_WEAKNESS_DATA,
        payload: data
    }
}

function updateAuditChecklistcategories(data){
    return {
        type: actions.UPDATE_AUDIT_CHECKLIST_CATEGORIES,
        payload: data,
    }
}

function updateAuditChecklistcategoryRearrangedquestions (data) {
    return {
        type : actions.UPADTE_AUDIT_CHECKLIST_REARRANGED_QUESTIONS,
        payload:data,
    }
}
export {
    getAuditCategory,
    getAuditTaskType,
    setAuditId,
    updateAuditAnswerIds,
    updateAuditAnswers,
    updateAuditPhotos,
    updateAuditCategory,
    updateAuditCategorySelectedValue,
    updateAuditCategoryData,
    updateAuditTask,
    updateAuditWeaknessData,
    updateAuditChecklistcategories,
    updateAuditChecklistcategoryRearrangedquestions,
}
