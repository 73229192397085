import React, { useRef } from 'react';
import ReportFilter from '../components/reportFilter';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getIntParam, getObjectParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { GridComponent, ColumnsDirective, ColumnDirective, AggregatesDirective, AggregateDirective, AggregateColumnsDirective, AggregateColumnDirective, Inject, Page, Aggregate, Sort } from '@syncfusion/ej2-react-grids';
import { constants, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { TYPE_DETAIL_VIEW, addTab, getActiveTabInfo, getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { sortArrayObjectByProperty, dateFormatFlatPicker, dateFormat } from '../../../services/helper/utils';
import { getImageNameByObject } from '../../../services/helper/common';
import { setHeaderByDispatch } from '../../../services/actions/headerActions';
import { getTabularReportPromise } from '../../../services/actions/SalesReportAction';
import { LinkToType } from '../../../services/constants/link';
import ShowCircularProgress from '../components/circularProgress';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showCustomSnackBar } from '../../../services/actions/snackBarAction'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import map from 'lodash/map';
import moment from 'moment';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '../components/pagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        padding: '8px 16px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '8px 16px'
    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000',
        fontSize: 14,
        padding: '8px 16px'
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ascSortIcon = <i className="fa fa-sort-asc" style={{ fontSize: '15px', color: '#717171', paddingLeft: '10px' }} />;

const descSortIcon = <i className="fa fa-sort-desc" style={{ fontSize: '15px', color: '#717171', paddingLeft: '10px' }} />;

function formatDate(date) {
    let d = date instanceof Date ? date : new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

function toDate(dateStr) {
    var parts = dateStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}
const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};


const TabularReport = ({ object, id }) => {

    const getPageSizeList = () => {
        let totalRows = getArrayParam(records).length;
        let pageSizeList = [{ text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: 'All', value: totalRows }];
        return pageSizeList;
    }

    const gridTemplate = (arrFields, props) => {
        try {
            arrFields = getArrayParam(arrFields).filter(f => { return !f.is_hidden });
            if (isValidParam(props) && arrFields.length > 0) {
                return (<StyledTableRow className="templateRow" style={{ height: 25, padding: '5px' }} >
                    {arrFields.map((col, i) => {
                        return getTd(col, props, i);
                    })}
                </StyledTableRow>)
            }
        } catch (error) {
            console.error("Error in 'tabularReport.js ---> gridTemplate()':" + error);
        }
    }

    const [isMounted, setIsMounted] = useState(false);
    const [report, setReport] = useState({
        id: 0,
        name: null,
        type: null,
        dueBy: null,
        fromDate: null,
        toDate: null,
        subType: null
    },);
    const [stateObject, setStateObject] = useState(null);
    const [fields, setFields] = useState(null);
    const [records, setRecords] = useState(null);
    const [aggregate, setAggregate] = useState({});
    const [hasAggregate, setHasAggregate] = useState(false);
    const [sortOptions, setSortOptions] = useState({
        columns: []
    });
    const [startIndex, setStartIndex] = useState(0);
    const [changeSize, setChangeSize] = useState({ pageSize: 50 });

    let pageSettings = {
        pageCount: 5,
        pageSize: 50,
        pageSizes: getPageSizeList()
    }
    const [totalCount, setTotalCount] = useState(0);
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'company',
        isDescending: false,
        onSort: (fieldName, isDescending, records) => onSortFn(fieldName, isDescending, records)
    },);

    // let template = gridTemplate;
    const gridInstance = useRef(null);
    let localizedStrings = getLocalizedStrings();
    let _selectedReport = getObjectParam(getActiveTabInfo()).selectedReport;
    const appState = useSelector((state) => state.app);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [changeLog, setchangeLog] = useState({startIndex:0});
    const dispatch = useDispatch();


    useEffect(() => {
        loadReport();
    }, []);

    useEffect(() => {
        let tempSelectedReport = getObjectParam(getActiveTabInfo().selectedReport);
        _selectedReport = tempSelectedReport;
        loadReport();
    }, [_selectedReport]);


    const loadReport = () => {
        updateState(_selectedReport);
        setHeader();
        getReport();
    };

    const setHeader = () => {
        let tempselectedReport = _selectedReport;
        setHeaderByDispatch(constants.SALES_OBJECT, tempselectedReport.item_name, null, false, true);
    }
    const onSortFn = (field, isDescending, records) => {
        let sortedRecords = [...records];
        let fieldName = field.name
        if (field.type === "TIMESTAMP" && isDescending) {
            const sortedDescending = sortedRecords.sort((a, b) => {
                return new Date(b[fieldName]) - new Date(a[fieldName]);
            });
              
        } else if (field.type === "TIMESTAMP")  {
            const sortedAscending = sortedRecords.sort((a, b) => {
                return new Date(a[fieldName]) - new Date(b[fieldName]);
              });
              
        } else {
            if (isDescending) {
                sortArrayObjectByPropertyDesc(sortedRecords, field.name);
            } else {
                sortArrayObjectByProperty(sortedRecords, field.name);
            }
        }
        setRecords(sortedRecords);
    };

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                let temp;
                if (
                    isValidParam(obj1) &&
                    obj1.hasOwnProperty(property) &&
                    isValidParam(obj2) &&
                    obj2.hasOwnProperty(property)
                ) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
        
                    // Convert to lowercase for string comparison (case-insensitive)
                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;
        
                    // For descending order, reverse the comparison
                    if (val1 < val2) {
                        temp = 1;   // If val1 < val2, return 1 (val2 before val1)
                    } else if (val1 > val2) {
                        temp = -1;  // If val1 > val2, return -1 (val1 before val2)
                    } else {
                        temp = 0;   // If equal, return 0 (no change)
                    }
                }
                return temp;
            });
        }	
      
    }

    const updateState = (reportParams) => {
        let tempReport = getObjectParam(report);
        try {
            reportParams = getObjectParam(reportParams);
            tempReport.id = reportParams.hasOwnProperty("parameter_id") ? getIntParam(reportParams.parameter_id) : getIntParam(tempReport.id);
            tempReport.name = reportParams.hasOwnProperty("item_name") ? getStringParam(reportParams.item_name) : getStringParam(tempReport.name);
            tempReport.type = reportParams.hasOwnProperty("item_type") ? getStringParam(reportParams.item_type) : getStringParam(tempReport.type);
            tempReport.subType = reportParams.hasOwnProperty("report_subtype") ? getStringParam(reportParams.report_subtype) : getStringParam(tempReport.subType);
            tempReport.dueBy = reportParams.hasOwnProperty("dueBy") ? getStringParam(reportParams.dueBy) : getStringParam(tempReport.dueBy);
            tempReport.fromDate = reportParams.hasOwnProperty("fromDate") ? toDate(getStringParam(reportParams.fromDate)) : getStringParam(tempReport.fromDate);
            tempReport.toDate = reportParams.hasOwnProperty("toDate") ? toDate(getStringParam(reportParams.toDate)) : getStringParam(tempReport.toDate);
            setReport({ ...tempReport });

            let tempObject = reportParams.hasOwnProperty("object") ? getIntParam(reportParams.object) : stateObject;
            let tempFields = reportParams.hasOwnProperty("fields") ? getArrayParam(reportParams.fields) : fields;
            let temprecords = reportParams.hasOwnProperty("records") ? getArrayParam(reportParams.records) : records;
            console.log("tempRecords",temprecords)
            let tempAggregate = reportParams.hasOwnProperty("aggregate") ? reportParams.aggregate : aggregate;
            if (reportParams.hasOwnProperty("aggregate") && isValidParam(reportParams.aggregate)) {
                setHasAggregate(true);
            }

            let tempsortOptions = { ...sortOptions };
            tempsortOptions.columns = reportParams.hasOwnProperty("sortOptions") ? reportParams.sortOptions : sortOptions.columns;
            setStateObject(tempObject);
            setFields(tempFields);
            setRecords(temprecords);
            setAggregate(tempAggregate);
            setSortOptions({ ...tempsortOptions });
        } catch (error) {
            console.error("Error in 'tabularReport.js -> updateState()': " + error);
        }
    };


    const getReportParams = () => {
        let reportParams = {};
        try {
            let { id, dueBy, fromDate, toDate, subType } = report;
            let _totalCount = 0;
            reportParams.id = getIntParam(id);
            reportParams.type = getStringParam(subType);
            reportParams.due_by = getStringParam(dueBy);
            reportParams.start_index = changeLog.startIndex;
            reportParams.page_size = changeSize.pageSize;
            if (dueBy === 'cust' && isValidParam(fromDate) && isValidParam(toDate)) {
                reportParams.from_date = formatDate(fromDate);
                reportParams.to_date = formatDate(toDate);
            }
        } catch (error) {
            console.error("Error in 'tabularReport.js -> getReportParams()':" + error);
        }
        return reportParams;
    }

    const getReport = () => {
        try {
            let totalCount = 0;
            let params = getReportParams();
            if (isValidParam(params)) {
                if (getBooleanParam(isMounted)) {
                    setIsMounted(false);
                }
                let promise = getTabularReportPromise(params);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        updateState(response);
                        setIsMounted(true);
                        totalCount = getIntParam(response.total_count);
                        setTotalCount(totalCount);
                        setSortedColumn(response.sortOptions[0].field)
                        if (response.sortOptions[0].direction === "Descending"){
                            setSortOrder('desc')
                        }
                        
                    }
                });
            }
        }
        catch (error) {
            console.error("Error in 'tabularReport.js ---> getReport()':" + error);
        }
    }

    const handleSortClick = (column) => {
            const newSortOrder = (column.name === sortedColumn && sortOrder === 'asc') ? 'desc' : 'asc';
            setSortedColumn(column.name);
            setSortOrder(newSortOrder);
            onSortFn(column, newSortOrder === 'desc', records);
    };

    const getColumnHeader = (fields) => {
        return fields.map((colData) => {
            let columnHeaderLabel = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(colData.label)
                ? getLocalizedStrings().label.DASHBOARD[colData.label]
                : colData.label;

            if (!colData.is_hidden) {
                let isSorted = colData.name === sortedColumn;
                let isDescending = sortOrder === 'desc';
                let sortIcon = isSorted && isDescending ? descSortIcon : ascSortIcon;
                return (
                    <StyledTableCell
                        key={colData.name}
                        onClick={() => handleSortClick(colData)}
                    >
                        <Tooltip title={columnHeaderLabel} placement="top">
                            <span>{columnHeaderLabel}</span>
                        </Tooltip>
                        {isSorted ? sortIcon : null}
                    </StyledTableCell>
                );
            }
            return null;
        });
    };

    const getRowData = (cols, _recordsData) => {
        return _recordsData?.map((item, index) => {
            let cells = cols.map((colData, columnIndex) => {
                if (!colData.is_hidden) {
                    if (colData.is_link && columnIndex === 0 && index === 0) {
                        return (
                            <div className="lead-score-link">
                                <StyledTableCell>
                                    <Link href="#" className="lead-score" onClick={() => getTd()}>
                                        {item.lead_score}
                                    </Link>
                                </StyledTableCell>
                            </div>
                        );
                    } else {
                        let cellContent = item[colData.name];

                        let textRange = cellContent.length > 13 ? `${cellContent.slice(0, 13)}...` : cellContent;
                        if (columnIndex === 1) {
                            let recordId = getIntParam(item.id);
                            let object = getStringParam(TABLEID_OBJECT_MAP[stateObject]);
                            let value = getStringParam(item[colData.name]).trim().length === 0 ? localizedStrings.label.COMMON.EMPTY : item[colData.name];
                            let linkProps = { children: value };
                            if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                                linkProps.onClick = () => openDrawer(object === constants.APPOINTMENTS ? 'Edit Appoinment' : 'Edit Task', object, recordId);
                            } else {
                                linkProps.onClick = () => openTab(value, '/' + object + '/detailview/' + recordId);
                            }
                            return <StyledTableCell style={{ ...rowCompanyStyle }} title={value}> <Link  {...linkProps}></Link></StyledTableCell >
                        } else {
                            return <StyledTableCell style={{ ...rowStyle }} title={cellContent} > {textRange} </StyledTableCell>
                        }
                    }

                }
            });
            return <StyledTableRow key={item.id}>{cells}</StyledTableRow>;
        });
    };


    const custom = (agrgt, props) => {
        let funcNm = null;
        agrgt = getObjectParam(agrgt);
        if (agrgt.hasOwnProperty("aggregateFunc") && getStringParam(agrgt.hasOwnProperty("aggregateFunc")).length > 0
            && agrgt.hasOwnProperty("value")) {
            funcNm = getStringParam(agrgt.aggregateFunc);
            props.Custom = agrgt.value;
        }
        return (<span>{funcNm}: {props.Custom}</span>)
    }



    const getTd = (td, props, i) => {

        let object = null;
        let recordId = 0;
        let tdContent = null;
        let tableId = stateObject;
        let value = null;
        try {
            tdContent = <StyledTableCell style={{ ...rowStyle }} title={props[td.name]} > {props[td.name]} </StyledTableCell >;
            if (i == 0) {
                recordId = getIntParam(props.id);
                object = getStringParam(TABLEID_OBJECT_MAP[tableId]);
                value = getStringParam(props[td.name]).trim().length === 0 ? localizedStrings.label.COMMON.EMPTY : props[td.name];
                let linkProps = { children: value };
                if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                    linkProps.onClick = openDrawer(object === constants.APPOINTMENTS ? 'Edit Appoinment' : 'Edit Task', object, recordId);
                } else {
                    linkProps.onClick = openTab(value, '/' + object + '/detailview/' + recordId);
                }
                tdContent = <StyledTableCell style={{ ...rowStyle }} title={value}> <a  {...linkProps}></a> </StyledTableCell >
            }
        } catch (error) {
            console.error("Error in 'tabularReport.js ---> getTd()':" + error);
        }
        return tdContent;
    }

    const handleDropDownChange = (name, event, value) => {
        try {
            let tempReport = report;
            tempReport[name] = value;
            if (name === "dueBy") {
                if (event === 'fromDate' || event === 'toDate') {
                    tempReport[name] = 'cust';
                    if (event === 'fromDate') {
                        tempReport.fromDate = value;
                    } else if (event === 'toDate') {
                        tempReport.toDate = value;
                    }
                } else if (value === "cust") {
                    tempReport.fromDate = getDate();
                    tempReport.toDate = getDate();
                } else {
                    tempReport.fromDate = null;
                    tempReport.toDate = null;
                }
            }
            setReport({ ...tempReport });
        } catch (error) {
            console.error("Error in 'tabularReport.js -> handleDropDownChange()':" + error);
        }
    }

    const getDate = (date) => {
        let dateValue = isValidParam(date) ? new Date(date) : new Date();
        dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
        return dateValue;
    }

    const reloadReport = () => {
        getReport();
    }

    const openTab = (label, url) => {
        let objectId = getIntParam(object);
        let objectName = getStringParam(TABLEID_OBJECT_MAP[objectId]);
        let imageName = getImageNameByObject(objectName);
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: imageName, url: url, isActive: true };
        addTab(tab, true);
    }

    const openDrawer = (label, object, id) => {
        let data = { object, id };
        dispatch(getAppDrawer(true, label, LinkToType.TYPE_EDIT, data, null));
    }

    const getMinDate = () => {
        let minDateVal = null;
        if (isValidParam(report.fromDate)) {
            let fromDateVal = new Date(report.fromDate);
            minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
        }
        return minDateVal;
    }

    const getFilterProps = () => {
        let fieldProps = [];

        fieldProps.push({
            date: {
                onChange: (event, value) => handleDropDownChange('dueBy', event, value),
                defaultValue: report.dueBy,
                fromDateOptions: { maxDate: new Date() },
                toDateOptions: { minDate: getMinDate() },
                profile: appState.me,
                fromDate: report.fromDate,
                toDate: report.toDate
            }
        });
        return fieldProps;
    }

    const print = (e) => {

        if (e !== null && e !== undefined) {
            if (getArrayParam(records).length > 0 && getArrayParam(fields).length > 0) {
                let headerComponents = generateHeadersToPrint();
                let rowComponents = generateRowsToPrint();

                let htmlTable = '<table style="border:1px solid black;border-collapse:collapse;"><thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
                let htmlData = htmlTable;
                if (isValidParam(htmlData) && htmlData !== '') {
                    var ifrmae = document.getElementById("ifmcontentstoprintTabularReport");
                    ifrmae.style.display = 'block'
                    var pri = document.getElementById("ifmcontentstoprintTabularReport").contentWindow;
                    ifrmae.style.display = 'block'

                    pri.document.open();
                    pri.document.write(htmlData);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                    ifrmae.style.display = 'none';
                }
            } else {
                dispatch(showCustomSnackBar(localizedStrings.message.COMMON.NO_RECORDS_FOR_PRINT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        }

    }

    const generateHeadersToPrint = () => {
        let cells = '', temp;
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th style="border:1px solid black;"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const generateRowsToPrint = () => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td style="border:1px solid black;">' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '" style="border:1px solid black;"> No data Found  </td>  </tr>');
        }
        return rowData;

    }


    const headerCellInfo = (args) => {

        if (args.cell.column.field !== null) {
            const toolcontent = args.cell.column.headerText;
            const tooltip = new Tooltip({ content: toolcontent });
            tooltip.appendTo(args.node);
        }
    }

    const onDataBound = () => {
        if (gridInstance.current) {
            let pageSize = pageSettings.pageSize;
            let minHeight = 100;
            let rowCount = getArrayParam(gridInstance.current.getCurrentViewRecords()).length;
            let contentHeight = (rowCount * 10) < minHeight ? minHeight : (rowCount * 10);
            gridInstance.current.height = rowCount < pageSize ? contentHeight : gridInstance.current.height;
        }
    }

    const getPaginationData = (object, params) => {
        try {
            if (isValidParam(params) !== null) {
                let startIndex = params.hasOwnProperty("start_index") ? getIntParam(params.start_index) : 0;
                let _pageSize = params.hasOwnProperty("page_size") ? getIntParam(params.page_size) : 50;
                setStartIndex(startIndex);
                let log = changeLog;
                log.startIndex = startIndex;
                setchangeLog({ ...log });
                let temp = changeSize;
                temp.pageSize = _pageSize;
                setChangeSize(temp);
                reloadReport();
            }
        }
        catch (error) {
            console.error("Error in 'tabularReport.js -> getPaginationData()':" + error);
        }
    }

    const excelGenerateBeforeHeaders = (rows,columns) => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(_selectedReport.item_name);
        let tempColumns = getArrayParam(columns);
        let _columns = tempColumns.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(_columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[report.dueBy]]);
            if(dueBy === 'Custom'){
                let { fromDate, toDate} = report;
                let start_date = moment(fromDate);
                start_date = start_date.format('DD/MM/YYYY');
                let end_date = moment(toDate);
                end_date = end_date.format('DD/MM/YYYY')
                cells = cells +'<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>'+ '<tr><td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.START_DATE + ':</td>  <td style="text-align:center;">' + start_date + ' </td>  <td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.END_DATE + ':</td> <td style="text-align:center;">' + end_date + ' </td></tr>';
            }else{
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
        }
        return cells;
    }

    const excelGenerateHeaders = (rows,columns) => {
        let cells = '';
        let reportName = getStringParam(_selectedReport.item_name);
        let tempColumns = getArrayParam(columns);
        let _columns = tempColumns.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        _columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = (rows,columns) => {
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }



    const downloadExcelReport = (e) => {
        if (e !== null && e !== undefined) {
            if(records.length > 0){
                let reportParams = {};
                setIsMounted(false);
                let { id, dueBy, fromDate, toDate, subType } = report;
                reportParams.id = getIntParam(id);
                reportParams.type = getStringParam(subType);
                reportParams.due_by = getStringParam(dueBy);
                reportParams.start_index = 0;
                reportParams.page_size = totalCount;
                if (dueBy === 'cust' && isValidParam(fromDate) && isValidParam(toDate)) {
                    reportParams.from_date = formatDate(fromDate);
                    reportParams.to_date = formatDate(toDate);
                }
                let promise = getTabularReportPromise(reportParams);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setIsMounted(true);
                        let rows = response.records;
                        let columns = response.fields;
                        let beforeHeaderComponent = excelGenerateBeforeHeaders(rows,columns);
                        let headerComponents = excelGenerateHeaders(rows,columns);
                        let rowComponents = excelGenerateRows(rows,columns);
                        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
                        let htmlData = htmlTable;
                        if (isValidParam(htmlData) && htmlData !== '') {
                            htmlData = htmlData.replace("%22", "\"");
                            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                            var downloadUrl = URL.createObjectURL(blob);
                            var a = document.createElement("a");
                            a.href = downloadUrl;
                            a.download = 'ResultSet_' + new Date().getTime() + '.xls';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                        }
                    }
                })
            }else{
                dispatch(showCustomSnackBar(localizedStrings.message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        }
    }



    let data = {};
    data.object = object;
    data.total_count = totalCount;
    data.page_size = changeSize.pageSize;
    let listviewData = {};
    listviewData.data = data;

    let columnHeader = null;
    let _isMounted = getBooleanParam(isMounted);
    let temphasAggregate = getBooleanParam(hasAggregate);
    let tempAggregate = null;
    let _records = null;
    let _fields = null;
    let _sortOptions = null;
    let showDateFilter = null;
    if (_isMounted) {
        _records = getRowData(fields, records);
        _fields = getArrayParam(fields);
        columnHeader = getColumnHeader(_fields);
        showDateFilter = getStringParam(report.dueBy).length > 0 ? true : false;
        if (temphasAggregate) {
            tempAggregate = aggregate;
        }
        _sortOptions = sortOptions;
    }
    console.log("totalCount", totalCount)
    return (
        <div style={{ width: '97%', marginLeft: 20 }}>
            {_isMounted && (
                <ReportFilter reportType={"Tabular Report"} isShowExcel={true} fieldProps={getFilterProps()} reload={() => reloadReport()} printEvent={(e) => print(e)} downloadExcel={(e) => downloadExcelReport(e)}/>)}
            {_isMounted && (
                <iframe
                    id="ifmcontentstoprintTabularReport"
                    title="ifmcontentstoprintTabularReport"
                    style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}
                ></iframe>
            )}
            {_isMounted ? (
                <div id="tabularReportContents" style={{ width: "100%", float: 'left', paddingBottom: '20px', overflow: 'hidden' }}>
                    {(records && records.length > 0) && (columnHeader && columnHeader.length > 0) ? (
                        <div className="col-md-12" style={{ width: '100%' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="customized table">
                                    <TableHead align="left">
                                        {columnHeader}
                                    </TableHead>
                                    <TableBody>
                                        {_records}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <NoRecordContent label={localizedStrings.label.INXPRESS_REPORT.NO_RECORDS_FOUND} />
                    )}
                </div>
            ) : (
                <Loader />
            )}
            {(records && records.length > 0) && (
                <div style={{ width: '100%', paddingTop: '13px', paddingBottom: '10px', marginLeft: '-45px', float: 'right' }}>
                    <Pagination
                        totalRecordCount={totalCount}
                        object={object}
                        listView={listviewData}
                        isLookup={false}
                        listViewCallBack={(object, params) => getPaginationData(object, params)}
                    />
                </div>

            )}

        </div>

    );


}


const StylePager = () => {
    return <style>
        {".e-pager .e-currentitem, .e-pager .e-currentitem:hover {background: #e50914;}"
            + ".e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover {color : #e50914 }"
            + ".e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,"
            + ".e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,"
            + " .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,"
            + " .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {background : #e50914 }"
            + " .e-columnheader .e-headercell .e-headercelldiv  .e-headertext"
            + "{text-overflow: ellipsis; white-space: nowrap;overflow: hidden;font-weight: bold;"
            + " text-transform: capitalize; font-size: 14px; color: #333; text-align:right; }"
        }
    </style>

}
export default TabularReport;

const NoRecordContent = (props) => {
    return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{isValidParam(props) ? props.label : null}</span></td></tr></table>);
}

const Loader = () => {
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return <div style={{ width: '100%', height: contentHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>
}

const rowStyle = {
    padding: '1px 20px 0px',
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    fontSize: '14px',
    textAlign: 'left',
    width: 120,
    color: 'rgb(98, 100, 100)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0,
    minWidth: '20px'
}

const rowCompanyStyle = {
    ...rowStyle,
    cursor: 'pointer'
}
