import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam, getStringParam, getObjectParam, getArrayParam, isEmail, isEmpty, getIntParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, TYPE_DETAIL_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import ShowCircularProgress from '../components/circularProgress';
import { Select, Button, TextField, FormControl, MenuItem, InputLabel, Checkbox, ListItemText } from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { phoneFormat, dateFormat } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";
import { getWorkflowList, getWorkflowStageById } from '../../../services/actions/importAction';
import PopOver from './PopOver';
import moment from 'moment';
import { refreshDetailViewData, refreshDetailViewListViewData,detailViewIsMounted,
	getDetailviewData,
	isDetailViewSet, } from '../../../services/actions/detailViewActions';
import { refreshColumnView } from '../../../services/actions/listsActions';
import { getTasksRefresh, getUnitTasksRefresh } from '../../../services/actions/taskActions';


const AddUnits = (props) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);

    const [first_name, setFirstName] = useState(null);
    const [last_name, setLastName] = useState(null);
    const [password, setPassword] = useState(null);
    const [retype_password, setRetypePassword] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [company, setCompany] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [allTimeZones, setAllTimeZones] = useState({});
    const [allRoyaltyPlans, setAllRoyaltyPlans] = useState({});
    const [selectedPlanNames, setSelectedPlanNames] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [states, setStates] = useState([]);
    const [faExpirationDate, setFAExpirationDate] = useState(null);
    const [timeZoneMenuItems, setTimeZoneMenuItems] = useState([]);
    const [countryMenuItems, setCountryMenuItems] = useState([]);
    const [stateMenuItems, setStateMenuItems] = useState([]);
    const [id, setId] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [form, setForm] = useState({status:null});
    const [inProgress, setInProgress] = useState(false);
    const [workflowList, setWorkflowList] = useState(null);
    const [workflowMounted, setWorkflowMounted] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [statusMounted, setStatusMounted] = useState(false);
    const [tempStatusList, setTempStatusList] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [workflowName, setWorkflowName] = useState(null);
    const [isShrinkStatus, setIsShrinkStatus] = useState(false);
    const [defaultSelectedStatus, setDefaultSelectedStatus] = useState(null);
    const [selectedAf, setSelectedAf] = useState(0);
    const [workflowMenuItems, setWorkflowMenuItems] = useState([]);
    const [statusMenuItems, setStatusMenuItems] = useState([]);
    const [selectedMUValue, setSelectedMuValue] = useState(0)
    const [changeLog, setChangeLog] = useState({ muList: [] })

    useEffect(() => {
        getDetails();
    }, [inProgress]);

    useEffect(() => {
        if (allTimeZones != null && allTimeZones != "") {
            setTimeZoneMenuItems(getTimezoneOptionsList(allTimeZones));
            setTimezone(app.me.timezone);
        }
    }, [allTimeZones]);

    useEffect(() => {
        if (countryList != null && countryList != "") {
            setCountryMenuItems(getCountryOptionsList(countryList));
        }
    }, [countryList]);

    useEffect(() => {
        if (country != null && country != "") {
            getStateList(country);
        }
    }, [country]);

    useEffect(() => {
        if (states != null && states != "") {
            setStateMenuItems(getStateOptionsList(states));
            setMounted(true);
        }
    }, [states]);

    useEffect(() => {
        if (workflowList != null && workflowList != "") {
            setWorkflowMenuItems(generateWorkflowList(workflowList));
            setWorkflowName(workflowList[0].name);
            setSelectedWorkflow(workflowList[0].id);
            getWorkStageByWorkflowId(workflowList[0].id);
        }
    }, [workflowList]);

    useEffect(() => {
        if (tempStatusList != null && tempStatusList != "") {
            setStatusMenuItems(generateStatusList(tempStatusList));
            setStatusMounted(true);
        }
    }, [tempStatusList]);

    const getDetails = () => {
        try {
            getWorkflowListFn();
            setCountryList(getArrayParam(app.countryISO2));
            setFirstName(props.data.records?.first_name);
            setLastName(props.data.records?.last_name);
            setEmail(props.data.records?.email);
            setPhone(props.data.records?.phone);
            setCompany(props.data.records?.company);
            setFAExpirationDate(props.data.records?.fa_expiration_date);
            let _country = props.data.records?.country;
            if (_country === null || _country === "" || _country === undefined) {
                _country = app.me.tenant_country;
            }
            setCountry(_country);
            setState(props.data.records?.state)
            populateMenus();
        } catch (error) {
            console.error("Error in 'addUnits.js -> getDetails()':" + error);
        }
    }

    const getAfList = () => {
        let menuItems = [];
        let afList = props.data?.AfInfo;
        if (afList !== null && afList?.length > 0) {
            afList.map((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.projectId} id={fieldOption.projectId} title={fieldOption.companyName} key={fieldOption.projectId} style={styles.popoverMenuItem} >{fieldOption.companyName} </MenuItem>);
            });
        }
        return menuItems;
    }
    const getMUList = () => {
        let menuItems = [];
        let mulist = changeLog.muList
        if (mulist !== null && mulist?.length > 0) {
            mulist.map((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.projectId} id={fieldOption.projectId} title={fieldOption.companyName} key={fieldOption.projectId} style={styles.popoverMenuItem} >{fieldOption.companyName} </MenuItem>);
            });
        }
        return menuItems;
    }
    const onChangeAFFiled = (event) => {
        let val = event.target.value;
        setSelectedAf(val);
        setSelectedMuValue(0)
        let url = endPoints.UNITS.GET_MU_LIST;
        let params = {};
        params.selectedAF = val;
        let promise = Promise.resolve(HTTPClient.get(url, params));;
        if (isValidParam(promise)) {
            promise.then((response) => {
                let arr = response;
                let temp = changeLog;
                temp.muList = arr;
                setChangeLog({ ...temp });
            });
        }

    }

    const onChangeMUField = (event) => {
        let val = event.target.value;
        setSelectedMuValue(val)
    }
    const getCountryOptionsList = (countryList) => {
        let menuItems = [];
        countryList = getArrayParam(countryList);
        if (countryList !== null && countryList.length > 0) {
            countryList.map((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2} style={styles.popoverMenuItem} >{fieldOption.t_country} </MenuItem>);
            });
        }
        return menuItems;
    }

    const getTimezoneOptionsList = (timeZoneMap) => {
        let menuItems = [];
        timeZoneMap = getObjectParam(timeZoneMap)
        if (Object.keys(timeZoneMap).length > 0) {
            Object.keys(timeZoneMap).map((key, i) => {
                menuItems.push(<MenuItem value={key} id={key} title={timeZoneMap[key]} key={key} style={styles.popoverMenuItem} >{timeZoneMap[key]}</MenuItem>);
            });
        }
        return menuItems;
    }

    const getStateOptionsList = (stateList) => {
        let menuItems = [];
        stateList = getArrayParam(stateList)
        if (stateList.length > 0) {
            stateList.map((s, i) => {
                menuItems.push(<MenuItem value={s.id} id={s.id} title={s.value} key={s.id} style={styles.popoverMenuItem} >{s.value}</MenuItem>);
            });
        }
        return menuItems;
    }

    const populateMenus = () => {
        let url = endPoints.UNITS.ADD_UNIT_FORM_DETAILS_GET;
        let params = {};
        let promise = Promise.resolve(HTTPClient.get(url, params));;
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setAllTimeZones(response.allTimeZones);
                    setAllRoyaltyPlans(response.allRoyaltyPlans);
                }
            });
        }
    }

    const getStateList = (value) => {
        try {
            let params = {};
            params.object = constants.ACCOUNTS_OBJECT;
            params.name = 'state';
            params.value = value;
            params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

            var promise = SFFormActions.getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    setStates(getArrayParam(response));
                }
            });
        } catch (error) {
            console.error("Error in 'addUnits.js -> getStateList()':" + error);
        }
    }

    const onChangeTextField = (event, field) => {
        let value = event.target.value
        if (field === 'first_name') {
            setFirstName(value);
        } else if (field === 'last_name') {
            setLastName(value);
        } else if (field === 'password') {
            setPassword(value);
        } else if (field === 'retype_password') {
            setRetypePassword(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'phone') {
            setPhone(phoneFormat(value));
        } else if (field === 'company') {
            setCompany(value);
        }
    }

    const setSelectedTimeZoneValue = (event) => {
        let value = event.target.value;
        setTimezone(value);
    }

    const setSelectedCountryValue = (event) => {
        let value = event.target.value;
        setCountry(value);
        //getStateList(value);
    }

    const setSelectedStateValue = (event) => {
        let value = event.target.value;
        setState(value);
    }

    const validateForm = () => {
        try {
            let isValidRequest = true;
            let fa_Expiration_Date = new Date();
            let tempfaExpirationDate = null;
            fa_Expiration_Date = new Date(fa_Expiration_Date.getFullYear(), fa_Expiration_Date.getMonth(), fa_Expiration_Date.getDate());

            const momentDate = moment(fa_Expiration_Date);
            if (moment !== null && moment !== "") {
                fa_Expiration_Date = momentDate.format("YYYY-MM-DD");
            }
            if (faExpirationDate !== null && faExpirationDate !== undefined && faExpirationDate !== '') {
                tempfaExpirationDate = moment(faExpirationDate).format("YYYY-MM-DD");
            }
            if (!isValidParam(first_name) || isEmpty(getStringParam(first_name))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_FIRST_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(last_name) || isEmpty(getStringParam(last_name))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_LAST_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(password) || isEmpty(getStringParam(password))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(retype_password) || isEmpty(getStringParam(retype_password))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }


            if (getStringParam(password).length > 0 && getStringParam(retype_password).length > 0) {
                if (!password.replace(/\s/g, '').length) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (!retype_password.replace(/\s/g, '').length) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (retype_password !== password) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNMATCH_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }

            if (!isValidParam(email) || isEmpty(getStringParam(email))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (isValidParam(email) && !isEmail(email)) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
            if (!isValidParam(phone) || isEmpty(getStringParam(phone))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PHONE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(company) || isEmpty(getStringParam(company))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_COMPANY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            /*if (!isValidParam(selectedPlanNames) || isEmpty(getStringParam(selectedPlanNames))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PLAN_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }*/

            if (!isValidParam(faExpirationDate) || isEmpty(getStringParam(faExpirationDate))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_FA_EXPIRATION_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!(tempfaExpirationDate >= fa_Expiration_Date)) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_FA_EXPIRATION_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;

            }

            if (isValidRequest) {
                checkDuplicateEmail();
            }
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> validateForm()':" + error);
        }

    }
    const doSave = () => {
        try {
            setForm({...form, status:'updating'})
            let currentDate = new Date().toJSON().slice(0, 10);
            setInProgress(true);
            let arrayStatusList = tempStatusList;
            let statusObj;
            if (isValidParam(arrayStatusList) && arrayStatusList.length > 0) {
                statusObj = arrayStatusList.filter(f => { return f.name === selectedStatus });
            }
            let _first_name = getStringParam(first_name).trim();
            let _last_name = getStringParam(last_name).trim();
            let _password = getStringParam(password).trim();
            let _retype_password = getStringParam(retype_password).trim();
            let _email = getStringParam(email).trim();
            let _phone = getStringParam(phone).trim();
            let _company = getStringParam(company).trim();
            let _timezone = getStringParam(timezone).trim();
            let _country = getStringParam(country).trim();
            let _state = getStringParam(state).trim();
            const _selectedPlanNames = allRoyaltyPlans?.filter(item => selectedPlanNames.includes(item.id)).map(item => `${item.name}:${currentDate}`);
            let _plan_name = _selectedPlanNames
            let franchise_industry = getStringParam(app.me.franchise_industry) == "" ? props.data?.baseIndustry.company : getStringParam(app.me.franchise_industry);
            let franchise_priceid = (getStringParam(app.me.franchise_priceid) == "" || getStringParam(app.me.franchise_priceid) == "0" || getStringParam(app.me.franchise_priceid) == 0)
                ? "99" : getStringParam(app.me.franchise_priceid);
            let accountFieldObj = sfForm.data[constants.ACCOUNTS_OBJECT];
            let allFields = accountFieldObj.fields;
            let fields = [];
            let params = {};
            params.first_name = _first_name;
            params.last_name = _last_name;
            params.password = _password;
            params.retype_password = _retype_password;
            params.email = _email;
            params.phone = _phone;
            params.company = _company;
            params.timezone = _timezone;
            params.country = _country;
            params.state = _state;
            if (_plan_name?.length > 0) {
                params.plan_name = _plan_name.join(',');
            }
            params.franchise_industry = franchise_industry;
            params.franchise_priceid = franchise_priceid;
            params.change_workflow_name = workflowName;
            params.workflow_id = selectedWorkflow;
            params.stage = selectedStatus;
            params.changed_status = selectedStatus;
            params.child_status = selectedStatus;

            params.click_form = props.data.click_form;
            params.is_applyworkflow = true;
            params.id = props.data.accountRecordId;


            allFields.forEach(inputField => {
                let tempField = {};
                tempField.id = inputField.id;
                tempField.name = inputField.name;
                if (inputField.name === 'company') {
                    tempField.value = _company;
                    fields.push(tempField);
                } else if (inputField.name === 'last_name') {
                    tempField.value = _last_name;
                    fields.push(tempField);
                } else if (inputField.name === 'first_name') {
                    tempField.value = _first_name;
                    fields.push(tempField);
                } else if (inputField.name === 'email') {
                    tempField.value = _email;
                    fields.push(tempField);
                } else if (inputField.name === 'phone') {
                    tempField.value = _phone;
                    fields.push(tempField);
                } else if (inputField.name === 'country') {
                    tempField.value = _country;
                    fields.push(tempField);
                } else if (inputField.name === 'state') {
                    tempField.value = _state;
                    fields.push(tempField);
                } else if (inputField.name === 'industry') {
                    tempField.value = franchise_industry;
                    fields.push(tempField);
                } else if (inputField.name === 'workflow_name') {
                    tempField.value = workflowName;
                    fields.push(tempField);
                } else if (inputField.name === 'workflow_id') {
                    tempField.value = selectedWorkflow;
                    fields.push(tempField);
                } else if (inputField.name === 't_status') {
                    tempField.value = selectedStatus;
                    fields.push(tempField);
                } else if (inputField.name === 'visible_to') {
                    tempField.value = "Just me";
                    fields.push(tempField);
                } else if (inputField.name === 'plan_name') {
                    if (_plan_name != null && _plan_name != undefined && _plan_name != "") {
                        tempField.value = _plan_name.join(',');
                        fields.push(tempField);
                    }
                } else if (inputField.name === 'fa_expiration_date') {
                    if (faExpirationDate !== null && faExpirationDate !== undefined && faExpirationDate !== '') {
                        tempField.value = moment(faExpirationDate).format("YYYY-MM-DD");
                        fields.push(tempField);
                    }
                } else if(inputField.name === 't_type') {
                    if(props.data?.baseValue && 
                        (props.data?.baseValue == "ML" || props.data?.baseValue == "AF" ||
                        props.data?.baseValue === "MU" || props.data?.baseValue === "Store")) {
                            tempField.value = props.data.baseValue;
                        }  else {
                            tempField.value = "Franchisee";
                        }
                        fields.push(tempField);    
                } 
            });
            params.fields = fields;
            params.is_applyworkflow = true;
            params.id = props.data.accountRecordId;
            params.type = props.data?.baseValue;
            params.afProjectId = selectedAf;
            params.muProjectId = selectedMUValue;
            if (props.data.click_form === 'Convert') {
                params.cancel_email_SMS_list = props.data.cancel_email_SMS_list;
                //params.workflow_id = selectedWorkflow;
                params.stage_id = statusObj[0].id;
                params.current_workflow_name = props.data.current_workflow_name;
                params.pending_task = props.data.pending_task;
                params.current_status = props.data.current_status;
                params.is_create_next_task = props.data.is_create_next_task;
                params.note_text = props.data.note_text;
                params.child_tableid = props.data.child_tableid;
                params.id = [props.data.accountRecordId];
                params.mainContactId = props.data.mainContactId;
            }

            let url = endPoints.UNITS.SAVE_UNIT;
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (response.status === 0) {
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNIT_SAVED, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));            
                    dispatch(getAppDrawer(false, null, null, null, null));

                    if (props.data.click_form === "addunit") {
                        let _id = response.data.recordId;
                        let label = _company;
                        label = getStringParam(label);
                        openTab(label, 'accounts', _id);
                    } else if (props.data.callFrom === "Detailview") {
                       dispatch(getTasksRefresh(true));
                       dispatch(refreshDetailViewListViewData(true));
                       dispatch(refreshDetailViewData(true));
                       dispatch(getUnitTasksRefresh(true));
                    } else if (props.data.callFrom === "columnview") {
                        refreshColumnView(constants.ACCOUNTS_OBJECT);
                    } else if (props.data.callFrom === constants.COLUMNVIEW_DRAGDROP) {
                        refreshColumnView(constants.ACCOUNTS_OBJECT);
                    } 
                } else {
                    setForm({...form, status:null})
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNIT_NOT_SAVED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                setInProgress(false);
            });
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> doSave()':" + error);
        }
    }

    const openTab = (label, object, recordId) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    const checkDuplicateEmail = () => {
        setForm({...form, status:'updating'})
        try {
            let Url = endPoints.UNITS.CHECK_DUPLICATE_TENANT_EMAIL;
            let searchParams = {};
            searchParams.email = getStringParam(email);
            let promise = Promise.resolve(HTTPClient.get(Url, searchParams));
            promise.then((response) => {
                if (isValidParam(response) && response.isExist) {
                    setForm({...form, status:null})
                    let msg = "This email address is not available. Please try another email address.";
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    doSave();
                }
            });

        } catch (error) {
            console.error("Error in 'addUnits.js -> checkDuplicateEmail()':" + error);
        }
    }



    const closeDrawer = () => {
        try {
            props.closeDrawer();
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> closeDrawer()':" + error);
        }
    }

    const getWorkflowListFn = () => {
        let promise = Promise.resolve(getWorkflowList(constants.ACCOUNTS_OBJECT));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setWorkflowMounted(true);
                    let arrayWorkflowList = getArrayParam(response);
                    arrayWorkflowList = arrayWorkflowList.filter(e => (e.name === 'Onboarding' || e.name === 'Franchisee'));
                    setWorkflowList(arrayWorkflowList);
                }
            });
        }
    }
    const generateWorkflowList = () => {
        let _workflowList = null;
        let arrayWorkflowList = getArrayParam(workflowList);
        if (isValidParam(arrayWorkflowList) && arrayWorkflowList.length > 0) {
            _workflowList = arrayWorkflowList.map((objWorkflow, i) => {
                return (
                    <MenuItem key={`workflow-list-${i}`} value={objWorkflow.id}>{objWorkflow.name}</MenuItem>
                );
            });
        }

        return _workflowList;
    }
    const getWorkStageByWorkflowId = (value) => {
        let params = {
            workflow_id: value
        }
        let promise = Promise.resolve(getWorkflowStageById(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    response.stages.map((objStatus, i) => {
                            if (i === 0) {
                                setDefaultSelectedStatus(objStatus.name);
                                setSelectedStatus(objStatus.name);
                            }
                    });
                    setTempStatusList(response.stages);
                }
            });
        }
    }
    const changeWorkflow = (event) => {
        let value = event.target.value;
        setSelectedWorkflow(value);
        setIsShrinkStatus(true);

        let selectedWF = null
        let arrayWorkflowList = getArrayParam(workflowList);
        if (isValidParam(arrayWorkflowList) && arrayWorkflowList.length > 0) {
            selectedWF = arrayWorkflowList.filter(f => { return f.id === value });
        }
        setSelectedWorkflow(value);
        if (isValidParam(selectedWF)) {
            setWorkflowName(selectedWF[0].name)
        }
        if (value > 0) {
            getWorkStageByWorkflowId(value);
        } else {
            setStatusMounted(false);
            setSelectedWorkflow(null);
        }

    }

    const generateStatusList = () => {
        let statusList = null;
        let arrayStatusList = tempStatusList;
        if (isValidParam(arrayStatusList)) {
            statusList = arrayStatusList.map((objStatus, i) => {

                return (
                    <MenuItem key={`workflow-status-list-${i}`} value={objStatus.name} >{objStatus.name}</MenuItem>
                );
            });
        }

        return statusList;
    }

    const changeStatus = (event) => {
        let value = event.target.value;
        setSelectedStatus(value);
    }

    const setMultiSelectValue = (event) => {
        setSelectedPlanNames(event.target.value);
    };
    
    function handleFlatpkrDateChange(date) {
        try {
            if (isValidParam(date)) {
                let selectedDate = new Date(date);
                let dateValue = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                );
                const momentDate = moment(dateValue);

                setFAExpirationDate(momentDate.format(dateFormat[app.me.date_format]));

            } else {
                setFAExpirationDate("");
            }
        } catch (error) {
            console.error(
                "Error in 'RoyaltyReportV2.js -> handleFlatpkrDateChange()':" + error
            );
        }
    }
    if (mounted && statusMounted && !inProgress) {
        let _phone = phoneFormat(phone);
        let workflowLabel = getLocalizedStrings().label.IMPORT.WORKFLOW;
        let multiValue = [];
        if (selectedPlanNames !== "") {
            if (selectedPlanNames.indexOf(",") > -1) {
                multiValue = selectedPlanNames.split(",");
            } else {
                multiValue = selectedPlanNames;
            }
        }
        return (
            <div style={{ marginBottom: '10px', paddingLeft: '3%', width: '95%' }}>
                <div className="row">
                    <div className='col-sm-12'>
                        <div style={{ fontSize: '24px', paddingBottom: '12px' }}> {getLocalizedStrings().label.UNITS.UNIT_DETAILS_LABEL}</div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        {selectedWorkflow != null && <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel shrink id="sf-addunites-from-simple-select-outlined-label" className="lineHeight_0_5 sf-addunites-from-sfform">{workflowLabel}</InputLabel>
                            <Select
                                labelId='sf-label-workflow'
                                id='sf-workflow'
                                value={selectedWorkflow}
                                onChange={(e) => changeWorkflow(e)}
                                label={workflowLabel}
                                style={{ height: '37px' }}
                                className={"sf-fields-bg"}
                            >
                                {workflowMenuItems}
                            </Select>
                        </FormControl>}
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        {defaultSelectedStatus != null && <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel shrink id="sf-addunites-from-simple-select-outlined-label" className="lineHeight_0_5 sf-addunites-from-sfform">
                                {getLocalizedStrings().label.IMPORT.STATUS}
                            </InputLabel>
                            <Select
                                name='sf-status'
                                key='sf-status'
                                id='sf-status'
                                variant="outlined"
                                value={selectedStatus === null ? defaultSelectedStatus : selectedStatus}
                                onChange={(e) => changeStatus(e)}
                                label={getLocalizedStrings().label.IMPORT.STATUS}
                                className={"sf-fields-bg"}
                                style={{ height: '37px' }}
                                inputprops={{
                                    name: 'Status_Label',
                                    id: 'outlined_Status_Label',
                                }}
                            >
                                {statusMenuItems}
                            </Select>
                        </FormControl>}
                    </div>
                </div>

                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="first_name"
                                id="first_name"
                                label={getLocalizedStrings().label.UNITS.FIRST_NAME}
                                fullWidth={true}
                                value={first_name}
                                onChange={(e) => onChangeTextField(e, 'first_name')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>

                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="last_name"
                                id="last_name"
                                label={getLocalizedStrings().label.UNITS.LAST_NAME}
                                fullWidth={true}
                                value={last_name}
                                onChange={(e) => onChangeTextField(e, 'last_name')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="password"
                                id="password"
                                type="password"
                                inputStyle={styles.textField.input}
                                style={{ ...styles.sfFormFieldContainer, borderRadius: '3px' }}
                                label={getLocalizedStrings().label.UNITS.PASSWORD}
                                fullWidth={true}
                                onPaste={function (e) { e.preventDefault(); }}
                                value={password}
                                onChange={(e) => onChangeTextField(e, 'password')}
                                inputprops={{ maxlength: '32' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="retype_password"
                                id="retype_password"
                                type="password"
                                label={getLocalizedStrings().label.UNITS.RETYPE_PASSWORD}
                                fullWidth={true}
                                onPaste={function (e) { e.preventDefault(); }}
                                value={retype_password}
                                onChange={(e) => onChangeTextField(e, 'retype_password')}
                                inputprops={{ maxlength: '32' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="email"
                                id="email"
                                label={getLocalizedStrings().label.UNITS.EMAIL}
                                fullWidth={true}
                                value={email}
                                onChange={(e) => onChangeTextField(e, 'email')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="phone"
                                id="phone"
                                label={getLocalizedStrings().label.UNITS.PHONE}
                                fullWidth={true}
                                value={_phone}
                                onChange={(e) => onChangeTextField(e, 'phone')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="company"
                                id="company"
                                label={getLocalizedStrings().label.UNITS.COMPANY}
                                fullWidth={true}
                                value={company}
                                onChange={(e) => onChangeTextField(e, 'company')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '13px' }}></div>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel id="sf-addunites-from-simple-select-outlined-label" className="sf-addunites-from-sfform">{getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}</InputLabel>
                            <Select
                                label={getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}
                                labelId='sf-label-time-zone'
                                id='sf-time-zone'
                                value={timezone}
                                onChange={(e) => setSelectedTimeZoneValue(e)}
                                style={{ height: '37px' }}
                                className={"sf-fields-bg"}
                            >
                                {timeZoneMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-sffromaddunits-simple-select-outlined-label" className="sf-add-units-from-sffromaddunits">{getLocalizedStrings().label.UNITS.COUNTRY_SELECT}</InputLabel>
                            <Select
                                labelId='sf-label-country'
                                id='sf-country'
                                label={getLocalizedStrings().label.UNITS.COUNTRY_SELECT}
                                value={country}
                                onChange={(e) => setSelectedCountryValue(e)}
                                style={{ height: '38px' }}
                                className={"sf-fields-bg"}
                            >
                                {countryMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.UNITS.STATE_SELECT}</InputLabel>
                            <Select
                                labelId='sf-label-menu'
                                id='sf-menu'
                                label={getLocalizedStrings().label.UNITS.STATE_SELECT}
                                value={state}
                                onChange={(e) => setSelectedStateValue(e)}
                                autowidth={"true"}
                                style={{ height: '38px' }}
                                className={"sf-fields-bg"}
                            >
                                {stateMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ margin: "15px 0", width: "100%" }} className={"sf-form-field dateTimeDiv-fa-expiration-date"} id="dateholder">
                            <PopOver
                                id={'field-ref-fa-expiration-date'}
                                key={"fa-expiration-date"}
                                name={"fa-expiration-date"}
                                btnType={"date"}
                                buttonOuterLabelStyle={{ backgroundColor: '#fff', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, position: 'absolute' }}
                                buttonStyle={{ fontSize: "16px" }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.UNITS.FA_EXPIRATION_DATE}
                                value={faExpirationDate}
                                onChange={(date) => handleFlatpkrDateChange(date)}
                                innerLabelStyle={{
                                    padding: "11 5 2 5",
                                    fontSize: "15px",
                                    // cursor: true ? "not-allowed" : "pointer",
                                    // color: true ? "#C6C6C6" : "default",
                                }}
                                height={40}
                            // disable={true}
                            />
                        </div>
                    </div>
                        <div className='col-sm-6'>
                        <div style={{ fontSize: '12px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '15px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ lineHeight: '0.9' }}>{getLocalizedStrings().label.UNITS.PLAN_NAME}</InputLabel>
                            <Select
                                key={"plan_name"}
                                id={"idplan_name"}
                                multiple
                                label={getLocalizedStrings().label.UNITS.PLAN_NAME}
                                value={selectedPlanNames}
                                onChange={(e) => setMultiSelectValue(e)}
                                style={{ height: "40px", marginTop: "2px" }}
                                className={"sf-fields-bg"}
                                renderValue={(selected) => selected.map(optionId =>
                                    allRoyaltyPlans.find(option => option.id === optionId).name
                                ).join(', ')}
                                variant="outlined"
                            >
                                {allRoyaltyPlans.map((listValue, listValueIndex) => {
                                    return (
                                        <MenuItem
                                            id={listValue.id}
                                            key={listValue.id}
                                            value={listValue.id}
                                            title={listValue.name}
                                        >
                                            <Checkbox
                                                checked={selectedPlanNames.includes(listValue.id)}
                                            />
                                            <ListItemText primary={listValue.name} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    
                 
                </div>

                {
                    props.data.baseValue == "MU" &&
                    <div className="row">

                        <div className='col-sm-6'>
                            <div style={{ fontSize: '12px' }}></div>
                            <FormControl style={{ width: '100%', height: '38px', marginTop: '15px' }} variant="outlined">
                                <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ lineHeight: '0.9' }}>AF</InputLabel>
                                <Select
                                    key={"AF"}
                                    id={"AF"}
                                    label="AF"
                                    value={selectedAf}
                                    onChange={(e) => onChangeAFFiled(e)}
                                    style={{ height: "40px", marginTop: "2px" }}
                                    className={"sf-fields-bg"}
                                    variant="outlined"
                                >
                                    {getAfList()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                {props.data.baseValue == "Store" &&
                    <div className="row">
                        <div className='col-sm-6'>
                            <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                            <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                                <InputLabel id="sf-add-units-from-sffromaddunits-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>AF</InputLabel>
                                <Select
                                    labelId='sf-label-country'
                                    id='sf-country'
                                    label="AF"
                                    value={selectedAf}
                                    onChange={(e) => onChangeAFFiled(e)}
                                    style={{ height: '38px' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getAfList()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-sm-6'>
                            <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                            <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                                <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>MU</InputLabel>
                                <Select
                                    labelId='sf-label-menu'
                                    id='sf-menu'
                                    label="MU"
                                    value={selectedMUValue}
                                    onChange={(e) => onChangeMUField(e)}
                                    autowidth={"true"}
                                    style={{ height: '38px' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getMUList()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }

                <div style={{ paddingTop: '10px' }}>
                    <span>{props.data.billingMessage}</span>
                </div>
                <div className="row" style={{ float: "right" }}>
                    <div style={{ float: 'right', paddingRight: '10px', paddingBottom: '30px', paddingTop: '15px' }}>
                        <Button
                            key='save'
                            onClick={() => validateForm()}
                            style={{ ...styles.primaryButton }}
                            disabled={form.status ==='updating'}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        <Button
                            key='close'
                            onClick={() => closeDrawer()}
                            style={{ ...styles.secondaryButton }}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>

                    </div>
                </div>
            </div>
        );

    } else {
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    };
};

export default AddUnits;
