import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styles} from '../../../services/constants/styles';
import {getArrayParam, isValidParam} from '../../../services/helper/parameterVerifier';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {getAppDialog} from '../../../services/actions/appContainerActions';
import {updateAuditCategory, updateAuditCategorySelectedValue} from '../../../services/actions/auditActions';
import {endPoints} from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import {Button, FormControl, TextField} from '@mui/material';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';


const AddCategoryDialog = () => {
    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);
    const auditDetails = useSelector((state) => state.auditDetails);

    const [categoryName, setCategoryName] = useState(null);
    const [categoryError, setCategoryError] = useState(false);

    useEffect(() => {
        let category = isValidParam(appContainer.dialogProps.data) && isValidParam(appContainer.dialogProps.data.category) ? appContainer.dialogProps.data.category : null;
        if (category !== null) {
            setCategoryName(category);
        }
    }, [appContainer.dialogProps]);

    const addNewCategory = () => {
        if (validateFields()) {
            try {
                let optionName = categoryName.trim();
                let params = { "categoryId": appContainer.dialogProps.data.categoryId, "categoryName": optionName, "orderId": appContainer.dialogProps.data.orderId }
                let url = endPoints.AUDIT_QUESTIONS.SAVE_CATEGORY;
                let promise = Promise.resolve(HTTPClient.post(url, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            if (response.status === 0) {
                                let tempData = getArrayParam(auditDetails.category);
                                tempData.push(response.data);
                                dispatch(updateAuditCategory(tempData));
                                dispatch(updateAuditCategorySelectedValue(response.data.t_category_name));
                                dispatch(showCustomSnackBar('Category Saved', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }else{
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_CATEGORY.MAX_EXCEED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                            closeDialog();
                        }
                    });
                }

            } catch (error) {
                console.error("Error in 'AddCategoryDialog.js -> saveNewCategory()':" + error);
            }
        }
    }
    const validateFields = () => {
        let errorObject = {
            errorMsg: '',
            isValidForm: false,
        }
        if (categoryName === null || categoryName === '' || !categoryName.trim()) {
            errorObject = {
                errorMsg: "Category Can't be Blank",
                isValidForm: false
            }
        } else {
            errorObject.isValidForm = true;

        } if (errorObject.isValidForm) {
            let categoryList = isValidParam(appContainer.dialogProps.data) ? getArrayParam(appContainer.dialogProps.data.categoryList) : []
            let optionName = categoryName.trim();
            let tempCategoryArr = categoryList.filter(v => v.audit_category_name.trim().toLowerCase() === optionName.toLowerCase())
            if (tempCategoryArr.length > 0) {
                errorObject = {
                    errorMsg: "This Category already exists. Duplicate Category creation is not allowed.",
                    isValidForm: false
                }
            }else if(tempCategoryArr.length === 0) {
                errorObject.isValidForm = true;
            }
        }
        focusElementByName(errorObject);
        return errorObject.isValidForm;
    }
    const focusElementByName = (errorObject) => {
        if (!errorObject.isValidForm) {
            let fieldId = 'field-ref-id-category-add';
            setCategoryError(true);
            let inputElement = document.getElementById(fieldId);
            if (isValidParam(inputElement) && inputElement !== null) {
                inputElement.focus();
            }
            dispatch(showCustomSnackBar(errorObject.errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            setCategoryError(false);
        }
    }

    const closeDialog = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    return (
        <div>
            <div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="questionName"
                        autoFocus={true}
                        id={'field-ref-id-category-add'}
                        error={categoryError}
                        value={categoryName}
                        onChange={e => setCategoryName(e.target.value)}
                        autoComplete="new-password"
                        style={{ zIndex: 0 }}
                        margin="dense"
                        size='small'
                        inputprops={{
                            maxLength: 254
                        }} />
                </FormControl>
            </div>
            <div style={{ width: 'auto' }}>
                <div style={{ textAlign: 'right', marginTop: '10px', marginBottom: '20px' }}>
                    <Button style={styles.primaryButton} onClick={() => addNewCategory()}>{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    <Button style={styles.secondaryButton} onClick={() => closeDialog()}>{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>
        </div>
    );
}

export default AddCategoryDialog;
