import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "../../../services/constants/styles";
import { Link } from "react-router-dom";
import { isValidParam, getStringParam } from "../../../services/helper/parameterVerifier";
import {
	initTab,
	MAX_TAB_SIZE,
	selectTabByIndex,
	closeTab,
	TYPE_HOME,
	TYPE_LIST_VIEW,
	TYPE_COLUMN_VIEW,
	TYPE_OTHER_VIEW,
	TYPE_DASHBOARD,
	TYPE_WEBSITE_TRACKING,
	TYPE_REPORT,
	TYPE_DETAIL_VIEW,
} from "../../../services/helper/sfTabManager";
import { constants } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {updateTabInfo } from '../../../services/helper/sfTabManager';
import { getActiveTabInfo} from '../../../services/helper/sfTabManager';
import { getActiveTab,updateActiveTab } from '../../../services/helper/sfTabManager';
import * as sfDialogs from '../components/sfDialogs';
/**
 *
 * @author Pradyut(2017-11-03)
 */

const SFTab = (props) => {

	const dispatch = useDispatch();

	const app = useSelector((state) => state.app);
	const tab = useSelector((state) => state.tab);
	const [over, setOver] = useState(false);


	useEffect(() => {
		initTab(constants.CALENDAR_TASK, true); //for init tab from cookie 
	}, []);


	useEffect(() => {
		let moduleName = getStringParam(app.selectedModuleName);
		let tabLength = isValidParam(tab.tabs) ? tab.tabs.length : 0;
		if (moduleName !== '' && tabLength === 0) {
			if (moduleName == constants.MODULE_OPERATIONS) {
				initTab(constants.UNITS_OBJECT, true);//for init tab from cookie 
			} else if (moduleName == constants.MODULE_HUB) {
				initTab(constants.TASKS, true);//for init tab from cookie 
			} else if (moduleName != '') {
				initTab(constants.CALENDAR_TASK, true);//for init tab from cookie 
			}
		}

	}, [app.selectedModuleName]);

	const generateTab = (tabs) => {
		let elements = [];

		let moduleName = isValidParam(getStringParam(app.selectedModuleName))
			? getStringParam(app.selectedModuleName) : "";
		try {
			elements = tabs.map((item, tabIndex) => {
				if (isValidParam(item)) {
					let imgName = getStringParam(item.imgName);

					if (
						item.object === constants.ACCOUNTS_OBJECT &&
						imgName === "fa fa-users"
					) {
						imgName = "fa fa-building";
					} else if (
						item.object === constants.SALES_OBJECT &&
						imgName === "fa fa-area-chart"
					) {
						imgName = "fa fa-bar-chart";
					} else if (
						item.object === constants.OPPORTUNITIES_OBJECT &&
						imgName === "fa fa-users"
					) {
						imgName = "fa fa-usd";
					} else if (
						item.object === constants.NOTES &&
						imgName === "fa fa-users"
					) {
						imgName = "fa fa-pencil-square-o";
					} else if (
						item.object === constants.COUPONS_OBJECT &&
						imgName === "fa fa-home"
					) {
						imgName = "fas fa-donate";
					} else if (item.object === constants.PETS_OBJECT) {
						imgName = "fas fa-paw";
					}

					let label = item.label;
					if (
						item.object === constants.APPOINTMENTS &&
						item.type === TYPE_HOME
					) {
						label = getLocalizedStrings().label.CALENDAR.CALENDAR_TASKS;
					}
					if (item.object === constants.ASSETS_OBJECT && label === "Library") {
						label = getLocalizedStrings().label.DOCUMENT_LIBRARY.LIBRARY;
					}
					if (item.object === constants.NEWS_OBJECT && label === "News") {
						label = getLocalizedStrings().label.SIDE_NAV.NEWS;
					}
					if (
						item.object === constants.CALENDAR_TASK &&
						item.type === TYPE_HOME
					) {
						label = getLocalizedStrings().label.CALENDAR.CALENDAR_TASKS;
					}
					if (
						item.object === constants.BROADCAST_CAMPAIGNS_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Broadcast Campaigns"
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS["Broadcast Campaigns"];
					}
					if (
						item.object === constants.MARKETING_TEMPLATE_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Marketing Templates"
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS["Marketing Templates"];
					}
					if (
						item.object === constants.AUTOMATION_DESIGNER_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						(label === "Automated Campaigns" || label === "Automation Designer")
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS[label];
					}
					if (
						item.object === constants.WEB_FORMS &&
						item.type === TYPE_LIST_VIEW &&
						label === "Web Forms"
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS["Web Forms"];
					}
					if (
						item.object === constants.SOCIAL_MARKETING &&
						item.type === TYPE_OTHER_VIEW &&
						label === "Social Marketing"
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS["Social Marketing"];
					}
					if (
						item.object === constants.ONLINE_REPUTATION &&
						item.type === TYPE_OTHER_VIEW &&
						label === "Online Reputation"
					) {
						label = getLocalizedStrings().label.SIDE_NAV["ONLINE REPUTATION"];
					}
					if (
						item.object === constants.DASHBOARD &&
						item.type === TYPE_DASHBOARD &&
						label === "Dashboard"
					) {
						label = getLocalizedStrings().label.SIDE_NAV.DASHBOARD;
					}
					if (
						item.object === constants.WEBSITE_TRACKING &&
						item.type === TYPE_WEBSITE_TRACKING &&
						label === "Website Tracking"
					) {
						label =
							getLocalizedStrings().label.MODULE_ITEMS["Website Tracking"];
					}
					if (
						item.object === constants.APPOINTMENTS &&
						item.type === TYPE_LIST_VIEW &&
						label === "All Appointments"
					) {
						label = getLocalizedStrings().label.HEADER.ALL_APPOINMENTS;
					}
					if (
						item.object === constants.TASKS &&
						item.type === TYPE_LIST_VIEW &&
						label === "All Tasks"
					) {
						label = getLocalizedStrings().label.HEADER.ALL_TASKS;
					}
					if (
						item.object === constants.CONTACTS_OBJECT &&
						(item.type === TYPE_LIST_VIEW || item.type === TYPE_COLUMN_VIEW) &&
						label === "Contacts"
					) {
						label = getLocalizedStrings().label.HEADER.CONTACTS;
					}

					if (
						item.object === constants.CONTACTS_OBJECT &&
						(item.type === TYPE_LIST_VIEW || item.type === TYPE_COLUMN_VIEW) &&
						label !== "Contacts"
					) {
						label = getLocalizedStrings().label.FILTER.hasOwnProperty(label)
							? getLocalizedStrings().label.FILTER[label]
							: label;
					}
					if (
						(item.type === TYPE_LIST_VIEW || item.type === TYPE_COLUMN_VIEW) &&
						label === "All records"
					) {
						label = getLocalizedStrings().label.FILTER["All records"];
					}
					if (
						item.object === constants.SALES_TEMPLATE_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Sales Templates"
					) {
						label = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATES;
					}
					if (
						item.object === constants.OPPORTUNITIES_OBJECT &&
						item.type === TYPE_LIST_VIEW
					) {
						if (label === "Added this Month") {
							label = getLocalizedStrings().label.FILTER["Added this Month"];
						}
						if (label === "Added this Week") {
							label = getLocalizedStrings().label.FILTER["Added this Week"];
						}
						if (label === "My Opportunities") {
							label = getLocalizedStrings().label.FILTER["My Opportunities"];
						}
						if (label === "Opportunities") {
							label = getLocalizedStrings().label.FILTER["Opportunities"];
						}
					}
					if (
						item.object === constants.ACCOUNTS_OBJECT &&
						(item.type === TYPE_LIST_VIEW || item.type === TYPE_COLUMN_VIEW)
					) {
						label = getLocalizedStrings().label.FILTER.hasOwnProperty(label)
							? getLocalizedStrings().label.FILTER[label]
							: label;
					}
					if (
						item.object === constants.CASES_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Cases"
					) {
						label = getLocalizedStrings().label.FILTER["Cases"];
					}
					if (
						item.object === constants.PROJECTS_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Projects"
					) {
						label = getLocalizedStrings().label.FILTER["Projects"];
					}
					if (
						item.object === constants.ISSUES_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Issues"
					) {
						label = getLocalizedStrings().label.FILTER["Issues"];
					}
					if (
						item.object === constants.SOLUTIONS_OBJECT &&
						item.type === TYPE_LIST_VIEW &&
						label === "Solutions"
					) {
						label = getLocalizedStrings().label.HEADER["Solutions"];
					}

					if (
						item.object === constants.TASKS &&
						item.type === TYPE_HOME &&
						moduleName === constants.MODULE_HUB
					) {
						label = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
					}

					if (item.object === "sales" && label === "Sales Reports") {
						label = getLocalizedStrings().label.REPORT.SALES_REPORT;
					}
					if (item.object === "services" && label === "Service Reports") {
						label = getLocalizedStrings().label.REPORT.SERVICE_REPORT;
					}
					if (item.object === "dashboard" && label === "Operations Dashboard") {
						label = "Dashboard";
					}
					if (item.object === "assets" && label === "Assets") {
						label = getLocalizedStrings().label.HEADER.Assets;
					}
					if (item.object === "units" && label === "Units") {
						label = getLocalizedStrings().label.HEADER.Units;
					}
					if (item.object === "onboarding" && label === "Onboarding") {
						label = getLocalizedStrings().label.HEADER.Onboarding;
					}
					if (item.object === "dashboard" && label === "Dashboard") {
						label = getLocalizedStrings().label.HEADER.Dashboard;
					}
					if (
						item.object === "operations-activities" &&
						label === "Activities"
					) {
						label = getLocalizedStrings().label.HEADER.Activities;
					}
					if (
						item.object === "operations-conversions" &&
						label === "Conversions"
					) {
						label = getLocalizedStrings().label.HEADER.Conversions;
					}
					if (item.object === "operations-campaigns" && label === "Campaigns") {
						label = getLocalizedStrings().label.HEADER.Campaigns;
					}
					if (
						item.object === "custom-operations-reports" &&
						label === "Reports"
					) {
						label = getLocalizedStrings().label.HEADER.Reports;
					}
					if (item.object === "coupons-reports" && label === "Reports") {
						label =
							getLocalizedStrings().label.COUPONS_CLAIM_REPORTS.COUPONS_REPORT;
					}
					if (item.object === constants.COUPONS_OBJECT && label === "Coupons") {
						label = getLocalizedStrings().label.COMMON.COUPONS;
					}
					if (
						item.object === constants.OPERATIONS_ASSETS_REPORTS &&
						label === "Asset Distribution"
					) {
						label =
							getLocalizedStrings().label.MODULE_ITEMS.ASSETS_DISTRIBUTION;
					}
					if (
						item.object === constants.USEFUL_LINK_OBJECT &&
						label === "Useful Links"
					) {
						label = getLocalizedStrings().label.MODULE_ITEMS.USEFUL_LINKS;
					}

					if (item.object === constants.ROYALTY_REPORT_OBJECT) {
						label = getLocalizedStrings().label.MODULE_ITEMS.Royalties;
					}

					if (item.object === constants.COMPLIANCE_REPORT_OBJECT) {
						label = getLocalizedStrings().label.MODULE_ITEMS.COMPLIANCE_REPORT;
					}

					/*** Drilldown report tab title label ***/
					if (item.info.isReport && item.item === "sales") {
						if (label === "Activities By Type") {
							label = getLocalizedStrings().label.REPORT.ACTIVITIES_BY_TYPE;
						}
						if (label === "Activities By Sales Rep") {
							label =
								getLocalizedStrings().label.REPORT.ACTIVITIES_BY_SALES_REP;
						}
						if (label === "All Conversions By Status") {
							label =
								getLocalizedStrings().label.REPORT.ALL_CONVERSIONS_BY_STATUS;
						}
						if (label === "Customers By Source") {
							label = getLocalizedStrings().label.REPORT.CUSTOMERS_BY_SOURCE;
						}
						if (label === "Pipeline Report") {
							label = getLocalizedStrings().label.REPORT["Pipeline Report"];
						}
						if (label === "Pipeline by Sales Rep") {
							label = getLocalizedStrings().label.REPORT.DEALS_BY_SALES_PER;
						}
						if (label === "Conversion By Sales Rep") {
							label =
								getLocalizedStrings().label.REPORT.CONVERSION_BY_SALES_REP;
						}
						if (label === "Deals by Stages") {
							label = getLocalizedStrings().label.REPORT.DEALS_BY_STAGES;
						}
						if (label === "Total Calls By Sales Rep") {
							label = getLocalizedStrings().label.REPORT.TOTAL_CALLS_BY_SALES_REP;
						}
						if (label === "Total Call Duration By Sales Rep") {
							label = getLocalizedStrings().label.REPORT.TOTAL_CALL_DURATION_BY_SALES_REP;
						}
						if (label === "Activity Matrix") {
							label = getLocalizedStrings().label.REPORT.ACTIVITY_MATRIX;
						}
						if (label === "Matrix By Status") {
							label = getLocalizedStrings().label.REPORT.MATRIX_BY_STATUS;
						}
						if (label === "Matrix By Source") {
							label = getLocalizedStrings().label.REPORT.MATRIX_BY_SOURCE;
						}
					}
					if (item.info.isReport && item.item === "services") {
						if (label === "Cases by Status") {
							label = getLocalizedStrings().label.REPORT.CASES_BY_STATUS;
						}
						if (label === "Cases by Priority") {
							label = getLocalizedStrings().label.REPORT.CASES_BY_PRIORITY;
						}
						if (label === "Projects by Status") {
							label = getLocalizedStrings().label.REPORT.PROJECTS_BY_STATUS;
						}
						if (label === "Projects by Type") {
							label = getLocalizedStrings().label.REPORT.PROJECTS_BY_TYPE;
						}
						if (label === "Issues by Status") {
							label = getLocalizedStrings().label.REPORT.ISSUES_BY_STATUS;
						}
						if (label === "Issues by Priority") {
							label = getLocalizedStrings().label.REPORT.ISSUES_BY_PRIORITY;
						}
						if (label === "Support Matrix") {
							label = getLocalizedStrings().label.REPORT.SUPPORT_MATRIX;
						}
						if (label === "Customers by Cases") {
							label = getLocalizedStrings().label.REPORT.CUSTOMER_BY_CASES;
						}
						if (label === "Project Matrix") {
							label = getLocalizedStrings().label.REPORT.PROJECT_MATRIX;
						}
						if (label === "Issue Matrix") {
							label = getLocalizedStrings().label.REPORT.ISSUE_MATRIX;
						}
					}
					if (
						item.object === constants.OPPORTUNITIES_OBJECT &&
						item.type === TYPE_REPORT &&
						label === "Forecast"
					) {
						label = getLocalizedStrings().label.DASHBOARD["Forecast"];
					}

					var TTab = styled((props) => <Tab {...props} />)(
						({ theme }) => ({
						  color: "#FFFFFF",
						  opacity: 1
						})
					  );
					let itemValue = (
						<div>
							<ItemVal
								key={item.label}
								item={item.item}
								label={label}
								imgName={imgName}
								tabIndex={tabIndex}
								object={item.object}
								index={item.index}
								opacity={item.isActive ? "1" : "0.5"}
								color={"#ffffff"}
								callBackToRedirect={props.callBackToRedirect}
							/>
						</div>
					);

					return (
						<TTab
							key={"K" + item.index}
							id={"K" + item.index}
							title={label}
							label={itemValue}
							value={item.index !== undefined ? item.index : ""}
							style={{ ...styles.tabsitem, height: "40px" }}
						/>
					);
				}
			});
		} catch (error) {
			console.error("Error in 'sfTab.js -> generateTab()':" + error);
		}
		return elements;
	};

	const getTabWidth = () => {
		let menuDrawyerWidth = 0;
		let clientWidth = document.body.clientWidth - 20;
		let tabContainerWidth = clientWidth - menuDrawyerWidth;
		let tabWidth = tabContainerWidth / MAX_TAB_SIZE;
		let tabLength = isValidParam(tab.tabs) ? tab.tabs.length : 0;
		let tabItemWidth = tabWidth * tabLength + "px";
		return tabItemWidth;
	};

	let tabData = isValidParam(tab.tabs) ? tab.tabs : null;

	let tabs = generateTab(tabData);

	let tabItemWidth = getTabWidth();
	return (
		<Tabs value={false} style={{ ...styles.tabs, width: tabItemWidth }}>
			{tabs}
		</Tabs>
	);
};

export default SFTab;

const ItemVal = ({ key, item, label, imgName, tabIndex, object, index, opacity, color, callBackToRedirect }) => {

	const [over, setOver] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [redirectURL, setRedirectURL] = useState(null);

	const app = useSelector((state) => state.app);
	const automationCampaign = useSelector((state)=> state.automationCampaign)
	const mouseOver = () => {
		setOver(true);
	};

	const mouseOut = () => {
		setOver(false);
	};

	const handleChange = (activeTabIndex) => {
		const activeTab = getActiveTab();
		if(object === constants.AUTOMATION_DESIGNER_OBJECT && activeTab.isActive && activeTab.type === TYPE_DETAIL_VIEW && automationCampaign.mounted) {
			let message = "Are you sure you want to leave this page? Any unsaved changes would be lost." 
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, message, () => selectTabByIndex(activeTabIndex), null);
		}else{
			selectTabByIndex(activeTabIndex);
			document.documentElement.style.overflow = "auto";
		}
	};


	let tabImageStyle = {
		fontSize: "11px",
		//fontWeight:'normal',
		marginRight: "5px",
		marginTop: "2.5px",
		float: "inherit",
	};
	if (imgName === "fa fa-home") {
		tabImageStyle.fontSize = "15px";
		tabImageStyle.marginTop = "0px";
	}
	let tabWidthStyle = {
		display: "inline-block",
		minWidth: 100,
		maxWidth: 110,
		float: "left",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textAlign: "left",
		textOverflow: "ellipsis",
		height: 40,
		paddingTop: 8.5,
		fontSize: "13px",
		fontWeight:400
	};
	if (tabIndex !== undefined && tabIndex === 0) {
		tabWidthStyle = {
			display: "inline-block",
			width:object == "units" ?'': "150px",
			float: "left",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textAlign: "left",
			textOverflow: "ellipsis",
			height: 40,
			paddingTop: 7.5,
			fontWeight:400
		};
	}
	const updateTab = () => {
		if (object == 'quotations') {
			let _info = getActiveTabInfo();
			if (_info.selectedPageNo > 1) {
				_info.selectedPageNo = 1;
				updateTabInfo(_info);
			}
		}
		if(object == 'units'){
			let activeTab = getActiveTab();
            activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
            updateActiveTab(activeTab);
		}
	}

	return (
		<div style={{ color: color, opacity: opacity, height: 40 }}>
			<div onClick={() => handleChange(index)} style={tabWidthStyle}>
				<i className={imgName} style={tabImageStyle} />
				<span>{label}</span>
			</div>
			<div style={{ float: "right", display: "inline-block", height: 40, paddingTop: 9.5, }} >
				{tabIndex !== undefined && tabIndex !== 0 && (
					<CloseIcon
						style={{ fontSize: "1.0em",fontWeight:'normal' }}
						title={getLocalizedStrings().label.COMMON.CLOSE}
						onMouseOver={(e) => mouseOver()}
						onMouseOut={(e) => mouseOut()}
						onClick={() => { closeTab(item, tabIndex); 
							updateTab()
						}}>
						<Link to="#" style={{ color: color, textDecoration: "none" }}></Link>
					</CloseIcon>
				)}
			</div>
		</div>
	);
};
