import React from 'react'
import { connect } from 'react-redux';
import ShowCircularProgress from '../components/circularProgress';
import {constants} from '../../../services/constants/constants';
import {endPoints} from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import {styles} from '../../../services/constants/styles';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {getActiveTab, updateActiveTab} from '../../../services/helper/sfTabManager';
import {
    getArrayParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isInteger,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import {Button, Checkbox, FormControl, FormControlLabel, IconButton, TextField,MenuItem,FormGroup} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import {getAppDialog, getAppDrawer} from '../../../services/actions/appContainerActions';
import {
    getAuditCategory,
    updateAuditCategory,
    updateAuditCategorySelectedValue
} from '../../../services/actions/auditActions';
import {sortArrayObjectByProperty, toCapitalize} from '../../../services/helper/utils';
import {refreshListView} from '../../../services/actions/listViewAcions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


class AuditQuestionTypes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: null,
            mounted: false,
            isShowLoader: false,
            errOnCategory: false,
            categoryList: [],
            categoryListOptions: [],
            selectedCategory: null,
            selectedCategoryInput: null,
            categoryShrink: false,
            searchedCategory: null,
            listQuestions: [],
            checkboxQuestions: [],
            minChoiceCount: 2,
            autoFocus:false,
            due_by:0,
        }
    }

    componentDidMount() {
        let _state = this.state;
        let tempChoices = [];
        let minCount = this.state.minChoiceCount
        for (let i = 0; i < minCount; i++) {
            tempChoices.push(this.getDefaultChoiceObj(i + 1));
        }
        let type = this.props?.data?.type?.toLowerCase();
        let optionKey = type + 'Questions';
        _state[optionKey] = tempChoices;

        if (this.props.auditDetails.category != null) {
            this.getCategory(this.props.auditDetails);
        } else {
            this.getAuditCategory()
        }
        if (this.props.data != null && this.props.data.id > 0) {
            this.getSingleQuestion(this.props.data.id)
        } else {
            _state.type = type
            _state = { ..._state, [type]: this.getDefaultTypeObj(type) };
        }
        _state.due_by = this.props?.data?.due_by ? this.props?.data?.due_by : 0;
        this.setState(_state);
    }
    getAuditCategory = () => {
        let promise = Promise.resolve(getAuditCategory());
        if (isValidParam(promise)) {
            promise.then((response) => {
                this.props.updateAuditCategory(response);
                let value = new Object;
                value.category = response
                value.selecteCategory = null;
                this.getCategory(value);
            });
        }
    }

    componentDidUpdate() {
        if (!this.state.mounted && this.state.categoryList.length == 0 && this.props.auditDetails.category != null) {
            this.getCategory(this.props.auditDetails);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.auditDetails.selecteCategory != null) || (nextProps.auditDetails.category != null && this.state.categoryList.length === 0)) {
            this.getCategory(nextProps.auditDetails);
        }
    }
    getDefaultChoiceObj = (orderId) => {
        let obj = { choice: '', score: 5, isDeleted: false, items_id: 0, status: 'Active', error: false, scoreError: false };
        obj.orderId = orderId;
        return obj;
    }
    getDefaultTypeObj = (type) => {
        let obj = {
            question: '',
            score: 5,
            required: false,
            requiresPhoto: false,
            enableNote: true,
            orderId: null,
            questionsId: "0",
            error: false,
            scoreError: false
        };
        if (type === 'list' || type === 'checkbox') {
            delete obj.score;
            delete obj.scoreError;
        }
        return obj;
    }
    getCategory = (value) => {
        let _state = this.state;

        let tempCategoryArr = new Array;
        let data = value.category;
        data.map(f => {
            tempCategoryArr.push(this.getAddOptonObject(f.order_id, f.t_pk, f.t_category_name, f.t_category_name));
        });
        _state.categoryList = tempCategoryArr;
        let optionName = 'Add a New Category';
        tempCategoryArr = tempCategoryArr.filter(v => v.audit_category_name != optionName);
        sortArrayObjectByProperty(tempCategoryArr, 'audit_category_name');
        tempCategoryArr.push(this.getAddOptonObject(data.length, 0, optionName, -99999));
        _state.categoryListOptions = tempCategoryArr
        if (value.selecteCategory != null) {
            _state.selectedCategory = value.selecteCategory;
            _state.selectedCategoryInput = value.selecteCategory;
            this.props.updateAuditCategorySelectedValue(null)
        }
        _state.mounted = true;
        this.setState(_state);
    }
    getSingleQuestion = (questionId) => {
        let _state = this.state
        _state.isShowLoader = true;
        this.setState(_state);
        try {
            const url = endPoints.AUDIT_QUESTIONS.GET_SINGLE_QUESTION
            const params = { "questionId": questionId }
            HTTPClient.get(url, params)
                .then(res => {
                    if (res.questions_id > 0) {
                        let type = res.type.toLowerCase();
                        _state = { ..._state, [type]: this.getDefaultTypeObj(type) };
                        _state.type = type
                        _state[type].question = res.questions;
                        _state[type].orderId = res.orderId;
                        _state[type].questionsId = res.questions_id;
                        _state.selectedCategory = res.audit_category_name;
                        _state.selectedCategoryInput = res.audit_category_name;
                        _state[type].required = res.required == 1 ? true : false;
                        _state[type].requiresPhoto = res.requiresPhoto == 1 ? true : false;
                        _state[type].enableNote = res.enableNote == 1 ? true : false;
                        if (type == 'text' || type == 'comment') {
                            _state[type].score = res.score;
                            _state[type].error = false;
                            _state[type].scoreError = false;
                        }
                        if (type == 'checkbox' || type == 'list') {
                            let items = [];
                            let optionKey = type + 'Questions';
                            res.items.map((val, index) => {
                                items.push({
                                    choice: val.items,
                                    score: val.score,
                                    items_id: val.items_id,
                                    orderId: val.orderId,
                                    status: val.status,
                                    error: false,
                                    scoreError: false
                                })
                            })
                            _state[optionKey] = items;
                        }
                    }
                    _state.isShowLoader = false;
                    this.setState(_state)
                })
        } catch (error) {
            console.error("Error in 'AuditQuestionTypes.js -> getSingleQuestion()':" + error);
        }
    }

    handleChoicesChange = (e, index, key) => {
        let _state = this.state
        const { name, value } = e.target;
        const checkbox = [...this.state[key]];
        checkbox[index][name] = value;
        _state[key] = checkbox;
        this.setState(_state)
    }

    addNewChoice = (key) => {
        let _state = this.state;
        _state[key] = [...this.state[key], this.getDefaultChoiceObj(this.state[key].length + 1)];
        _state.autoFocus = true;
        this.setState(_state)
    }

    removeChoice = (e, index, key) => {
        let _state = this.state
        let checkbox = [..._state[key]];
        if (checkbox.filter((x) => x.status == 'Active').length < 3) {
            this.props.showCustomSnackBar(getLocalizedStrings().label.AUDIT_QUESTIONS.MINIMUM_OPTIONS, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        } else {
            checkbox[index].status = 'Deleted'
            _state[key] = checkbox;
            this.setState(_state);
        }
    }

    closeDrawer = () => {
        this.props.getAppDrawer(false, null, null, null, null);
    }

    getDuplicates = (arr) => {
        var duplicates = [];
        for (var i = 0; i < arr.length; i++) {
            if (arr.filter(c => c.status != "Deleted" && c.choice.trim() == arr[i].choice.trim()).length > 1) {
                duplicates.push(i);
            }
        }
        return duplicates;
    };



    addNewQuestion = () => {
        let _state = this.state;
        _state.isShowLoader = true;
        try {
            if (this.validateFields()) {
                let data = this.getDataParams();
                if (isValidParam(data)) {
                    const url = endPoints.AUDIT_QUESTIONS.SAVE_QUESTION
                    HTTPClient.post(url, data)
                        .then(res => {
                            if (res.status === 0) {
                                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                let tab = getActiveTab();
                                tab.info = { ...tab.info, groupByCondition: "All" };
                                delete tab.info.startIndex;
                                delete tab.info.pageSize;
                                delete tab.info.selectedPageNo;
                                updateActiveTab(tab);
                                let params = { "query_name": "All Audit Questions", "query_type": "query" };
                                refreshListView(constants.AUDIT_QUESTIONS_OBJECT, params)
                                this.props.getAppDrawer(false, null, null, null, null);
                            } else {
                                _state.isShowLoader = false;
                                this.setState(_state);
                                this.props.showCustomSnackBar(res.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            }
                        })
                }
            } else {
                _state.isShowLoader = false;
                this.setState(_state);
            }
        } catch (error) {
            console.error("Error in 'AuditQuestionTypes.js -> addNewQuestion()':" + error);
        }
    }
    getDataParams = () => {
        let _state = this.state;
        let data = new Object
        let type = this.state.type.toLowerCase();
        let optionKey = type + 'Questions';
        let tempselectedcategoryId = this.state.categoryListOptions?.filter((ele)=> {return ele.value === this.state.selectedCategory});
        tempselectedcategoryId = tempselectedcategoryId? tempselectedcategoryId[0].categoryId : 0;
        data = {
            questions_id: _state[type].questionsId,
            questions: _state[type].question.trim(),
            type: toCapitalize(this.state.type),
            required: Number(_state[type].required),
            requiresPhoto: Number(_state[type].requiresPhoto),
            enableNote: Number(_state[type].enableNote),
            score: Number(_state[type].score),
            status: "Active",
            categoryName: this.state.selectedCategory,
            category_id: tempselectedcategoryId,
            orderId: Number(_state[type].orderId),
            due_by : _state.due_by,
            items: []
        }
        if (type === 'list' || type === 'checkbox') {
            let listQuestions = []
            this.state[optionKey].map((val, idx) => {
                listQuestions.push({
                    "items_id": val.items_id,
                    "items": val.choice.trim(),
                    "score": Number(val.score),
                    "orderId": idx + 1,
                    "status": val.status
                })
            })
            delete data.score;
            data.items = listQuestions
        }
        return data;
    }
    validateFields = () => {
        try {
            let _state = this.state
            let type = this.state.type.toLowerCase();
            let errorObject = {
                type: type,
                errorOf: 'question',
                msg: null,
                index: 0,
                isValidForm: true
            }
            let options = [];
            if (this.state[type].question.trim()) {
                errorObject.isValidForm = true;
                _state[type].error = false;
            } else if (!this.state[type].question.trim()) {

                _state[type].error = true;
                errorObject = {
                    type: type,
                    msg: getLocalizedStrings().message.AUDIT_QUESTIONS.QUESTION_REQUIRED,
                    errorOf: 'question',
                    index: 0,
                    isValidForm: false
                }
            }
            if (errorObject.isValidForm) {
                if (errorObject.isValidForm && (type == 'text' || type == 'comment')) {
                    if (isInteger(this.state[type].score)) {
                        _state[type].scoreError = false;
                        errorObject.isValidForm = true;
                    } else if (!isInteger(this.state[type].score)) {
                        _state[type].scoreError = true;
                        errorObject = {
                            type: type,
                            msg: getLocalizedStrings().message.AUDIT_QUESTIONS.ENTER_VALID_SCORE,
                            errorOf: 'score',
                            index: 0,
                            isValidForm: false
                        }
                    }
                }
                else if (errorObject.isValidForm && (type == 'list' || type == 'checkbox')) {
                    let optionKey = type + 'Questions'
                    options = this.state[optionKey];
                    for (let index = 0; index < options.length; index++) {
                        if (options[index].status == 'Active') {
                            if (!options[index].choice.trim()) {
                                _state[optionKey][index].error = true;
                                errorObject = {
                                    type: type,
                                    msg: getLocalizedStrings().message.AUDIT_QUESTIONS.ENTER_VALID_CHOICES,
                                    errorOf: 'choice',
                                    index: index,
                                    isValidForm: false
                                }
                                index = options.length
                            } else {
                                let dups = this.getDuplicates(options)
                                _state[optionKey][index].error = true;
                                if (dups.length > 0) {
                                    errorObject = {
                                        type: type,
                                        msg: 'Duplicate choice found',
                                        errorOf: 'choice',
                                        index: index,
                                        isValidForm: false
                                    }
                                    index = options.length
                                } else {
                                    _state[optionKey][index].error = false;
                                    errorObject.isValidForm = true;
                                }
                            }
                            if (index < options.length && !isInteger(options[index].score)) {
                                _state[optionKey][index].scoreError = true;
                                errorObject = {
                                    type: type,
                                    msg: getLocalizedStrings().message.AUDIT_QUESTIONS.ENTER_VALID_SCORE,
                                    errorOf: 'score',
                                    index: index,
                                    isValidForm: false
                                }
                                index = options.length
                            } else if (index < options.length && isInteger(options[index].score)) {
                                _state[optionKey][index].scoreError = false;
                                errorObject.isValidForm = true;
                            }
                        }
                    }
                }
                if (errorObject.isValidForm && this.state.selectedCategory == null) {
                    _state.errOnCategory = true
                    errorObject = {
                        type: type,
                        msg: 'Please select a Category',
                        errorOf: 'category',
                        index: 0,
                        isValidForm: false
                    }
                } else if (errorObject.isValidForm && this.state.selectedCategory != null) {
                    _state.errOnCategory = false;
                    errorObject.isValidForm = true;
                }
            }
            this.focusElementByName(errorObject);
            this.setState(_state)

            return errorObject.isValidForm;

        }
        catch (error) {
            console.error("Error in 'AuditQuestionTypes.js -> validateFields()':" + error);
        }
    }

    focusElementByName = (errorObject) => {
        let inputElement = null;
        let fieldId = null;
        let fieldType = null;
        let errorOf = null;
        let index = 0;
        try {
            errorObject = getObjectParam(errorObject);
            if (!errorObject.isValidForm) {
                this.props.showCustomSnackBar(errorObject.msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                fieldType = getStringParam(errorObject.type);
                errorOf = getStringParam(errorObject.errorOf);
                index = getIntParam(errorObject.index)
                fieldId = 'field-ref-id-' + fieldType + '-' + errorOf;
                if (fieldType == 'list' || fieldType == 'checkbox') {
                    if (errorOf == 'choice' || errorOf == 'score') {
                        fieldId = 'field-ref-id-' + fieldType + '-' + errorOf + '-' + index
                    }
                }
                inputElement = document.getElementById(fieldId);
                if (fieldType != '' && isValidParam(inputElement) && inputElement != null) {
                    if (inputElement != null) {
                        inputElement.focus();
                    }
                }
            }

        } catch (error) {
            console.error("Error in 'AuditQuestionTypes.js -> focusElementByName()':" + error);
        }
    }

    updateFields = (e, field) => {
        let _state = this.state
        let type = this.state.type.toLowerCase()
        _state[type][field] = (['required', 'requiresPhoto', 'enableNote'].includes(field)) ? e.target.checked : e.target.value;
        this.setState(_state);
    }

    getScoreHelpText = () => {
        return (<div>
            <Tooltip title={getLocalizedStrings().label.AUDIT_QUESTIONS.SCORE_HELP_TOOLTIP}  style={{ fontSize: '15px', alignSelf: 'self-end', marginTop: '0.9em', paddingLeft: '34%' }} arrow  >
                <div>
                    <HelpOutlineIcon />
                </div>
            </Tooltip>
        </div>)
    }

    handleChangeDueBy = (event) =>{
        let value = event.target.value;
        const numericValue = value.replace(/[^0-9]/g, "");
        this.setState({due_by : numericValue});
    }

    getQuestionContent = (type) => {
        let _state = this.state
        let element = [];
        let categoryListOptions = isValidParam(this.state.categoryListOptions) ? getArrayParam(this.state.categoryListOptions) : [];
        let selectedCategory = isValidParam(this.state.categoryListOptions.find(v => v.value == this.state.selectedCategory)) ? this.state.categoryListOptions.find(v => v.value == this.state.selectedCategory) : {};
        let shrink = this.state.selectedCategory != null ? true : this.state.categoryShrink
        type = type.toLowerCase();
        let optionKey = type + 'Questions'
        if (type == 'text' || type == 'comment') {
            element.push(
                <>
                    <div style={{ ...styles.row, padding: 0 }}>
                        <div style={{ ...styles.sf_12 }} >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.QUESTION}
                                    id={'field-ref-id-' + type + '-question'}
                                    error={this.state[type].error}
                                    name="questionName"
                                    autoFocus={true}
                                    inputprops={{
                                        maxLength: 254
                                    }}
                                    value={this.state[type].question}
                                    onChange={e => this.updateFields(e, 'question')}
                                    size='small'
                                    autoComplete="new-password"
                                    style={{ zIndex: 0 }}
                                    margin="dense"
                                    className={"sf-fields-bg"} />
                            </FormControl>
                        </div>
                        <div style={{ ...styles.sf_3, padding: '0px 0px 0 15px' }} >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.SCORE}
                                    id={'field-ref-id-' + type + '-score'}
                                    value={this.state[type].score}
                                    error={this.state[type].scoreError}
                                    onChange={e => this.updateFields(e, 'score')}
                                    name="score"
                                    size='small'
                                    autoComplete="new-password"
                                    style={{ zIndex: 0 }}
                                    margin="dense"
                                    className={"sf-fields-bg"} />
                            </FormControl>
                        </div>
                        <div style={{ width: '50px' }} >{this.getScoreHelpText()}</div>
                    </div>
                </>
            );
        } else if (type == 'list' || type == 'checkbox') {
            element.push(
                <>
                    <div style={{ ...styles.row, padding: 0 }}>
                        <div style={{ ...styles.sf_12 }}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.QUESTION}
                                    id={'field-ref-id-' + type + '-question'}
                                    error={this.state[type].error}
                                    name="questionName"
                                    inputprops={{
                                        maxLength: 254,
                                    }}
                                    value={this.state[type].question}
                                    onChange={e => this.updateFields(e, 'question')}
                                    autoFocus={true}
                                    autoComplete="new-password"
                                    style={{ zIndex: 0 }}
                                    size='small'
                                    margin="dense"
                                    className={"sf-fields-bg"} />
                            </FormControl>
                        </div>
                    </div>
                    {
                        this.state[optionKey].map((value, index) => {
                            if (value.status == 'Active') {
                                return (
                                    <div style={{ ...styles.row, padding: 0 }}>
                                        <div style={{ ...styles.sf_12 }} >
                                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                <TextField
                                                    variant="outlined"
                                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.CHOICE + ' ' + (index + 1)}
                                                    id={'field-ref-id-' + type + '-choice-' + index}
                                                    error={value.error}
                                                    name="choice"
                                                    onChange={e => this.handleChoicesChange(e, index, optionKey)}
                                                    value={value.choice}
                                                    size='small'
                                                    autoFocus = {this.state.autoFocus}
                                                    autoComplete="new-password"
                                                    margin="dense"
                                                    className={"sf-fields-bg"} />
                                            </FormControl>
                                        </div>
                                        <div style={{ ...styles.sf_3, padding: '0px 0px 0 15px' }} >
                                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                <TextField
                                                    variant="outlined"
                                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.SCORE}
                                                    id={'field-ref-id-' + type + '-score-' + index}
                                                    error={value.scoreError}
                                                    value={value.score}
                                                    onChange={e => this.handleChoicesChange(e, index, optionKey)}
                                                    size='small'
                                                    name="score"
                                                    autoComplete="new-password"
                                                    margin="dense"
                                                    className={"sf-fields-bg"} />
                                            </FormControl>
                                        </div>
                                        <div style={{ width: '50px' }} >{this.getScoreHelpText()}</div>
                                        <div style={{ width: '50px' }}>
                                            <IconButton style={{ marginTop: '3px', textAlign: 'left' }} aria-label="close" onClick={e => this.removeChoice(e, index, optionKey)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    <div style={{ marginTop: '15px' }}>
                        <Button style={styles.secondaryButton} onClick={() => this.addNewChoice(optionKey)}>{getLocalizedStrings().label.AUDIT_QUESTIONS.NEW_CHOICE}</Button>
                    </div>
                </>
            );
        } else {
            element.push(<div />);
        }
        element.push(
            <>
                <div style={{ ...styles.row, padding: 0, marginTop: '10px' }}>
                    <div style={{ width: '100%' }} >
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            {categoryListOptions.length > 0 && <Autocomplete
                                id={'field-ref-id-' + type + '-category'}
                                disableClearable
                                filterOptions={(x) => x}
                                options={categoryListOptions}
                                getOptionLabel={(option) => option.audit_category_name ? option.audit_category_name : ''}
                                value={selectedCategory}
                                size='small'
                                inputValue={this.state.selectedCategoryInput}
                                renderInput={(params) => <TextField  {...params}
                                    InputLabelProps={{
                                        shrink: shrink,
                                    }}
                                    autoFocus={this.state.errOnCategory} error={this.state.errOnCategory} onChange={(e) => this.setCategoryChange(e.target.value)} label={'Select Category'} variant="outlined" margin="dense" />}
                                onChange={(e, newValue) => this.handlecategoryChange(e, newValue)}
                                onFocus={() => this.handlecategoryOnFocus()}
                                onBlur={() => this.handlecategoryOnBlur()}
                            />
                            }
                        </FormControl>
                    </div>
                </div>
            </>);
        element.push(
            <div style={{ ...styles.row, padding: 0 }}>
                <div style={{ ...styles.sf_12, display: 'flex', justifyContent: 'space-between' }} >
                    <FormControl className="test" noValidate autoComplete="off">
                        <FormControlLabel control={<Checkbox color="default" id="required"
                            onChange={e => this.updateFields(e, 'required')}
                            checked={this.state[type].required} />} label={getLocalizedStrings().label.AUDIT_QUESTIONS.REQUIRED} style={{ marginRight: '5px', padding: '0px' }} labelStyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="left
                                        " />
                    </FormControl>
                    <FormControl className="test" noValidate autoComplete="off">
                        <FormControlLabel control={<Checkbox color="default" id="requires-photo"
                            onChange={e => this.updateFields(e, 'requiresPhoto')}
                            checked={this.state[type].requiresPhoto} />} label={getLocalizedStrings().label.AUDIT_QUESTIONS.REQUIRES_PHOTO} style={{ marginRight: '5px', padding: '0px' }} labelStyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="left
                                        " />
                    </FormControl>
                </div>
            </div>
        );
        element.push(
            <div style={{ ...styles.row, padding: 0 }}>
                <div style={{ ...styles.sf_12 }} >
                    <FormControl className="test" noValidate autoComplete="off">
                        <FormControlLabel control={<Checkbox color="default" id="enable-note"
                            onChange={e => this.updateFields(e, 'enableNote')}
                            checked={this.state[type].enableNote} />} label={getLocalizedStrings().label.AUDIT_QUESTIONS.ENABLE_NOTE} style={{ marginRight: '5px', padding: '0px' }} labelStyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="left
                                        " />
                    </FormControl>
                </div>
            </div>
        );
        element.push(
            <>
                <div style={{width:'100%',height:'fit-content',display:'inline'}}>

                    {/* corrective Action Due By*/}

                    <div className='Due_by_title' style={{marginTop:'10px'}}>
                        <span style={{fontSize:'16px',fontWeight:'500',fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif'}}>Corrective actions due by</span>
                    </div>
                    <div style={{width:'100%',height:'fit-content',display:'flex',marginTop:'10px'}}>
                        <div className='due_by_section' style={{width:'100%',height:'fit-content',display:'flex'}}>
                            <FormControl style={{ width: '5rem' }} className="test" noValidate autoComplete="off">
                                <TextField
                                id='due_by' 
                                variant="outlined"
                                style={{width: '5rem'}}
                                InputProps={{ style: { height: '30px' } }}
                                value={this.state.due_by} 
                                defaultValue={this.state.due_by}
                                onChange={this.handleChangeDueBy}
                                size='small'
                                />
                            </FormControl>
                            <div style={{marginLeft:'10px',paddingTop:'5px'}}><span style={{fontSize:'14.5px',fontWeight:'400',fontFamily: '-apple-system, Roboto, Helvetica, Arial, sans-serif'}}>days</span></div>
                        </div>
                    </div>

                </div>
            </>
        )
        element.push(
            <>
                <div style={{ textAlign: 'right', marginTop: '10px', marginBottom: '20px' }}>
                    <Button style={styles.primaryButton} onClick={() => this.addNewQuestion()}>{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    <Button onClick={() => this.closeDrawer()} style={styles.secondaryButton} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </>
        )
        return element;

    }
    SearchForObjectsWithName = (arrayOfVariable, sortValue) => {
        let ansArray = [];
        arrayOfVariable.map((data) => {
            let toAdd = false
            Object.values(data).forEach((value) => {
                if (value != null) {
                    let mainString = value.toString().replace(/\s/g, '').toLowerCase()
                    let subString = sortValue.replace(/\s/g, '').toLowerCase()
                    if (mainString.includes(subString)) {
                        toAdd = true
                    }
                }
            })
            if (toAdd) {
                ansArray.push(data)
            }
        })
        return (ansArray);
    }
    setCategoryChange = (data) => {
        let _state = this.state;
        let dataSearchList = this.state.categoryList;
        let tempCategoryArr = new Array;
        dataSearchList.map(f => {
            tempCategoryArr.push(this.getAddOptonObject(f.orderId, f.categoryId, f.audit_category_name, f.audit_category_name));
        });
        let optionName = 'Add a New Category';
        tempCategoryArr = tempCategoryArr.filter(v => v.audit_category_name != optionName);
        sortArrayObjectByProperty(tempCategoryArr, 'audit_category_name');
        tempCategoryArr.push(this.getAddOptonObject(dataSearchList.length, 0, optionName, -99999));
        if (data != undefined) {
            let searchString = data;
            if (searchString.replace(/\s/g, '') != '') {
                tempCategoryArr = this.SearchForObjectsWithName(tempCategoryArr, searchString)
            }
            if (tempCategoryArr.length == 0) {
                let tempList = [];
                let optionName = 'Add a New Category (' + data + ')';
                tempList.push(this.getAddOptonObject(this.state.categoryList.length, 0, optionName, -99999));
                tempCategoryArr = tempList;
            }
        }
        _state.searchedCategory = data;
        _state.selectedCategoryInput = data;
        _state.categoryListOptions = tempCategoryArr;
        this.setState(_state)
    }

    getAddOptonObject = (order, Id, Name, value) => {
        let temObj = new Object;
        temObj.value = value;
        temObj.orderId = order;
        temObj.categoryId = Id;
        temObj.audit_category_name = Name;
        return temObj;

    }
    handlecategoryOnFocus = () => {
        let _state = this.state;
        _state.categoryShrink = true;
        this.setState(_state)
    }
    handlecategoryOnBlur = () => {
        let _state = this.state;
        _state.selectedCategoryInput = this.state.selectedCategory;
        if (_state.selectedCategoryInput == null) {
            _state.selectedCategoryInput = '';
            _state.searchedCategory = null;
        }
        let categoryList = this.state.categoryList;
        let tempCategoryArr = new Array;
        categoryList.map(f => {
            tempCategoryArr.push(this.getAddOptonObject(f.orderId, f.categoryId, f.audit_category_name, f.audit_category_name));
        });
        let optionName = 'Add a New Category';
        tempCategoryArr = tempCategoryArr.filter(v => v.audit_category_name != optionName);
        sortArrayObjectByProperty(tempCategoryArr, 'audit_category_name');
        tempCategoryArr.push(this.getAddOptonObject(categoryList.length, 0, optionName, -99999));
        _state.categoryListOptions = tempCategoryArr;
        _state.categoryShrink = false;
        this.setState(_state)
    }

    handlecategoryChange = (event, value) => {
        let _state = this.state
        if (event != undefined) {
            if (isValidParam(value) && value.value != -99999) {
                _state.selectedCategory = value.value
                _state.selectedCategoryInput = value.value
            } else if (isValidParam(value) && value.value == -99999) {
                let data = new Object();
                data.categoryList = this.state.categoryList;
                data.category = this.state.searchedCategory;
                data.categoryId = value.categoryId;
                data.orderId = value.orderId;
                data.object = constants.AUDIT_QUESTIONS_OBJECT;
                let label = getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_NEW_CATEGORY;
                this.props.getAppDialog(true, constants.AUDIT_CATEGORY_TYPE_ADD_DIALOG, label, null, data, null);
            }
        }
        let categoryList = this.state.categoryList;
        let tempCategoryArr = new Array;
        categoryList.map(f => {
            tempCategoryArr.push(this.getAddOptonObject(f.orderId, f.categoryId, f.audit_category_name, f.audit_category_name));
        });
        let optionName = 'Add a New Category';
        tempCategoryArr = tempCategoryArr.filter(v => v.audit_category_name != optionName);
        sortArrayObjectByProperty(tempCategoryArr, 'audit_category_name');
        tempCategoryArr.push(this.getAddOptonObject(categoryList.length, 0, optionName, -99999));
        _state.categoryListOptions = tempCategoryArr;
        this.setState(_state)
    }
    render() {
        if (this.state.mounted && !this.state.isShowLoader && this.state.type !== null) {
            return (
                <div style={{ marginLeft: '27px', marginTop: '24px', marginRight: '27px', height: '100%' }}>
                    {isValidParam(this.state[this.state.type]) && this.getQuestionContent(this.state.type)}
                </div>
            )
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const getListViewInstanceFromRedux = (state, props) => {
    return state.listView;
}

const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        auditDetails: state.auditDetails,
        listView: getListViewInstanceFromRedux(state, props)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        updateAuditCategory: (data) => {
            dispatch(updateAuditCategory(data));
        },
        updateAuditCategorySelectedValue: (data) => {
            dispatch(updateAuditCategorySelectedValue(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditQuestionTypes)