import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useState } from "react";
import { useParams } from "react-router-dom";

const ChangePassword = () => {
    let param = useParams();
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isPassChanged, setIsPassChanged] = useState(false);
    const [password, setPassword] = useState({ newPassword: { value: '', showPassword: false, error: '' }, confirmPassword: { value: '', showPassword: false, error: '' } });

    const submitForgotPassword = () => {
        if (!password.newPassword.value.trim() && !password.confirmPassword.value.trim()) {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Enter Password' }, confirmPassword: { ...password['confirmPassword'], error: 'Enter Password' } });
        }else if (!password.newPassword.value.trim()) {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Enter Password' } });
        } else if (!password.confirmPassword.value.trim()) {
            setPassword({ ...password, confirmPassword: { ...password['confirmPassword'], error: 'Enter Password' } });
        } else {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: '' }, confirmPassword: { ...password['confirmPassword'], error: '' } });
            if (password.newPassword.value === password.confirmPassword.value) {
                let params = { "encodedParams": param.eKey, "password": password.newPassword.value.trim(), "confirmPassword": password.confirmPassword.value.trim() }
                var promise = Promise.resolve(HTTPClient.getFullResponseWithoutToken(endPoints.LOGIN.RESET_PASSWORD, params));
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        if (response.status === 0) {
                            setIsPassChanged(true);
                            setIsResetPassword(true);
                        } else {
                            setPassword({ newPassword: { ...password['newPassword'], error: 'Enter Password' }, confirmPassword: { ...password['confirmPassword'], error: 'Enter Password' } });
                        }
                    }
                })
            }
            else {
                setPassword({ newPassword: { ...password['newPassword'], error: 'Please make sure your passwords match' }, confirmPassword: { ...password['confirmPassword'], error: 'Please make sure your passwords match' } });
            }
        }
    }
    const userLogout = (e) => {
        window.location.replace('/');
    }

    const setPass = (filedName, value) => {
        if (value.indexOf(" ") > -1) {
            setPassword({ ...password, [filedName]: { ...password[filedName], value: '', error: 'Space not allowed.Enter valid password.' } });
        } else {
            setPassword({ ...password, [filedName]: { ...password[filedName], value: value, error: '' } });
        }
    }
    const handleClickShowPassword = (filedName) => {
        setPassword({ ...password, [filedName]: { ...password[filedName], showPassword: !password[filedName].showPassword } });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="col-md-6 col-md-offset-3" >
            <div className="col-md-10 col-md-offset-1">
                <div className='abc' style={{ width: isPassChanged ? '480px' : '514px', marginTop: '32%', marginLeft: '80%' }}>
                    {(!isResetPassword) &&
                        <Box boxShadow={3} className='sf-login-box'>
                            <Card style={{ height: '340px' }} >
                                <CardContent >
                                    <Typography className='sf-login-Typography' color="textSecondary" gutterBottom bottommargin="10px">
                                        Reset Your Password
                                    </Typography>
                                    <div className="sf-email-adr" >
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <InputLabel
                                                error={password.newPassword.error !== ''} htmlFor="new-password">New Password (required)</InputLabel>
                                            <OutlinedInput
                                                id="new-password"
                                                name="new-password"
                                                label="New Password (required)"
                                                value={password.newPassword.value}
                                                helperText={password.newPassword.error}
                                                onChange={(e) => setPass('newPassword', e.target.value)}
                                                type={password.newPassword.showPassword ? 'text' : 'password'}
                                                error={password.newPassword.error !== ''}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword('newPassword')}
                                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                                            edge="end"
                                                        >
                                                            {password.newPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {password.newPassword.error !== '' && <FormHelperText error sx={{ m: 0 }} id="outlined-weight-helper-text">{password.newPassword.error}</FormHelperText>}
                                        </FormControl>
                                    </div>
                                    <div className="sf-pwd-adr" style={{ marginTop: '25px' }}>
                                        <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                            <InputLabel
                                                error={password.confirmPassword.error !== ''} htmlFor="confirm-password">Confirm New Password (required)</InputLabel>
                                            <OutlinedInput
                                                id="confirm-password"
                                                name="confirm-password"
                                                label="Confirm New Password (required)"
                                                value={password.confirmPassword.value}
                                                helperText={password.confirmPassword.error}
                                                onChange={(e) => setPass('confirmPassword', e.target.value)}
                                                type={password.confirmPassword.showPassword ? 'text' : 'password'}
                                                error={password.confirmPassword.error !== ''}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword('confirmPassword')}
                                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                                            edge="end"
                                                        >
                                                            {password.confirmPassword.showPassword ?  <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {password.confirmPassword.error !== '' && <FormHelperText sx={{ m: 0 }} error id="confirm-password">{password.confirmPassword.error}</FormHelperText>}
                                        </FormControl>
                                    </div>
                                </CardContent>
                                <CardActions style={{ float: 'right' }}>
                                    <div style={{ textAlign: 'center', marginRight: '7px' }}>
                                        <Button variant="contained" style={{ backgroundColor: '#3fc7f6', fontSize: 13, marginBottom: '21px', marginRight: '10px' }} color="primary" size="large" primary={true} onClick={() => submitForgotPassword()} >change</Button>
                                        <Button variant="contained" style={{ backgroundColor: '#ffffff', fontSize: 13, marginBottom: "21px", color: 'black' }} size="large" onClick={() => userLogout()}>Cancel</Button>
                                    </div>
                                </CardActions>
                            </Card>
                        </Box>
                    }
                    {(isPassChanged) &&
                        <Box boxShadow={3} className='sf-login-box'>
                            <Card style={{ height: '320px' }} >
                                <CardContent style={{ marginTop: '40px' }}>
                                    <div style={{ marginTop: '10px', marginLeft: '45%' }} ><i className='fa fa-check' style={{ fontSize: '4em' }} /></div>
                                    <Typography color="textSecondary" gutterBottom align="center" style={{ fontWeight: '700', fontSize: 25 }}>
                                        Your Password has been changed 
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom align="center" style={{ fontWeight: '400', fontSize: 15 }}>
                                        Please sign in with your new password 
                                    </Typography>
                                </CardContent>
                                <div style={{ textAlign: 'center', marginLeft: '30px', marginRight: '30px' }}>
                                    <Button variant="contained" style={{ backgroundColor: '#3fc7f6', fontSize: 13, marginBottom: "21px", }} size="large" onClick={() => userLogout()}  >Sign In </Button>
                                </div>
                            </Card>
                        </Box>
                    }
                </div>
            </div>
        </div>
    );
}
export default ChangePassword;