import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


const RecurringDeleteDialog = ({ object }) => {
    const dispatch = useDispatch();

    const [actionType, setActionType] = useState('only');
    const [changelog, setChangeLog] = useState({actionType:'only'});

    const appContainer = useSelector((state) => state.appContainer);

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleDeleteRecurring = (val) => {
        appContainer.dialogProps.eventName(val);
    }

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,
            <Button

                primary={true}
                onClick={() => handleRedioOnChange()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.OK}</Button>,
        ];

        return actions;
    }

    const selectActionType = (value) => {
        let _changelog = changelog;
        _changelog.actionType = value;
        setChangeLog({..._changelog});
        setActionType(value);

    }
    const handleRedioOnChange = () => {
        let value = changelog.actionType;
        if (value != null) {
            handleDeleteRecurring(value);
        }
    }

    let objName = object;
    let objTxt = '';
    if (objName !== null) {
        if (objName === 'appointments') {
            objTxt = "Appointment";
        } else if (objName === 'tasks') {
            objTxt = "Task";
        }
    }
    let secondCheckboxMarginTop = '7%';
    let thirdCheckboxMarginTop = '12%';
    if (objName === constants.TASKS) {
        secondCheckboxMarginTop = '9%';
        thirdCheckboxMarginTop = '18%';
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm-5">
                    <div>

                        <FormControl component="fieldset">
                            <RadioGroup name="layout" onChange={(event) => selectActionType(event.target.value)} value={changelog.actionType} style={{ marginTop: '10px', fontSize: '15px' }} >
                                <FormControlLabel value="only" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.THIS + " " + objTxt} />
                                <FormControlLabel value="following" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.THIS_AND_FOLLOWING + " " + objTxt + "s"} />
                                <FormControlLabel value="all" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ALL + " " + objTxt + "s"} />

                            </RadioGroup>
                        </FormControl>


                    </div>
                </div>
            </div>
        </div>
    );

}

export default RecurringDeleteDialog;