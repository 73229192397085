import React, { useCallback, useRef} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../services/constants/actions";
import { constants } from '../../../services/constants/constants';
import update from 'immutability-helper'
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { updateAuditChecklistcategories } from '../../../services/actions/auditActions';
import CloseIcon from '@mui/icons-material/Close';
import AuditChecklistChildCard from "./AuditChecklistChildCard";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";

const AuditCheckListCard = ({key,index,id,card}) => {
    
  const dispatch = useDispatch();
  const AuditChecklist = useSelector((state)=> state.auditDetails?.AuditChecklist);
  const ref = useRef(null)


  const deleteTask = (card) =>{
    let _AuditChecklist = [...AuditChecklist];
    _AuditChecklist = _AuditChecklist?.filter((ele) => {return ele.master_id !== card.master_id});
    dispatch(updateAuditChecklistcategories(_AuditChecklist));
  }

  const openAuditCategoryQuestionsDialog = (card) =>{
    let data = new Object();
    data.master_id = card.master_id;
    data.category = card.t_category_name;
    data.object = constants.AUDIT_CHECKLIST_CATEGORY_OBJECT;
    let label =  card.t_category_name + getLocalizedStrings().label.AUDIT_CATEGORY.CATEGORY_QUESTIONS_LABLE;
    dispatch(getAppDialog(true, constants.AUDIT_CATEGORY_QUESTION_TYPE_DIALOG, label, null, data, null));
  }

   const [, drop] = useDrop({
      accept: actions.CHECKLIST_CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    }); 

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
          const dragCard = AuditChecklist[dragIndex]
          let rearrangeRows = update(AuditChecklist, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            });
            dispatch(updateAuditChecklistcategories(rearrangeRows));
        },[dispatch, AuditChecklist],
    )

    const [{ isDragging }, drag] = useDrag({
        type: actions.CHECKLIST_CARD,
        item: {  id, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      });

      const renderSubCard = (item,i,questions) =>{
        return (
          <AuditChecklistChildCard 
            key={item.master_id}
            index={i}
            id={card.master_id}
            card={item}
            questions = {questions}
          />
        )
      }

    drag(drop(ref));

  return (
     <>
            <div style={{marginTop:'3px',display:'flex',width:'97%',borderRadius:'0%',height:'3rem',marginTop:'10px',border:'2px solid #CCCCCC'}}>
              <div style={{width:'3%',background:'#999999',cursor: 'grab',border:'2px solid #CCCCCC'}}  ref={ref}></div>
                <div style={{display:'flex',justifyContent:'space-between', width:'100%',textOverflow:'ellipsis',overflow:'hidden',paddingTop:'4px',paddingBottom:'4px',paddingLeft:'10px',backgroundColor:'#FFFF',alignItems:'center'}}>
                    <div style={{width:'80%',overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                        <span style={{fontSize:'16px',fontWeight:'bold',fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap',paddingBottom:'1px'}}>{card.t_category_name} </span>
                    </div>
                    <div style={{width:'20%',display:'flex'}}>
                        <div style={{textDecoration:'underline',marginRight:'10px',marginBottom:'3px',color:'rgb(5, 138, 229)',cursor:'pointer',fontSize:'15px',alignContent:'center'}} onClick={()=>openAuditCategoryQuestionsDialog(card)}>Questions</div>
                        <CloseIcon title="close" style={{fontSize:'18px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => deleteTask(card)}/>
                    </div>
                </div>
            </div>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
              {card?.questions?.map((item, i) => renderSubCard(item, i,card.questions))}
            </div>
        </>
  )
}

export default AuditCheckListCard