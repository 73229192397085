import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import CardsContainer from './CardsContainer';
import CustomDragLayer from './CustomDragLayer';
import { FormControl, Icon, IconButton, InputLabel, Menu, MenuItem, Popper, Select } from '@mui/material';
import * as ListsActions from '../../../services/actions/listsActions';
import { removeColumnViewHorizontalScrollPosition, removeColumnViewScrollPosition, updateColumnViewHorizontalScrollPosition } from '../../../services/actions/listsActions';
import '../../../asset/styles/styl/kanban.styl';
import { styles } from '../../../services/constants/styles';
import { setHeader } from '../../../services/actions/headerActions';
import { getArrayParam, getBooleanParam, getIntParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { getActiveTab, getActiveTabInfo, TYPE_COLUMN_VIEW } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getMultilingualLabelName, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { populateFiltersFromActiveTab, setListViewMounted } from '../../../services/actions/listViewAcions';
import ListViewFilterDropDown from '../components/listViewFilterDropDown';
import * as sfDialogs from '../components/sfDialogs';
import ListViewSetup from '../components/listViewSetup';
import NoRecord from '../components/noRecord';
import { openLeftSideMenu } from '../../../services/actions/appActions';
import { hasAccessPermission } from '../../../services/helper/common';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';

const localStyles = {
	root: {
		fontSize: 13,
		backgroundColor: '#fff',
	},
};


const ColumnView = (props) => {

	const dispatch = useDispatch();
	const _lists = useSelector(state => state.lists);
	const _isFetching = useSelector(state => state.lists.isFetching);
	const _app = useSelector(state => state.app);
	const reduxTab = useSelector((state) => state['tab']);
	const _wokflowList = useSelector(state => state.lists.wokflowList);
	const _fields = useSelector(state => state.lists.fields);
	const _selectedFields = useSelector(state => state.lists.selectedFields);
	const _totalCount = useSelector(state => state.lists.totalCount);
	const _queueList = useSelector(state => state.queueList);

	const [workflowList, setWorkflowList] = useState([]);
	const [selectedFields, setSelectedFields] = useState([]);

	const [selectedSearchFieldName, setSelectedSearchFieldName] = useState('');
	const [selectedSearchFieldLabel, setSelectedSearchFieldLabel] = useState('')
	const [searchText, setSearchText] = useState('')
	const [isUpdateSearchText, setIsUpdateSearchText] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);
	const [workflowId, setWorkflowId] = useState(-9999);

	const [lastRunQueryId, setLastRunQueryId] = useState(-1);
	const [lastRunQueryType, setLastRunQueryType] = useState('');
	const [lastRunQueryName, setLastRunQueryName] = useState('');

	const [searchAnchorEl, setSearchAnchorEl] = useState(null);
	const [setupAnchorEl, setSetupAnchorEl] = useState(null);
	const [browserName, setBrowserName] = useState(null);
	const isOpenSearchFieldsList = Boolean(searchAnchorEl);
	const isOpenSetupPopover = Boolean(setupAnchorEl);

	let scrollInterval = null;
	let isOnboardingCheck = _app.me.is_franchisee

	const searchDivStyle = {
		...styles.togButton,
		cursor: 'default',
		height: '35px',
		width: '352px',
		border: '1px solid #e0e0e0',
		backgroundColor: 'transparent',
		marginLeft: '5px',
		borderRadius: 2
	};
	const searchTextFieldDivStyle = {
		display: 'inline-block',
		verticalAlign: 'top',
		backgroundColor: 'fff',
		height: '33px',
		float: 'right',
		width: '260px'
	};
	const searchTextFieldStyle = {
		backgroundColor: '#fff',
		paddingLeft: '9px',
		paddingRight: '0px',
		border: 'none',
		height: '31px',
		paddingTop: '4px',
		outline: '0 none',
		color: '#888888',
		width: '230px'
	}


	useEffect(() => {
		dispatch(openLeftSideMenu(false));
		getBrowserName();
		setColumnViewHeader();
	}, []);

	
    useEffect(()=>{
        if(reduxTab?.tabs[1]?.info?.searchText){
            setSearchText(reduxTab?.tabs[1]?.info?.searchText);
			setIsUpdateSearchText(false);
			getColumnView();
        }
		if(reduxTab?.tabs[1]?.info?.searchText == '' ){
            setSearchText('');
        }
    },[reduxTab])

	useEffect(() => {
		const params = populateFiltersFromActiveTab() || {};
		const shouldGetData = isNewFilter(params, props.object);
		if (shouldGetData) {
			getData(props.object);
			let scrollInfo = null;
			if (isValidParam(_lists.HorizontalscrollPosition[props.object])) {
				scrollInfo = _lists.HorizontalscrollPosition[props.object];
			}
			if (scrollInfo !== null) {
				$('.kanbanBord').scrollLeft(scrollInfo);
			}
		}
		setColumnViewHeader();
	}, [props.object, _lists]);


	useEffect(() => {
		processSelectedFields();
	}, [_lists]);

	function manageScroll() {
		let leftPos = $('.kanbanBord').scrollLeft();
		let tempPositionsObj = _lists.HorizontalscrollPosition;
		tempPositionsObj = {
			[props.object]: leftPos
		}
		dispatch(updateColumnViewHorizontalScrollPosition(tempPositionsObj, props.object));
	}

	function getData(object) {
		let activeTab = getObjectParam(getActiveTab());
		let tabType = activeTab.type;
		let defaultWorkflowId = workflowId
		if (tabType === TYPE_COLUMN_VIEW) {
			if (_lists.mounted && _lists.wokflowList) {
				let tempWorkflowList = getArrayParam(_wokflowList);
				if (isOnboardingCheck) {
					tempWorkflowList = tempWorkflowList.filter(f => f.name !== constants.ONBOARDING_WORKFLOW);
				}
	
				if (tempWorkflowList !== null && tempWorkflowList.length > 0) {
					defaultWorkflowId = tempWorkflowList[0].id; 	
					setWorkflowId(defaultWorkflowId);
					handleWorkflowIdChange(defaultWorkflowId);
				}	
				setWorkflowList(tempWorkflowList);
	
				let isGetData = _lists.object !== constants.TASKS ? _lists.mounted : false;
				if (!isGetData || _lists.view === 'KanbanView') {
					getListData(object, defaultWorkflowId);
				}
			} else {
				getWorkflowList(object);
			}
		}
	}

	function getWorkflowList(object) {
		const promise = Promise.resolve(ListsActions.getWorkflowList(object));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (isValidParam(response)) {
					let resWorkflowList = getArrayParam(response);
					dispatch(ListsActions.storeWorkFlowList(resWorkflowList));
					
					if (isOnboardingCheck) {
						resWorkflowList = resWorkflowList.filter(f => f.name !== constants.ONBOARDING_WORKFLOW);
					}
					sortArrayObjectByProperty(resWorkflowList, 'name');
					resWorkflowList.push({ id: -9999, name: "All" });
					setWorkflowList(resWorkflowList);
	
					let defaultWorkflowId = workflowId
					if (resWorkflowList.length) {
						defaultWorkflowId = resWorkflowList[0].id;
					} 
					setWorkflowId(defaultWorkflowId);
	
					if (object && defaultWorkflowId) {
						getListData(object, defaultWorkflowId);
					}
				}
			});
		}
	}

	function handleWorkflowIdChange(id) {
		if (id !== -9999) {
			id = getIntParam(id);
		} else {
			id = parseInt(id, 10);
		}
		setWorkflowId(id);

		let info = getActiveTabInfo();
		info.workflowId = id;

		let cookieName = 'select-' + props.object + '-workflow-' + _app.me.projectId + '-' + _app.me.id;
		setCookie(cookieName, id);
	}

	function initWorkflowId(tempWorkflowList) {
		let _workflowId = -9999;
		try {
	
			let cookieName = 'select-' + props.object + '-workflow-' + _app.me.projectId + '-' + _app.me.id;
			let workflowIdFromCookie = getCookie(cookieName);
			if (workflowIdFromCookie) {
				const lastWorkflowId = parseInt(workflowIdFromCookie);
				if (lastWorkflowId !== -9999) {
					let workflowListtemp = tempWorkflowList.filter((obj) => obj.id === lastWorkflowId);
					if (workflowListtemp.length > 0) {
						_workflowId = lastWorkflowId;
					}
				}
			}

			handleWorkflowIdChange(_workflowId);
		} catch (error) {
			console.error("Error in 'columnView.js -> initWorkflowId()':" + error);
		}
		return _workflowId;
	}

	function getListData(object, selectedWorkflowId) {
		try {
			let info = getObjectParam(getActiveTabInfo());
			let filter = getObjectParam(info.filter);
			let queryName = getStringParam(filter.name);
			let queryType = getStringParam(filter.type);
			let queryId = getIntParam(filter.id);
			let groupByFieldName = getStringParam(info.groupByFieldName);
			if (groupByFieldName === null || groupByFieldName === "") {
				groupByFieldName = 't_status';
			}
			let params = {};
			params.query_name = queryName;
			params.query_type = queryType;
			params.query_id = queryId;

			// let selectedWorkflowId = selectedWorkflowId
			// if (lastRunQueryId > 0 && lastRunQueryId === queryId) {
				if (selectedWorkflowId && selectedWorkflowId !== -9999 && selectedWorkflowId !== 0) {
					params.input_param_sql = "workflow_id = " + selectedWorkflowId;
					info.input_param_sql = params.input_param_sql
				} else if (info.hasOwnProperty('input_param_sql')) {
					delete info.input_param_sql;
				}
			 else {
				selectedWorkflowId = -9999;
			}
			if (selectedWorkflowId === -9999) {
				params.is_call_for_all = true;
			}
			if (selectedWorkflowId !== 0 && selectedWorkflowId <= 0 && workflowList && workflowList.length > 1 && selectedWorkflowId !== -9999 && props.object !== constants.PROJECTS_OBJECT) {
				params.isWorkflowSetup = true;
				info.isWorkflowSetup = true;
			}

			if (object === constants.CONTACTS_OBJECT && getBooleanParam(params.isWorkflowSetup)) {
				if (selectedWorkflowId === 0) {
					params.is_workflow = false;
					info.is_workflow = false;
				} else {
					params.is_workflow = true;
					info.is_workflow = true;
				}

			} else {
				if (info.hasOwnProperty('is_workflow')) {
					delete info.is_workflow;
				}
			}

			let tabSearchFieldName = getStringParam(info.searchFieldName);
			let tabSearchText = getStringParam(info.searchText);
			let tempSearchText = null;
			let tempSelectedSearchFieldName = selectedSearchFieldName;
			let tempSelectedSearchFieldLabel = selectedSearchFieldLabel;
			if (isValidParam(tabSearchText) && tabSearchText.length > 0) {
				tempSearchText = tabSearchText;
				tempSelectedSearchFieldName = tabSearchFieldName;

				let fldName = getStringParam(tempSelectedSearchFieldName);
				let fldValue = getStringParam(tempSearchText);

				info.searchFieldName = fldName;
				info.searchText = fldValue;

				params.search_field_name = fldName;
				params.search_type = constants.SEARCH_TYPE_ANYWHERE;
				params.search_text = fldValue;
			} else {
				tempSearchText = null;
				tempSelectedSearchFieldLabel = '';
			}

			params.group_by_field_name = groupByFieldName;
			params.group_by_condition = '';
			params.start_index = 0;
			params.is_group_by_info = true;
			params.call_from = constants.COLUMN_VIEW;
			params.is_column_view = true;

			params.page_size = ListsActions.DEFAULT_PAGE_SIZE;

			if (info.hasOwnProperty('scrollInfo')) {
				delete info.scrollInfo;
			}
			// workflowList.forEach((wf, i) => {
			// 	if (tempWorkflowId === wf.id) {
			// 		params.workflowName = wf.name;
			// 	}
			// });

			dispatch(ListsActions.getLists(object, params));
			setSelectedSearchFieldName(tempSelectedSearchFieldName);
			setSelectedSearchFieldLabel(tempSelectedSearchFieldLabel);
			setSearchText(tempSearchText);
			handleWorkflowIdChange(selectedWorkflowId);
		} catch (error) {
			console.error("Error in 'columnView.js -> getListData()':" + error);
		}
	}

	// UNSAFE_componentWillReceiveProps(nextProps) {
	//     let params = populateFiltersFromActiveTab();
	//     let object = getStringParam(props.object);
	//     let nextPropsObject = getStringParam(nextProps.object);
	//     if (nextProps.lists.hasOwnProperty("workFlowId")) {
	//         setWorkFlowIdFromRedux(nextProps);
	//     }
	//     if (_lists.hasOwnProperty("queryInfo") && _lists.queryInfo !== undefined ) {
	//         let lastQueryId = _lists.queryInfo !== null && _lists.queryInfo !== undefined
	//             ? _lists.queryInfo.query_id : 0;
	//         state.lastQueryId = lastQueryId;
	//     }
	//     if (isValidParam(object) && isValidParam(nextPropsObject)) {
	//         initWorkflowList();
	//         let isFetchListView = isNewFilter(params, nextPropsObject, nextProps);
	//         if (nextPropsObject !== object || (nextPropsObject === object && isFetchListView)) {
	//             getData(false, nextPropsObject);
	//         }
	//         let leftPos = $('.kanbanBord').scrollLeft();
	//         if (!nextProps.lists.HorizontalscrollPosition.hasOwnProperty(nextPropsObject) && leftPos !== 0) {
	//             $('.kanbanBord').scrollLeft(0);
	//         }
	//         if (nextPropsObject !== object || (nextPropsObject === object && (isFetchListView || state.isWorkFlowChange)) || nextProps.totalCount !== _totalCount) {
	//             setColumnViewHeader(nextProps);
	//         }
	//     }
	//     getBrowserName();
	// }

	// function initWorkflowList() {
	// 	if (_lists.mounted) {
	// 		let tempWorkflowList = getArrayParam(_wokflowList);
	// 		let isOnboarding = getBooleanParam(props.isOnboarding);
	// 		if (isOnboarding) {
	// 			tempWorkflowList = tempWorkflowList.filter(f => {
	// 				return f.name === constants.ONBOARDING_WORKFLOW
	// 			});
	// 		}
	// 		// getWorkFlowListMenuItem(tempWorkflowList);
	// 		setWorkflowList(tempWorkflowList);
	// 	}
	// }

	function isNewFilter(params, nextPropsObject) {
		let isValid = false;
		try {
			let activeTab = getObjectParam(getActiveTab());
			if (isValidParam(params)) {
				params = getObjectParam(params);
				let queryId = getIntParam(params.query_id);
				let queryType = getStringParam(params.query_type);
				let queryName = getStringParam(params.query_name);
				if (lastRunQueryId === -1 && lastRunQueryType === '' && lastRunQueryName === '') {
					isValid = true;
				} else if (activeTab.object === nextPropsObject) {
					if (lastRunQueryType !== queryType) {
						isValid = true;
					} else if (lastRunQueryType === queryType) {
						if (lastRunQueryName !== queryName) {
							isValid = true;
						} else if (lastRunQueryName === queryName) {
							if (lastRunQueryId !== queryId) {
								isValid = true;
							}
						}
					}
				}
				if (isValid) {
					console.info("Should Fetch", isValid);
					setLastRunQueryId(queryId);
					setLastRunQueryType(queryType);
					setLastRunQueryName(queryName);
				}
			}
		} catch (error) {
			console.error("Error in 'columnView.js -> isNewFilter()':" + error);
		}
		return isValid;
	}

	function startScrolling(direction) {
		switch (direction) {
			case 'toLeft':
				setIsScrolling(true);
				scrollLeft()
				break;
			case 'toRight':
				setIsScrolling(true);
				scrollRight()
				break;
			default:
				break;
		}
	}

	function scrollRight() {
		if (document.getElementsByTagName('main').length > 0) {
			function scroll() {
				if(document.getElementsByTagName('main')[0].scrollLeft) {
					document.getElementsByTagName('main')[0].scrollLeft += 10;
				}
			}

			scrollInterval = setInterval(scroll, 10);
		}
	}

	function scrollLeft() {
		if (document.getElementsByTagName('main').length > 0) {
			function scroll() {
				if(document.getElementsByTagName('main')[0].scrollLeft) {
					document.getElementsByTagName('main')[0].scrollLeft -= 10;
				}
			}
			scrollInterval = setInterval(scroll, 10);
		}
	}

	function stopScrolling() {
		clearInterval(scrollInterval);
		setIsScrolling(false);
	}

	function moveCard(lastX, lastY, nextX, nextY) {
		dispatch(ListsActions.moveCard(lastX, lastY, nextX, nextY));
	}

	// function moveList(listId, nextX) {
	// 	const { lastX } = findList(listId);
	// 	dispatch(ListsActions.moveList(lastX, nextX));
	// }

	function findList(id) {
		const { lists } = _lists;
		const list = lists.filter(l => l.id === id)[0];

		return {
			list,
			lastX: lists.indexOf(list)
		};
	}

	function setColumnViewHeader() {
		let isOnboarding = getBooleanParam(props.isOnboarding);
		let filterNameWithCount = getFilterNameWithCount(props);
		let show = false;
		let currentView = null;
		let showAddIcon = true;
		if (props.object === constants.ACCOUNTS_OBJECT || props.object === constants.CONTACTS_OBJECT
			|| props.object === constants.OPPORTUNITIES_OBJECT || props.object === constants.CASES_OBJECT
			|| props.object === constants.PROJECTS_OBJECT || props.object === constants.ISSUES_OBJECT
			|| props.object === constants.CUSTOM_TABLE1_OBJECT || props.object === constants.CUSTOM_TABLE2_OBJECT
			|| props.object === constants.CUSTOM_TABLE3_OBJECT || props.object === constants.CUSTOM_TABLE4_OBJECT
			|| props.object === constants.JOBS_OBJECT) {
			show = true;
			currentView = 'column';
		}

		let kanbanObject = {};
		kanbanObject.currentView = currentView;
		kanbanObject.show = show;

		if (isOnboarding) {
			showAddIcon = false;
			kanbanObject = null;
		}
		dispatch(setHeader(props.object, filterNameWithCount, kanbanObject, showAddIcon, true));
	}

	function getFilterNameWithCount(props) {
		 
		let headerLabel = '';
		let filterAndCount = null;
		let filterName = '';
		let groupByCondition = 'All';
		let list = _lists;
		let totalCount = 0;
		let activeTab = null;
		let tabInfo = null;
		let object = null;
		let isReport = false;
		let filter = null;
		let queryType = '';
		let queryName = '';
		let isShowQueryByFilter = false;
		let isForecast = false;
		let hasPermission = false;

		if (list.object === props.object && _totalCount > 0) {
			totalCount = _totalCount;
		}
		let isOnboarding = getBooleanParam(props.isOnboarding);
		activeTab = getObjectParam(getActiveTab());
		tabInfo = getObjectParam(activeTab.info);
		object = isOnboarding ? constants.ACCOUNTS_OBJECT : getStringParam(activeTab.object);
		isReport = getBooleanParam(tabInfo.isReport);
		// isForecast = getBooleanParam(tabInfo.isForecast);

		filter = tabInfo.filter !== null && tabInfo.filter !== undefined ? getObjectParam(tabInfo.filter) : _lists.queryInfo !== null && _lists.queryInfo !== undefined ? _lists.queryInfo : {};
		queryType = filter.type !== null && filter.type !== undefined ? getStringParam(filter.type) : filter.query_type !== null && filter.query_type !== undefined ? filter.query_type : "";
		queryName = filter.name !== null && filter.name !== undefined ? getStringParam(filter.name) : filter.query_name !== null && filter.query_name !== undefined ? filter.query_name : "";

		if (!isReport && object !== constants.SEARCH_RESULTS && object !== constants.ADVANCE_SEARCH_RESULTS
			&& object !== constants.SOLUTIONS_OBJECT && queryType !== 'group' && !isOnboarding) {
			isShowQueryByFilter = true;
		}

		if (object === constants.SEARCH_RESULTS) {
			let search = getObjectParam(tabInfo.search);
			filterName = getStringParam(search.searchText);
		} else {
			filterName = getStringParam(queryName);
		}

		if (groupByCondition === 'All') {
			groupByCondition = getLocalizedStrings().label.COMMON.ALL;
		}

		groupByCondition = getStringParam(groupByCondition);

		if (object === constants.ACCOUNTS_OBJECT) {
			filterName = getLocalizedStrings().label.FILTER.hasOwnProperty(filterName) ? getLocalizedStrings().label.FILTER[filterName] : filterName;
		}
		if (object === constants.CASES_OBJECT && filterName === 'Cases') {
			filterName = getLocalizedStrings().label.FILTER['Cases'];
		}
		if (object === constants.PROJECTS_OBJECT && filterName === 'Projects') {
			filterName = getLocalizedStrings().label.FILTER['Projects'];
		}
		if (object === constants.ISSUES_OBJECT && filterName === 'Issues') {
			filterName = getLocalizedStrings().label.FILTER['Issues'];
		}
		if (filterName === 'All records') {
			filterName = getLocalizedStrings().label.FILTER['All records'];
		}

		headerLabel = getFilterNameCountsElement(filterName, groupByCondition);
		hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
		hasPermission = _app.selectedModuleName === constants.MODULE_OPERATIONS ? false : hasPermission;
		try {
			filterAndCount =
				<div className="col-sm-12 hdrfiltr" style={
					{
						display: 'flex',
						fontSize: '24px',
						alignItems: 'center',
						justifyContent: 'space-around'
					}
				}>
					{headerLabel}
					<div style={{ fontSize: '14px', cursor: 'pointer', display: 'inline-block', marginLeft: '2px', marginTop: 4,fontWeight:'bold' }}>{' (' + totalCount + ')'}</div>
					<div style={{ marginTop: 6, float: "right" }}>
						{isShowQueryByFilter &&
							<ListViewFilterDropDown object={object} queryName={queryName}/>}
					</div>
					<div>
						{hasPermission && isValidParam(filter) && filter.type === 'filter' &&
							<div style={
								{}
							}>
                            <span style={{ cursor: 'pointer', color: '#717171' }} onClick={() => editFilter(filter)}>
                                <Icon key={filter.id} style={{ 'fontSize': '0.9em' }} title={getLocalizedStrings().label.COMMON.EDIT}>edit</Icon>
                            </span>
							</div>
						}

					</div>
				</div>;
		} catch (error) {
			console.error("Error in 'listView.js -> getFilterNameWithCount()':" + error);
		}
		return filterAndCount;
	}

	function getFilterNameCountsElement(filterName, groupByCondition) {
		let elements = [];
		try {
			let activeTab = getObjectParam(getActiveTab());
			filterName = getStringParam(filterName);
			groupByCondition = getStringParam(groupByCondition);
			if (filterName !== '') {
				elements.push(
					<div title={filterName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 930, float: 'left' }}>{filterName}</div>);
				if (activeTab.object !== constants.ONBOARDING_OBJECT) {
					elements.push(
						<div title={groupByCondition} style={{ float: 'left' }}>&nbsp;{"- " + groupByCondition}</div>);
				}
			} else {
				elements.push(
					<div title={groupByCondition} style={{ float: 'left' }}>{groupByCondition}</div>);
			}
		} catch (error) {
			console.error("Error in 'listView.js -> getFilterNameCountsElement()':" + error);
		}
		return elements;
	}

	function onChangeValue(event) {
		let value = parseInt(event.target.value, 10);
		dispatch(removeColumnViewScrollPosition(props.object));
		dispatch(removeColumnViewHorizontalScrollPosition(props.object));
		getListData(props.object, value);
	}

	function openListViewSetupPopOver(event) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (!isInXpress) {
			event.preventDefault();
			setSetupAnchorEl(event.currentTarget);
		} else {
			let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
		}
	}

	function closeListViewSetupPopOver() {
		setSetupAnchorEl(null);
	}

	function getDataContainer() {
		let pageHeight = window.innerHeight - 247;
		let top = (pageHeight - 10) / 2;
		let { lists, sort_field_name, sort_type } = _lists;
		let info = getObjectParam(getActiveTabInfo());
		if (_lists.mounted && _isFetching && lists !== undefined && lists !== null) {
			if (lists.length > 0) {
				if (info.length === undefined) {
					if (_lists.hasOwnProperty("queryInfo") && _lists.queryInfo !== undefined && info.object != 'products') {
						let queryId = _lists.queryInfo.query_id;
						let queryName = _lists.queryInfo.query_name;
						let queryType = _lists.queryInfo.query_type;
						info.filter = {
							"id": queryId,
							"name": queryName,
							"type": queryType
						}
					}
				}
				return (
					<div id="divKanbanView" onScroll={manageScroll} className="kanbanBord" style={{ width: '100%', overflowX: 'auto', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '0px', margin: '0px' }}>
						<CustomDragLayer snapToGrid={false}/>
						{lists.map((item, i) =>
							<CardsContainer
								key={item.id}
								id={item.id}
								item={item}
								x={i}
								moveCard={moveCard}
								startScrolling={startScrolling}
								stopScrolling={stopScrolling}
								isScrolling={isScrolling}
								object={props.object}
								reportBy={undefined}
								sortBy={sort_field_name}
								sortType={sort_type}
								fields={_fields}
								selectedFields={_selectedFields}
								browserName={browserName}
								workflowId={workflowId}
								queueList={props.hasOwnProperty('queueList') && _queueList !== null ? _queueList : null}
								app={_app}
							/>
						)}
					</div>);
			} else {
				return (
					<NoRecord object={props.object} view={constants.COLUMN_VIEW}/>);
			}
		} else if (_lists.mounted && _isFetching) {
			return (
				<NoRecord object={props.object} view={constants.COLUMN_VIEW}/>);
		} else {
			return (
				<div style={{ width: '100%', height: pageHeight }}>
					<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
						<div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
							<ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }}/>
						</div>
					</div>
				</div>);
		}
	}

	function getColumnView() {
		try {
			getListData(props.object);
		} catch (error) {
			console.error("Error in 'ColumnView.js -> getColumnView()':" + error);
		}
	}

	function columnviewSearch(searchText, name, label) {
		try {
			setSelectedSearchFieldName(name);
			setSelectedSearchFieldLabel(label);
			dispatch(removeColumnViewScrollPosition(props.object));
			dispatch(removeColumnViewHorizontalScrollPosition(props.object));
			searchWithinList(searchText, name);
		} catch (error) {
			console.error("Error in 'ColumnView.js -> columnviewSearch()':" + error);
		}
	}

	function handleSearchFieldsListOpen(event) {
		event.preventDefault();
		setSearchAnchorEl(event.currentTarget);
	}

	function handleSearchFieldsListClose() {
		setSearchAnchorEl(null);
	}

	function populateAvailableSearchFields(selectedFields) {
		let availableSearchFieldList = [];
		let fields = _fields;
		let field = null;
		try {
			let selectedFieldList = getArrayParam(selectedFields);
			selectedFieldList.forEach((fieldName, i) => {
				if (i < 5 && fieldName !== 'workflow_id') {
					field = fields.filter(f => f.name === fieldName);
					if (field !== null && field.length > 0) {
						let data = {};
						data.label = getMultilingualLabelName(props.object, field[0].label);
						data.value = field[0].name;
						availableSearchFieldList.push(
							<MenuItem
								key={field[0].name}
								className='menuitemListviewSearch'
								selected={field[0].name === selectedSearchFieldName}
								style={styles.popoverMenuItem}
								labelstyle={{ color: '#479ccf' }}
								underlinestyle={{ display: 'none' }}
								autowidth={"true"}
								title={getMultilingualLabelName(props.object, field[0].label)}
								onClick={(event) => changeSearchFieldName(event, fieldName)}
							>
								{getMultilingualLabelName(props.object, field[0].label)}
							</MenuItem>
						);
					}
				}
			});
		} catch (error) {
			console.error("Error in 'ColumnView.js -> populateAvailableSearchFields()':" + error);
		}
		return availableSearchFieldList;
	}

	function changeSearchFieldName(event, fieldName) {
		try {
			let name = getStringParam(fieldName);
			let label = name !== '' ? getFieldLabelByFieldName(name) : '';
			setSelectedSearchFieldName(name);
			setSelectedSearchFieldLabel(label);
			setSearchAnchorEl(null);
		} catch (error) {
			console.error("Error in 'ColumnView.js -> changeSearchFieldName()':" + error);
		}
	}

	function handleKeyUp(event, searchText, name, label) {
		try {
			let tempSelectedSearchFieldName = name;
			let tempSelectedSearchFieldLabel = label;
			let search_text = searchText.trim();
			if (event.keyCode === 13) {
				tempSelectedSearchFieldName = name;
				tempSelectedSearchFieldLabel = label;
				searchWithinList(search_text, name);
				setListViewMounted(false, props.object);
			}
			setSelectedSearchFieldName(tempSelectedSearchFieldName);
			setSelectedSearchFieldLabel(tempSelectedSearchFieldLabel);
		} catch (error) {
			console.error("Error in 'columnView.js -> handleKeyUp()':" + error);
		}
	}

	function changeSearchText(event) {
		setSearchText(event.target.value);
		setIsUpdateSearchText(true);
	}


	function searchWithinList(search_text, name) {
		try {
			let searchFieldName = name;
			let tempSearchText = search_text.trim();
			let tab = getObjectParam(getActiveTab());
			if (isValidParam(tab)) {
				let info = getObjectParam(tab.info);
				if ((getStringParam(info.searchFieldName) !== searchFieldName|| (getStringParam(info.searchFieldName) === searchFieldName) && getStringParam(info.searchText) !== tempSearchText)) {

					if (tempSearchText === '') {
						delete info.searchFieldName;
						delete info.searchText;
					} else {
						info.searchFieldName = searchFieldName;
						info.searchText = tempSearchText;
					}
					setSearchText(tempSearchText);
					setIsUpdateSearchText(false);
					getColumnView();
				}
			}
		} catch (error) {
			console.error("Error in 'ColumnView.js -> searchWithinList()':" + error);
		}
	}

	function processSelectedFields() {	
		let searchField = [];
		let tempSelectedFields = [];
		try {
			let lists = getObjectParam(_lists);
			let fields = lists.fields;
			let isMounted = getBooleanParam(lists.mounted);
			tempSelectedFields = isMounted ? getArrayParam(lists.selectedFields) : [];
			tempSelectedFields = tempSelectedFields.length > 6 ? tempSelectedFields.slice(0, 6) : tempSelectedFields;
			let selectedFldLength = 0;
			tempSelectedFields.forEach((fldName, i) => {
				if (fldName === 'workflow_id') {
					selectedFldLength = i + 1;
				}
			});
			tempSelectedFields = (selectedFldLength > 0 && tempSelectedFields.length > selectedFldLength) ? tempSelectedFields.slice(0, selectedFldLength) : tempSelectedFields;

			for (let i = 0; i < tempSelectedFields.length; i++) {
				let searchFieldNew = fields.filter(f => {
					return (f.name === tempSelectedFields[i] && f.field_type !== "datetime" && f.field_type !== "date")
				});
				if (searchFieldNew.length > 0) {
					searchField.push(searchFieldNew[0].name);
				}
			}
			tempSelectedFields = searchField;

			if (tempSelectedFields.length > 0) {
				if (tempSelectedFields[0] === 'lead_score' && tempSelectedFields.length > 1) {
					tempSelectedFields[0] = tempSelectedFields[1];
					tempSelectedFields[1] = 'lead_score';
				}

				let tabInfo = getActiveTabInfo();
				let tabSearchFieldName = getStringParam(tabInfo.searchFieldName);
				let tabSearchText = getStringParam(tabInfo.searchText);


				let tempSelectedSearchFieldName = getStringParam(selectedSearchFieldName);
				let tempSelectedSearchFieldLabel = getStringParam(selectedSearchFieldLabel);
				if (tabSearchFieldName !== '' && !isUpdateSearchText) {
					tempSelectedSearchFieldName = tabSearchFieldName;
					tempSelectedSearchFieldLabel = getFieldLabelByFieldName(tempSelectedSearchFieldName);
				}

				if (tempSelectedSearchFieldName === '' || tempSelectedSearchFieldLabel === '' || tempSelectedFields.indexOf(tempSelectedSearchFieldName) < 0) {
					tempSelectedSearchFieldName = getStringParam(tempSelectedFields[0]);
					tempSelectedSearchFieldLabel = getFieldLabelByFieldName(tempSelectedSearchFieldName);
					delete tabInfo.searchFieldName;
					delete tabInfo.searchText;
				}
				setSelectedFields(tempSelectedFields);
				setSelectedSearchFieldLabel(tempSelectedSearchFieldLabel);
				setSelectedSearchFieldName(tempSelectedSearchFieldName);
			}
			if (lists.mounted && lists.workFlowId) {
				handleWorkflowIdChange(lists.workFlowId);
			}
		} catch (error) {
			console.error("Error in 'ColumnView.js -> processSelectedFields()':" + error);
		}
	}

	function clearSearchText (){
		document.getElementById("searchText").value =''
		setSearchText('')
		reduxTab.tabs[1].info.searchText =''
	}

	function getSearchComponent() {
		let component = null;
		try {
			if (selectedFields.length > 0) {
				let searchTextPlaceHolder = getLocalizedStrings().label.COMMON.SEARCH_PLACEHOLDER;
				let multilingualLabel = getMultilingualLabelName(props.object, selectedSearchFieldLabel);
				component =
					<div style={searchDivStyle}>
						<div>
							<div
								onClick={(e) => handleSearchFieldsListOpen(e)}
								style={{ cursor: 'pointer', display: 'inline-block', color: '#717171', paddingLeft: '5px', paddingTop: '7px', width: '89px', float: 'left' }}>
								<div title={multilingualLabel} style={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>{multilingualLabel}</div>
								<div>
									<Icon style={{ color: '#717171' }}>keyboard_arrow_down</Icon>
								</div>
							</div>
							<div style={searchTextFieldDivStyle}>
								<div style={{ display: 'inline-block' }}>
									<input
										type='text'
										id="searchText"
										value={searchText}
										onKeyUp={(e) => handleKeyUp(e, e.target.value, selectedSearchFieldName, multilingualLabel)}
										onChange={(event) => changeSearchText(event)}
										placeholder={searchTextPlaceHolder} style={searchTextFieldStyle}
									/>
								</div>
								<div style={{ display: 'inline-block' }}>
									<i className="fa fa-search"
									   title={getLocalizedStrings().label.COMMON.CLICK_TO_SEARCH}
									   onClick={() => columnviewSearch(searchText, selectedSearchFieldName, multilingualLabel)}
									   style={{ fontSize: '14px', marginTop: '6px', paddingRight: '5px', color: '#ccc', cursor: 'pointer', paddingLeft: '5px' }}
									/>
								</div>
							</div>
						</div>
						<Menu
							open={isOpenSearchFieldsList}
							anchorEl={searchAnchorEl}
							anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
							targetOrigin={{ horizontal: 'left', vertical: 'top' }}
							onClose={handleSearchFieldsListClose}
							id='modules'
							transformOrigin={{ vertical: "top", horizontal: "center" }}
						>
							{populateAvailableSearchFields(selectedFields)}
						</Menu>
					</div>;
			}
		} catch (error) {
			console.error("Error in 'ColumnView.js -> getSearchComponent()':" + error);
		}
		return component;
	}

	function getFieldLabelByFieldName(fieldName) {
		let label = null;
		let fields = [];
		try {
			fieldName = getStringParam(fieldName);
			if (fieldName !== "") {
				let lists = getObjectParam(_lists);
				let isMounted = getBooleanParam(lists.mounted);
				fields = isMounted ? getArrayParam(lists.fields) : [];
				fields = fields.filter(f => {
					return f.name === fieldName
				});
				label = fields.length > 0 ? getStringParam(fields[0].label) : "";
			}
		} catch (error) {
			console.error("Error in 'ColumnView.js -> getFieldLabelByFieldName()':" + error);
		}
		return label;
	}

	function getBrowserName() {
		let browser = null;
		if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
			browser = 'Opera';
		} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
			browser = 'Chrome';
		} else if (navigator.userAgent.indexOf("Safari") !== -1) {
			browser = 'Safari';
		} else if (navigator.userAgent.indexOf("Firefox") !== -1) {
			browser = 'Firefox';
		} else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
		{
			browser = 'IE';
		} else {
			browser = 'unknown';
		}

		setBrowserName(browser);
	}

	function editFilter(filter) {
		if (isValidParam(filter)) {
			let hasPermission = null;
			hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
			if (hasPermission) {
				try {
					let label = filter.name;
					let data = {};
					data.object = 'filter';
					data.id = filter.id;
					data.table = props.object.toLowerCase();
					data.mode = 'edit';
					data.callFromListView = true;
					dispatch(getAppDrawer(true, label, constants.FILTER, data, null));
				} catch (error) {
					console.error("Error in 'listView.js -> editFilter()':" + error);
				}
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		}
	}


	if (props.object === constants.CONTACTS_OBJECT) {
		searchDivStyle.marginLeft = '2px';
	}
	if (_app.me.language === "de-DE") {
		searchDivStyle.width = '504px';
		searchTextFieldDivStyle.width = '412px';
		searchTextFieldStyle.width = '384px';
	}

	let contentHeight;
	contentHeight = document.body.clientHeight - 251;
	let top = (contentHeight - 10) / 2;
	return (
		<div style={styles.workAreaContainer}>
			{_lists.mounted ?
				<div id="divKanbanView" style={{ height: contentHeight, padding: '0px' }}>
					<div className="clearfix" style={{ paddingLeft: '20px' }}>
						<div style={{ float: 'left' }}>
							{(workflowList && workflowList.length > 1) &&
								<FormControl variant="outlined" style={{ width: '100%' }}>
									<InputLabel id="sf-columnview-simple-select-outlined-label" className="sf-columnview">{getLocalizedStrings().label.KANBAN_REPORT.WORKFLOW}</InputLabel>
									<Select
										key="workflowlist"
										label={getLocalizedStrings().label.KANBAN_REPORT.WORKFLOW}
										id='workflowlist'
										value={workflowId}
										onChange={(e) => onChangeValue(e)}
										name='workflowlist'
										style={{ height: '35px', marginRight: '5px' }}
										className={"sf-fields-bg"}
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left'
											},
											getContentAnchorEl: null
										}}
									>
										{
											workflowList.map((wf) => {
												return (
													<MenuItem
														key={wf.id}
														value={wf.id}
														style={styles.popoverMenuItem}
													>
														<div
															style={{
																textOverflow: 'ellipsis',
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																width: '162px'
															}}
															title={wf.name}
														>{wf.name}</div>
													</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
							}
						</div>
						{getSearchComponent()}
						<div className="pageSetColumnView" style={{ marginTop: '10px', cursor: 'pointer', width: "2%", float: 'left', marginLeft: '5px', height: '16px' }}>
							<IconButton onClick={(e) => openListViewSetupPopOver(e)} style={{ color: '#717171', padding: 0 }}>
								<Icon style={{ opacity: 0.7, fontSize: '16px', paddingLeft: '1px' }} title={getLocalizedStrings().label.LIST_VIEW.SET_UP}>settings</Icon>
							</IconButton>
							<Popper
								open={isOpenSetupPopover}
								anchorEl={setupAnchorEl}
								placement="left"
								sx={{...styles.popperStyle}}
							>
								<ListViewSetup
									object={props.object}
									closeEvent={() => closeListViewSetupPopOver()}
									isDetailView={props.isDetailView}
									columnView={true}
									showWorkflowList={workflowList && workflowList.length > 1}
									clearSearch ={clearSearchText}
								/>
							</Popper>
						</div>
					</div>
					{getDataContainer()}
				</div> :
				<div style={{ width: '100%', height: contentHeight }}>
					<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '47%' }}>
						<div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
							<ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }}/>
						</div>
					</div>
				</div>}

		</div>
	);
}

export default ColumnView;