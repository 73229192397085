import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery';
import ShowCircularProgress from '../components/circularProgress';
import { GridComponent, ColumnsDirective, ColumnDirective, Selection, RowDD, Inject, Edit } from '@syncfusion/ej2-react-grids'
import { setHeader } from '../../../services/actions/headerActions';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, getBooleanParam, getIntParam, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getListViewData, getListViewSelectedRecord } from '../../../services/actions/listViewAcions';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { moveArrayItemToNewIndex,sortArrayObjectByProperty } from '../../../services/helper/utils';
import { saveListViewLayoutRowWidth } from '../../../services/actions/listViewAcions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getAppDrawer,getAppCustomDrawer } from '../../../services/actions/appContainerActions';
const getListViewInstanceFromRedux = (state, props) => {
    return state.listView;
}

const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        listView: getListViewInstanceFromRedux(state, props)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getListViewSelectedRecord: (selectedRows, isDetailView, isExpandedTaskList) => {
            dispatch(getListViewSelectedRecord(selectedRows, isDetailView, isExpandedTaskList));
        },
        getListViewData: (object, params) => {
            dispatch(getListViewData(object, params));
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        }, 
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },

    }
};

class DraggableDataTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.pageSettings = { pageSize: 50 }
        this.state = {
            listViewData: props.listView.data,
            parentRecordId: null,
            queryName: null,
            queryType: null,
            columnsData: null,
            records: null,
            object: null,
        }
    }
    componentDidMount() {
        this.preparePropsData(this.props);
    }

    componentDidUpdate() {
        if (this.state.listViewData === null) {
            this.preparePropsData(this.props);
        }
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.listViewData === null) {
            this.preparePropsData(nextProps);
        }
        if (this.state.listViewData !== null && this.state.listViewData !== nextProps.listView.data) {
            this.preparePropsData(nextProps);
        }
    }
    preparePropsData = (props) => {
        try {
            let _state = this.state;
            let object = getStringParam(props.object)
            if (isValidParam(props) && props.listView !== null) {
                let listViewData = props.listView.data;
                _state.listViewData = listViewData;
                _state.parentRecordId = getIntParam(listViewData.parent_record_id);
                _state.queryName = listViewData.query_name;
                _state.queryType = listViewData.query_type;
                _state.columnsData = listViewData.fields;
                _state.records = listViewData.records;
                _state.object = object;
            }
            this.setState(_state)
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> preparePropsData()':" + error);
        }
    }
    getTypes = (type) => {
        try {
            switch (type) {
                case 'Text':
                    return 'Short Text'
                case 'Comment':
                    return 'Long Text'
                case 'List':
                    return 'Single Choice (dropdown)'
                case 'Checkbox':
                    return 'Multiple Choice (checkboxes)'
                default:
                    return ''
            }
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> getTypes()':" + error);
        }
    }

    processDataSource = () => {
        try {
            let object = this.props.object;
            let questionsArr = []
            let listViewData = this.state.listViewData;
            let res = listViewData.records;
            if (listViewData !== undefined && listViewData !== null) {
                if (object === constants.AUDIT_QUESTIONS_OBJECT) {
                    for (let index = 0; index < res.length; index++) {
                        let tempObj = {};
                        tempObj = res[index];
                        this.state.listViewData.fields.map((f) => {
                            if (f.name === 'type') {
                                if(res[index][f.name] === 'Text' ||  res[index][f.name] =='Comment' ||  res[index][f.name] === 'Checkbox'|| res[index][f.name] =='List' ){
                                tempObj[f.name] = this.getTypes(res[index][f.name]);
                                }
                            } else if (f.name === 'required') {
                                if(res[index][f.name] === 1 || res[index][f.name] === 0){
                                    tempObj[f.name] = res[index][f.name] === 1 ? 'Required' : 'Not Required';
                                }
                            } else {
                                tempObj[f.name] = res[index][f.name];
                            }
                        })
                        questionsArr.push({...tempObj});
                    }
                } else {
                    questionsArr = getArrayParam(res);
                }
            }

            this.props.listView.data = this.state.listViewData;
            sortArrayObjectByProperty(questionsArr, 'order_id');
            return questionsArr
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> processDataSource()':" + error);
        }
    }

    rowDataBound = (args) => {
        
        try {
            if (isValidParam(args) && isValidParam(args.row)) {
                var element = args.row;
                let groupByCondition = this.props.listView.groupByInfo.group_by_condition;
                if (isValidParam(element)) {
                    let dataElement = element.querySelectorAll("td");
                    for (let i = 0; i < dataElement.length; i++){
                        let columnElement = dataElement[i];
                        if (isValidParam(columnElement)) {
                            let text = columnElement.innerText;
                            if((groupByCondition === 'All' && i== 1)||(groupByCondition !== 'All' && i== 2)){
                                text = text !== '' ? text : 'Empty';
                                columnElement.innerHTML = '';
        
                                var hyperLinkEl = document.createElement('a');
                                hyperLinkEl.style.cursor = 'pointer'
                                hyperLinkEl.innerText = text;
                                hyperLinkEl.title = text;
        
                                hyperLinkEl.addEventListener("click", this.editMode.bind(this, args.data));
                                columnElement.appendChild(hyperLinkEl);
                            }
                            else if((groupByCondition === 'All' && i== 2)||(groupByCondition !== 'All' && i== 3)){
                                text = text !== '' ? text : 'Empty';
                                columnElement.innerHTML = '';
                                var spanElement = document.createElement('span');
                                spanElement.style.cursor = 'default';
                                spanElement.innerText = text;
                                spanElement.title = text;
                                columnElement.appendChild(spanElement);
                            }else if ((groupByCondition === 'All' && i!= 1 && i!= 2 && i!= 0)||(groupByCondition !== 'All' && i !== 2 && i !== 3 && i!= 1 && i!= 0)){
                                columnElement.innerHTML = '';
                                var spanElement = document.createElement('span');
                                spanElement.style.cursor = 'default';
                                spanElement.innerText = text;
                                spanElement.title = text;
                                columnElement.appendChild(spanElement);
                            }

                        }

                    }
                    
                    
                }
            }
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> rowDataBound()':" + error);
        }
    }

    editMode = (data) => {
        try {
            let selectedObject = this.props.listView.data?.object;            
            if(selectedObject === constants.AUDIT_CHECKLISTS_OBJECT){
                let style = {};
                style.width = '45%';
                data.object = selectedObject;
                data.actionType = "EDIT";
                let labelName = getLocalizedStrings().label.COMMON.EDIT;
                this.props.getAppCustomDrawer(true, labelName, "Edit", style, data, null, false);
            }else{
                this.props.getAppDrawer(true, 'Edit', constants.AUDIT_QUESTIONS_OBJECT, data, constants.LIST_VIEW);
            }
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> editMode()':" + error);
        }
    }

    rowSelected = (row) => {
        try {
            if (row.name === 'rowSelected') {
                if (row.data.length === undefined || (row.data.length === 1 && this.props.listView.selectedRows.length === 1)) {
                    this.props.listView.selectedRows.push({ rowIdx: row.rowIndex, id: row.data.id, title: row.data.Question, row: row.data });
                } else {
                    for (let index = 0; index < row.data.length; index++) {
                        this.props.listView.selectedRows.push({ rowIdx: row.rowIndexes[index], id: row.data[index].id, title: row.data[index].Question, row: row.data[index] });
                    }
                }
                this.props.getListViewSelectedRecord(this.props.listView.selectedRows, false, false);
            } else {
                if (row.data.length === undefined || (row.data.length === 1 && this.props.listView.selectedRows.length === 1)) {
                    this.props.listView.selectedRows = this.props.listView.selectedRows.filter((el) => {
                        return el.rowIdx !== row.rowIndex;
                    });
                } else {
                    this.props.listView.selectedRows = []
                }
                this.props.getListViewSelectedRecord(this.props.listView.selectedRows, false, false);
            }
            setTimeout(() => {
                $('.e-headerchkcelldiv').find('div').find('span').removeClass('e-stop')
            }, 1)
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> rowSelected()':" + error);
        }
    }
setReorderedData=(data)=>{
    let _state = this.state;
    let params = {};
    let paramsArr = [];
    let orderedData = [];
    let records = this.state.listViewData.records;
    sortArrayObjectByProperty(records, 'order_id');
    let indexedData = moveArrayItemToNewIndex(records,data.fromIndex,data.dropIndex);
    indexedData.map((el,i) => {
        let orderedDataObj = el;
        orderedDataObj.order_id = i+1;
        let tempObj = {};
        tempObj.questionId = el.id;
        tempObj.orderId = i+1;
        paramsArr.push(tempObj);
        orderedData.push(orderedDataObj);
    })
    params.questions=paramsArr;
    const url = endPoints.AUDIT_QUESTIONS.REARRANGE
                HTTPClient.post(url, params)
                    .then(res => {
                        if (res.status === 0) {
                            _state.listViewData.records = orderedData;
                            this.setState(_state);
                            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                            
                        } else {
                            this.props.showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        }
                    })
}
    rowDrop = (args) => {
        try {
            if (args.data.length === 1) {
                this.setReorderedData(args);
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().label.AUDIT_QUESTIONS.SINGLE_RECORD, styles.snackbarBodyStyleWarning, styles.snackBarStyleTop);
                let params = { "query_name": "All Audit Questions", "query_type": "query" }
                refreshListView(constants.AUDIT_QUESTIONS_OBJECT, params)
            }
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> rowDrop()':" + error);
        }
    }
    handleColumnResizing = () => {
        
        let _state = this.state;
        let element = document.getElementsByClassName("e-resized");
        if (element !== undefined && element !== null && element.length>0) {
            for(let i = 0; i < element.length; i++) {
                let width = element[i].offsetWidth;
                let fieldLabel = element[i].outerText;
                _state.listViewData.fields.map(function(element) { 
                    if(element.label === fieldLabel){
                        element.width = width;
                    }
                    return element;
                 })
            }
            this.onColumnResize(_state.listViewData.fields)
            this.setState(_state);
        }

    }
    onColumnResize = (columnsData) => {
        let isLookup = false;
        let updatedFieldId = 0;
        let params = {};
        let object = null
        let listViewData = null;
        try {
            let info = getObjectParam(getActiveTabInfo());
            let isInXpress = getBooleanParam(info.isInXpress);
            isLookup = getBooleanParam(this.props.isLookup);
            object = this.props.object;
            listViewData = this.props.listView.data;
            if (!isLookup && !isInXpress && isValidParam(listViewData)) {
                if (object === constants.AUDIT_QUESTIONS_OBJECT) {
                    params.query_name = listViewData.query_name;
                    params.query_type = listViewData.query_type;
                    params.query_id = listViewData.query_id;
                }
                params.page_size = listViewData.page_size;
                params.sort_field_name = listViewData.sort_field_name;
                params.sort_type = listViewData.sort_type;

                let isShowPic = getBooleanParam(listViewData.is_show_pic);
                params.is_show_pic = isShowPic;
                params.field_width_info = [];
                let fields = getArrayParam(columnsData);
                fields = fields.filter(field => { return field !== null && !field.is_hidden });
                fields.map((field, index) => {
                    params.field_width_info.push({
                        id: field.id,
                        name: field.name,
                        width: field.width< 80 ? 80 : field.width
                    });
                });

                params.child_objects = [];
                var promise = Promise.resolve(saveListViewLayoutRowWidth(object, params));
                if (isValidParam(promise)) {
                    promise.updateRowOnRedux = this.updateRowOnRedux;
                    promise.then((response) => {
                       // this.props.listView.data = this.state.listViewData;
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'draggableDataTable.js -> onColumnResize()':" + error);
        }

    }


    render() {
        let contentHeight = document.body.clientHeight - 250;
        let gridComponentHeight = contentHeight - 60;
        let progressDivWidth = '100%';
        let top = (contentHeight - 10) / 2;
        let loaderLeftPadding = '45%';
        let object = this.props.object;
        let groupByCondition = null;
        let listViewData = this.state.listViewData;
            let res = listViewData.records
        if (this.state.listViewData !== null && this.state.listViewData.records.length>0) {
            let fields = this.state.listViewData.fields;
            groupByCondition = this.props.listView.groupByInfo.group_by_condition;
            let tempColumn = fields.filter(f => f.is_hidden !== true);
            if(object === constants.AUDIT_QUESTIONS_OBJECT){
                tempColumn = tempColumn.filter(f => f.name !== "order_id");
            }
            // window.addEventListener('mouseup', (event) => {
            //     this.handleColumnResizing()
            //   });
            return (
                
                <div className="control-pane"  >
                    <div style={{overflowY:object === constants.AUDIT_QUESTIONS_OBJECT? 'hidden':'', marginTop:object === constants.AUDIT_QUESTIONS_OBJECT?'40px':''}}>
                        <GridComponent
                            ref={g => this.grid = g}
                            enableStickyHeader={true}
                            rowDrop={e => this.rowDrop(e)}
                            rowSelected={(row)=>this.rowSelected(row)}
                            //allowResizing={false}
                            autoFitColumns={true}
                            rowDeselected={(row)=>this.rowSelected(row)}
                            dataSource={this.processDataSource()}
                            allowRowDragAndDrop={groupByCondition === 'All'? false:true}
                            height={object === constants.AUDIT_QUESTIONS_OBJECT? gridComponentHeight : contentHeight}
                            width="100%"
                            rowDataBound={(args)=>this.rowDataBound(args)}
                            selectionSettings={{ type: 'Multiple', checkboxOnly: 'ResetOnRowClick' }}
                        >
                            <ColumnsDirective>
                                <ColumnDirective type="checkbox" showCheckbox={true} width="50" headerText='Select' ></ColumnDirective>
                                {tempColumn.map((f) => {
                                    return (
                                        <ColumnDirective
                                            key={f.id}
                                            isPrimaryKey={f.name === "order_id" ? true : false}
                                            field={f.name}
                                            headerText={f.label}
                                            // minWidth='20'
                                            // width={f.width}
                                        ></ColumnDirective>
                                    );
                                })}
                            </ColumnsDirective>
                            <Inject services={[RowDD, Selection, Edit]} />
                        </GridComponent>
                    </div>
                </div>
            );
        }else if(this.state.listViewData !== null && this.state.listViewData.records.length==0){
            <div style={{ width: progressDivWidth, height: contentHeight }}>No Data</div>
        }
         else {
            return (
                <div style={{ width: progressDivWidth, height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: loaderLeftPadding }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                            <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                        </div>
                    </div>
                </div>);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableDataTable);
