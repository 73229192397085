import { Box, Typography } from "@mui/material";
import { useEffect, useState, memo } from "react";
import SfDataTable from "./sfDataTable";
import * as HTTPClient from '../../../services/helper/httpClient';
import { DURATION_ITEMS } from "./AuditPerformanceReport";
import ShowCircularProgress from "./circularProgress";
import { styles } from "../../../services/constants/styles";
import { endPoints } from "../../../services/constants/endPoints";


const Loading = () => {
    return <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
    </div></div>
}


const AuditWeaknessFailedReport = (props) => {

    const [state, setState] = useState({
        records: [],
        loading: true
    });

    const { period, questionId } = props?.data;
    const periodValue = DURATION_ITEMS.find(item => item.label === period)?.value;

    useEffect(() => {
        (async () => {
            try {
                const response = await HTTPClient.get(endPoints.AUDIT_ANSWERS.AUDIT_FAILURE_REPORT, JSON.stringify({ period: periodValue, questionId: questionId.toString() }));
                if (Array.isArray(response.records)) {
                    const records = response.records.sort((a, b) => b.failure_count - a.failure_count);
                    setState((prev) => ({ ...prev, records: records, loading: false }));
                };
            } catch (error) {
                console.log(error);
            }

        })()
    }, []);

    if (state.loading) {
        return (<Loading />)

    }
    if (state.records.length === 0) {
        return (
            <Typography variant="h6" sx={{ marginLeft: '10px', color: 'red' }}>No records found</Typography>
        )
    }

    return <Box style={{ padding: '2rem' }}>
               <Box sx={{ marginLeft: '10px' }}>
            <Typography>
                <Typography component="span" variant="h6">Question: </Typography>
                <Typography component="span" variant="body1">{state?.records[0]?.question_name}</Typography>
            </Typography>
            <Typography>
                <Typography component="span" variant="h6">Period: </Typography>
                <Typography component="span" variant="body1">{period}</Typography>
            </Typography>
        </Box>

        <Box sx={{ marginTop: '1rem' }}>
            <SfDataTable columns={columns} rows={state.records} />
        </Box>
    </Box>
}

export default memo(AuditWeaknessFailedReport);

const columns = [
    { name: 'unit', label: 'Unit', width: 90, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: false, style: {} },
    { name: 'failure_count', label: 'Failure Count', width: 60, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: false, style: {} },
];