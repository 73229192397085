import React, { useState, useEffect } from 'react';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants} from '../../../services/constants/constants';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from './sfDataTable';
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Box,
} from '@mui/material';
import AutocompleteMultiSelect from './AutoCompleteMultiselect';
import ShowCircularProgress from './circularProgress';
import { updateAuditWeaknessData } from '../../../services/actions/auditActions.js';
import { getAuditCategory } from '../../../services/actions/auditActions.js';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions.js';


const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

const PERIOD_ITEMS = ["This Month", "This Quarter", "This Year", "Last Month", "Last Quarter", "Last Year"];

const AuditWeaknessReport = () =>{
    const dispatch =  useDispatch();
    const AuditWeaknessData = useSelector((state)=>state.auditDetails.AuditWeakness);
    const [isLoading,setIsLoading] = useState(false);
    const [showReport,setshowReport] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [selectedCategoroies ,setselectedCategoroies] = useState([]);
    const [period, setPeriod] = useState('');
    const [failureCount,setFailureCount] = useState('');
    const [rows,setRows] = useState([]);
    const [columns,setColumns] = useState([]);

    
    useEffect(()=>{
        if(Object.keys(AuditWeaknessData).length !== 0){
            if (AuditWeaknessData.hasOwnProperty('availableParams')) {
                const availableParams = AuditWeaknessData.availableParams;
                if (availableParams && availableParams.hasOwnProperty('categoriesOptions')) {
                    setCategoriesOptions(availableParams.categoriesOptions);
                }
            }
            if (AuditWeaknessData.hasOwnProperty('selectedParams')) {
                const selectedParams = AuditWeaknessData.selectedParams;
                if(selectedParams && selectedParams.hasOwnProperty('selectedCategoroies')){
                    setselectedCategoroies(selectedParams.selectedCategoroies);
                }
                if(selectedParams && selectedParams.hasOwnProperty('period')){
                    setPeriod(selectedParams.period);
                }
                if(selectedParams && selectedParams.hasOwnProperty('failureCount')){
                    setFailureCount(selectedParams.failureCount);
                }
            }
            if(AuditWeaknessData.hasOwnProperty('columns')){
                setColumns(AuditWeaknessData.columns);
            }
            if(AuditWeaknessData.hasOwnProperty('rows')){
                setRows(AuditWeaknessData.rows);
            }
            setIsLoading(false);
            setshowReport(true);
        }
    },[])

    useEffect(()=>{
        fetchUnits();
        dispatch(setHeader(constants.AUDIT_WEAKNESS, getLocalizedStrings().label.AUDIT_WEAKNESS_REPORT.AUDIT_WEAKNESS_REPORT_TITLRE, null, false, true));
    },[])


    function fetchUnits() {
        setIsLoading(true);
        let promise = Promise.resolve(getAuditCategory());
        if (isValidParam(promise)) {
            promise.then((response) => {
                let tempUnits = [];
                response.map((items)=>{
                    let data ={};
                    data.id = items.t_pk;
                    data.label = items.t_category_name;
                    tempUnits.push(data)
                })
                tempUnits.sort(function(a, b){
                    if(a.label < b.label) { return -1; }
                    if(a.label > b.label) { return 1; }
                    return 0;
                })
                setCategoriesOptions([...tempUnits]);
                setIsLoading(false);
            })
        }
    }

    const handlePeriodChange=(tempPeriod)=>{
        if (tempPeriod && tempPeriod !== "") {
            setPeriod(tempPeriod);
        }
    }


    const handleFailureCountChange = (value) =>{
        const numericValue = value.replace(/[^0-9]/g, ""); 
        setFailureCount(numericValue);
    }
    const getReportFilter = () =>{
        let element = (
            <>
                {/* Categories */}
                <div className="row" style={{ padding: '0px', display: "flex", marginTop:'15px' }}>
                    <CustomDateInputStyle />
                    <div className="col-md-4" style={{ paddingLeft: '0px' ,width:'400px'}} >
                        <AutocompleteMultiSelect
                            value={selectedCategoroies}
                            items={Object.keys(AuditWeaknessData).length !== 0 ? AuditWeaknessData?.availableParams?.categoriesOptions : categoriesOptions}
                            placeholderText={getLocalizedStrings().label.AUDIT_WEAKNESS.CATEGORY}
                            selectAllLabel='All'
                            onChange={setselectedCategoroies}
                        />
                    </div>

                    {/* period */}

                    <div className="col-md-2" style={{ paddingLeft: '0px',display:'flex',width:'12rem' }}>
                        <FormControl variant="outlined" style={{  }} className='rrfc-select'>
                            <InputLabel id="sf-salesandrefund-simple-select-timeline-outlined-label" style={{marginTop:period.length > 0 ?'3px' :0}} className="sf-salesandrefund">{getLocalizedStrings().label.SALES_AND_REFUND.PERIOD}</InputLabel>
                            <Select
                                key="timeline"
                                name="timeline"
                                labelId='timeline-label'
                                id='SalesandrefundPeriod'
                                style={{width:'12rem'}}
                                value={period}
                                size="small"
                                className={"sf-select-height"}
                                label={getLocalizedStrings().label.SALES_AND_REFUND.PERIOD}
                                onChange={(event) => handlePeriodChange(event.target.value)}
                            >
                                {
                                    PERIOD_ITEMS.map(item => <MenuItem key={item} value={item} style={styles.popoverMenuItem} >{item}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>

                    {/* Min failure Count  */}

                    <div className="col-md-2" style={{ paddingLeft: '0px',display:'flex',width:'12rem',marginLeft:'15px' }}>
                        <FormControl style={{ width: '12rem' }} className="test" noValidate autoComplete="off">
                            <TextField
                               id='MinFailureCount1' 
                               label={getLocalizedStrings().label.AUDIT_WEAKNESS.MIN_FAILURE_COUNT}
                               variant="outlined"
                               style={{width:'100%'}}
                               InputProps={{ style: { height: '46px' } }}
                               value={failureCount} 
                               onChange={(event) => handleFailureCountChange(event.target.value)}
                               size='small'
                            />
                        </FormControl>
                    </div>

                    <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', marginLeft: '-46px', alignItems: 'center',paddingLeft:'4rem' }}>
                        <Button
                            onClick={() => getReportData()}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '56px',
                                width: '19%'
                            }}
                            title={getLocalizedStrings().label.COMMON.GO}
                        > {getLocalizedStrings().label.COMMON.GO}</Button>
                    </div>
                </div>
            </>
        )
        return element;
    }

    const getReportData = () =>{
        const params = {};
        let valid = true;
        if (selectedCategoroies && selectedCategoroies.length > 0) {
            params.categories = selectedCategoroies.map(u => u.label);
        } else {
            valid = false;
        }
        if(period){
            let _period = '';
            if(period === 'This Month'){
                _period = 'tm';
            }else if(period === "This Quarter"){
                _period = 'tq';
            }else if(period === 'This Year'){
                _period = 'ty';
            }else if(period === 'Last Month'){
                _period = 'lm';
            }else if(period === 'Last Quarter'){
                _period = 'lq';
            }else if(period === 'Last Year'){
                _period = 'ly';
            }
            params.period = _period;
        }else{
            valid = false;
        }
        if(failureCount){
            params.count = failureCount;
        }else{
            valid = false;
        }
        if (!valid) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_REPORT_V2.ALL_OPTIONS_REQUIRED, null);
            return;
        }
        setIsLoading(true);

        const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_WEAKNESS_REPORT.GET_AUDIT_FAILED_QUESTIONS, params));
        if (promise) {
            promise.then(res => {
                let tempRows = res?.data?.records;
                let tempColumns = res?.data?.fields;
                tempRows?.sort(function(a, b) {
                    var c = a.failure_count;
                    var d = b.failure_count;
                    return c - d;
                });
                tempRows = tempRows? tempRows.reverse() : [];
                setRows(tempRows);
                setColumns(tempColumns);
                let data = {
                    availableParams: {categoriesOptions},
                    selectedParams: {selectedCategoroies,period,failureCount},
                    rows: tempRows,
                    columns:tempColumns,
                };
                dispatch(updateAuditWeaknessData(data));
                setIsLoading(false);
                setshowReport(true);
            })
        }


    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex",marginLeft:'10px'}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</h5>
            </div>
        )
    }
    const customEvent = (eventType, column, row, event) => {
        const style = {
            width: '50%',
            };


        const payload = {
            period,
            count: failureCount,
            questionId: row?.id,
         };

        if (eventType === 'is_detail_link' && column.name === 'failure_count') {
            dispatch(getAppCustomDrawer(true,getLocalizedStrings().label.AUDIT_WEAKNESS_REPORT.AUDIT_WEAKNESS_FAILED_REPORT, constants.AUDIT_WEAKNESS_FAILED_REPORT, style, payload, null, false));
        }
    }


    const getReportView = () =>{
        let view = [];
        if (!rows || Object.keys(rows).length === 0) {
            return getNoRecordContent();
        }else{
            view.push(<div style={{height:'81%',overflowY:'scroll'}}>
                <SFDataTable columns={columns}  rows={rows} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
            </div>)

            return view;
        }
    }
    
    let tempStyle = { width: '100%', paddingTop: 0 ,height:'fit-content',overflowY : !isLoading ?'scroll':'hidden',paddingLeft:'25px'};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 3
    return (
         <div style={tempStyle}>
            {getReportFilter()}
            <div style={{ marginTop:'15px',marginLeft:'-10px'}}>
                {showReport && ( !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>

    )
}

export default AuditWeaknessReport;