import React, { useState, useEffect, useRef } from 'react';
import { KanbanToggle } from '../components/kanbanToggle';
import { LinkToType } from '../../../services/constants/link';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { constants, HOME_MODULE_ID_NAME_MAP, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { MenuItem, Button, Icon, IconButton,DialogContent,DialogTitle, Dialog,TextField} from '@mui/material';
import { hasAccessPermission, getSelectedObjectLabel, getImageNameByObject, getObjectLabelByObject } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import CampaignToggle from '../components/campaignToggle';
import CampaignToggleAutomation from '../components/campaignToggleAutomation';
import { addTab } from '../../../services/helper/sfTabManager';
import { getActiveTab,updateActiveTab, getActiveTabInfo, TYPE_LIST_VIEW, TYPE_DETAIL_VIEW, TYPE_COLUMN_VIEW, TYPE_OTHER_VIEW, TYPE_HOME, selectTabByIndex, getCalenderPageType, addNewFormTabs } from '../../../services/helper/sfTabManager';
import { refreshListView, setListViewMounted } from '../../../services/actions/listViewAcions';
import { removeColumnViewScrollPosition, removeColumnViewHorizontalScrollPosition, refreshColumnView } from '../../../services/actions/listsActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { doRefreshAssets, doRefreshOnboardingUnits } from '../../../services/actions/headerActions';
import { getSalesObjectListForHome } from '../../../services/helper/utils';
import { pullQBItems } from '../../../services/actions/UserListAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getCalendarRefresh, setSelectedDate, getJobCalendarRefresh } from '../../../services/actions/calendarActions';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { refreshSmsInbox } from '../../../services/actions/smsAction'
import PopOver from './PopOver';
import map from 'lodash/map';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getVerifiedEmais } from '../../../services/actions/sendMailActions';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    height: 40,
    width: 110,
    textAlign: 'center',
    display: 'inline-block',
    margin: '10px 0px 0px 29px',
    paddingTop: '10px',
    color: '#000000'
};
const ObjectHeading = ({ header, object, kanban, label, showAddIcon, callBackFromApp }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const tab = useSelector((state) => state.tab);
    const userlist = useSelector((state) => state.userlist);
    const calendar = useSelector((state) => state.calendar);
    const reduxListView = useSelector((state) => state['listView']);

    const [imgName, setImgName] = useState(null);
    const [headerObject, setHeaderObject] = useState(null);
    const [headerLabel, setHeaderLabel] = useState("");
    const [kanbanDetail, setKanbanDetail] = useState({
        currentView: 'list',
        show: false,
        isOnlyCalendar: false,
    });
    const [isShowAddIcon, setIsShowAddIcon] = useState(false);
    const [openAddList, setOpenAddList] = useState(false);
    const [objectIconToolTip, setObjectIconToolTip] = useState('');


    const [openActionList, setOpenActionList] = useState(false);
    const [selected_user, setSelectedUser] = useState(null);
    const [selected_salesRep, setSelectedSalesRep] = useState(null);
    const [agreementType, setAgreementType] = useState('');
    const [isAuditPassFailOpen,setisAuditPassFailOpen] = useState(false);
    const [passingScore,setPassingScore] = useState('');

    useEffect(() => {
        setHeaderIcon();
    }, [header, object, kanban, showAddIcon, headerLabel]);


    const setHeaderIcon = () => {
        try {
            let _kanBanDetails = kanbanDetail;
            setKanbanDetail({ ..._kanBanDetails, currentView: null, show: null });
            let _object = getStringParam(header.selectedTabObject);
            let _label = isValidParam(header.selectedHeader) ? header.selectedHeader : '';
            let _kanban = getObjectParam(header.kanban);
            let kanbanCurrentView = getObjectParam(_kanban.currentView);
            let isKanbanShow = getBooleanParam(_kanban.show);
            let _showAddIcon = getBooleanParam(header.showAddIcon);
            let showHeader = getBooleanParam(header.showHeader);

            if (isValidParam(header)) {
                let tab = getActiveTab();
                if (isValidParam(tab)) {
                    tab = getObjectParam(tab);
                    let tabObject = getStringParam(tab.object);
                    let tabImgName = getStringParam(tab.imgName);
                    let type = getStringParam(tab.type);

                    setObjectIconToolTip((type === TYPE_LIST_VIEW || type === TYPE_COLUMN_VIEW
                        || (type === TYPE_OTHER_VIEW && tabObject === constants.ASSETS_OBJECT)
                        || (type === TYPE_OTHER_VIEW && tabObject === constants.NEWS_OBJECT)
                        || (type === TYPE_HOME && tabObject === constants.TASKS)
                        || (type === TYPE_HOME && tabObject === constants.SALES_OBJECT)) ? getLocalizedStrings().label.HOME.CLICK_TO_REFRESH : '');

                    if (tabObject === constants.APPOINTMENTS || tabObject === constants.CALENDAR_TASK || tabObject === constants.TASKS) {
                        let type = getCalenderPageType();
                        if (type === constants.TASKS) {
                            tabImgName = 'fa fa-clock-o';
                        }
                    } else if (tabObject === constants.ACCOUNTS_OBJECT && tabImgName === 'fa fa-users') {
                        tabImgName = 'fa fa-building';
                    } else if (tabObject === constants.SALES_OBJECT && tabImgName === 'fa fa-area-chart') {
                        tabImgName = 'fa fa-bar-chart';
                    } else if (tabObject === constants.OPPORTUNITIES_OBJECT && tabImgName === 'fa fa-users') {
                        tabImgName = 'fa fa-usd';
                    } else if (tabObject === constants.NOTES && tabImgName === 'fa fa-users') {
                        tabImgName = 'fa fa-pencil-square-o';
                    } else if (tabObject === constants.COUPONS_OBJECT && tabImgName === 'fa fa-home') {
                        tabImgName = 'fas fa-donate';
                    }
                    setImgName(tabImgName);
                    if (_object === constants.UNITS_OBJECT && getBooleanParam(app.me.franchisor_add_unit) && (getBooleanParam(app.me.is_tenant_admin) || constants.SECURITY_ROLE_ADMIN === getStringParam(app.me.t_name))) {
                        _showAddIcon = true;
                    }
                    if (_object === constants.REPORTED_SALES_OBJECT &&( getBooleanParam(app.me.is_franchisee) || getBooleanParam(app.me.is_franchisor))) {
                        _showAddIcon = false;
                    }
                    if (tab.item === constants.CALENDAR_TASK && tab.url === "/appointments/calendar") {
                        if (calendar.isrefresh) {
                            dispatch(getCalendarRefresh(true));
                            dispatch(getTasksRefresh(true));
                        }

                    }

                }
                if (_object === constants.JOB_SCHEDULER) {
                    isKanbanShow = true;
                    let _kanBanDetails = kanbanDetail;
                    setKanbanDetail({ ..._kanBanDetails, show: isKanbanShow });
                }
                setHeaderObject(_object);
                setIsShowAddIcon(_showAddIcon);
                if (isValidParam(kanban) && isKanbanShow) {
                    let _kanBanDetails = kanbanDetail;
                    setKanbanDetail({ ..._kanBanDetails, currentView: kanbanCurrentView, show: isKanbanShow, isOnlyCalendar: kanban.isOnlyCalendar });
                }
                setHeaderLabel(_label);
            }
        } catch (error) {
            console.error("Error in 'objectHeading.js -> setHeaderIcon()':" + error);
        }
    }

    const addNewRecord = (value, agreement_Type) => {
        let data = {};
        let labelName = null;
        let objectName = null;
        let linkTo = null;
        let isOpenDrawer = true;
        let isCampaign = false;
        let hasPermission = false;
        let filter = null;
        let isMarketingTemplate = false;
        let isBroadcastCampaign = false;
        try {
            let tempObjName = object;
            if (tempObjName === constants.JOB_SCHEDULER) {
                tempObjName = constants.JOBS_OBJECT;
            }
            if (object === constants.SEARCH_RESULTS) {
                let activeTab = getObjectParam(getActiveTab());
                let activeTabInfo = getObjectParam(activeTab.info);
                let search = getObjectParam(activeTabInfo.search);
                tempObjName = getStringParam(search.object);
            } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
                let activeTab = getObjectParam(getActiveTab());
                let activeTabInfo = getObjectParam(activeTab.info);
                let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
                tempObjName = getStringParam(advanceSearch.object);
            } else if (object === constants.SCHEDULER) {
                tempObjName = constants.APPOINTMENTS;
            }
            objectName = tempObjName;
            value = getStringParam(value);
            if (objectName === constants.COUPONS_OBJECT) {
                hasPermission = app.me.t_name === 'Admin' || app.me.t_name === 'Marketing' ? true : false;
            } else if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.CALL_SCRIPT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
                if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                    if (app.me.t_name !== 'Admin') {
                        hasPermission = false;
                    }
                }
            } else if (objectName === constants.WEB_FORMS) {
                hasPermission = true;
            } else {
                hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            }
            if (hasPermission) {
                if (value === constants.NEW) {
                    if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                        labelName = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATE_CREATE_TAB_LABEL;
                    } else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                        labelName = getLocalizedStrings().label.MARKETING_TEMPLATE.MARKETING_TEMPLATE_CREATE_TAB_LABEL;
                        isOpenDrawer = false;
                        isMarketingTemplate = true;
                    }
                    if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        if (agreement_Type === constants.FDD) {
                            labelName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATE_FDD_CREATE_TAB_LABEL;
                        } else if (agreement_Type === constants.FRANHCISE_AGREEMENT) {
                            labelName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATE_FRANCHISE_AGREEMENT_CREATE_TAB_LABEL;
                        } else if (agreement_Type === constants.AGREEMENT) {
                            labelName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATE_CREATE_TAB_LABEL;
                        }
                    } else if (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                        labelName = constants.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
                        isCampaign = true;
                        isOpenDrawer = false;
                        isBroadcastCampaign = true;
                    } else if (objectName === constants.AB_CAMPAIGNS_OBJECT) {
                        labelName = constants.AB_CAMPAIGN_CREATE_TAB_LABEL;
                        isCampaign = true;
                        isOpenDrawer = false;
                    } else if (objectName === constants.SOCIAL_CAMPAIGNS_OBJECT) {
                        labelName = constants.SOCIAL_CAMPAIGN_CREATE_TAB_LABEL;
                    } else if (objectName === constants.AUTOMATION_DESIGNER_OBJECT) {
                        labelName = getLocalizedStrings().label.COMMON.CREATE + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
                    } else if (objectName === constants.WEB_FORMS) {
                        labelName = constants.WEB_FORM_CREATE_TAB_LABEL;
                    } else if (objectName === constants.ASSIGNMENT_RULES_OBJECT) {
                        labelName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE_CREATE_TAB_LABEL;
                    } else if (objectName === constants.SOURCE_OBJECT || objectName === constants.NOTE_TYPES_OBJECT || objectName == constants.LOCATION_OBJECT || objectName === constants.CAMPAIGN_TYPES_OBJECT || objectName === constants.RELATIONS_OBJECT || objectName === constants.APPOINTMENT_TYPES_OBJECT || objectName === constants.ROLL_UP_OBJECT) {
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.SETTINGS[objectName.toUpperCase()];
                    }
                    else if (objectName === 'taskinfo') {
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;

                    }
                    else if (objectName === constants.NEWS_OBJECT) {
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.COMMON.NEWS;
                    } else if (objectName === constants.EMAIL_INTEGRATION_OBJECT) {
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.EMAIL_INTEGRATION.EMAIL;
                    } else if (objectName === constants.APPOINTMENTS) {
                        labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                    } else if (objectName === constants.TASKS) {
                        labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                    } else if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.LANDING_PAGE.LANDING_PAGE;
                    } else if (objectName === constants.LMS_USERS_OBJECT){
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.SETTINGS.LMS_USERS;
                    }else if(objectName === constants.AUDIT_CHECKLISTS_OBJECT){
                        labelName = getLocalizedStrings().label.AUDIT_CHECKLISTS.TITLE;
                    }
                     else {
                        let objectName = null;
                        let itemLabel = null;
                        let activeTab = getObjectParam(getActiveTab());
                        let activeTabObject = getObjectParam(activeTab.object);
                        if (activeTabObject === constants.SEARCH_RESULTS) {
                            let activeTabInfo = getObjectParam(activeTab.info);
                            let search = getObjectParam(activeTabInfo.search);
                            objectName = getStringParam(search.object);
                            itemLabel = getObjectLabelByObject(objectName);
                        } else if (activeTabObject === constants.ADVANCE_SEARCH_RESULTS) {
                            let activeTabInfo = getObjectParam(activeTab.info);
                            let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
                            objectName = getStringParam(advanceSearch.object);
                            let objList = getArrayParam(app.objectList);
                            if (objList.length > 0) {
                                let arr = objList.filter(f => f.name === objectName);
                                if (arr.length > 0) {
                                    itemLabel = getStringParam(arr[0].label);
                                }
                            }
                        } else {
                            itemLabel = getObjectLabelByObject(activeTabObject);
                        }
                        if (itemLabel === null) {
                            itemLabel = getSelectedObjectLabel();
                        }
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + itemLabel;
                        if (object === constants.GROUP_OBJECT) {
                            data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
                        } else if (object === constants.COMPLIANCE_OBJECT) {
                            data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
                        } else {
                            data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                        }
                        if (activeTabObject === constants.JOBS_OBJECT || activeTabObject === constants.JOB_SCHEDULER) {
                            isOpenDrawer = false;
                        }
                    }
                    linkTo = LinkToType.TYPE_NEW;
                }

                if (objectName === constants.AUTOMATION_DESIGNER_OBJECT || objectName === constants.TOUCH_CAMPAIGNS_OBJECT) {
                    if (objectName === constants.TOUCH_CAMPAIGNS_OBJECT) {
                        objectName = constants.AUTOMATION_DESIGNER_OBJECT;
                    }
                    labelName = getLocalizedStrings().label.AUTOMATION_DESIGNER.AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL;
                    let url = "/" + objectName + "/" + linkTo;
                    let tabs = tab.tabs;
                    let automationTab = tabs.find((tab) => { return tab.type === TYPE_DETAIL_VIEW && tab.object === constants.AUTOMATION_DESIGNER_OBJECT });
                    if (!isValidParam(automationTab)) {
                        let tab = {
                            item: objectName,
                            label: labelName,
                            object: objectName,
                            type: TYPE_DETAIL_VIEW,
                            url: url
                        };
                        addTab(tab, true);
                    } else {
                        (sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD, () => handleSfDialogAlertobjh(automationTab), null));
                    }
                } else if (objectName === constants.WEB_FORMS) {
                    isOpenDrawer = false;
                    let tableId = 10;
                    let token = localStorage.getItem('token');
                    let redirectUrl = constants.snapshotUrl;
                    redirectUrl += '&token=' + token;
                    redirectUrl += '&callingfor=' + "webform";
                    redirectUrl += '&type=CUSTOM_VIEW';
                    redirectUrl += '&label=' + labelName;
                    redirectUrl += '&tableId=' + tableId;
                    window.open(redirectUrl);
                } else if (object === "workflows") {
                    let activeTab = getObjectParam(getActiveTab());
                    let activeTabInfo = getObjectParam(activeTab.info);
                    let objName = "";
                    let tableId = 0;
                    if (activeTabInfo.hasOwnProperty('groupByCondition') && activeTabInfo.groupByCondition !== null && activeTabInfo.groupByCondition.length > 0) {
                        let objLabel = getStringParam(activeTabInfo.groupByCondition);
                        let objList = getArrayParam(app.objectList);
                        if (objList.length > 0) {
                            let arr = objList.filter(f => f.label === objLabel);
                            if (arr.length > 0) {
                                objName = getStringParam(arr[0].name);
                                tableId = OBJECT_TABLEID_MAP[objName];
                            }
                        }
                    }

                    let label = "New Workflow"
                    let token = localStorage.getItem('token');
                    let redirectUrl = constants.snapshotUrl;
                    redirectUrl += '&token=' + token;
                    redirectUrl += '&callingfor=' + "workflows";
                    redirectUrl += '&type=new';
                    redirectUrl += '&label=' + label;
                    redirectUrl += '&tableId=' + tableId;
                    if (app.me.is_customize_workflow) {
                        window.open(redirectUrl);
                    } else {
                        let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                    }

                }
                else if (object == constants.UNITS_OBJECT && isOpenDrawer) {
                    let unitData = {}
                    unitData.object = objectName;
                    unitData.dialogContentHeight = '120px';
                    dispatch(getAppDialog(true, constants.SELECT_ACCOUNT, getLocalizedStrings().label.COMMON.SELECT_AN_ACCOUNT, null, unitData, constants.LIST_VIEW));
                }
                else if (isOpenDrawer) {
                    data.object = objectName;
                    if (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.CONTACTS_OBJECT) {
                        let activeTabInfo = getObjectParam(getActiveTabInfo());
                        filter = getObjectParam(activeTabInfo.filter);
                        let filterId = getIntParam(filter.id);
                        let type = getStringParam(filter.type);
                        if (type === 'group') {
                            data.parent_object = constants.GROUP_OBJECT;
                            data.parent_record_id = filterId;
                        }
                    } else if (objectName === constants.USEFUL_LINK_OBJECT) {
                        data = {};
                        data.type = value;
                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.USEFUL_LINKS.LINK;
                        value = constants.USEFUL_LINK;
                    }
                    if (objectName === constants.ASSIGNMENT_RULES_OBJECT) {
                        data.table = constants.ACCOUNTS_OBJECT;
                        data.callFrom = 'Assignment Rules';
                        data.mode = "New";
                    }

                    if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                        let style = {};
                        style.width = '83%';
                        dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_NEW, style, data, null, false));
                    } else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        let style = {};
                        style.width = '83%';
                        data.agreement_type = agreement_Type;
                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true));
                    } else if (objectName === constants.NEWS_OBJECT) {
                        let style = {};
                        style.width = '60%';
                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                    } else if (objectName === constants.ROYALTY_PLANS_OBJECT) {
                        let style = {};
                        style.width = '60%';
                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                    }  else if (objectName === constants.AUDIT_CHECKLISTS_OBJECT) {
                        let style = {};
                        style.width = '45%';
                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                    } 
                    else {
                        dispatch(getAppDrawer(true, labelName, value, data, null));
                    }
                    setOpenActionList(false);
                } else {
                    if (isCampaign) {
                        if (isBroadcastCampaign) {
                            let callbackData = {
                                labelName: labelName,
                                value: value,
                                data: data,
                                object: objectName,
                            }
                            dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
                        } else {
                            let callbackData = {
                                labelName: labelName,
                                value: value,
                                data: data,
                                object: objectName,
                            }
                            dispatch(getAppDialog(true, constants.TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
                        }
                    } else if (isMarketingTemplate) {
                        let data = {
                            labelName: labelName,
                            value: value,
                            type: constants.EDITOR_BEE,
                            object: objectName
                        }
                        let style = {};
                        style.width = '100%';
                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                    } else if (objectName === constants.JOBS_OBJECT) {
                        let callFrom = getStringParam(header.kanban.currentView);
                        let info = { id: 0, title: labelName, type: 'New', object: objectName };
                        info.callFrom = callFrom === LinkToType.TYPE_COLUMN ? constants.COLUMN_VIEW : constants.LIST_VIEW;
                        addNewFormTabs(info);
                    }
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
            }
        } catch (error) {
            console.error("Error in 'listViewHeader.js -> handleAction()':" + error);
        }
    };

    // function//

    const handleSfDialogAlertobjh = (automationTab) => {
        let tabIndex = automationTab.index;
        selectTabByIndex(tabIndex);
    }


    const addReportAction = (value) => {
        setOpenAddList(false);
        addReport(value);
    }

    const addAction = (value) => {
        if (value === "Appointments") {
            add(constants.APPOINTMENTS);
        } else if (value === "Tasks") {
            add(constants.TASKS);
        } else if (value === "Jobs") {
            addNewRecord(constants.NEW);
        } else if (
            value === 'Text' ||
            value === 'List' ||
            value === 'Checkbox' ||
            value === 'Comment'
        ) {
            addQuestionType(constants.AUDIT_QUESTIONS_OBJECT, value)
        }
    }

    const addQuestionType = (objectValue, value) => {
        let data = {
            id: 0,
            object: objectValue,
            type: value
        }
        let header = getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_NEW + ' '
        if (value === 'Text') {
            header += getLocalizedStrings().label.AUDIT_QUESTIONS.FREE_FORM + ' '
        }
        header += getLocalizedStrings().label.AUDIT_QUESTIONS[value.toUpperCase()]
        dispatch(getAppDrawer(true, header, objectValue, data, null));
    }

    const generateMenuForAddOrImport = (objectName) => {
        let actions = [];
        let arrActions = [];
        let imageName = "";
        if (objectName === constants.USEFUL_LINK_OBJECT) {
        } else {
            arrActions.push({ name: "Enter By Hand", label: getLocalizedStrings().label.HOME.ENTER_BY_HAND });
            if (objectName !== constants.CONTACTS_OBJECT || app.me.is_contact_import) {
                arrActions.push({ name: "Import", label: getLocalizedStrings().label.HOME.IMPORT });
            }
        }

        arrActions.map((item, i) => {
            if (objectName === constants.USEFUL_LINK_OBJECT && item.name === "Category") {
                imageName = "fas fa-layer-group";
            } else {
            }
            actions.push(<MenuItem
                key={item.name}
                value={item.name}
                style={{ ...styles.popoverMenuItem, margin: '4px', paddingLeft: '40px' }}
                fullWidth={true}
                underlinestyle={{ display: 'none' }}
                labelstyle={{ color: '#479ccf' }}
                autowidth={"true"}
                leftIcon={<div style={{ width: '15px', marginRight: '0px' }} ><i className={imageName} /></div>}
            >{item.label}</MenuItem>);
        });
        return actions;
    }

    const add = (objectName) => {
        let hasPermission = null;
        hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
        if (hasPermission) {
            let data = {
                id: 0,
                object: objectName,
                callFrom: 'calendarTaskPage'
            }
            let objName = "";
            let labelName = "";
            if (objectName === constants.APPOINTMENTS) {
                objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT
            } else if (objectName === constants.TASKS) {
                objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK
            } else {
                objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
            }
            if (objectName === constants.APPOINTMENTS || objectName === constants.TASKS) {
                labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + objName;
            } else {
                labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objName;
            }
            dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
        }
    }

    const addReport = (objectName) => {
        let hasPermission = null;
        hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_REPORTS);
        if (hasPermission) {
            let data = {
                id: 0,
                object: constants.SALES_OBJECT,
                reportObj: objectName
            }
            let objectLabel = getObjectLabelByObject(objectName);
            let labelName;
            if (objectLabel == "Calendar/Tasks" && objectName == 'appointments') {
                labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + 'Appointments' + ' ' + getLocalizedStrings().label.HEADER.REPORT;
            }
            else {
                labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objectLabel + ' ' + getLocalizedStrings().label.HEADER.REPORT;
            }
            dispatch(getAppDrawer(true, labelName, constants.USER_REPORT, data, constants.OTHER));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
        }
    }

    const getKanbanToggle = () => {
        let kanbanElement = null;
        let activeTabInfo = getObjectParam(getActiveTabInfo());
        let _tab = getActiveTab();

        let isFormSearch = false;
        let isReport = getBooleanParam(activeTabInfo['isReport']);
        let isForecast = getBooleanParam(activeTabInfo['isForecast']);
        if (kanbanDetail.show) {
            if ((headerObject === constants.AUTOMATION_DESIGNER_OBJECT ||
                headerObject === constants.TOUCH_CAMPAIGNS_OBJECT) && app.me.is_show_touch) {
                kanbanElement = <div style={{ cursor: 'pointer', paddingRight: '0px', marginLeft: '-15px', float: 'left', }}>
                    <CampaignToggleAutomation object={headerObject} />
                </div>
            }
            else {
                if (headerObject === constants.AUDIT_QUESTIONS_OBJECT) {
                    return <></>
                }
                if (activeTabInfo.hasOwnProperty("search")) {
                    isFormSearch = true;
                }
                if (isFormSearch) {
                    return <></>;
                }
                if (!isReport && !isForecast && _tab?.object !== constants.ADVANCE_SEARCH_RESULTS) {
                    kanbanElement = <div style={{cursor: 'pointer', paddingLeft: '0px', paddingRight: '0px', float: 'right', marginRight: '10px', marginTop: '-2px', marginLeft: '25px' }}>
                        <KanbanToggle currentView={kanbanDetail.currentView} object={headerObject} />
                    </div>
                }
            }
        }
        return kanbanElement;
    }

    const reloadAppContainer = () => {
        let activeTab = getActiveTab();
            activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
            updateActiveTab(activeTab);
        let info = getObjectParam(activeTab.info);
        let params = {};
        if (activeTab.type === TYPE_LIST_VIEW) {
            if (object === constants.ONBOARDING_OBJECT) {
                dispatch(doRefreshOnboardingUnits(true));
            } else {
                if (info.hasOwnProperty('startIndex') && info.hasOwnProperty('pageSize')) {
                    params.start_index = getIntParam(info.startIndex);
                    params.page_size = getIntParam(info.pageSize);
                }
                if (object === constants.EMAIL_INTEGRATION_OBJECT) {
                    dispatch(getVerifiedEmais(false))
                } 
                if(activeTab.object === constants.ACCOUNTS_OBJECT && activeTab.type === "TYPE_LIST_VIEW" && activeTab.url === "/accounts/queries"){
                    activeTab.info.selectedPageNo = 1;
                } if(activeTab.object === constants.AUDIT_QUESTIONS_OBJECT && activeTab.type === "TYPE_LIST_VIEW" && activeTab.url === "/setting/module"){
                    activeTab.info.selectedPageNo = 1;
                } if(activeTab.object === constants.NOTES && activeTab.type === "TYPE_LIST_VIEW" && activeTab.url === "/notes/queries"){
                    activeTab.info.selectedPageNo = 1;
                } if(activeTab.object === constants.AUDIT_CHECKLISTS_OBJECT && activeTab.type === "TYPE_LIST_VIEW" && activeTab.url === "/setting/module"){
                    activeTab.info.selectedPageNo = 1;
                }
                updateActiveTab(activeTab);
                setListViewMounted(false, object);
                refreshListView(object);
            }
        } else if (activeTab.type === TYPE_COLUMN_VIEW || (activeTab.type === TYPE_HOME && object === constants.TASKS)) {
            refreshColumnView(object);
        } else if (activeTab.type === TYPE_OTHER_VIEW) {
            if (activeTab.object === constants.ASSETS_OBJECT) {
                dispatch(doRefreshAssets(true));
            } else if (activeTab.object === constants.INBOX_OBJECT) {
                refreshSmsInbox(true);
            } else if (activeTab.object === constants.NEWS_OBJECT) {
                info.getAllNews();
            } else if ((object === constants.JOB_SCHEDULER) && (activeTab.url === "/jobscheduler/jobscheduler" || activeTab.url === "/jobscheduler/jobDetailviewScheduler")) {
                updateCalenderDateTimeLine();
                dispatch(getJobCalendarRefresh(true));
            }
        } else if (object === constants.TASKS && activeTab.type === TYPE_HOME) {
            dispatch(removeColumnViewScrollPosition(activeTab.object));
            dispatch(removeColumnViewHorizontalScrollPosition(activeTab.object));
            info.getTaskPageData(activeTab.object);
        } else if (object === constants.APPOINTMENTS && activeTab.type === TYPE_HOME && activeTab.url === "/appointments/calendaronly") {
            updateCalenderDateTimeLine();
            dispatch(getCalendarRefresh(true));
        } else if (object === constants.APPOINTMENTS && activeTab.type === TYPE_HOME && activeTab.url === "/appointments/calendar") {
            updateCalenderDateTimeLine();
            dispatch(getCalendarRefresh(true));
            dispatch(getTasksRefresh(true));
            refreshColumnView(constants.TASKS);
        }
    }

    const updateCalenderDateTimeLine = () => {
        var sObj = document.querySelector(".e-schedule").ej2_instances[0];
        var currentDates = sObj.getCurrentViewDates();
        var currentView = sObj.viewIndex;
        var startDate = currentDates[0];
        if (currentView === 3) {
            startDate = currentDates[15];
        }
        if (startDate !== null && startDate !== undefined) {
            dispatch(setSelectedDate(startDate));
        }
    };


    const isShowRefreshIcon = () => {
        let isShow = false;
        let activeTab = getObjectParam(getActiveTab());
        if (activeTab.type === TYPE_LIST_VIEW && activeTab.object !== constants.COUPONS_CLAIM_REPORTS &&
            activeTab.object !== constants.ONBOARDING_OBJECT && activeTab.object !== constants.USEFUL_LINK_OBJECT &&
            activeTab.object !== constants.OPERATIONS_ASSETS_REPORTS && activeTab.object !== constants.OPERATIONS_ACTIVITIES &&
            activeTab.object !== constants.OPERATIONS_CONVERSIONS && activeTab.object !== constants.OPERATIONS_CAMPAIGNS && activeTab.object !== constants.OPERATIONS_RECENT_CAMPAIGNS &&
            activeTab.object !== constants.CUSTOM_OPERATIONS_REPORTS && activeTab.object !== constants.MARKETING_OBJECT && activeTab.object !== constants.ROYALTY_REPORT_OBJECT && activeTab.object !== constants.COMPLIANCE_REPORT_OBJECT && activeTab.object !== constants.COMPLIANCE_REPORT_OBJ &&
            activeTab.object !== constants.ROYALTY_REPORT_OBJECT_V2 && activeTab.object !== constants.AUDIT_REPORT && activeTab.object !== constants.SALES_REFUND && activeTab.object !== constants.AUDIT_WEAKNESS_REPORT && activeTab.object !== 'sales-pipeline-report' && 
            activeTab.object !== 'audit-performance-report'&& activeTab.object !== constants.TASK_REPORT &&  activeTab.object !== 'corrective-action-report') {
            isShow = true;
        } else if (activeTab.type === TYPE_COLUMN_VIEW) {
            isShow = true;
        } else if ((object === constants.TASKS || object === constants.APPOINTMENTS || object === constants.CALENDAR_TASK) && activeTab.type === TYPE_HOME) {
            isShow = true;
        } else if ((object === constants.JOB_SCHEDULER) && activeTab.type === TYPE_OTHER_VIEW) {
            isShow = true;

        } else if (activeTab.object === constants.INBOX_OBJECT) {
            isShow = true;
        }

        if (object === constants.OPPORTUNITIES_OBJECT && activeTab.url === '/opportunities/kanban') {
            isShow = false;
        }
        if (activeTab.object === constants.ASSETS_OBJECT){
            isShow = true;
        }
        return isShow;
    }

    const addNewOrImport = (event, value) => {
        setOpenAddList(false);
        if (object === constants.USEFUL_LINK_OBJECT) {
            addNewRecord(event, value);
        } else if (value === "Enter By Hand") {
            addNewRecord(event, constants.NEW);
        } else if (value === "Import") {
            openImport();
        }
    }

    const openImport = () => {
        let objectName = object;
        let hasPermission = null;
        hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_IMPORT_RECORDS);
        if (hasPermission) {
            let data = {
                id: 0,
                object: objectName
            }
            let tempObjName = getObjectLabelByObject(objectName)
            if (isValidParam(getLocalizedStrings().label.HEADER[tempObjName])) {
                tempObjName = getLocalizedStrings().label.HEADER[tempObjName];
            }
            let labelName = getLocalizedStrings().label.COMMON.IMPORT + ' ' + tempObjName;
            dispatch(getAppDrawer(true, labelName, constants.IMPORT, data, constants.OTHER));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
        }
    }

    const generateAddReportObjectItems = () => {
        let quickAddObjectItems = [];
        let moduleMap = HOME_MODULE_ID_NAME_MAP;
        let keyMap = {};
        let objects = app.objectList;

        if (app.selectedModuleName === constants.MODULE_SALES) {
            let moduleItems = app.home.modules.filter(f => { return f.module === constants.MODULE_SALES }).map(m => { return m.module_items });
            moduleItems = moduleItems.length > 0 ? moduleItems[0] : [];
            objects = objects.length > 0 ? getSalesObjectListForHome(objects, moduleItems) : [];
        }

        let salesReportLabel = '';
        if (app.selectedModuleName === 'Sales') {
            objects.push({ name: 'appointments', label: 'Appointments' });
            objects.push({ name: 'tasks', label: 'Tasks' });

            salesReportLabel = object === constants.SALES_OBJECT ? ' ' + getLocalizedStrings().label.HEADER.REPORT : salesReportLabel;
        }


        objects.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });
        map(objects, function (object, i) {
            let objLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(object.label) ? getLocalizedStrings().label.HEADER[object.label] + salesReportLabel : object.label + salesReportLabel;
            let data = {};
            data.label = objLabel;
            data.value = object.name;
            data.leftIcon = <i className={getImageNameByObject(object.name)} />;

            quickAddObjectItems.push(data);


        });

        return quickAddObjectItems;
    }

    const pullFromQb = () => {
        try {
            let is_qbsetup = getBooleanParam(app.me.is_qbsetup);
            if (!is_qbsetup) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.QUICKBOOKS.COONECT_QB_MSG, handleSfDialogPullFromQb(), null);
            } else {
                pullItemsFromQb();
            }

        } catch (error) {
            console.error("Error in 'objectHeading.js -> pullFromQb()':" + error);
        }
    }

    const handleSfDialogPullFromQb = () => {
        dispatch(getAppDrawer(true, getLocalizedStrings().label.MY_INTEGRATION.MY_INTEGRATIONS, constants.MY_INTEGRATIONS, null, constants.PRODUCTS_OBJECT));
    }

    const pullItemsFromQb = () => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Products will be imported from Quickbooks. Are you sure?", handleSfDialogPullItemsFromQb.bind(), null);
        } catch (error) {
            console.log("Error in 'objectHeading.js -> pullItemsFromQb()':" + error);
        }

    }


    const handleSfDialogPullItemsFromQb = () => {
        try {
            var promise = Promise.resolve(pullQBItems());
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PRODUCTS.PULLED_FROM_QUICKBOOKS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
        } catch (error) {
            console.log("Error in 'objectHeading.js -> handleSfDialogPullItemsFromQb()':" + error);
        }
    }

    const getMenuItems = () => {
        let itemsArr = [];
        let items = [];

        try {
            itemsArr.push({ value: 'Appointments', text: getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS, rightIconClassName: 'fa fa-calendar' });
            if (object !== constants.JOB_SCHEDULER) {
                itemsArr.push({ value: 'Tasks', text: getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS, rightIconClassName: 'fa fa-clock-o' });
            } else {
                itemsArr.push({ value: 'Jobs', text: 'Jobs', rightIconClassName: 'fas fa-file-invoice-dollar' });
            }

            if (object === constants.AUDIT_QUESTIONS_OBJECT) {
                itemsArr = []
                itemsArr.push({ value: 'Text', text: getLocalizedStrings().label.AUDIT_QUESTIONS.TEXT, rightIconClassName: null });
                itemsArr.push({ value: 'List', text: getLocalizedStrings().label.AUDIT_QUESTIONS.LIST, rightIconClassName: null });
                itemsArr.push({ value: 'Checkbox', text: getLocalizedStrings().label.AUDIT_QUESTIONS.CHECKBOX, rightIconClassName: null });
                itemsArr.push({ value: 'Comment', text: getLocalizedStrings().label.AUDIT_QUESTIONS.COMMENT, rightIconClassName: null });
            }

            itemsArr.map((item, i) => {
                let data = {};
                data.label = item.text;
                data.value = item.value;
                data.leftIcon = <i className={item.rightIconClassName} />;
                items.push(data);
            });
        } catch (error) {
            console.error("Error in 'objectHeading.js -> getMenuItems()':" + error);
        }
        return items;
    }

    const getAgreementTemplateMenuItems = () => {
        let menuItems = [];
        try {
            menuItems.push({ label: 'FDD', value: 'FDD' });
            menuItems.push({ label: 'Franchise Agreement', value: 'Franchise Agreement' });
            menuItems.push({ label: 'Agreement', value: 'Agreement' });

        } catch (error) {
            console.error("Error in 'objectHeading.js -> getAgreementTemplateMenuItems()':" + error);
        }
        return menuItems;
    }

    const addAgreementTemplatections = (value) => {
        try {
            if (value === 'FDD') {
                addNewRecord(constants.NEW, value);
                setAgreementType(value);
            } else if (value === 'Franchise Agreement') {
                addNewRecord(constants.NEW, value,);
                setAgreementType(value);
            } else if (value === 'Agreement') {
                addNewRecord(constants.NEW, value);
                setAgreementType(value);
            }

        } catch (error) {
            console.error("Error in 'objectHeading.js -> addAgreementTemplatections()':" + error);
        }
    }
    const addBranchName = () => {
        let unitData = {}
        unitData.object = "Add Branch";
        unitData.dialogContentHeight = '116px';
        dispatch(getAppDialog(true, 'Add Branch', "Select/Create a Branch", null, unitData, constants.LIST_VIEW));

    }
    const addUnitName = () => {
        let unitData = {}
        unitData.object = "units";
        unitData.dialogContentHeight = '120px';
        dispatch(getAppDialog(true, constants.SELECT_ACCOUNT, getLocalizedStrings().label.COMMON.SELECT_AN_ACCOUNT, null, unitData, constants.LIST_VIEW));

    }
    const addBaseName = () => {
        // let unitData = {}
        // unitData.object = "Add Base";
        // unitData.dialogContentHeight = '116px';
        // dispatch(getAppDialog(true, 'Add Base', "Select/Create a Base", null, unitData, constants.LIST_VIEW));
        let labelName = "Add Base";
        let data = {};
        data.object = constants.ADD_UNIT_BASE;
        data.mode = "QUICK";
        data.records = "";
        data.mainContactName = "";
        data.accountRecordId = 0;
        data.clientProjectId = 0;
        data.clientEmail = '';
        data.click_form = 'addunit';
        data.billingMessage = '';
        dispatch(getAppDrawer(true, labelName, 'New', data, null));
    }
    const getAddNumbersMenuItems = () => {
        let menuItems = [];
        let phoneTextProvider = app.me.phone_text_provider ?? constants.NONE;
        try {
                if(phoneTextProvider === constants.TWILIO) {
                    menuItems.push({ label: getLocalizedStrings().label.COMMON.IMPORT_NUMBER, value: 'import' });
                } else if (phoneTextProvider === "Soffront") {
                    menuItems.push({ label: getLocalizedStrings().label.COMMON.LEASE_NUMBER, value: 'lease' });
                }
                menuItems.push({ label: getLocalizedStrings().label.COMMON.BRING_YOUR_OWN, value: 'byo' });
        } catch (error) {
            console.error("Error in 'objectHeading.js -> getAddNumbersMenuItems()':" + error);
        }
        return menuItems;
    }

    const addPhoneNumberActions = (value) => {
        try {
            let phoneTextProvider = app.me.phone_text_provider ?? constants.NONE;
            if (phoneTextProvider !== "None") {
                let _data = {};
                _data.action_type = value;
                _data.action_mode = 'add';
                if (value === 'lease') {
                    dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.LEASE_NUMBER, constants.LEASE_NUMBER, _data, constants.PHONE_NUMBERS_OBJECT));
                } else if (value === 'import') {
                    dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.IMPORT_NUMBER, constants.IMPORT_NUMBER, _data, constants.PHONE_NUMBERS_OBJECT));
                } else {
                    dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.BRING_YOUR_OWN, constants.BRING_YOUR_OWN, null, constants.PHONE_NUMBERS_OBJECT));
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER, null);
            }
        } catch (error) {
            console.error("Error in 'objectHeading.js -> addPhoneNumberActions()':" + error);
        }
    }

    const openPhoneNumberSetup = () => {
        dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.SETTINGS, constants.PHONE_NUMBER_SETUP, null, constants.PHONE_NUMBERS_OBJECT));
    }

    const purchasePhoneCredits = () => {
        let tenantType = app.me.customerType;
        let token = localStorage.getItem('token');
        let redirectUrl = constants.snapshotUrl;
        redirectUrl += '&token=' + token;
        redirectUrl += '&callingfor=' + "subscription";
        if (tenantType != null && tenantType == "BrandWide") {
            redirectUrl += '&type=purchase-products-BrandWide';
        } else {
            redirectUrl += '&type=purchase-products';
        }
        window.open(redirectUrl);
    }

    const OpenAuditCategoriesPopup = () =>{
        let data = {};
        data.object = object;
        let callFrom = constants.AUDIT_QUESTIONS_OBJECT
        let label = getLocalizedStrings().label.AUDIT_CATEGORY.MANAGE_CATEGORY;
        dispatch(getAppDialog(true, object, label, data, callFrom));
    }

    const openRequestApproval = () =>{
        let label = "10DLC Validation"
        let data={}
        let type= constants.NEEDS_APPROVAL
                data.id = 0;
                data.object = constants.PHONE_NUMBERS_OBJECT;
                dispatch(getAppDrawer(true, label, type.toLowerCase(), data, null));
    }

    let queryType = '';
    let filter = null;
    let isShowQueryByFilter = false;
    let activeTabInfo = getObjectParam(getActiveTabInfo());
    let isReport = getBooleanParam(activeTabInfo['isReport']);
    let isUnitsListView = getBooleanParam(activeTabInfo['isUnitsListView']);
    let isForecast = getBooleanParam(activeTabInfo['isForecast']);
    let isCanNotAddWorkflow = app.me.is_canNotAddWorkflow;

    if (!isReport) {
        filter = getObjectParam(activeTabInfo.filter);
        queryType = getStringParam(filter.type);
    }
    if (!isReport && object !== constants.SEARCH_RESULTS && object !== constants.ADVANCE_SEARCH_RESULTS
        && object !== constants.AUTOMATION_DESIGNER_OBJECT && object !== constants.BROADCAST_CAMPAIGNS_OBJECT
        && object !== constants.SALES_TEMPLATE_OBJECT && object !== constants.MARKETING_TEMPLATE_OBJECT && object !== constants.AGREEMENT_TEMPLATE_OBJECT
        && object !== constants.SOLUTIONS_OBJECT && object !== constants.ASSIGNMENT_RULES_OBJECT && object !== constants.COUPONS_OBJECT
        && object !== constants.UNITS_OBJECT && object !== constants.NEWS_OBJECT && object !== constants.LANDING_PAGES_FROALA_OBJECT
        && queryType !== 'group' && !isForecast && object !== constants.CALL_SCRIPT_OBJECT && object !== constants.SMS_TEMPLATE_OBJECT && label !== "Calendar/Tasks" && label !== "Calendar" && label !== "Tasks") {
        isShowQueryByFilter = true;
    }

    let refreshMarginLeft = (isShowQueryByFilter) ? '0px' : '0px';
    if ((object === constants.JOB_SCHEDULER)) {
        refreshMarginLeft = 1;
    }
    let addOrImportMenu = null;
    if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT
        || object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
        || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT
        || object === constants.SALES_LEADS_OBJECT || object === constants.USEFUL_LINK_OBJECT) {
        addOrImportMenu = generateMenuForAddOrImport(object);
    }
    let themeColor = app.me.background;
    let iconStyle = { float: 'left', marginTop: '3px', fontSize: '14px', marginLeft: '-6px', marginRight: '10px', backgroundColor: themeColor };
    if (imgName === 'fa fa-home') {
        iconStyle.fontSize = '21px';
        iconStyle.lineHeight = '1.4';
    }
    let is_franchisor = app.me.is_franchisor;
    let is_franchisee = app.me.is_franchisee;
    let phone_text_provider = app.me.phone_text_provider ?? constants.NONE; 
    let isShowPhoneNumberButtons = true;
    if(app.me.is_manage_unit_phone_by_fr == true){
        isShowPhoneNumberButtons = false;
    }
    let isShowImportAndAddButton = true;
    if(object == constants.CONTACTS_OBJECT && is_franchisee && reduxListView.data.hasOwnProperty("doNotShowAction") && reduxListView.data?.doNotShowAction){
        isShowImportAndAddButton = false;
    }
    return (
        <div>
            <div style={{ paddingLeft: '0px', float: 'left' }}>
                <i className={imgName} id='instantColorChange'
                    style={iconStyle}
                />
                {object == "units"  &&
	       <span style={{ paddingTop:'7px', paddingRight:'2px'}}>{app.me.tenant_company_name}:
				</span>}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-1px',
                        display: 'inline-block',
                        marginLeft: '5px',
                        marginRight: '10px'
                    }}
                    title={typeof headerLabel === 'string' ? headerLabel : ''}
                >
                    {
                        object === constants.ONBOARDING_OBJECT && headerLabel === '' ?
                            "Onboarding"
                            :
                            object === 'operations-dashboard' ?
                                "Operation Dashboard"
                                :
                                headerLabel}
                </div>
            </div>
            {object === constants.PRODUCTS_OBJECT &&
                ((app.me.hasOwnProperty('xero_integration') &&
                    app.me.xero_integration &&
                    !app.me.xero_integration.is_xero) || app.me.is_qbsetup) &&
                <div className='abtn1' style={{ paddingRight: '20px', float: 'right', marginLeft: '-13px' }}>
                    <Button
                        onClick={() => pullFromQb()}
                        style={styles.listViewPrimaryButton}
                    > {getLocalizedStrings().label.COMMON.ITEM_FROM_QB}</Button>
                </div>
            }

            {isShowAddIcon
                ? <div className='abtn1' style={{ paddingRight: '34px', float: 'right' }}>
                    {!(object === constants.APPOINTMENTS) && !(object === constants.ACCOUNTS_OBJECT) && !(object === constants.ROYALTY_RUN_OBJECT) &&
                        !(object === constants.CONTACTS_OBJECT) && !(object === constants.CUSTOM_TABLE1_OBJECT) &&
                        !(object === constants.CUSTOM_TABLE2_OBJECT) && !(object === constants.CUSTOM_TABLE3_OBJECT) &&
                        !(object === constants.CUSTOM_TABLE4_OBJECT) && !(object === constants.SALES_LEADS_OBJECT) &&
                        !(object === constants.RECYCLE_BIN_OBJECT) && !(object === constants.SALES_OBJECT) &&
                        !(object === constants.ORDERS_OBJECTS) && !(object === constants.QUOTATION_OBJECT)
                        && !(object === constants.PURCHASE_ORDER_OBJECT) && !(object === constants.WORKFLOW_OBJECT)
                        && !(object === constants.SALES_ORDER_OBJECT) && !(object === constants.PHONE_NUMBERS_OBJECT)
                        && !(object === constants.SECURITY_LEVEL_OBJECT) && !(object === constants.JOB_SCHEDULER) && 
                        (!isReport) && !(object === constants.AUDIT_QUESTIONS_OBJECT) && !(object === constants.AUDIT_ANSWERS_OBJECT) 
                        && !(object === constants.AUDITS_OBJECT) && !(object === constants.AGREEMENT_TEMPLATE_OBJECT) &&
                        !(object === constants.UNITS_OBJECT) &&
                        <Button
                            startIcon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
                            onClick={() => addNewRecord(constants.NEW)}
                            style={styles.listViewPrimaryButton}
                        > {getLocalizedStrings().label.COMMON.ADD}</Button>}


                    {is_franchisor === true && object === constants.AGREEMENT_TEMPLATE_OBJECT ? (
                        <div className='abtn1' style={{ paddingRight: '20px', float: 'right', marginLeft: '-13px' }}>
                            <PopOver
                                id={"PopOver"}
                                key={"PopOver"}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={styles.listViewPrimaryButton}
                                labelposition={"before"}
                                buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                                options={getAgreementTemplateMenuItems()}
                                onclickAction={(v) => addAgreementTemplatections(v)}
                                buttonLineOrigin={[28, 80]}
                            />
                        </div>
                    ) : (
                        (is_franchisee === true || is_franchisor === false) && object === constants.AGREEMENT_TEMPLATE_OBJECT ? (
                            <Button
                                startIcon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
                                onClick={() => addNewRecord(constants.NEW, constants.AGREEMENT)}
                                style={styles.listViewPrimaryButton}
                            >
                                {getLocalizedStrings().label.COMMON.ADD}
                            </Button>
                        ) : null
                    )}

                    {(object === constants.WORKFLOW_OBJECT && (isCanNotAddWorkflow === false)) &&
                        <Button
                            startIcon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
                            onClick={() => addNewRecord(constants.NEW)}
                            style={styles.listViewPrimaryButton}
                        > {getLocalizedStrings().label.COMMON.ADD}</Button>}
                    {(object === constants.APPOINTMENTS && kanbanDetail.isOnlyCalendar === false) && getMenuItems != undefined && getMenuItems != null && <div>

                        <PopOver
                            id={"PopOver"}
                            key={"PopOver"}
                            buttonEndIcon={"keyboard_arrow_down"}
                            buttonStyle={styles.listViewPrimaryButton}
                            labelposition={"before"}
                            buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                            options={getMenuItems()}
                            onclickAction={(v) => addAction(v)}
                            buttonLineOrigin={[28, 80]}

                        />
                    </div>}

                    {(object === constants.SALES_OBJECT) && <div>

                        <PopOver
                            id={"PopOver"}
                            key={"PopOver"}
                            buttonEndIcon={"keyboard_arrow_down"}
                            buttonStyle={styles.listViewPrimaryButton}
                            labelposition={"before"}
                            buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                            options={generateAddReportObjectItems()}
                            onclickAction={(v) => addReportAction(v)}
                            buttonLineOrigin={[28, 126]}

                        />

                    </div>}
                    {(object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT
                        || object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
                        || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT
                        || object === constants.SALES_LEADS_OBJECT) && (!isReport) && isShowImportAndAddButton && <div>
                            <Button
                                startIcon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
                                onClick={() => addNewRecord(constants.NEW)}
                                title={getLocalizedStrings().label.COMMON.ADD}
                                style={styles.listViewPrimaryButton}
                            > {getLocalizedStrings().label.COMMON.ADD}</Button>
                            {(!isUnitsListView) &&
                                < Button
                                    startIcon={<Icon style={{ fontWeight: 'bold' }}>import_export</Icon>}
                                    onClick={() => openImport()}
                                    title={getLocalizedStrings().label.COMMON.IMPORT}
                                    style={{ ...styles.listViewPrimaryButton, marginLeft: '7px' }}
                                >{getLocalizedStrings().label.COMMON.IMPORT}</Button>
                            }
                        </div>}
                    {object === constants.PHONE_NUMBERS_OBJECT && phone_text_provider !== constants.NONE && 
                        <div style={{ display: 'flex' }}>
                            {isShowPhoneNumberButtons && <div style={{ display: 'flex' }}> 
                            <div style={{ float: 'left', height: '16px', fontSize: '14px', marginRight: '10px', marginTop: '5px' }}>
                                <SmsCallCredits />
                            </div>
                            <div style={{ float: 'left', paddingLeft: '10px' }}>
                                <PopOver
                                    id={"PopOver"}
                                    key={"PopOver"}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={styles.listViewPrimaryButton}
                                    labelposition={"before"}
                                    buttonLabel={getLocalizedStrings().label.COMMON.ADD_NUMBERS}
                                    options={getAddNumbersMenuItems()}
                                    onclickAction={(v) => addPhoneNumberActions(v)}
                                    buttonLineOrigin={[28, 80]}
                                />
                            </div>

                            {phone_text_provider !== constants.NONE && <div style={{ paddingLeft: '5px' }}>
                                <Button
                                    onClick={() => openRequestApproval()}
                                    title={getLocalizedStrings().label.DLC_Validation}
                                    style={{ ...styles.listViewPrimaryButton, marginLeft: '7px' }}
                                >{getLocalizedStrings().label.COMMON.DLC_Validation}</Button>
                            </div>}
                            
                            { phone_text_provider == constants.SOFFRONT && <div style={{ paddingLeft: '5px' }}>
                                <Button
                                    onClick={() => purchasePhoneCredits()}
                                    title={getLocalizedStrings().label.COMMON.ADD_CREDITS}
                                    style={{ ...styles.listViewPrimaryButton, marginLeft: '7px' }}
                                >{getLocalizedStrings().label.COMMON.ADD_CREDITS}</Button>
                            </div> }

                        </div> }

                            <div style={{ paddingLeft: '10px' }}>
                                <IconButton onClick={() => openPhoneNumberSetup()} style={{ color: '#717171', padding: 5 }}>
                                    <Icon style={{ opacity: 0.7, fontSize: '16px', paddingLeft: '1px' }} title={getLocalizedStrings().label.LIST_VIEW.SET_UP}>settings</Icon>
                                </IconButton>
                            </div>
                        </div>
                    }
                    {object === constants.AUDIT_QUESTIONS_OBJECT &&
                        <div style={{display:'flex'}}>
                            <PopOver
                                id={"PopOver"}
                                key={"PopOver"}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={styles.listViewPrimaryButton}
                                labelposition={"before"}
                                buttonLabel={getLocalizedStrings().label.AUDIT_QUESTIONS.NEW_QUESTION}
                                options={getMenuItems()}
                                onclickAction={(v) => addAction(v)}
                                buttonLineOrigin={[0, 0]}

                            />

                            <Button
                                onClick={() => OpenAuditCategoriesPopup()}
                                title= {getLocalizedStrings().label.AUDIT_CATEGORY.CATEGORIES}
                                style={{ ...styles.listViewPrimaryButton,width:'100%',padding:'5px 15px 5px 15px', marginLeft: '5px' }}
                            >Categories</Button>
                        </div>
                    }

                </div>
                : null
            }

            {object == constants.UNITS_OBJECT && <div style={{ display: 'flex', float: 'right', paddingRight: '28px' }}>

                <Button
                    startIcon={<Icon style={{ fontWeight: 'bold', marginRight: '-5px' }}>add</Icon>}
                    onClick={() => addBaseName()}
                    style={styles.listViewPrimaryButton}
                > {getLocalizedStrings().label.COMMON.PLUS_BASE}</Button>

                <Button
                    startIcon={<Icon style={{ fontWeight: 'bold', marginRight: '-5px' }}>add</Icon>}
                    onClick={() => addBranchName()}
                    style={styles.listViewPrimaryButton}
                > {getLocalizedStrings().label.COMMON.PLUS_BRANCH}</Button>
                { getBooleanParam(app.me.franchisor_add_unit) && (getBooleanParam(app.me.is_tenant_admin) || constants.SECURITY_ROLE_ADMIN === getStringParam(app.me.t_name))
                    &&
                    <Button
                        startIcon={<Icon style={{ fontWeight: 'bold', marginRight: '-5px' }}>add</Icon>}
                        onClick={() => addUnitName()}
                        style={styles.listViewPrimaryButton}
                    > {getLocalizedStrings().label.COMMON.PLUS_UNIT}</Button>

                }


            </div>}

            {getKanbanToggle()}
            {isShowRefreshIcon() &&
                isValidParam(headerLabel) &&
                getStringParam(headerLabel) !== '' &&
                <span title={objectIconToolTip} style={{ position: 'absolute', marginLeft: 7, marginTop: 9 }}>
                    <div className='refreshIcon' style={{ cursor: 'pointer' }} onClick={reloadAppContainer}>
                        <span style={{ fontSize: 12, fontWeight: 'bold', color: '#039be5', backgroundColor: 'inherit', marginLeft: refreshMarginLeft }}>{getLocalizedStrings().label.REPORT.REFRESH}</span>
                    </div>
                </span>
            }
        </div>
    )
}

export default ObjectHeading;

export const SmsCallCredits = () => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);
    const recentFilters = useSelector((state) => state.filters.recentFilters);
    const [creditText, setcreditText] = useState('');

    useEffect(() => {
        let phoneTextProvider = app.me.phone_text_provider ?? constants.NONE;
        if(phoneTextProvider!= null && phoneTextProvider == constants.SOFFRONT){
            getCallSmsCredits();
        }
    }, []);

    const getCallSmsCredits = () => {
        let url = null;

        try {
            url = endPoints.PHONE_NUMBERS.GET_CALL_SMS_CREDITS;
            let promise = Promise.resolve(HTTPClient.get(url));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let textCredits = response.sms_credits;
                    let callCredits = response.call_credits;
                    let tempcreditText = creditText;
                    tempcreditText = callCredits + ' ' + getLocalizedStrings().label.COMMON.CALL_MINUTES + ' ' + textCredits + ' ' + getLocalizedStrings().label.COMMON.TEXT_CREDIT;

                    setcreditText(tempcreditText);
                }
            })
        } catch (error) {
            console.error("Error in 'objectHeading.js -> SmsCallCredits -> addPhoneNumberActions()':" + error);
        }
    }

    return (
        <div style={{ height: '10px' }}>
            <span>{creditText}</span>
        </div>
    )

}