import React, { useCallback, useRef} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../services/constants/actions";
import update from 'immutability-helper'
import { updateAuditChecklistcategoryRearrangedquestions,updateAuditChecklistcategories } from '../../../services/actions/auditActions';
import CloseIcon from '@mui/icons-material/Close';

const AuditChecklistChildCard = ({key,index,id,card,questions}) => {
    const dispatch = useDispatch();
    const AuditChecklist = useSelector((state)=> state.auditDetails?.AuditChecklist);
    const cardRef = useRef(null)
    let categoryCards = questions; 

    const deleteChildTask = (task) =>{
        let tempAuditChecklist = AuditChecklist;
        tempAuditChecklist =  deleteQuestionById(tempAuditChecklist, task.master_id);
        dispatch(updateAuditChecklistcategories(tempAuditChecklist));
    }

    const deleteQuestionById = (array, questionId) => {
        // Loop through the main array to find the questions array
        array.forEach(item => {
            if (item.questions) {
                item.questions = item.questions.filter(question => question.master_id !== questionId);
            }
        });

        return array;
    };


    const [, drop] = useDrop({
      accept: actions.CHECKLIST_CHILD_CARD,
      hover(item, monitor) {
        if (!cardRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = cardRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    }); 

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
          const dragCard = categoryCards[dragIndex]
          let rearrangeRows = update(categoryCards, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            });
            dispatch(updateAuditChecklistcategoryRearrangedquestions(rearrangeRows));
        },[dispatch, categoryCards],
    )

    const [{ isDragging }, drag] = useDrag({
        type: actions.CHECKLIST_CHILD_CARD,
        item: {  id, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      });
       
      drag(drop(cardRef));


  return (
    <>
     <div style={{marginTop:'3px',display:'flex',width:'97%',cursor: 'pointer',borderRadius:'0%',border:'2px solid #CCCCCC',marginBottom:'5px',height:'2.5rem'}}>
            <div style={{width:'3%',background:'#999999',cursor: 'grab',border:'2px solid #CCCCCC'}}  ref={cardRef}></div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center', width:'97%',textOverflow:'ellipsis',overflow:'hidden',paddingLeft:'10px',backgroundColor:'#FFFF'}}>
                <div style={{width:'95%',overflow:'hidden',textOverflow:'ellipsis'}}>
                    <span style={{fontSize:'16px',fontWeight:'400',fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap',paddingBottom:'1px'}}>{card.questions} </span>
                </div>
                <div style={{width:'5%'}}>
                    <CloseIcon title="close" style={{fontSize:'18px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => deleteChildTask(card)}/>
                </div>
            </div>
        </div>
    </>
  )
}

export default AuditChecklistChildCard