import { actions } from "..//constants/actions";

const detailViewInitialState = {
	mounted: false,
	fieldsMounted: false,
	data: null,
	record: null,
	workflow: null,
	fields: null,
	childObjects: null,
	actions: null,
	greenlight_actions: [],
	isRefresh: false,
	detailViewFieldsData: {},
	isDetailView: false,
	taskAppointment: null,
	taskAppMounted: false,
	pinnedNote: null,
	child_setup_details: null,
	avaliableContacts: null,
	avaliableAccounts: null,
	isQueueRefresh: false,
	isQueueNoteRefresh: false,
	scrollPosition: {},
	prevScrollPosition: {},
	isDetailviewLoading: true
};

const detailViewReducer = (state = detailViewInitialState, action) => {
	switch (action.type) {
		case actions.DETAILVIEW_GET_FULFILLED:
			return {
				...state,
				mounted: true,
				data: action.payload,
				record: action.payload.record,
				workflow: action.payload.workflow,
				fields: action.payload.fields,
				childObjects: action.payload.child_objects,
				actions: action.payload.actions,
				greenlight_actions: action.payload.greenlight_actions,
				child_setup_details: action.payload.child_setup_details,
				avaliableContacts: action.payload.avaliableContacts,
				avaliableAccounts: action.payload.avaliableAccounts,
				isDetailviewLoading: false
			};

		case actions.DETAILVIEW_IS_REFERSH:
			return {
				...state,
				isRefresh: action.payload,
			};
		case actions.DETAILVIEW_HEADER_IS_REFERSH:
			return{
				...state,
                data: {
                    ...state.data,
                    is_convert: action.payload.isHeaderRefresh,
                },
			}
		case actions.DETAILVIEW_FIELDS_GET_FULFILLED:
			return {
				...state,
				fieldsMounted: true,
				detailViewFieldsData: action.payload.fields,
			};
		case actions.IS_DETAILVIEW:
			return {
				...state,
				isDetailView: action.payload,
				// mounted: false
			};

		case actions.IS_DETAILVIEW_MOUNTED:
			return {
				...state,
				mounted: action.payload,
			};
		// case actions.DETAILVIEW_TASK_APP_GET_FULFILLED:
		//     return {
		//         ...state,
		//         taskAppointment: action.payload,
		//         taskAppMounted: true
		//     }
		case actions.DETAILVIEW_PINNED_NOTE_GET_FULFILLED:
			return {
				...state,
				pinnedNote: action.payload,
			};
		case actions.QUEUEVIEW_IS_REFERSH:
			return {
				...state,
				isQueueRefresh: action.payload,
			};
		case actions.QUEUEVIEW_NOTE_IS_REFERSH:
			return {
				...state,
				isQueueNoteRefresh: action.payload,
			};
		case actions.UPDATE_DETAIL_VIEW_SCROLL_POSITION: {
			return {
				...state,
				scrollPosition: {
					...state.scrollPosition,
					[action.payload.obj]: action.payload.pos,
				},
			};
		}
		case actions.REMOVE_DETAIL_VIEW_SCROLL_POSITION: {
			delete state.scrollPosition[action.payload.obj];
			return {
				...state,
			};
		}
		default:
			return state;
	}
};

export default detailViewReducer;
