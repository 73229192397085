import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styles } from "../../../services/constants/styles";
import {
	Menu,
	Popover,
	MenuItem,
	Icon,
	ListItemIcon,
	AppBar,
	Typography,
	IconButton,
	Toolbar,
	ListItemButton,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Slide from '@mui/material/Slide';
import SFTab from "../components/sfTab";
import Search from "../components/search";
import Profile from "../components/profile";
import { constants } from "../../../services/constants/constants";
import {
	setSelectedModuleItems,
	openLeftSideMenu,
} from "../../../services/actions/appActions";
import { setHeader } from "../../../services/actions/headerActions";
import map from "lodash/map";
import filter from "lodash/filter";
import { Logo } from "../components/logo";
import { hideSnackBar } from "../../../services/actions/snackBarAction";
import ObjectHeading from "../components/objectHeading";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { updateShowInformationDialog } from "../../../services/actions/appActions";
import NotificationComponent from "../components/notificationComponent";
import CallDialog from "../components/callDialog";
import SFCustomDrawer from "../containers/sfCustomDrawer";
import SFCustomDialog from "./sfCustomDialog";
import SFSlidingPopup from "../../sfPlugins/sfSlidingPopup";
import {
	hasAccessPermission,
	getSelectedObject,
	getImageNameByObject,
	setSelectedModuleInfo,
	getObjectLabelByObject,
} from "../../../services/helper/common";
import * as sfDialogs from "../components/sfDialogs";
import {
	isValidParam,
	getArrayParam,
	getObjectParam,
	getStringParam,
	getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import { getLasetSelectedModuleName } from "../../../services/helper/sfCookies";
import {
	initTab,
	addTab,
	TYPE_LIST_VIEW,
	TYPE_HOME,
	addNewFormTabs,
	getActiveTab,
	TYPE_DETAIL_VIEW
} from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {setListViewMounted, setMounted} from "../../../services/actions/listViewAcions";
import { setLasetSelectedModuleName } from "../../../services/helper/sfCookies";
import { setKanbanMounted } from "../../../services/actions/listsActions";
import { refreshCalendarForBroadcast } from "../../../services/actions/calendarActions";
import Button from "@mui/material/Button";
import { getDashboard } from "../../../services/actions/dashboardAcions";
import CustomNotification from "../components/customNotification";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { TopNavBar } from "../components/TopNavBar";
import { List, ListItem, ListItemText } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { set } from "lodash";
import { treeViewUpdate } from "@syncfusion/ej2-react-pivotview";

const Layout = ({ children, params }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const app = useSelector((state) => state.app);
	const header = useSelector((state) => state.header);
	const snackBar = useSelector((state) => state.snackBar);
	const appContainer = useSelector((state) => state.appContainer);
	const calendarInfo = useSelector((state) => state.calendar.calendarInfo);
	const dashboard = useSelector((state) => state.dashboard);
	const powerCalling = useSelector((state) => state.powerCalling);

	const [moduleName, setModuleName] = useState(app.selectedModuleName);
	const [badgePopOver, setBadgePopOver] = useState(false);
	const [isObjectAddOpen, setIsObjectAddOpen] = useState(false);
	const [isCallBackRedirect, setIsCallBackRedirect] = useState(false);
	const [callBackRedirectUrl, setCallBackRedirectUrl] = useState("");
	const [subMenuList, setSubMenuList] = useState("");
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [subMenuStyle, setSubMenuStyle] = useState({});
	const [currentHoverMenu, setCurrentHoverMenu] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isWebsiteTracking, setIsWebsiteTracking] = useState(false);
	const [isWebsiteDashboard, setIsWebsiteDashboard] = useState(false);
	const [quickAddObjectItems, setQuickAddObjectItems] = useState([]);
	const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
	const [snackBarOpenDuration, setSnackBarOpenDuration] = useState(2000);
	
	useEffect(() => {
		generateQuickAddObjectItems();
		if (isCallBackRedirect) {
			navigate(callBackRedirectUrl)
		}
		if (app.isRedirect) {
			navigate(app.redirectUrl);
		}
		if(app.me.show_aurinko_reauth_msg){
			sfDialogs.singleAlert(
				`Information`,
				`Please re-authorize your email and calendar integration setup.`,
				()=>closeInformationDialog(false)
			);
		}
	}, [isCallBackRedirect, app]);
	

	useEffect(() => {
		let delay = 2000;
		if (snackBar?.snackbar?.duration !== null) {
			delay = parseInt(snackBar.snackbar.duration);
		}
		setSnackBarOpenDuration(delay);
		setSnackBarOpenStatus(snackBar.snackbar.open);
		if (snackBar.snackbar.open) {
			setTimeout(() => {
				setSnackBarOpenStatus(false);
				dispatch(hideSnackBar());
			}, delay);
		}
	}, [snackBar])

	useEffect(()=>{
		let userSecurityLevel = app.me.t_name;
        if(params?.object === constants.LANDING_PAGES_FROALA_OBJECT &&  (userSecurityLevel && userSecurityLevel !== "Admin")) {
			header.showHeader = false
        }
		let phone_text_provider = app.me.phone_text_provider ?? constants.NONE;
		if(params?.object === constants.INBOX_OBJECT &&  (phone_text_provider && phone_text_provider == constants.NONE)) {
			header.showHeader = false
        }
	},[app.me.t_name, app.redirectUrl, header, params])

	const closeInformationDialog  = (status) => {
		dispatch(updateShowInformationDialog(status))
	}

	const handleChange = (value) => {
		let activeTab = getActiveTab()
		let isDisabled =
			(getBooleanParam(appContainer.customDrawerProps.isOpen) &&
				!getBooleanParam(appContainer.customDrawerProps.isMinimize)) ||
				getStringParam(moduleName) === value
				? true
				: false;
		if (isDisabled) {
			return;
		}
		let selectedObject = getSelectedObject();
		setListViewMounted(false);
		if (
			selectedObject !== "" &&
			selectedObject !== constants.TASKS &&
			selectedObject !== "Calendar/Tasks"
		) {
			setKanbanMounted(false);
		}	
		if (activeTab.object === constants.AUTOMATION_DESIGNER_OBJECT && activeTab.isActive && activeTab.type === TYPE_DETAIL_VIEW) {
			let message = "Are you sure you want to leave this page? Any unsaved changes would be lost."
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, message, () => selectModule(value), null);
		} else {
			selectModule(value)
		}
	};

	const selectModule = (value) =>{
		let lastSelectedModuleName = app.selectedModuleName;
		setModuleName(value);
		let tempModules = filter(modules, function (module, i) {
			return module.module === value;
		});
		if (
			tempModules !== null &&
			tempModules !== undefined &&
			tempModules.length > 0
		) {
			let moduleName = tempModules[0].module;
			let moduleItems = tempModules[0].module_items;
			dispatch(
				setSelectedModuleItems({
					moduleName: moduleName,
					moduleItems: moduleItems,
					lastSelectedModuleName:lastSelectedModuleName
				})
			);
			let object = getSelectedObject();
			object = object === constants.SETTING ? "" : object;
			if (object === "" || object === "landing_pages") {
				let moduleItem =
					moduleItems.length > 0 && isValidParam(moduleItems[0])
						? moduleItems[0]
						: null;
				if (
					object === "landing_pages" ||
					(moduleItem !== null && moduleItem.type === "Calendar")
				) {
					object = constants.CALENDAR_TASK;
				} else {
					object = getStringParam(moduleItem.object);
				}
			}
			object =
				object === constants.SEARCH_RESULTS
					? (constants.SEARCH_RESULTS + "-" + value).toLowerCase()
					: object;
			object = object === "Calendar/Tasks" ? constants.CALENDAR_TASK : object;

			if (value === "Marketing" && object === constants.CALENDAR_TASK) {
				let calAssign = getArrayParam(calendarInfo.assign);
				if (isValidParam(calAssign) && calAssign.length > 0) {
					dispatch(refreshCalendarForBroadcast(true));
				}
			}

			let isInitTab = initTab(object, true); //for init tab from cookie
			if (!isInitTab) {
				let selectedModuleItems =
					tempModules.length > 0
						? getArrayParam(tempModules[0].module_items)
						: [];
				selectedModuleItems = selectedModuleItems.filter((f) => {
					return f.object === object.replace("-", "_") || f.object === object;
				});
				let defaultModuleItem = getObjectParam(selectedModuleItems[0]);

				if (getArrayParam(defaultModuleItem.sub_items).length > 0) {
					defaultModuleItem = defaultModuleItem.sub_items[0];
				}

				object = getStringParam(defaultModuleItem.object).includes("_")
					? getStringParam(defaultModuleItem.object).replace("_", "-")
					: getStringParam(defaultModuleItem.object);
				let tab = {
					imgName: defaultModuleItem.image_name,
					item: object,
					label: defaultModuleItem.label,
					object: object,
					type: defaultModuleItem.has_filter ? TYPE_HOME : TYPE_LIST_VIEW,
					url: defaultModuleItem.has_filter
						? "/" + object + "/filters"
						: "/" + object + "/queries",
				};
				if (value.toLowerCase() === constants.MODULE_OPERATIONS) {
					tab.url =
						"/" +
						object +
						"/" +
						getStringParam(defaultModuleItem.type).toLowerCase();
				}

				addTab(tab, true);
			}
			if (
				dashboard.dashboardId > 0 &&
				(header.selectedHeader ===
					getLocalizedStrings().label.DASHBOARD.MARKETING_DASHBOARD ||
					header.selectedHeader ===
					getLocalizedStrings().label.DASHBOARD.SALES_DASHBOARD)
			) {
				let isInxpress = getBooleanParam(app.me.is_inxpress);
				let type = "";
				let label = "";
				let timeLine = null; //localStorage.getItem('dashboardTimeline');
				if (moduleName === constants.MODULE_MARKETING) {
					label = getLocalizedStrings().label.DASHBOARD.MARKETING_DASHBOARD;
					timeLine = localStorage.getItem("marketingDashboardTimeline");
				} else if (moduleName === constants.MODULE_SALES) {
					label = getLocalizedStrings().label.DASHBOARD.SALES_DASHBOARD;
					timeLine = localStorage.getItem("salesDashboardTimeline");
				}
				if (timeLine === null || timeLine === "") {
					timeLine = "tw";
				}
				let kanbanObject = {};
				kanbanObject.currentView = null;
				kanbanObject.show = false;
				dispatch(setHeader(object, label, kanbanObject, false, true));

				if (isInxpress) {
					let industryType = getStringParam(app.me.industry_type);
					if (
						industryType ===
						(constants.INDUSTRY_TYPE_INXPRESS_GLOBAL ||
							constants.INDUSTRY_TYPE_INXPRESS_COUNTRY)
					) {
						type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
					} else if (
						industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE
					) {
						type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
					}
				} else {
					if (app.me.projectId === constants.INDUSTRY_TYPE_INXPRESS_GLOBAL) {
						type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
					} else {
						type = getStringParam(moduleName);
					}
				}
				dispatch(
					getDashboard(
						app.me.projectId,
						app.me.id,
						dashboard.dashboardId,
						timeLine,
						type
					)
				);
				//getDashboard(app.me.projectId, app.me.id, dashboard.dashboardId, timeLine, moduleName);
			}
			dashboard.mounted = false;
		}
		setLasetSelectedModuleName();
	}

	const openNewWindow = () => {
		let helpUrl = null;
		if (app.me.hasOwnProperty("is_help_url") && app.me.is_help_url === null) {
			helpUrl = constants.HELP;
		} else if (
			app.me.hasOwnProperty("is_help_url") &&
			app.me.is_help_url !== null &&
			app.me.is_help_url !== ""
		) {
			helpUrl = app.me.is_help_url;
		} else if (
			app.me.hasOwnProperty("is_help_url") &&
			app.me.is_help_url === ""
		) {
			helpUrl = app.me.is_help_url;
		}

		closeBadgePopover();
		if (helpUrl !== null && helpUrl !== "") {
			window.open(helpUrl);
		} else {
			dispatch(
				showCustomSnackBar(
					"Help Article Currently Unavailable, Please configure the URL for the help article from Settings.",
					styles.snackbarBodyStyleError,
					styles.snackBarStyleLongMsg
				)
			);
		}
	};

	const closeBadgePopover = () => {
		setBadgePopOver(false);
	};

	const openSettings = () => {
		let object = constants.SETTING;
		setSelectedModuleInfo(object);
		let label = getLocalizedStrings().label.COMMON.MODULE_SETTING;
		let tab = {
			imgName: "fas fa-cog",
			item: object,
			label: label,
			object: object,
			type: TYPE_HOME,
			url: "/setting/module",
		};
		addTab(tab, true);
		dispatch(openLeftSideMenu(false));
		dispatch(setMounted(false, false, false, false, false));
	};

	const openSubMenu = (subMenuList, position, currentHoverDiv) => {
		// closeSubMenu();

		let subMenuStyle = { left: position.left };

		if (isValidParam(position.maxHeight)) {
			subMenuStyle.maxHeight = position.maxHeight;
		}
		if (isValidParam(position.bottom)) {
			subMenuStyle.bottom = position.bottom;
		}
		if (isValidParam(position.top)) {
			subMenuStyle.top = position.top;
		}
		setSubMenuList(subMenuList);
		setShowSubMenu(true);
		setSubMenuStyle(subMenuStyle);
		setCurrentHoverMenu(currentHoverDiv);
	};

	const changeSubMenuPosition = (position) => {
		setSubMenuStyle({ ...subMenuStyle, ...position });
	};

	const closeSubMenu = () => {
		setSubMenuList("");
		setShowSubMenu(false);
		setSubMenuStyle({});
	};

	// const websiteTracking = (websiteObj) => {
	// 	setIsWebsiteTracking(websiteObj.isWebsiteTracking);
	// 	setIsWebsiteDashboard(websiteObj.isWebsiteDashboard);
	// };

	const handleObjectAddOpen = (event, quickAddObjectItems) => {
		closeSubMenu();
		if (quickAddObjectItems.length === 0) {
			dispatch(
				showCustomSnackBar(
					getLocalizedStrings().message.COMMON.NO_ACTIONS_AVAILABLE,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				)
			);
		} else {
			setAnchorEl(event.currentTarget);
			setIsObjectAddOpen(true);
		}
	};

	const handleObjectAddClose = () => {
		setAnchorEl(null);
		setIsObjectAddOpen(false);
	};

	const generateQuickAddObjectItems = () => {
		let tempQuickAddObjectItems = [];
		let excludeObjects = [
			constants.ORDERS_OBJECTS,
			constants.QUOTATION_OBJECT,
			constants.PURCHASE_ORDER_OBJECT,
			constants.SALES_ORDER_OBJECT
		];
		let objects = [];
		try {
			let selectedModuleName = app.selectedModuleName;
			let moduleArr = app.home
				? getArrayParam(app.home.modules).filter((f) => {
					return f.module === selectedModuleName;
				})
				: [];
			if (moduleArr.length > 0) {
				let moduleItems = getArrayParam(moduleArr[0].module_items);
				objects = moduleItems
					.filter(
						(f) => f.is_system_object && !excludeObjects.includes(f.object)
					)
					.map((m) => {
						return { name: m.object, label: m.label };
					});
				let isfranchisor = app.me.is_franchisor
				if (isfranchisor) {
					objects = objects.filter(
						(f) =>
							f.name !== constants.COMPLIANCE_OBJECT &&
							f.name !== constants.REPORTED_SALES_OBJECT
					);
				}
				let offerItem = moduleItems.find((f) => f.object === constants.OFFERS);
				if (offerItem) {
					let coupounItem = getArrayParam(offerItem.sub_items).find(
						(f) => f.object === constants.COUPONS
					);
					if (coupounItem) {
						objects.push({
							name: coupounItem.object,
							label: coupounItem.label,
						});
					}
				}

				if (selectedModuleName === "Sales") {
					let appointmentmoduleItem = moduleItems.filter(
						(f) => f.object === constants.APPOINTMENTS
					);
					if (appointmentmoduleItem.length === 0) {
						appointmentmoduleItem = moduleItems.filter(
							(f) => f.object === constants.TASKS
						);
					}
					if (appointmentmoduleItem.length > 0) {
						objects.push({ name: "appointments", label: "Appointments" });
						objects.push({ name: "tasks", label: "Tasks" });
					}
				}
			}

			if (
				selectedModuleName === "Marketing" &&
				app.objectList.some(
					(object) =>
						object.name === "contacts" &&
						constants.MODULE_ID_SALES_MARKETING === object.homeId
				) &&
				objects.filter((e) => e.name === "contacts").length === 0
			) {
				objects.push({ name: "contacts", label: "Contacts" });
			}

			objects.sort(function (a, b) {
				return a.label.localeCompare(b.label);
			});
			objects.forEach((object, i) => {
				let objLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(
					object.label
				)
					? getLocalizedStrings().label.HEADER[object.label]
					: object.label;
				tempQuickAddObjectItems.push(
					<MenuItem
						key={"menu-item-quick-add-" + i}
						value={object.name}
						onClick={() => handleAddObjectChange(object.name)}
					>
						<div style={{width:'20px',color:'grey'}}>
							{<i className={getImageNameByObject(object.name)} />}
						</div>
						<Typography>{objLabel}</Typography>
					</MenuItem>
				);
				setQuickAddObjectItems(tempQuickAddObjectItems)
			});
		} catch (error) {
			console.error(
				"Error in 'App.js -> generateQuickAddObjectItems()':" + error
			);
		}
	};

	const handleAddObjectChange = (value) => {
		handleObjectAddClose();
		let hasPermission = false;
		let label = "";
		setIsObjectAddOpen(false);
		if (value === constants.COUPONS_OBJECT) {
			hasPermission =
				app.me.t_name === "Admin" || app.me.t_name === "Marketing"
					? true
					: false;
		} else {
			hasPermission = hasAccessPermission(
				value,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_CREATE
			);
		}
		let itemLabel = getObjectLabelByObject(value);

		if (hasPermission) {
			if (value === constants.APPOINTMENTS) {
				label =
					getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
					" " +
					getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
			} else if (value === constants.TASKS) {
				label =
					getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
					" " +
					getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
			} else {
				label = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			}
			if (value === constants.JOBS_OBJECT) {
				let info = { id: 0, title: label, type: "New", object: value };
				addNewFormTabs(info);
			} else {
				let data = { object: value };
				if (value === constants.COMPLIANCE_OBJECT) {
					data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
				}
				dispatch(getAppDrawer(true, label, constants.NEW, data, null));
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const callBack = (props) => {
		if (
			isValidParam(props) &&
			props.hasOwnProperty("url") &&
			isValidParam(props.url) &&
			props.url !== ""
		) {
			setCallBackRedirectUrl(props.url);
		}
		if (isValidParam(callBackRedirectUrl) && callBackRedirectUrl !== "") {
			setIsCallBackRedirect(true);
			setCallBackRedirectUrl(callBackRedirectUrl);
		}
	};

	const goToFranchisorLogin = () => {
		let gloabalFranchisorToken = getStringParam(localStorage.getItem("global_franchisor_token"));
		const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
		if(franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
			const index = franchiseAppLoginDetails.length - 2;
			const token = franchiseAppLoginDetails[index].token
			localStorage.setItem("token", token);
		} else {
			localStorage.setItem("token", gloabalFranchisorToken);
			localStorage.removeItem("is_franchisor_token");
		}
		if (constants.APP_URL === "") {
			window.location.replace("/");
		} else {
			window.location.replace(constants.APP_URL);
		}
	};

	const createTopModules = () => {
		let moduleItems = null;
		try {
			let selectedModuleName = getStringParam(getLasetSelectedModuleName()) !=='' ? getStringParam(getLasetSelectedModuleName()) : app.selectedModuleName;
			let moduleNameNew = getStringParam(moduleName) ? moduleName : selectedModuleName;
			let modules =
				isValidParam(app) && isValidParam(app.home)
					? getArrayParam(app.home.modules)
					: [];
			let moduleNames = modules.map((m) => {
				return m.module;
			});

			//setState({modules:modules});
			if (moduleNames.length > 0) {
				moduleItems = (
					<div>
						<List style={{ display: "flex", padding: "0px", height: 50 }}>
							{map(modules, (module, index) => {
								if (module.is_show) {
									let label = module.module_label;
									if (
										module.module !== constants.MODULE_HUB &&
										isValidParam(
											getLocalizedStrings().label.MODULE[module.module_label]
										)
									) {
										label =
											getLocalizedStrings().label.MODULE[module.module_label];
									}
									let isSameModule =
										moduleNameNew === module.module ? true : false;
									let style = { height: 50 };
									let textStyle = {
										color: "#b5b5b5",
										maxWidth: "110px",
										height: "20px",
										fontSize: "16px",
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									};
									if (isSameModule) {
										style = { ...style, backgroundColor: "#515151" };
										textStyle = { ...textStyle, color: "#f0f0f0" };
									}
									return (
										<ListItem key={"list-module-item-" + index} style={style} disablePadding>
											<ListItemButton
												style={{ height: 50 }}
												onClick={() => handleChange(module.module)}
											>
												<ListItemText
													primary={<div style={textStyle}>{label}</div>}
												/>
											</ListItemButton>
										</ListItem>
									);
								}
							})}
						</List>
					</div>
				);
			}
		} catch (error) {
			console.error("Error in 'App.js -> createTopModules()':" + error);
		}
		return moduleItems;
	};

	//const pad = 16;
	const pad = 0;
	const appbarHeight = 64;
	// let drawerWidth = 180;
	let drawerWidth = 5;

	const contentWidth = "calc(100% - " + drawerWidth + "px)";
	const left = 0;
	const top = appbarHeight;
	let contentStyle = {
		contentWidth: contentWidth,
		marginTop: top + pad,
		marginLeft: left + pad,
		marginBottom: pad,
		marginRight: pad,
		padding: 0,
		contentLeft: 0,
	};

	let home = getObjectParam(app.home);
	let homeSetUp = getObjectParam(app.homeSetup);
	let modules = getArrayParam(home.modules);
	let temModules = [];

	if (modules.length > 0) {
		modules = modules.filter((f) => f?.is_show);
		let selectedModuleName = getStringParam(getLasetSelectedModuleName());

		if (
			(app.me.is_free_crm || !app.me.is_client_connect) &&
			selectedModuleName === constants.MODULE_OPERATIONS
		) {
			selectedModuleName = "";
			setLasetSelectedModuleName();
		}
		temModules = modules?.filter((f) => {
			return f.module === selectedModuleName;
		});
		if (temModules.length === 0) {
			temModules = [modules[0]];
		}

		selectedModuleName = getStringParam(app.selectedModuleName);

		let createdAt = app.me.created_on;
		let date = new Date(createdAt);
		let dtstring =
			date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

		
	}

	let themeColor = app.me.background;
	let hasNotificationData = false;
	if (homeSetUp.is_notification === 1) {
		hasNotificationData =
			app.me.hasOwnProperty("is_task_popup") &&
				app.me.hasOwnProperty("is_appointment_popup")
				? true
				: false;
	}
	let franchisorToken = getStringParam(
		localStorage.getItem("is_franchisor_token")
	);
	let iconElementLeft = null;
	let logoColorCode = "#efefef";
	if (
		app.me.hasOwnProperty("logoColorCode") &&
		app.me.logoColorCode !== null &&
		app.me.logoColorCode !== undefined &&
		app.me.logoColorCode !== ""
	) {
		if (app.me.logoColorCode === "Light") {
			logoColorCode = "#efefef";
		} else {
			logoColorCode = "#404040";
		}
	}
	if (app.me.is_logo) {
		iconElementLeft = (
			<div
				style={{
					...styles.title,
					height: "50px",
					backgroundColor: logoColorCode,
					padding: "5px 34px 0px 20px",
				}}
			>
				<Logo />
			</div>
		);
	}

	let innerHeight = window.innerHeight - 195;

	return (
		<div>
			<div>
				<div
					className="mainContainer"
					style={{
						// position: "fixed",
						zIndex: "100",
						width: "100%",
						top: "0px",
					}}
				>
					<AppBar
						style={{
							backgroundColor: "#404040",
							zIndex: "0px",
							height: "50px",
						}}
						className="aapbar"
					>
						<div 
							onMouseEnter={() => {
								closeSubMenu();
							}}
						>
							<Toolbar>
								{iconElementLeft}
								{createTopModules()}
								<div
									style={{
										position: "absolute",
										right: "0",
										marginRight: "24px",
										display: "flex",
									}}
								>
									{homeSetUp.is_search === 1 && (
										<div
											style={{
												paddingTop: "6px",
												position: "relative",
												flex: "1",
											}}
										>
											{app.home !== null &&
											<Search closeSubMenu={closeSubMenu} />
											}
										</div>
									)}
									{franchisorToken && (
										<div
											style={{
												float: "right",
												cursor: "pointer",
												marginRight: 15,
												color: "#cccccc",
												marginTop: 9,
											}}
										>
											<Button
												onClick={goToFranchisorLogin}
												style={{
													...styles.listViewPrimaryButton,
													padding: 2,
												}}
												title={getLocalizedStrings().label.COMMON.BACK}
											>
												{getLocalizedStrings().label.COMMON.BACK}
											</Button>
										</div>
									)}
									{homeSetUp.is_global_add === 1 && (
										<div
											style={{
												float: "right",
												cursor: "pointer",
												color: "#cccccc",
												zIndex: "1000",
												display: 'grid'
											}}
										>
											<IconButton
												onClick={(e) =>
													handleObjectAddOpen(e, quickAddObjectItems)
												}
											>
												<i
													className="fa fa-plus"
													aria-hidden="true"
													title={getLocalizedStrings().label.HOME.ADD_NEW}
													style={{ color: "#cccccc", fontSize: "20px" }}
												/>
											</IconButton>
										</div>
									)}
									<div>
										<Menu
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={isObjectAddOpen}
												onClose={()=>handleObjectAddClose()}
												PaperProps={{
													elevation: 0,
													sx: {
													overflow: "visible",
													filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
													"&:before": {
														content: '""',
														display: "block",
														position: "absolute",
														top: 0,
														left: 12,
														width: 10,
														height: 10,
														bgcolor: "background.paper",
														transform: "translateY(-50%) rotate(45deg)",
														zIndex: 0,
													}
													}
												}}
											
											>
												{quickAddObjectItems}
											</Menu>
									</div>
									<div>
										{hasNotificationData && (
											<NotificationComponent
												accessConfig={{
													is_appointment_popup: app.me.is_appointment_popup,
													is_task_popup: app.me.is_task_popup,
												}}
											/>
										)}
									</div>

									{homeSetUp.is_settings === 1 && (
										<div className="setting" style={styles.homePageSettingIcon}>
											<IconButton onClick={openSettings}>
												<Icon
													title={getLocalizedStrings().label.COMMON.SETTINGS}
													style={{ color: "#ccc", fontSize: "21px" }}
												>
													settings
												</Icon>
											</IconButton>
										</div>
									)}
									{homeSetUp.is_help === 1 && (
										<div className="help" style={styles.homePageHelpIcon}>
											<IconButton onClick={openNewWindow}>
												<i
													className="fa fa-question-circle"
													title={getLocalizedStrings().label.COMMON.HELP}
													style={{ fontSize: "20px", color: "#cccccc" }}
												></i>
											</IconButton>
										</div>
									)}
									<div
										style={{ padding: "6px" }}
										title={app.me.name + ": " + app.me.email}
									>
										<Profile userName={app.me.name} />
									</div>
								</div>
							</Toolbar>
						</div>
						<div>
							{app.home !== null &&
								(
									<TopNavBar
										className="abc"
										isOpen={app.menuDrawerIsOpen}
										moduleItems={app.selectedModuleItems}
										// websiteTracking={websiteTracking} 
										openSubMenu={openSubMenu}
										closeSubMenu={closeSubMenu}
										subMenuOpen={showSubMenu}
										changeSubMenuPosition={changeSubMenuPosition}
									/>
								)
							}
						</div>
					</AppBar>
				</div>
				{app.me.id > 0 && (
					<div
						style={{
							position: "relative",
							float: "left",
							width: contentStyle.contentWidth,
							left: contentStyle.marginLeft,
							top: "90px",
							zIndex: "999 !important",
						}}
					>
						<div
							className="contentHeader"
							style={{
								position: "fixed",
								paddingLeft: "23px",
								backgroundColor: themeColor,
								zIndex: "99",
								width: "100%",
								borderBottom: "1px solid #E0E0E0",
								borderTop: "1px solid #E0E0E0",
							}}
						>
							<SFTab callBackToRedirect={callBack} />
						</div>

						<div
							className="bodyContent"
							style={{
								// height: innerHeight,
								position: "relative",
								padding: "0px 0px 0px 0px",
								width: "100%",
								top: "40px",
							}}
							onMouseEnter={() => {
								closeSubMenu();
							}}
						>
							<div style={styles.workAreaContainer}>
								<div style={{ height: "0px" }}>&nbsp;</div>
								{header.showHeader ? (
									<div
										className="appheader"
										style={{
											backgroundColor: "#fff",
											marginBottom: "15px",
											width:'100%'
										}}
									>
										<div style={styles.listViewHeaderFilter}>
											<div style={styles.listViewFilterBox}>
												<div
													className={"headingApp"}
													style={{
														float: "left",
														height: "40px",
														width: "100%",
														cursor: "default",
													}}
												>
													<ObjectHeading
														header={header}
														object={header.selectedTabObject}
														label={header.selectedHeader}
														kanban={header.kanban}
														showAddIcon={header.showAddIcon}
														callBackFromApp={callBack}
													/>
												</div>
											</div>
										</div>
									</div>
								) : null}
								<div 
									onMouseEnter={() => {
											closeSubMenu();
									}} 
									style={styles.workAreaContainer}
								>	
									{children}
								</div>
							</div>
						</div>
					</div>
				)}
				{snackBarOpenStatus && <Snackbar
					open={snackBarOpenStatus}
					onClose={()=>{dispatch(hideSnackBar())}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={snackBarOpenDuration}
					TransitionComponent={Slide}
					children={
						<Alert
							icon={false}
							style={{
								overflow: "hidden",
								Width: "800px",
								...getObjectParam(snackBar.snackbar.bodyStyle),
								...getObjectParam(snackBar.snackbar.style),
							}}
						>
							{snackBar.snackbar.message}
						</Alert>
					}
				/>}
				<CustomNotification />
				{showSubMenu && (
					<div
						id="filter-sub-menu"
						className="filter-sub-menu"
						style={subMenuStyle}
						onMouseLeave={closeSubMenu}
					>
						{subMenuList}
					</div>
				)}
			</div>
			{powerCalling !== undefined &&
				powerCalling.callDrawerOpend &&
				powerCalling.callDetail !== undefined &&
				powerCalling.callDetail !== null &&
				Object.keys(powerCalling.callDetail).length && (
					<CallDialog
						data={powerCalling.callDetail}
						label={powerCalling.callDrawerLabel}
					/>
				)}
			{appContainer !== null &&
				appContainer !== undefined &&
				appContainer.customDrawerProps !== undefined &&
				appContainer.customDrawerProps.isOpen && <SFCustomDrawer />}
			<SFCustomDialog params={params} />
			<SFSlidingPopup/>
		</div>
	);

};

export default Layout;
