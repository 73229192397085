import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam, getStringParam, getObjectParam, getArrayParam, isEmail, isEmpty, getIntParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getListData, refreshListView } from '../../../services/actions/listViewAcions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import ShowCircularProgress from '../components/circularProgress';
import { Select, Button, TextField, FormControl, MenuItem, InputLabel } from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { phoneFormat } from '../../../services/helper/utils';
import { refreshTreeView } from '../../../services/actions/treeViewAcions';
import { useDispatch, useSelector } from "react-redux";
import * as sfDialogs from '../components/sfDialogs';
import { detailViewIsMounted, isDetailViewSet, } from '../../../services/actions/detailViewActions';
import { showUnitBaseButton } from '../../../services/actions/listViewAcions';
import { setFranchisorIndustry } from '../../../services/actions/appActions';
import { addTab, TYPE_DETAIL_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';

const UnitBase = (props) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);

    const [first_name, setFirstName] = useState(null);
    const [last_name, setLastName] = useState(null);
    const [password, setPassword] = useState('');
    const [retype_password, setRetypePassword] = useState('');
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [allTimeZones, setAllTimeZones] = useState({});
    const [allRoyaltyPlans, setAllRoyaltyPlans] = useState({});
    const [selectedPlanNames, setSelectedPlanNames] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [states, setStates] = useState([]);
    const [faExpirationDate, setFAExpirationDate] = useState(null);
    const [timeZoneMenuItems, setTimeZoneMenuItems] = useState([]);
    const [countryMenuItems, setCountryMenuItems] = useState([]);
    const [stateMenuItems, setStateMenuItems] = useState([]);
    const [id, setId] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [accountType, setAccountType] = useState('');


    useEffect(() => {
        getDetails();
    }, [inProgress, isSavedClicked]);

    useEffect(() => {
        if (allTimeZones != null && allTimeZones != "") {
            setTimeZoneMenuItems(getTimezoneOptionsList(allTimeZones));
            setTimezone(app.me.timezone);
        }
    }, [allTimeZones]);

    useEffect(() => {
        if (countryList != null && countryList != "") {
            setCountryMenuItems(getCountryOptionsList(countryList));
            //setCountry(getStringParam(app.me.tenant_country));
        }
    }, [countryList]);

    useEffect(() => {
        if (country != null && country != "") {
            getStateList(country);
        }
    }, [country]);

    useEffect(() => {
        if (states != null && states != "") {
            //setState(getArrayParam(states).length > 0 ? getStringParam(states[0].id) : null);
            setStateMenuItems(getStateOptionsList(states));
            setMounted(true);
        }
    }, [states]);


    const getDetails = () => {
        try {
            setCountryList(getArrayParam(app.countryISO2));
            var companyName = app.me.tenant_company_name;
            companyName = companyName.split(" ").join("")
            setEmail(props.data.records?.email);

            setCompany(props.data.records?.company);
            let _country = props.data.records?.country;
            if (_country === null || _country === "" || _country === undefined) {
                _country = app.me.tenant_country;
            }
            setCountry(_country);
            setFirstName(props.data.records?.first_name);
            setLastName(props.data.records?.last_name);
            setPhone(props.data.records?.phone);
            populateMenus();
        } catch (error) {
            console.error("Error in 'addUnits.js -> getDetails()':" + error);
        }
    }
    const getCountryOptionsList = (countryList) => {
        let menuItems = [];
        countryList = getArrayParam(countryList);
        if (countryList !== null && countryList.length > 0) {
            countryList.map((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2} style={styles.popoverMenuItem} >{fieldOption.t_country} </MenuItem>);
            });
        }
        return menuItems;
    }

    const typeMenuList = () => {
        let menuItems = [];
        let typeArr = ['ML Base', 'AF Base', 'MU Base', 'Store Base'];
        typeArr.map((fieldOption, i) => {
            menuItems.push(
                <MenuItem value={fieldOption} id={fieldOption} title={fieldOption} key={fieldOption} style={styles.popoverMenuItem} >{fieldOption} </MenuItem>);
        });
        return menuItems;
    }
    const getTimezoneOptionsList = (timeZoneMap) => {
        let menuItems = [];
        timeZoneMap = getObjectParam(timeZoneMap)
        if (Object.keys(timeZoneMap).length > 0) {
            Object.keys(timeZoneMap).map((key, i) => {
                menuItems.push(<MenuItem value={key} id={key} title={timeZoneMap[key]} key={key} style={styles.popoverMenuItem} >{timeZoneMap[key]}</MenuItem>);
            });
        }
        return menuItems;
    }

    const getStateOptionsList = (stateList) => {
        let menuItems = [];
        stateList = getArrayParam(stateList)
        if (stateList.length > 0) {
            stateList.map((s, i) => {
                menuItems.push(<MenuItem value={s.id} id={s.id} title={s.value} key={s.id} style={styles.popoverMenuItem} >{s.value}</MenuItem>);
            });
        }
        return menuItems;
    }

    const populateMenus = () => {
        let url = endPoints.UNITS.ADD_UNIT_FORM_DETAILS_GET;
        let params = {};
        let promise = Promise.resolve(HTTPClient.get(url, params));;
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setAllTimeZones(response.allTimeZones);
                    setAllRoyaltyPlans(response.allRoyaltyPlans);
                }
            });
        }
    }

    const getStateList = (value) => {
        try {
            let params = {};
            params.object = constants.ACCOUNTS_OBJECT;
            params.name = 'state';
            params.value = value;
            params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

            var promise = SFFormActions.getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    setStates(getArrayParam(response));
                }
            });
        } catch (error) {
            console.error("Error in 'addUnits.js -> getStateList()':" + error);
        }
    }

    const onChangeTextField = (event, field) => {
        let value = event.target.value
        if (field === 'first_name') {
            setFirstName(value);
        } else if (field === 'last_name') {
            setLastName(value);
        } else if (field === 'password') {
            setPassword(value);
        } else if (field === 'retype_password') {
            setRetypePassword(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'phone') {
            setPhone(phoneFormat(value));
        } else if (field === 'company') {
            setCompany(value);
        }
    }

    const setSelectedTimeZoneValue = (event) => {
        let value = event.target.value;
        setTimezone(value);
    }

    const setSelectedCountryValue = (event) => {
        let value = event.target.value;
        setCountry(value);
        //getStateList(value);
    }
    const setSelectedAccountTypeValue = (event) => {
        let value = event.target.value;
        setAccountType(value)
    }
    const setSelectedStateValue = (event) => {
        let value = event.target.value;
        setState(value);
    }

    const validateForm = () => {
        try {
            let isValidRequest = true;

            if (!isValidParam(first_name) || isEmpty(getStringParam(first_name))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_FIRST_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(last_name) || isEmpty(getStringParam(last_name))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_LAST_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(password) || isEmpty(getStringParam(password))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(retype_password) || isEmpty(getStringParam(retype_password))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }


            if (getStringParam(password).length > 0 && getStringParam(retype_password).length > 0) {
                if (!password.replace(/\s/g, '').length) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (!retype_password.replace(/\s/g, '').length) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (retype_password !== password) {
                    isValidRequest = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNMATCH_RETYPE_PASSWORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }

            if (!isValidParam(email) || isEmpty(getStringParam(email))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (isValidParam(email) && !isEmail(email)) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
            if (!isValidParam(phone) || isEmpty(getStringParam(phone))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PHONE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(company) || isEmpty(getStringParam(company))) {
                isValidRequest = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_COMPANY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }


            if (isValidRequest) {
                checkDuplicateEmail();
            }
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> validateForm()':" + error);
        }

    }
    const doSave = () => {
        try {
            setInProgress(true);
            setIsSavedClicked(true);
            let _first_name = getStringParam(first_name).trim();
            let _last_name = getStringParam(last_name).trim();
            let _password = getStringParam(password).trim();
            let _retype_password = getStringParam(retype_password).trim();
            let _email = getStringParam(email).trim();
            let _phone = getStringParam(phone).trim();
            let _company = getStringParam(company).trim();
            let _timezone = getStringParam(timezone).trim();
            let _country = getStringParam(country).trim();
            let _state = getStringParam(state).trim();
            let franchise_industry = getStringParam(app.me.franchise_industry);
            let franchise_priceid = getStringParam(app.me.franchise_priceid);
            let accountFieldObj = sfForm.data[constants.ACCOUNTS_OBJECT];
            let allFields = accountFieldObj.fields;
            let fields = [];
            let params = {};
            params.first_name = _first_name;
            params.last_name = _last_name;
            params.password = _password;
            params.retype_password = _retype_password;
            params.email = _email;
            params.phone = _phone;
            params.company = _company;
            params.timezone = _timezone;
            params.country = _country;
            params.state = _state;
            params.franchise_industry = franchise_industry;
            params.franchise_priceid = franchise_priceid;

            params.is_applyworkflow = true;
            allFields.forEach(inputField => {
                let tempField = {};
                tempField.id = inputField.id;
                tempField.name = inputField.name;
                if (inputField.name === 'company') {
                    tempField.value = _company;
                    fields.push(tempField);
                } else if (inputField.name === 'last_name') {
                    tempField.value = _last_name;
                    fields.push(tempField);
                } else if (inputField.name === 'first_name') {
                    tempField.value = _first_name;
                    fields.push(tempField);
                } else if (inputField.name === 'email') {
                    tempField.value = _email;
                    fields.push(tempField);
                } else if (inputField.name === 'phone') {
                    tempField.value = _phone;
                    fields.push(tempField);
                } else if (inputField.name === 'country') {
                    tempField.value = _country;
                    fields.push(tempField);
                } else if (inputField.name === 'state') {
                    tempField.value = _state;
                    fields.push(tempField);
                } else if (inputField.name === 'industry') {
                    tempField.value = franchise_industry;
                    fields.push(tempField);
                } else if (inputField.name === 'visible_to') {
                    tempField.value = "Just me";
                    fields.push(tempField);
                } else if (inputField.name === 't_type') {
                    tempField.value = accountType;
                    fields.push(tempField);
                } else if (inputField.name === 'base' && accountType != null && accountType != "") {
                    tempField.value = "Yes";
                    fields.push(tempField);
                }
            });
            params.fields = fields;
            params.is_applyworkflow = true;
            if (accountType != null && accountType != "") {
                params.base = "Yes";
            }
            params.type = accountType;
            params.id = props.data?.accountRecordId;

            let promise = Promise.resolve(HTTPClient.post(endPoints.BASE_UNIT.SAVE_BASE_UNIT, params));
            promise.then((response) => {
                if (response.status === 0) {
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNIT_SAVED, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getAppDrawer(false, null, null, null, null));
                    dispatch(showUnitBaseButton(false));
                    let _data = {}
                    if (accountType == "" && app.me.franchise_industry == "") {

                        _data.franchise_industry = response.data.franchise_industry;
                        _data.franchise_priceid = response.data.franchise_priceid;
                        _data.base_copy_flag = response.data.base_copy_flag;
                        
                    }
                    _data.franchisor_add_unit = true;
                    dispatch(setFranchisorIndustry(_data));

                    refreshTreeView(true);
                    let _id = response.data.recordId;
                    let label = _company;
                    label = getStringParam(label);
                    openTab(label, 'accounts', _id);
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.UNIT_NOT_SAVED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                setInProgress(false);
                setIsSavedClicked(false);
            });
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> doSave()':" + error);
        }
    }
    const openTab = (label, object, recordId) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    const checkDuplicateEmail = () => {
        try {
            let Url = endPoints.UNITS.CHECK_DUPLICATE_TENANT_EMAIL;
            let searchParams = {};
            searchParams.email = getStringParam(email);
            let promise = Promise.resolve(HTTPClient.get(Url, searchParams));
            promise.then((response) => {
                if (isValidParam(response) && response.isExist) {
                    let msg = "This email address is not available. Please try another email address.";
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    doSave();
                }
            });

        } catch (error) {
            console.error("Error in 'addUnits.js -> checkDuplicateEmail()':" + error);
        }
    }



    const closeDrawer = () => {
        try {
            props.closeDrawer();
        }
        catch (error) {
            console.error("Error in 'addUnits.js -> closeDrawer()':" + error);
        }
    }



    const setMultiSelectValue = (event) => {
        let value = event.target.value;
        let tempValue = "";
        value.forEach((item, Index) => {
            if (tempValue === "") {
                tempValue = item;
            } else {
                tempValue += "," + item;
            }
        });

        if (value != null && value.length > 1) {
            setSelectedPlanNames(tempValue);
        } else {
            setSelectedPlanNames(value);
        }
    };

    if (mounted && !inProgress) {
        let _phone = phoneFormat(phone);
        let multiValue = [];
        if (selectedPlanNames !== "") {
            if (selectedPlanNames.indexOf(",") > -1) {
                multiValue = selectedPlanNames.split(",");
            } else {
                multiValue = selectedPlanNames;
            }
        }

        return (
            <div style={{ marginBottom: '10px', paddingLeft: '3%', width: '95%' }}>
                <div className="row">
                    <div className='col-sm-12'>
                        <div style={{ fontSize: '24px', paddingBottom: '12px' }}> Base Details</div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="first_name"
                                id="first_name"
                                label={getLocalizedStrings().label.UNITS.FIRST_NAME}
                                fullWidth={true}
                                value={first_name}
                                onChange={(e) => onChangeTextField(e, 'first_name')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>

                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="last_name"
                                id="last_name"
                                label={getLocalizedStrings().label.UNITS.LAST_NAME}
                                fullWidth={true}
                                value={last_name}
                                onChange={(e) => onChangeTextField(e, 'last_name')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="password"
                                id="password"
                                type="password"
                                inputStyle={styles.textField.input}
                                style={{ ...styles.sfFormFieldContainer, borderRadius: '3px' }}
                                label={getLocalizedStrings().label.UNITS.PASSWORD}
                                fullWidth={true}
                                onPaste={function (e) { e.preventDefault(); }}
                                value={password}
                                onChange={(e) => onChangeTextField(e, 'password')}
                                inputprops={{ maxlength: '32' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="retype_password"
                                id="retype_password"
                                type="password"
                                label={getLocalizedStrings().label.UNITS.RETYPE_PASSWORD}
                                fullWidth={true}
                                onPaste={function (e) { e.preventDefault(); }}
                                value={retype_password}
                                onChange={(e) => onChangeTextField(e, 'retype_password')}
                                inputprops={{ maxlength: '32' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="email"
                                id="email"
                                label={getLocalizedStrings().label.UNITS.EMAIL}
                                fullWidth={true}
                                value={email}
                                onChange={(e) => onChangeTextField(e, 'email')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="phone"
                                id="phone"
                                label={getLocalizedStrings().label.UNITS.PHONE}
                                fullWidth={true}
                                value={_phone}
                                onChange={(e) => onChangeTextField(e, 'phone')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="company"
                                id="company"
                                label={getLocalizedStrings().label.UNITS.COMPANY}
                                fullWidth={true}
                                value={company}
                                onChange={(e) => onChangeTextField(e, 'company')}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin='dense'
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '13px' }}></div>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel id="sf-addunites-from-simple-select-outlined-label" className="sf-addunites-from-sfform">{getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}</InputLabel>
                            <Select
                                label={getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}
                                labelId='sf-label-time-zone'
                                id='sf-time-zone'
                                value={timezone}
                                onChange={(e) => setSelectedTimeZoneValue(e)}
                                style={{ height: '37px' }}
                                className={"sf-fields-bg"}
                            >
                                {timeZoneMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-sffromaddunits-simple-select-outlined-label" className="sf-add-units-from-sffromaddunits">{getLocalizedStrings().label.UNITS.COUNTRY_SELECT}</InputLabel>
                            <Select
                                labelId='sf-label-country'
                                id='sf-country'
                                label={getLocalizedStrings().label.UNITS.COUNTRY_SELECT}
                                value={country}
                                onChange={(e) => setSelectedCountryValue(e)}
                                style={{ height: '38px' }}
                                className={"sf-fields-bg"}
                            >
                                {countryMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.UNITS.STATE_SELECT}</InputLabel>
                            <Select
                                labelId='sf-label-menu'
                                id='sf-menu'
                                label={getLocalizedStrings().label.UNITS.STATE_SELECT}
                                value={state}
                                onChange={(e) => setSelectedStateValue(e)}
                                autowidth={"true"}
                                style={{ height: '38px' }}
                                className={"sf-fields-bg"}
                            >
                                {stateMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <div style={{ fontSize: '12px', paddingBottom: '5px', marginTop: '5px' }}></div>
                        <FormControl style={{ width: '100%', height: '38px', marginTop: '7px' }} variant="outlined">
                            <InputLabel id="sf-add-units-from-sffromaddunits-simple-select-outlined-label" style={{ marginTop: '-7px' }} className="sf-add-units-plan-name">Type</InputLabel>
                            <Select
                                labelId='sf-label-type'
                                id='sf-type'
                                label="Type"
                                value={accountType}
                                onChange={(e) => setSelectedAccountTypeValue(e)}
                                style={{ height: '38px' }}
                                className={"sf-fields-bg"}
                            >
                                {typeMenuList()}
                            </Select>
                        </FormControl>
                    </div>

                </div>
                <div className="row" style={{ float: "right" }}>
                    <div style={{ float: 'right', paddingRight: '10px', paddingBottom: '30px', paddingTop: '15px', paddingLeft: '15px' }}>
                        <Button
                            key='save'
                            onClick={() => validateForm()}
                            style={{ ...styles.primaryButton }}
                            disabled={isSavedClicked}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        <Button
                            key='close'
                            onClick={() => closeDrawer()}
                            style={{ ...styles.secondaryButton }}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>

                    </div>
                </div>
            </div>
        );

    } else {
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    };
};

export default UnitBase;
