import React, { useState, useEffect } from "react";
import $ from "jquery";
import { styles } from "../../../services/constants/styles";
import {
	constants,
	OBJECT_TABLEID_MAP,
	SOURDOUGH_LOCAL_FILENAME_MAP,
	COUNTRY_CODE_MAP,
	JOB_ACTIONS,
	ROYALTY_RUN_ACTIONS,
	REPORTED_SALES_ACTIONS
} from "../../../services/constants/constants";
import * as ParameterVerifier from "../../../services/helper/parameterVerifier";
import {
	getAppDrawer,
	getAppCustomDrawer,
} from "../../../services/actions/appContainerActions";
import {
	deleteAction,
	updateFields,
	completeAction,
	reschedule,
} from "../../../services/actions/batchActions";
import {
	isValidParam,
	getIntParam,
	getStringParam,
	getBooleanParam,
	getArrayParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { getEndPointObject } from "../../../services/actions/sfFormActions";
import { getTasksCompletedPromise } from "../../../services/actions/sfFormActions";
import { Icon,Dialog,DialogTitle,IconButton,DialogContent,Select,InputLabel,MenuItem} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {
	groupUsedInCampaigns,
	refreshDetailViewData,
	refreshDetailViewListViewData, detailViewIsMounted,
	getDetailviewData,
	isDetailViewSet, refreshQueueDetailViewData
} from "../../../services/actions/detailViewActions";
import { setSelectedDate } from "../../../services/actions/calendarActions";
import map from "lodash/map";
import _ from 'lodash';
import {
	hasAccessPermission,
	getRecentRecord,
	getObjectLabelByObject,
} from "../../../services/helper/common";
import * as sfDialogs from "../components/sfDialogs";
import {
	isLinkedRecord,
	setListViewMounted,
	refreshListView, setMounted
} from "../../../services/actions/listViewAcions";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import {
	getActiveTab as tabManagerActiveTab,
	closeTab,
	addTab,
	getActiveTabInfo,
	updateActiveTab,
	addNewFormTabs,
	TYPE_LIST_VIEW,
	updateTabByTabIndex,
	updateTabInfo,
} from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { updateCouponsStatus, deleteCoupons } from "../components/createCoupon";
import { KanbanToggle } from "../components/kanbanToggle";
import { getCookie } from "../../../services/helper/sfCookies";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import * as SFFormActions from "../../../services/actions/sfFormActions";
import { checkDuplicateProductName } from "../../../services/actions/sfFormActions";
import isArray from "lodash/isArray";
import {
	openDetailviewQueueTab,
	removeRecordFromRedux, loadQueueList, removeAcoountRecordInQueueFromRedux
} from "../../../services/actions/queueActions";
import { getTasksRefresh } from "../../../services/actions/taskActions";
import { getObjectSpecificEndpoint } from "../../../services/helper/utils";
import { LinkToType } from "../../../services/constants/link";
import { franchiseLogin } from "../../../services/actions/unitsAction.js";
import {
	formatDate,
	selectedDateRangeUpdate,
} from "../../../services/actions/calendarActions";
import { useDispatch, useSelector } from "react-redux";
import PopOver from "./PopOver";
import { Tune } from "@mui/icons-material";
import {
	getRecentFilters,
	getGroups,
} from "../../../services/helper/common";
import { refreshDetailViewGroup } from '../../../services/actions/detailViewActions';

const convertLabel = getLocalizedStrings().label.COMMON.CONVERT;

const DetailViewHeader = ({
	headerProps,
	object,
	refreshView,
	queueObj,
	isEdit,
}) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const tab = useSelector((state) => state.tab);
	const childListView = useSelector((state) => state.childListView);
	const detailViewRecord = useSelector((state) => state.detailView.record);
	const detailView = useSelector((state) => state.detailView);
	const listView = useSelector((state) => state.listView);
	const queueList = useSelector((state) => state.queueList);
	const taskAppointment = useSelector((state) => state.taskAppointment);
	const calendar = useSelector((state) => state.calendar);
	const sfForm = useSelector((state) => state.sfForm);
	const header = useSelector((state) => state.header);
	const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);

	const [openActionList, setOpenActionList] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [mounted, setstateMounted] = useState(0);
	const [data, setData] = useState(null);
	const [showEditTitleTextField, setShowEditTitleTextField] = useState(false);
	const [showEditTitleIcon, setShowEditTitleIcon] = useState(false);
	const [fields, setFields] = useState(null);
	const [isDayDisabled, setIsDayDisabled] = useState(null);
	const [isWeekDisabled, setIsWeekDisabled] = useState(false);
	const [isMonthDisabled, setIsMonthDisabled] = useState(false);
	const [isNewDisabled, setIsNewDisabled] = useState(null);
	const [greenlightActions, setGreenlightActions] = useState([]);
	const [recurring_fields, setRecurring_fields] = useState({});
	const [recurring_edit, setRecurring_edit] = useState(false);
	const [fieldValue, setFieldValue] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const [myClientId, setMyClientId] = useState("");
	const [hasSecurityRolePermission, setHasSecurityRolePermission] =
		useState(false);
	const [requiredFields, setRequiredFields] = useState("");
	const [showRequiredFormFields, setShowRequiredFormFields] = useState("");
	const [isSelfAuditChecked,setIsSelfAuditChecked] = useState(false);

	const [showConvertButton, setShowConvertButton] = useState(true);
	const [showSearchKBButton, setShowSearchKBButton] = useState(false);
	const [showEditButton, setShowEditButton] = useState(true);
	const [showMore, setShowMore] = useState(true);
	const [showPushButton, setShowPushButton] = useState(false);
	const [titleField1, setTitleField1] = useState(null);
	const [titleField2, setTitleField2] = useState(null);
	const [title, setTitle] = useState(headerProps.title);
	const [titleFieldWidth, setTitleFieldWidth] = useState("35%");
	const [showConvertToAccountButton, setShowConvertToAccountButton] =
		useState(false);
	const [convertToAccountLabel, setConvertToAccountLabel] = useState("");
	const [showToggle, setShowToggle] = useState(false);
	const [queueObjInner, setQueueObjInner] = useState(
		queueList !== null &&
			queueList !== undefined &&
			queueList.hasOwnProperty("data") &&
			queueList.data !== null
			? queueList.data.object
			: ""
	);
	const [buttonLineOrigin, setButtonLineOrigin] = useState(
		object === constants.CUSTOM_TABLE1_OBJECT ||
			object === constants.CUSTOM_TABLE2_OBJECT ||
			object === constants.CUSTOM_TABLE3_OBJECT ||
			object === constants.CUSTOM_TABLE4_OBJECT
			? [24, 65]
			: [24, 126]
	);
	const [tabInfo, setTabInfo] = useState(getActiveTabInfo());
	const [actions, setActions] = useState([]);

	useEffect(()=>{
		if(queueList !== null &&
			queueList !== undefined &&
			queueList.hasOwnProperty("data") &&
			queueList.data !== null){
	  setQueueObjInner(queueList.data.object)			
			}else if(object == 'accounts') {
				setQueueObjInner(object)			

			}
	},[])

	useEffect(() => {
		const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
		let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`));
		let callFrom;

		if (franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
			const index = franchiseAppLoginDetails.length - 1;
			const previousUnitLoginDetails = franchiseAppLoginDetails[index]
			callFrom = previousUnitLoginDetails.callFrom
		} else if (franchiseAppLoginDetails && franchiseAppLoginDetails.length === 1) {
			const previousUnitLoginDetails = franchiseAppLoginDetails[0]
			callFrom = previousUnitLoginDetails.callFrom
		}
		if (callFrom === constants.DETAIL) {
			if (shouldReturnToInitialPosition) {
				if (franchiseAppLoginDetails.length === 1) {
					localStorage.removeItem('franchiseAppLoginDetails')
				} else {
					franchiseAppLoginDetails.pop()
					localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(franchiseAppLoginDetails))
				}
				localStorage.removeItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRedirect(false);
		if (
			headerProps.titleFieldName === "sales_rep" ||
			headerProps.titleFieldName === "inventory_no" ||
			getBooleanParam(showEditTitleTextField)
		) {
			setShowEditTitleIcon(false);
		} else if (
			detailView.fields !== null &&
			getArrayParam(detailView.fields).length > 0
		) {
			let fields = detailView.fields.filter((f) => {
				if (f.name === headerProps.titleFieldName) {
					return f;
				}
			});
			if (
				getArrayParam(fields).length > 0 &&
				fields[0].hasOwnProperty("is_inline_edit")
			) {
				setShowEditTitleIcon(getBooleanParam(fields[0].is_inline_edit));
			} else if (getArrayParam(fields).length === 0) {
				setShowEditTitleIcon(true);
			}
		}

		if (redirect && !redirectUrl === "") {
			return <Navigate push to={redirectUrl} />;
		}

		//setTabLabel();

		if (object === constants.CONTACTS_OBJECT) {
			if (ParameterVerifier.isValidParam(detailView.data)) {
				setConvertToAccountLabel(convertLabel + " TO ACCOUNTS");
				setShowConvertToAccountButton(detailView.data.is_convert);
			}
		}

		if (
			object === constants.SOLUTIONS_OBJECT ||
			object === constants.PETS_OBJECT ||
			object === constants.ORDERS_OBJECTS ||
			object === constants.ORDER_ITEMS_OBJECTS ||
			object === constants.PRODUCTS_OBJECT ||
			object === constants.INVENTORY_OBJECT ||
			object === constants.JOBS_OBJECT
		) {
			setShowConvertButton(false);
		}
		if (
			object === constants.COUPONS_OBJECT ||
			object === constants.RECYCLE_BIN_OBJECT
		) {
			setShowConvertButton(false);
			setShowEditButton(false);
			setShowMore(false);
		}
		if (
			object === constants.ORDERS_OBJECTS ||
			object === constants.ORDER_ITEMS_OBJECTS
		) {
			setShowEditButton(false);
		}

		if (object === constants.CASES_OBJECT) {
			let kbLink = app.me.kb_link;
			if (kbLink !== null && kbLink !== " " && kbLink !== undefined) {
				setShowSearchKBButton(false);
			}
		}
		if (object === constants.SALES_LEADS_OBJECT) {
			let actionItems = headerProps.actions;
			let actionName = [];
			if (isValidParam(actionItems)) {
				actionName = actionItems.filter((item) => item === constants.PUSH);
			}
			if (getArrayParam(actionName) && actionName.length > 0) {
				if (actionName[0] === constants.PUSH && app.me.t_name === "Admin") {
					setShowPushButton(true);
				}
			}
		}

		if (app.selectedModuleName === constants.MODULE_HUB) {
			setShowConvertButton(false);
			setShowSearchKBButton(false);
			if (object === constants.REPORTED_SALES_OBJECT) {
				setShowEditButton(true);
			} else {
				setShowEditButton(false);
			}
			setShowEditButton(false);
			setShowMore(false);
			setShowPushButton(false);
		}

		if (
			object === constants.ORDERS_OBJECTS ||
			object === constants.ORDER_ITEMS_OBJECTS
		) {
			setShowEditButton(false);
		}

		if (object === constants.APPOINTMENTS) {
			setShowConvertButton(false);
			setShowEditButton(false);
			setShowMore(false);
		}
		if (object === constants.TASKS) {
			setShowConvertButton(false);
			setShowEditButton(true);
			setShowMore(false);
		}
		if (object === constants.ROYALTY_RUN_OBJECT || object === constants.REPORTED_SALES_OBJECT) {
			setShowMore(false);
		}

		if (object === constants.JOBS_OBJECT) {
			performActions();
		}
	}, [showConvertToAccountButton, detailView]);

	useEffect(() => {
		let isGreenLight =
			isValidParam(app) && isValidParam(app.me)
				? getBooleanParam(app.me.is_greenlight)
				: false;
		if (object === constants.ACCOUNTS_OBJECT && isGreenLight === true) {
			let isEqual = _.isEqual(
				detailView.greenlight_actions,
				greenlightActions
			);
			if (!isEqual) {
				setGreenlightActions([...detailView.greenlight_actions]);
			}

		}
		setRedirect(false);

	}, [greenlightActions, setGreenlightActions])

	useEffect(() => {

		let tempTile = title === "" ? constants.EMPTY : title;
		if (isValidParam(fields) && object !== constants.ROYALTY_RUN_OBJECT) {
			let titleField1Val = getObjectParam(fields[headerProps.titleFieldName]);
			setTitleField1(titleField1Val);
			if (object === constants.CONTACTS_OBJECT && isValidParam(titleField1)) {
				let titleField2Val = fields["last_name"] !== null ? getObjectParam(fields["last_name"]) : null;
				setTitleField2(titleField2Val);
				tempTile = getTitleForContactsObject(titleField1Val, titleField2Val);
			} else if (object === constants.AUDITS_OBJECT) {
				tempTile = detailViewRecord["audit_date"] ?? constants.EMPTY;
			} else if (isValidParam(titleField1Val)) {
				if (!titleField1Val.value || titleField1Val.value === "") {
					tempTile = constants.EMPTY;
				} else {
					tempTile = getStringParam(titleField1Val.value);
				}
			}
		}

		if (object === constants.AUDITS_OBJECT) {
			if (childListView.data.object === constants.AUDIT_ANSWERS_OBJECT) {
				let totalCountScore = childListView.data.records.reduce((prev, cur) => prev + cur.total_score, 0);
				tempTile = tempTile + " (" + totalCountScore + ")";
			}
		}
		setTitle(tempTile);
	}, [childListView.data.object, childListView.data.records, fields, object])
	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	if (detailView.isRefresh !== nextProps.detailView.isRefresh) {
	// 		if (nextProps.detailView.isRefresh === true) {
	// 		}
	// 	}
	// 	if (
	// 		nextProps.headerProps !== null &&
	// 		nextProps.headerProps.title !== headerProps.title
	// 	) {
	// 		getTitleField(nextProps);
	// 	}

	// 	let isGreenLight =
	// 		isValidParam(app) && isValidParam(app.me)
	// 			? getBooleanParam(app.me.is_greenlight)
	// 			: false;
	// 	if (
	// 		(object === constants.ACCOUNTS_OBJECT) &
	// 		(isGreenLight === true)
	// 	) {
	// 		let isEqual = _.isEqual(
	// 			detailView.greenlight_actions,
	// 			nextProps.detailView.greenlight_actions
	// 		);
	// 		if (!isEqual) {
	// 			setState({
	// 				greenlightActions: nextProps.detailView.greenlight_actions,
	// 			});
	// 		}
	// 	}
	// }

	const performActions = () => {
		try {
			let tab = tabManagerActiveTab();
			let tabs = tab.tabs;
			if (tab.callFor === constants.SAVE_AND_SCHEDULE) {
				handleAction(constants.JOB_SCHEDULER);
				delete tab.callFor;
				updateTabByTabIndex(tab);
				tabs = tab.tabs;
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> performActions()': " + error
			);
		}
	};

	const refreshDeatilsView = (action) => {
		if (action === constants.DELETE || action === constants.REMOVE) {
			dispatch(detailViewIsMounted(false));
			dispatch(isDetailViewSet(false));
		} else {
			dispatch(detailViewIsMounted(false));
			dispatch(isDetailViewSet(false));
			dispatch(refreshDetailViewData(true));
		}
	};

	const closeDetailviewActionList = () => {
		setOpenActionList(false);
	};

	const getRecordDetailsForSMS = (fieldName) => {
		let params = null;
		var response = null;
		let endPoint = null;
		let recordIdArr = [];
		try {
			let recordId = getIntParam(detailView.record.id);
			recordIdArr.push(recordId);
			params = {
				recordIds: recordIdArr,
				type: "Texts",
				tableId: OBJECT_TABLEID_MAP[getStringParam(object)],
			};
			endPoint = endPoints.CONTACTS.GET_SMS_UNSUBSCRIBE_LIST;
			response = Promise.resolve(HTTPClient.get(endPoint, params));
			response.then((response) => {
				if (isValidParam(response) && getArrayParam(response).length === 1) {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.SMS_INTEGRATION
							.RECORD_UNSUBSCRIBE_ALERT,
						null
					);
				} else {
					let endPoint = getObjectSpecificEndpoint(
						object,
						endPoints.RECORDS.MAP_DATA
					);
					let params = {
						id: recordId,
						fields: [
							"company",
							"first_name",
							"last_name",
							"phone",
							"mobile",
							"country",
						],
					};
					if (object === constants.JOBS_OBJECT) {
						params.fields = [
							"account",
							"contact",
							"phone",
							"mobile",
							"sales_rep",
						];
					}

					var promise = Promise.resolve(HTTPClient.get(endPoint, params));
					if (isValidParam(promise)) {
						promise.then((response) => {
							if (isValidParam(response)) {
								let records = response.records;
								processInfoAndOpenSMSDialog(records);
							}
						});
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> getRecordDetailsForSMS()': " + error
			);
		}
	};

	const processInfoAndOpenSMSDialog = (records) => {
		try {
			let recordId = getIntParam(detailView.record.id);
			if (isValidParam(records)) {
				let fullName = "";
				let toNumber = getStringParam(records.mobile);
				if (toNumber) {
					if (
						object === constants.ACCOUNTS_OBJECT ||
						object === constants.CONTACTS_OBJECT
					) {
						let firstName = getStringParam(records.first_name);
						let lastName = getStringParam(records.last_name);

						fullName = (firstName + " " + lastName).trim();
						fullName = fullName || getStringParam(records.company);
					} else if (object === constants.JOBS_OBJECT) {
						fullName = getStringParam(records.contact).trim();
						fullName = fullName || getStringParam(records.account);
					}

					fullName = fullName || toNumber;
					let title = fullName !== "" ? "To " + fullName : "";

					let countryCode = COUNTRY_CODE_MAP[app.me.tenant_country];
					if (toNumber.indexOf("+") < 0) {
						toNumber = countryCode + toNumber;
					}
					let data = {};
					data.id = recordId;
					data.object = object;
					data.toNumber = toNumber;
					data.isDetailView = true;
					if (object === constants.JOBS_OBJECT) {
						let companyName = getStringParam(records.sales_rep);
						data.textMsg = `Hi, this is ${companyName}. We will arrive in 1 hour`;
						data.callFor = constants.OMW;
					}
					let style = {};
					style.width = "40%";
					dispatch(
						getAppCustomDrawer(
							true,
							title,
							LinkToType.TYPE_CLICK_TO_SMS,
							style,
							data,
							null,
							false
						)
					);
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.SMS_INTEGRATION
							.NO_LINKED_MOBILE_NUMBER_MSG
					);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> processInfoAndOpenSMSDialog()': " +
				error
			);
		}
	};

	const checkReportedSalesPresent = (id) => {
		let isExist = false;
		let _params = {
			"id": id,
		}
		var _response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORTED_SALES_REJECTED_AVAILABLE, _params));
		_response.then((_response) => {
			if (_response.status == 0) {
				if (_response.data == true) {
					let msg = "";
					let tempMsg1 = 'There are Reported Sales record(s) which are in Rejected status!';
					let tempMsg2 = 'Please communicate with the respective units and asked them to rectify their reported sales.';
					let tempMsg3 = 'You can perform Submit when there is no Reported Sales record in Rejected Status.';
					msg += '<span>' + tempMsg1 + '</span><br><br>';
					msg += '<span>' + tempMsg2 + '</span><br><br>';
					msg += '<span>' + tempMsg3 + '</span>';
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
				}
				else {
					let params = {
						"id": id
					}
					var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORTED_SALES_AVAILABLE, params));


					var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORTED_SALES_AVAILABLE, params));
					response.then((response) => {
						if (response.status == 0) {
							isExist = response.data;
							if (!isExist) {
								let msg = "";
								let tempMsg1 = 'There is no Royalty record(s) available for this Royalty Run Record.You can perform submit action only when Royalty record(s) are available';
								sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, tempMsg1, null);
							} else {
								/* Calculate */
								dispatch(setMounted(false, true));
								let params = {
									"id": id,
								}
								var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.PROCESS, params));
								response.then((response) => {
									dispatch(setMounted(true, true));
									if (response.status == 0) {
										sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_PROCESSED, null);
										dispatch(refreshDetailViewData(true));
										dispatch(refreshDetailViewListViewData(true));

										let msg = "";
										let tempMsg1 = 'No more changes are allowed after the Submitting.';
										let tempMsg2 = 'Any further change must be submitted to the <b>BrandWide</b> team for <br> processing at an additional cost.';
										let tempMsg3 = 'Would you like to Proceed?';
										msg += '<span>' + tempMsg1 + '</span><br><br>';
										msg += '<span>' + tempMsg2 + '</span><br><br>';
										msg += '<span>' + tempMsg3 + '</span>';
										sfDialogs.confirm(getLocalizedStrings().message.COMMON.WARNING_DIALOG_TITLE, msg, () => submitRoyaltyRun(detailView.data.record.id), null);

									} /* else if (response.status == -2) {
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_NOT_PROCESSED, null);
						}*/
								});
							}
						}
					});
				}
			}
		});


	}
	const submitQuickBooks =(id)=>{
		dispatch(setMounted(false,true));
		let params = {
			"id": id
		}
		var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.TO_QUICKBOOKS, params));
		response.then((response) => {
			dispatch(setMounted(true,true));
                if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.QUICKBOOKS_SUCCESS, null);
                } 
		});
	}

	const handleAction = (value) => {
		let hasSecurityRolePermission = false;
		if (value === constants.SHARE) {
			let params = {
				"security_level_name": "Admin",
				"template_id": app.me.t_id
			}
			if (app.me.t_id != 2 && !app.me.is_tenant) {
				var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.HAS_SECURITY_ROLE_PERMISSION, params));
				response.then((response) => {
					if (response.status == 0) {
						hasSecurityRolePermission = response.hasSecurityRolePermission;
					} else {
						hasSecurityRolePermission = false;
					}
					triggerAction(value, hasSecurityRolePermission);
				});
			} else {
				triggerAction(value, hasSecurityRolePermission);
			}
		} else if ('Open Audit Form' === value) {
			try {
				let dialogData = {};
				dialogData.recordId = detailView.record.id;
				dispatch(getAppDialog(true, constants.AUDIT_SELECT_CHECKLIST, getLocalizedStrings().label.AUDIT_QUESTIONS.GENERATE_AUDIT_LINK, null, dialogData));
			} catch (error) {
				console.error(
					"Error in 'detailViewHeader.js ---> processToXMS() '" + error
				);
			}
		}else if (value === JOB_ACTIONS.DONE) {
			let fieldsArr = [];
			let data = {};
			data.name = 't_status';
			data.value = 'Done';
			fieldsArr.push(data);
			let params = {
				"id": detailView.data.record.id,
				"fields": fieldsArr,
				"call_from": 'detailview'
			}
			var response = Promise.resolve(HTTPClient.post(endPoints.JOBS.SAVE, params));
			response.then((response) => {
				if (response.status == 0) {
					dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					dispatch(refreshDetailViewData(true));
				}
			});
		} else if (value === ROYALTY_RUN_ACTIONS.REPORT_SALES) {
			let params = {
				"id": detailView.data.record.id
			}
			var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORT_SALES, params));
			response.then((response) => {
				if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.REPORT_SALES_SAVED_SUCESS, null);
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
				} else if (response.status == -3) {
					let msg = "";
					let tempMsg1 = 'No Reported Sales record has been created.';
					let tempMsg2 = 'No units are enrolled in this plan!';
					let tempMsg3 = `<span>Enroll units in this plan then try again.<a href=${'#'}> Click here</a> for instructions</span>`;
					msg += '<span>' + tempMsg1 + '<br/></span>';
					msg += '<span>' + tempMsg2 + '<br/></span>';
					msg += '<span>' + tempMsg3 + '<br/></span>';
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
				}
			});
		} else if (value === ROYALTY_RUN_ACTIONS.PROCESS) {
			let params = {
				"id": detailView.data.record.id
			}
			var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.PROCESS, params));
			response.then((response) => {
				if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_PROCESSED, null);
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
				} else if (response.status == -2) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_NOT_PROCESSED, null);
				} /*else if (response.status == -3) {
					let resData = response.data;
					let totalRSCount = resData.hasOwnProperty('total_reported_sales') ? resData.total_reported_sales : 0;
					let openRSCount = resData.hasOwnProperty('open_reported_sales') ? resData.open_reported_sales : 0;
					let message = "Royalties could not be processed now as "+openRSCount+" out of "+totalRSCount+" Reported Sales records are yet to be closed. First close those Reported Sales record(s) then perform Process operation."
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
				} */
			});
		} else if (value === ROYALTY_RUN_ACTIONS.SUBMIT) {
			checkReportedSalesPresent(detailView.data.record.id);
		} else if (value === ROYALTY_RUN_ACTIONS.INVOICE_ALL) {
			dispatch(setMounted(false, true));
			let params = {
				"id": detailView.data.record.id
			}
			var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.INVOICEALL, params));
			response.then((response) => {
				dispatch(setMounted(true, true));
				if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.INVOICE_ALL_CREATED, null);
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
				} else if (response.status == -2) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.INVOICE_NOT_CREATED, null);
				}
			});
		} else if (value === ROYALTY_RUN_ACTIONS.MARKPAID_ALL) {
			let dataValue = {
				object: constants.MARK_PAID_ALL,
				sfform: sfForm,
				id: detailView.data.record.id

			};
			dispatch(getAppDialog(true, constants.MARK_PAID_ALL, " Mark Paid All", null, dataValue));

		} else if (value === ROYALTY_RUN_ACTIONS.EMAIL_ALL) {
			dispatch(setMounted(false, true));
			let params = {
				"id": detailView.data.record.id
			}
			var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.EMAIL_ALL, params));
			response.then((response) => {
				dispatch(setMounted(true, true));
				if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.EMAIL_ALL_SUCCESS, null);
                } 
            });
        } else if (value === ROYALTY_RUN_ACTIONS.TO_UNITS || value === ROYALTY_RUN_ACTIONS.POST_ALL ) {
			dispatch(setMounted(false,true));
            let params = {
                "id": detailView.data.record.id
            }
            var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.POST_ALL, params));
            response.then((response) => {
				dispatch(setMounted(true,true));
                if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.POST_ALL_SUCCESS, null);
                } 
            });
        } else if (value === ROYALTY_RUN_ACTIONS.TO_QUICKBOOKS) {			
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.TO_QUICKBOOKS_MSG, () => submitQuickBooks(detailView.data.record.id), null);
        } else if (value === REPORTED_SALES_ACTIONS.UPDATE) {
            let params = {
                "id": detailView.data.record.id
            }
            var response = Promise.resolve(HTTPClient.post(endPoints.REPORTED_SALES.UPDATE, params));
            response.then((response) => {
                if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_UPDATED, null);
					dispatch(refreshDetailViewData(true));
				} else if (response.status == -3) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_SOURCE_NOT_ADDED, null);
				}
			});
		} /*else if (value === REPORTED_SALES_ACTIONS.CLOSE) {
            let params = {
                "id": detailView.data.record.id
            }
            var response = Promise.resolve(HTTPClient.post(endPoints.REPORTED_SALES.CLOSE, params));
            response.then((response) => {
                if (response.status == 0) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_CLOSED, null);
                    dispatch(refreshDetailViewData(true));
                } else if(response.status == -3){
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_SOURCE_NOT_ADDED, null);
				}
            });
        } */ else if (value === constants.LOGIN) {
			if (object == constants.ACCOUNTS_OBJECT) {
				let selectedRow = detailViewRecord;
				let selectedRowId = detailView.data.record.id;
				let params = {};
				if (selectedRow) {
					params = {
						object: constants.ACCOUNTS_OBJECT,
						id: selectedRowId,
						callFrom: constants.UNITS_OBJECT,
					}
					let _myClientId = '';
					var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, params));
					response.then((response) => {
						if (response.status == 0) {
							hasSecurityRolePermission = true;
							_myClientId = response.myClientId;
							setMyClientId(_myClientId);
						} else {
							hasSecurityRolePermission = false;
						}
						triggerAction(value, hasSecurityRolePermission, _myClientId);
					});

				} else {
					setMyClientId(selectedRow.myclient_id);
					triggerAction(value, true);
				}
			}
		}
		else if (object == constants.CONTACTS_OBJECT && value == constants.SHARE_WITH_UNITS) {
			setOpenActionList(false);
			let selectedIds = detailView.data.record.id;
			let msg = `1 record(s) will be shared with units. Are you sure ?`
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => shareWithUnitsAction(selectedIds), null);

		}

		else {
			triggerAction(value, hasSecurityRolePermission);
		}
	}
	const shareWithUnitsAction =(id)=>{
        let dataParam = {
            id:[id]
        };
        let promise = Promise.resolve(HTTPClient.post(endPoints.CONTACTS.SHARE_WITH_UNITS, dataParam));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === 0) {
                    let msg = `1 record(s) shared successfully with the units.`
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } 
            }})
    }

	const submitRoyaltyRun = (id) => {
		dispatch(setMounted(false, false));
		let params = {
			"id": id
		}
		var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.SUBMIT, params));
		response.then((response) => {
			dispatch(setMounted(false, true));
			if (response.status == 0) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.SUCCESS_ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_SUBMITTED, null);
				dispatch(refreshDetailViewGroup(true));
				dispatch(detailViewIsMounted(false));
				dispatch(refreshDetailViewData(true));
				dispatch(refreshDetailViewListViewData(true));
			} else if (response.status == -2) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_NOT_SUBMITTED, null);
			}
		});
	}

	const handleReschedule = (id, duration) => {
		let recordIdArr = [];
		recordIdArr.push(id);
		let msg =
			object === constants.TASKS
				? getLocalizedStrings().message.APPOINTMENTS_TASKS
					.RESCHEDULE_TASK_SUCCESS
				: getLocalizedStrings().message.APPOINTMENTS_TASKS
					.RESCHEDULE_APPOINTMENT_SUCCESS;
		let params = {
			id: recordIdArr,
			type: duration,
			no_of_days: 0,
			date: "",
		};
		let hasPermission = false;
		hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		if (object === constants.TASKS && (duration === "nextday" || duration === "nextweek") || duration === "nextmonth") {
			if (hasPermission) {
				var response = reschedule(object, params);
				// setState({ mounted: false });
				response.then((response) => {
					if (response.status === 0) {
						dispatch(
							showCustomSnackBar(
								msg,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
						dispatch(refreshDetailViewGroup(true));
						dispatch(detailViewIsMounted(false));
						dispatch(refreshDetailViewData(true));
						if (duration === "nextday") {
							setIsDayDisabled(false);
						} else if (duration === "nextmonth") {
							setIsMonthDisabled(false);
						} else {
							setIsWeekDisabled(false);
						}
					} else {
					}
				});
			}
			else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
			}
		}
		else {
			var response = reschedule(object, params);
			// setState({ mounted: false });
			response.then((response) => {
				if (response.status === 0) {
					dispatch(
						showCustomSnackBar(
							msg,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
					dispatch(refreshDetailViewData(true));
					if (duration === "nextday") {
						setIsDayDisabled(false);
					} else if (duration === "nextmonth") {
						setIsMonthDisabled(false);
					} else {
						setIsWeekDisabled(false);
					}
				} else {
				}
			});
		}

	};


	const handleTasksAction = (value) => {
		let isUnitsAccount = false;
		let tabInfo = null;
		let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
		let id = detailViewRecord.id;
		tabInfo = getActiveTabInfo();
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		if (isUnitsAccount && app.me.is_franchisor) {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
		} else {
			if (value === constants.COMPLETE && !isUnitsAccount || (isUnitsAccount && tabInfo.callFrom === "mini_edit" && app.me.is_franchisee)) {
				let idarr = [];
				idarr.push(id);
				let hasPermission = false;
				hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
				if (hasPermission) {
					let promise = getTasksCompletedPromise(idarr);
					if (isValidParam(promise)) {
						promise.then((response) => {
							if (isValidParam(response)) {
								if ("COMPLETED" === response) {
									let alertDialogMessage = getLocalizedStrings().label.APPOINTMENTS_TASKS.ALL_TASK_COMPLETED;
									sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
								}
								else if ("NOT-COMPLETED" === response) {
									let labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE + " " + getLocalizedStrings().label.COMMON.THIS + " " + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
									let data = {
										id: id,
										object: object,
										taskType: detailViewRecord.task_type != null && detailViewRecord.task_type != undefined && detailViewRecord.task_type != "" ? detailViewRecord.task_type : "Task",
									}
									dispatch(getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW));

								}
							}
						});
					}
					else {
						let labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE + " this " + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
						dispatch(getAppDrawer(true, labelName, value, constants.LIST_VIEW));
					}
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
				}
			}
			if (value === constants.DAY && !isUnitsAccount) {
				setIsDayDisabled(true);
				handleReschedule(id, "nextday")
			}

		}
		if (value === constants.WEEK && !isUnitsAccount) {
			if (!isWeekDisabled) {
				setIsWeekDisabled(true);
				handleReschedule(id, "nextweek")
			}
		}
		if (value === constants.MONTH && !isUnitsAccount) {
			setIsMonthDisabled(true)
			handleReschedule(id, "nextmonth")
		}
		if (value === constants.DELETE && !isUnitsAccount) {
			// this.handleAction(constants.DELETE);
			let hasPermission = null;
			let parentId = detailView.record.parent_recordid;
			hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
			if (hasPermission) {
				if (parentId != null && parentId != '' && parentId > 0) {
					let dataValue = {
						object: constants.TASKS,
					};
					dispatch(getAppDialog(true, constants.RECURRING_DELETE_DIALOG, getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE, (val) => getRecurringDeleteChoice(val), dataValue));
				}
				else {
					sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.DELETE, () => deleteRecord(detailView.record.id, null), null);
				}

			}
			else {
				handleAction(constants.DELETE);

			}
		}


		if (value === constants.COMPLETE_NEW && !isUnitsAccount) {

			if (!isNewDisabled) {
				setIsNewDisabled(true);
				completeTaskApp(id, "completeandnew")
			}
			setIsNewDisabled(false);
		}
	}


	const getRecurringDeleteChoice = (val) => {
		let id = detailView.record.id;
		dispatch(
			getAppDialog(
				false,
				constants.RECURRING_DELETE_DIALOG,
				null,
				null,
				null,
				null
			)
		);
		deleteRecord(id, val);
	};

	const deleteRecord = (id, delete_action_type) => {
		let params = {};
		params.id = [id];
		if (delete_action_type !== null) {
			params.delete_action_type = delete_action_type;
		}
		if (
			id > 0 &&
			(object === constants.APPOINTMENTS ||
				object === constants.NOTE_OBJECT ||
				object === constants.TASKS)
		) {
			let linkObject = getEndPointObject(object);
			let url = linkObject.DELETE;
			var promise = Promise.resolve(HTTPClient.post(url, params));
			promise.then((response) => {
				if (response.status === 0) {
					dispatch(
						showCustomSnackBar(
							getLocalizedStrings().message.COMMON.DELETE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
					let tabInfo = getActiveTabInfo();
					if (
						tabInfo.hasOwnProperty("is_callFrom_queue") &&
						getBooleanParam(tabInfo.is_callFrom_queue)
					) {
						let queueObjInner =
							queueList !== null &&
								queueList !== undefined &&
								queueList.hasOwnProperty("data") &&
								queueList.data !== null
								? queueList.data.object
								: "";
						if (queueObjInner === constants.TASKS) {
							removeFromQueue([id], constants.DELETE);
							// let activeTab = tabManagerActiveTab();
							// closeTab(activeTab.item, activeTab.index);
						}
					} else {
						let activeTab = tabManagerActiveTab();
						if (isValidParam(activeTab)) {
							closeTab(activeTab.item, activeTab.index);
							let queueObjInner =
								queueList !== null &&
									queueList !== undefined &&
									queueList.hasOwnProperty("data") &&
									queueList.data !== null
									? queueList.data.object
									: "";
							if (queueObjInner === constants.TASKS) {
								removeFromQueue([id], constants.DELETE);
							}
							refreshListView(object);
						}
					}
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(
						showCustomSnackBar(
							response.error.message,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						)
					);
				}
			});
		}
	};

	const completeTaskApp = (id, name) => {
		try {
			var recordIds = [];
			recordIds[0] = id;
			let params = {
				id: recordIds,
				audit_trail_tableIds: getStringParam(app.me.audit_trail_table_ids),
			};

			let msg = "";

			let hasPermission = false;
			hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (object === constants.TASKS) {
				if (hasPermission) {
					var response = completeAction(object, params);
					response.then((response) => {
						if (response.status === 0) {
							dispatch(refreshDetailViewData(true));
							refreshListView(object);

							msg =
								object.charAt(0).toUpperCase() +
								object.slice(1, object.length - 1) +
								" " +
								getLocalizedStrings().message.BATCH_ACTION.COMPLETE_SUCCESSFULL;
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							if (name === "completeandnew") {
								confirmAndNewTaskApp(object);
								if (object == 'tasks') {
									dispatch(refreshDetailViewGroup(true));
									dispatch(detailViewIsMounted(false));
									dispatch(refreshDetailViewData(true));
									dispatch(refreshDetailViewListViewData(true));
								}
							}
						} else if (response.status !== 0 && response.error.message !== "") {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								response.error.message,
								null
							);
							setstateMounted(true);
							return false;
						} else {
							msg =
								object.charAt(0).toUpperCase() +
								object.slice(1, object.length - 1) +
								" " +
								getLocalizedStrings().message.BATCH_ACTION.COMPLETE_UNSUCCESSFULL;
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleError,
									styles.snackBarStyleTop
								)
							);
							return false;
						}
					});
				}
				else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)

				}
			}
			else {
				response.then((response) => {
					if (response.status === 0) {
						dispatch(refreshDetailViewData(true));
						refreshListView(object);

						msg =
							object.charAt(0).toUpperCase() +
							object.slice(1, object.length - 1) +
							" " +
							getLocalizedStrings().message.BATCH_ACTION.COMPLETE_SUCCESSFULL;
						dispatch(
							showCustomSnackBar(
								msg,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
						if (name === "completeandnew") {
							confirmAndNewTaskApp(object);
						}
					} else if (response.status !== 0 && response.error.message !== "") {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							response.error.message,
							null
						);
						setstateMounted(true);
						return false;
					} else {
						msg =
							object.charAt(0).toUpperCase() +
							object.slice(1, object.length - 1) +
							" " +
							getLocalizedStrings().message.BATCH_ACTION.COMPLETE_UNSUCCESSFULL;
						dispatch(
							showCustomSnackBar(
								msg,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							)
						);
						return false;
					}
				});
			}

		} catch (error) {
			console.error("Error in 'sfForm.js -> completeTaskApp()':" + error);
		}
	};

	const confirmAndNewTaskApp = (objectName) => {
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			objectName,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_CREATE
		);
		if (hasPermission) {
			let data = {
				id: 0,
				object: objectName,
			};

			data["parent_object"] = constants.TASKS;
			data["parent_record_id"] = detailView.data.record.id;
			data["isDetailView"] = true;

			if (isValidParam(detailView.data.record.link_info)) {
				data.parent_link_info = detailView.data.record.link_info;
				data.isCompleAndNew = true;
				let recordDetails = {};
				recordDetails.company = detailView.data.record.company;
				recordDetails.fullname = detailView.data.record.fullname;
				recordDetails.opportunity_name =
					detailView.data.record.opportunity_name;
				data.recordDetails = recordDetails;
			}
			let objName = "";
			let labelName = "";
			if (objectName === constants.APPOINTMENTS) {
				objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
			} else if (objectName === constants.TASKS) {
				objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
			} else {
				objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
			}
			if (
				objectName === constants.APPOINTMENTS ||
				objectName === constants.TASKS
			) {
				labelName =
					getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
					" " +
					objName;
			} else {
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + objName;
			}
			dispatch(
				getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER)
			);
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED
			);
		}
	};

	const onConfirmGetUrl = (connectGetUrl, msg2) => {
		var response = Promise.resolve(HTTPClient.get(connectGetUrl, null));
		response.then((response) => {
			if (response.status === 0) {
				dispatch(
					showCustomSnackBar(
						msg2,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);
				dispatch(refreshDetailViewData(true));
			}
		});
	};

	const onConfirmDeleteAction = (event, objectName, recordIds) => {
		var recordIdList = [];
		for (var i = 0; i < recordIds.length; i++) {
			recordIdList.push(recordIds[i]);
		}
		let param = {
			id: recordIdList,
		};
		var response = deleteAction(objectName, param);
		response.then((response) => {
			if (response.status === 0) {
				getRecentRecord(objectName);
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.DELETE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);

				let tabInfo = getActiveTabInfo();
				if (
					tabInfo.hasOwnProperty("is_callFrom_queue") &&
					getBooleanParam(tabInfo.is_callFrom_queue)
				) {
					removeFromQueue(recordIdList, constants.DELETE);
				} else {
					let activeTab = tabManagerActiveTab();
					if (isValidParam(activeTab)) {
						closeTab(activeTab.item, activeTab.index);
						let tabs = getArrayParam(tab.tabs);
						let tempTab = tabs.find((tab) => {
							return (
								tab.type === TYPE_LIST_VIEW && tab.object === activeTab.object
							);
						});
						if (tempTab) {
							let queueObjInner =
								queueList !== null &&
									queueList !== undefined &&
									queueList.hasOwnProperty("data") &&
									queueList.data !== null
									? queueList.data.object
									: "";
							if (queueObjInner === constants.TASKS) {
								removeFromQueue(recordIds, constants.DELETE);
							}

							if (objectName === 'accounts' && customDrawer.actionType === "Convert" && customDrawer.isMinimize === true && customDrawer.isOpen === true) {
								console.log("customDrawer", customDrawer)
								customDrawer.actionType = "";
								customDrawer.data = {};
								customDrawer.minimizeList = { Convert: false };
								customDrawer.callFrom = null;
								customDrawer.isMinimize = false;
								customDrawer.isOpen = false;
								customDrawer.style = {};
								customDrawer.dialogName = '';
								customDrawer.minimizable = false;
								console.log("customDrawer", customDrawer)
							}
							if (objectName === 'accounts' && customDrawer.isOpen === false && customDrawer.minimizeList.Convert === true) {

								customDrawer.minimizeList = { Convert: false };

								console.log("customDrawer", customDrawer)
							}
							refreshListView(tempTab.object);
						}
					}
				}
				if (objectName == "groups") {
					getRecentFilters(constants.ACCOUNTS_OBJECT);
					getRecentFilters(constants.CONTACTS_OBJECT);
					getGroups(constants.ACCOUNTS_OBJECT);
				}

			}
		});
	};

	const onConfirmDeleteCoupons = (recordId, objectName) => {
		var promise = Promise.resolve(deleteCoupons(recordId));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (isValidParam(response)) {
					if (response.status === 0) {
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.COMMON.DELETE,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
						let activeTab = tabManagerActiveTab();
						if (isValidParam(activeTab)) {
							closeTab(activeTab.item, activeTab.index);
							refreshListView(objectName);
							setListViewMounted(false, objectName);
						}
					}
				}
			});
		}
	};

	const onConfirmIsLinkedRecord = (link_details) => {
		let linkDetails = link_details;
		let linkToUrl = "/accounts/detailview/" + linkDetails.id;
		let labelName = linkDetails.title;
		let objectName = "accounts";
		let linkTo = "";
		addTab(labelName, objectName, linkTo, null, linkToUrl);
		setRedirect(true);
		setRedirectUrl(linkToUrl);
	};

	const triggerAction = (value, hasSecurityRolePermission, _myClientId) => {
		let labelName = null;
		let objectName = null;
		let linkTo = null;
		let linkToUrl = null;
		let isOpenDrawer = false;
		let isRedirect = false;
		let isOpenTab = true;
		let data = null;
		let permissionMessage = null;
		let tabInfo = null;
		let isInXpress = false;
		let isUnitsAccount = false;
		try {
			permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			tabInfo = getActiveTabInfo();
			
			isInXpress = getBooleanParam(tabInfo.isInXpress);
			if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
				isUnitsAccount = tabInfo.isUnitsAccount;
			}

			if ((!isInXpress && !isUnitsAccount) ||(isUnitsAccount && tabInfo.callFrom === "mini_edit" && app.me.is_franchisee) || value === constants.PRINT ) {
				objectName = object;
				var recordIds = [];
				recordIds[0] = detailViewRecord.id;
				if (
					ParameterVerifier.isValidParam(objectName) &&
					ParameterVerifier.isValidParam(value)
				) {
					// value = value === constants.CONVERT && objectName === constants.CONTACTS_OBJECT ? constants.CONVERT_TO_ACCOUNTS : value;
					if (value === constants.EDIT || value === constants.COPY) {
						let hasPermission = false;
						isOpenDrawer = false;
						hasPermission = hasAccessPermission(
							objectName,
							constants.SECURITY_LEVEL_TYPE_ACCESS,
							constants.ACCESS_TYPE_EDIT
						);
						if (hasPermission) {
							data = {
								object: objectName,
								id: detailViewRecord.id,
								mode: objectName === constants.REPORTED_SALES_OBJECT ? constants.SF_FORM_OPEN_MODE_QUICK : constants.SF_FORM_OPEN_MODE_EXPANDED,
								isDetailView: true,
							};
							let tempObj = app?.objectList?.filter((f) => {
								return f.name === objectName;
							});
							isOpenDrawer = true;
							let tempname = getObjectLabelByObject(objectName);
							tempname =
								tempname === "" || tempname === "Calendar/Tasks"
									? tempObj.length > 0
										? tempObj[0].label
										: tempname
									: tempname;
							linkTo = value + "/" + detailViewRecord.id;
							tempname = isValidParam(
								getLocalizedStrings().label.DETAIL_VIEW[tempname.toUpperCase()]
							)
								? getLocalizedStrings().label.DETAIL_VIEW[
								tempname.toUpperCase()
								]
								: tempname;
							//labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()] + ' ' + tempname;
							if (isValidParam(getLocalizedStrings().label.HEADER[tempname])) {
								labelName =
									getLocalizedStrings().label.COMMON[value.toUpperCase()] +
									" " +
									getLocalizedStrings().label.HEADER[tempname];
							} else {
								labelName =
									getLocalizedStrings().label.COMMON[value.toUpperCase()] +
									" " +
									tempname;
							}
						} else {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								getLocalizedStrings().message.COMMON.ACCESS_DENIED,
								null
							);
						}
					} else if (
						value === constants.CONVERT ||
						value === constants.CONVERT_TO_ACCOUNTS ||
						value === constants.ADD_TO_GROUP ||
						value === constants.ASSIGN ||
						value === constants.SHARE ||
						value === constants.PUSH ||
						value === constants.MAP ||
						value === constants.NOTE ||
						value === constants.TO_EXCEL ||
						value === constants.PRINT ||
						value === constants.COMPLETE
					) {
						if (value === constants.SHARE) {
							labelName =
								getLocalizedStrings().label.COMMON[value.toUpperCase()] +
								" " +
								getLocalizedStrings().label.COMMON.WITH;
						} else {
							if (value === "Add to Group") {
								labelName = getLocalizedStrings().label.COMMON.ADD_TO_GROUP;
							} else if (value === "To Excel") {
								labelName = getLocalizedStrings().label.COMMON.TO_EXCEL;
							} else if (value === "Convert to Accounts") {
								labelName =
									getLocalizedStrings().label.COMMON.CONVERT_TO_ACCOUNTS;
							} else {
								labelName =
									getLocalizedStrings().label.COMMON[value.toUpperCase()];
							}
						}

						let hasPermission = false;
						//let hasSecurityRolePermission = false;
						let checkValue = null;
						let securityLevelType = null;
						if (value === constants.ASSIGN || value === constants.SHARE) {
							if (value === constants.SHARE) {
								if (
									app.me.is_tenant ||
									hasSecurityRolePermission ||
									app.me.t_id === 2
								) {
									hasPermission = true;
								}
								permissionMessage =
									getLocalizedStrings().message.LIST_VIEW.SHARE_PERMISSION;
							} else {
								checkValue = constants.ACCESS_TYPE_EDIT;
								securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
								hasPermission = hasAccessPermission(
									objectName,
									securityLevelType,
									checkValue
								);
							}
						} else {
							hasPermission = true;
						}
						if (!hasPermission) {
							isOpenDrawer = false;
							isOpenTab = false;
						}
						if (hasPermission) {
							if (value === constants.MAP) {
								data = {
									object: object,
									id: recordIds,
								};
							}
							if (value === constants.CONVERT) {
								data = {
									object: object,
									isDetailviewChild: false,
								};
							}
							if (value === constants.PRINT) {
								isRedirect = true;
								isOpenDrawer = false;
							} else {
								isOpenDrawer = true;
							}
						} else {
							setOpenActionList(false);
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								permissionMessage,
								null
							);
						}
					} else if (value === constants.AUDIT) {
						labelName = getLocalizedStrings().label.COMMON.AUDIT;
						isOpenDrawer = true;
						data = {
							parent_object: object,
							parent_record_id: recordIds,
						};
						setOpenActionList(false);
					} else if (value === constants.CONNECT) {
						labelName = getLocalizedStrings().label.COMMON.CONFIRM_REQUEST;
						isOpenDrawer = true;
						data = {
							parent_object: object,
							parent_record_id: recordIds,
						};
						setOpenActionList(false);
					} else if (value === constants.DISCONNECT) {
						var connectGetUrl =
							endPoints.COMMON.CLIENT_DISCONNECT_GET + detailViewRecord.id;
						let msg = getLocalizedStrings().message.COMMON.DISCONNECT_ALERT_MSG;
						let msg2 = getLocalizedStrings().message.COMMON.DISCONNECT_SUCCESS;
						setOpenActionList(false);

						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							msg,
							() => onConfirmGetUrl(connectGetUrl, msg2),
							null
						);
					} else if (value === constants.LOGIN) {
						let hasPermission = hasSecurityRolePermission;
						if (hasPermission === true) {
							login(_myClientId);
						}
					} else if (
						value === constants.DELETE &&
						objectName !== constants.COUPONS_OBJECT
					) {
						if (detailViewRecord?.myclient_Id > 0) {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								"Sorry, you do not have the permission to perform delete operation on a Unit Account record. Please contact Brandwide Support to delete the Unit Account record.",
								null
							);
						} else {

							let hasPermission = false;
							isOpenTab = false;
							let checkValue = null;
							let securityLevelType = null;
							let msg = "";
							let errorMessage = "";
							hasPermission = hasAccessPermission(
								objectName,
								constants.SECURITY_LEVEL_TYPE_ACCESS,
								constants.ACCESS_TYPE_DELETE
							);
							if (hasPermission) {
								if (objectName === constants.GROUP_OBJECT) {
									//process group delete
									setOpenActionList(false);
									let objParam = {};
									objParam.recordIds = recordIds;
									var promise = groupUsedInCampaigns(objParam);
									var groupAlert = "";
									if (promise !== null) {
										promise.then((response) => {
											if (response != null && response !== "") {
												groupAlert = processGroupData(response);
												let dialogData = {};
												dialogData.message = groupAlert;
												dispatch(
													getAppDialog(
														true,
														constants.ALERT_DIALOG,
														getLocalizedStrings().message.COMMON
															.ALERT_DIALOG_TITLE,
														null,
														dialogData,
														constants.DETAIL_VIEW
													)
												);
											} else {
												if (detailViewRecord.id > 0) {
													msg =
														getLocalizedStrings().message.COMMON
															.CONFIRM_DELETE_RECORD;
												}

												sfDialogs.confirm(
													getLocalizedStrings().message.COMMON
														.CONFIRM_DIALOG_TITLE,
													msg,
													() =>
														onConfirmDeleteAction(this, objectName, recordIds),
													null
												);
											}
										});
									}
								} else {
									if (detailViewRecord.id > 0) {
										msg =
											getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD;
									}
									sfDialogs.confirm(
										getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
										msg,
										() => onConfirmDeleteAction(this, objectName, recordIds),
										null
									);
									setOpenActionList(false);
								}
							} else {
								setOpenActionList(false);
								sfDialogs.alert(
									getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
									getLocalizedStrings().message.COMMON.ACCESS_DENIED,
									null
								);
							}
						}

					} else if (value === constants.REFRESH) {
						refreshDeatilsView();
						return;
					} else if (value === constants.DOWNLOAD) {
						let filename = "";
						var locationArray = [
							"Local",
							"Northbound-Fairbanks",
							"Northbound-Anchorage",
							"Southbound-Fairbanks",
							"Southbound-Anchorage",
						];
						let location = "";
						let endPoint = endPoints.ACCOUNTS.MAP_DATA;
						let params = { id: 0, fields: ["custom_field11"] };
						params.id = detailViewRecord.id;
						var addressResponse = Promise.resolve(
							HTTPClient.get(endPoint, params)
						);
						addressResponse.then((response) => {
							location = response.records["custom_field11"];

							if (location !== null && location !== "") {
								var isExist = locationArray.indexOf(location);
								if (isExist === -1) {
									setOpenActionList(false);
									sfDialogs.alert(
										getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
										getLocalizedStrings().message.CUSTOMIZATION
											.LOCATION_VAL_NOT_MATCH,
										null
									);
								} else {
									filename = SOURDOUGH_LOCAL_FILENAME_MAP[location];
									let endPointsDocDnlode =
										endPoints.COMMON.SOURDOUGH_DOC_DOWNLODE +
										detailViewRecord.id;
									setOpenActionList(false);
									var formData = new FormData();
									HTTPClient.postFormDataDownloadFile(
										endPointsDocDnlode,
										formData,
										filename
									);
									// ));
								}
							} else {
								setOpenActionList(false);

								sfDialogs.alert(
									getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
									getLocalizedStrings().message.CUSTOMIZATION.LOCATION_VAL_BLANK
								);
							}
						});
					} else if (
						objectName === constants.CASES_OBJECT &&
						value === constants.SEARCH_KB
					) {
						isRedirect = true;
						isOpenDrawer = false;
					} else if (
						objectName === constants.CONTACTS_OBJECT &&
						value === constants.UNSUBSCRIBE
					) {
						setOpenActionList(false);
						if (detailViewRecord.id > 0) {
							let selectedIds = [];
							selectedIds.push(detailViewRecord.id);
							data = {
								recordIds: selectedIds,
								isDetailView: true,
							};
						}
						dispatch(
							getAppDrawer(
								true,
								getLocalizedStrings().label.COMMON.UNSUBSCRIBE,
								value,
								data,
								constants.LIST_VIEW
							)
						);
					} else if (
						value === constants.DOCUSIGN &&
						(objectName === constants.ACCOUNTS_OBJECT ||
							objectName === constants.OPPORTUNITIES_OBJECT)
					) {
						isOpenDrawer = true;
					}
					//Rabinarayan Asha
					else if (
						value === constants.ENABLE_MAILING ||
						value === constants.DISABLE_MAILING
					) {
						let url = endPoints.COMMON.SAVE_SENDMAIL_FLAG;
						let params = {};
						params.id = recordIds[0];
						if (value === constants.ENABLE_MAILING) {
							params.flagId = 1;
						} else if (value === constants.DISABLE_MAILING) {
							params.flagId = 0;
						}
						var promise = Promise.resolve(HTTPClient.post(url, params));
						if (promise !== null && promise !== undefined) {
							promise.then((response) => {
								setOpenActionList(false);
								if (response.data === true) {
									if (value === constants.ENABLE_MAILING) {
										sfDialogs.alert(
											getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
											getLocalizedStrings().message.COMMON
												.ENABLE_MAILING_SUCCESS,
											null
										);
										dispatch(refreshDetailViewData(true));
									} else if (value === constants.DISABLE_MAILING) {
										sfDialogs.alert(
											getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
											getLocalizedStrings().message.COMMON
												.DISABLE_MAILING_SUCCESS,
											null
										);
										dispatch(refreshDetailViewData(true));
									}
								} else {
									sfDialogs.alert(
										getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
										getLocalizedStrings().message.COMMON.MAILING_FAILURE,
										null
									);
								}
							});
						}
					} else if (objectName === constants.COUPONS_OBJECT) {
						let hasPermission =
							app.me.t_name === "Admin" || app.me.t_name === "Marketing"
								? true
								: false;
						if (hasPermission) {
							let params = {};
							let msg = "";
							let recordId = recordIds[0];
							if (value === constants.INACTIVE || value === constants.ACTIVE) {
								params.status = value;
								msg =
									value === constants.INACTIVE
										? getLocalizedStrings().message.COUPONS.INACTIVE_SUCCESSFULL
										: getLocalizedStrings().message.COUPONS.ACTIVE_SUCCESSFULL;
								processUpdateCoupons(params, msg, recordId);
							} else if (value === constants.DELETE) {

								let msg =
									getLocalizedStrings().message.COUPONS.DELETE_CONFIRMATION;
								sfDialogs.confirm(
									getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
									msg,
									onConfirmDeleteCoupons.bind(recordId, objectName),
									null
								);
							}
						} else {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								getLocalizedStrings().message.COMMON.ACCESS_DENIED,
								null
							);
						}
					} else if (value === constants.HUB_CASE_CLOSE) {
						isOpenDrawer = true;
						labelName = getLocalizedStrings().label.COMMON.CLOSE_CASE;
						data = {};
						data.isDetailView = true;
						data.record_id = recordIds[0];
					} else if (value === constants.ADD_QUICKBOOKS) {
						processAddQB(recordIds);
					} else if (
						(value === constants.PREVIOUS ||
							value === constants.NEXT ||
							value === constants.REMOVE ||
							value === constants.CLOSE) &&
						objectName !== constants.JOBS_OBJECT
					) {
						console.log("recordIds",recordIds)
						queueActions(recordIds, value);
					} else if (
						(value === constants.CLOSE || value === constants.CANCEL) &&
						objectName === constants.JOBS_OBJECT
					) {
						let status = value === constants.CLOSE ? "Done" : "Cancelled";
						let params = {
							ids: recordIds,
							fields: [{ name: "t_status", value: status }],
						};
						let promise = updateFields(objectName, params);
						if (isValidParam(promise)) {
							promise.then((response) => {
								if (response.status === 0) {
									let message =
										getLocalizedStrings().message.WORKFLOWS.STATUS_CHANGED +
										status;
									dispatch(
										showCustomSnackBar(
											message,
											styles.snackbarBodyStyleSuccess,
											styles.snackBarStyleTop
										)
									);
									refreshDeatilsView();
									dispatch(refreshDetailViewListViewData(true));
									dispatch(getTasksRefresh(true));
									closeDetailviewActionList();
								}
							});
						}
					} else if (value === constants.ADD_PAYMENT) {
						let data = {
							object: constants.PAYMENTS_OBJECT,
							recordId: 0,
							isDetailView: true,
							parent_record_id: recordIds[0],
							parent_object: objectName,
						};
						dispatch(
							getAppDrawer(true, "Add New Payment", constants.NEW, data, null)
						);
						closeDetailviewActionList();
					} else if (value === constants.APPOINTMENTS) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
							" " +
							getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
						let data = {
							isDetailView: true,
							object: constants.APPOINTMENTS,
							parent_object: object,
							parent_record_id: getIntParam(detailView.record.id),
						};
						dispatch(
							getAppDrawer(true, labelName, "New", data, constants.DETAIL_VIEW)
						);
					} else if (
						(value === constants.JOB_SCHEDULER ||
							value === constants.JOB_RESCHEDULER) &&
						objectName === constants.JOBS_OBJECT
					) {
						isOpenDrawer = false;
						labelName = "Schedule for " + detailViewRecord.job_id;
						let selectedDate = new Date();
						let viewMode = "Week";
						if (value === constants.JOB_RESCHEDULER) {
							if (
								taskAppointment?.futureTask &&
								taskAppointment.futureTask?.recordList &&
								taskAppointment.futureTask.recordList?.length > 0
							) {
								selectedDate =
									taskAppointment.futureTask.recordList[0].future[0].dateField;
							} else if (
								taskAppointment?.overdueTask &&
								taskAppointment.overdueTask?.recordList &&
								taskAppointment.overdueTask.recordList?.length > 0
							) {
								selectedDate =
									taskAppointment.overdueTask.recordList[0].overdue[0]
										.dateField;
							}
							viewMode = "Day";
							value = constants.JOB_SCHEDULER;
							//Get Update date in day
							var dateRange = {};
							let startDate = formatDate(selectedDate, "start");
							let endDate = formatDate(selectedDate, "end");
							dateRange.startDate = startDate;
							dateRange.endDate = endDate;
							dispatch(selectedDateRangeUpdate(dateRange));
						}
						calendar.calendarInfo.schedulerView = viewMode;
						dispatch(setSelectedDate(selectedDate));
						let info = {
							title: labelName,
							type: value,
							object: objectName,
							detailView_recordid: detailView.record.id,
							jobName: detailViewRecord.job_name,
						};
						addNewFormTabs(info);
					} else if (value === constants.SEND_TEXT) {
						getRecordDetailsForSMS();
					} else if (value === constants.TO_XMS) {
						processToXMS(recordIds[0]);
					}

					if (
						(value === constants.EDIT ||
							value === constants.COPY ||
							value === constants.GENERATE_INVOICE) &&
						objectName === constants.JOBS_OBJECT
					) {
						isOpenDrawer = false;
						labelName =
							value === constants.GENERATE_INVOICE
								? getLocalizedStrings().label.COMMON["Generate Invoice"]
								: labelName;
						let info = {
							id: recordIds[0],
							title: labelName,
							type: value,
							object: objectName,
						};
						addNewFormTabs(info);
					}

					if (value !== constants.PRINT && value !== constants.JOB_SCHEDULER) {
						linkToUrl = "/" + objectName + "/" + linkTo;
						linkToUrl = ParameterVerifier.makeValidAppUrl(linkToUrl);
					}
					if (isOpenDrawer) {
						if (value === constants.CONVERT_TO_ACCOUNTS) {
							let hasPermission = false;
							hasPermission = hasAccessPermission(
								constants.ACCOUNTS_OBJECT,
								constants.SECURITY_LEVEL_TYPE_ACCESS,
								constants.ACCESS_TYPE_CREATE
							);
							if (hasPermission) {
								let params = {};
								params.is_linked_contact = true;
								params.parent_object = "accounts";
								params.object = "contacts";
								params.record_id = recordIds[0];
								let promise = Promise.resolve(isLinkedRecord(params));
								if (isValidParam(promise)) {
									promise.then((response) => {
										if (isValidParam(response) && response.is_linked) {
											sfDialogs.confirm(
												getLocalizedStrings().message.COMMON
													.CONFIRM_DIALOG_TITLE,
												getLocalizedStrings().message.LIST_VIEW
													.CONVERT_CONTACT_TO_ACCOUNT_CONFIRM,
												onConfirmIsLinkedRecord.bind(response.link_details),
												null
											);
										} else {
											data = {
												object: constants.ACCOUNTS_OBJECT,
												parent_object: objectName,
												parent_record_id: recordIds[0],
												is_convert: true,
												mode: constants.SF_FORM_OPEN_MODE_QUICK,
											};
											dispatch(
												getAppDrawer(
													true,
													labelName,
													value,
													data,
													constants.LIST_VIEW
												)
											);
										}
									});
								}
							} else {
								setOpenActionList(false);
								sfDialogs.alert(
									getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
									getLocalizedStrings().message.COMMON.ACCESS_DENIED,
									null
								);
							}
						} else if (value === constants.DOCUSIGN) {
							// let status = fnGetDocusignEnvelopeList();
							// if (app.me.is_free_crm === false) {
							labelName =
								getLocalizedStrings().label.COMMON.DOCUSIGN_INTEGRATION;
							data = {
								parent_object: object,
								parent_record_id: recordIds,
							}
							dispatch(getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW));
							setOpenActionList(false);
							// } else {
							// 	sfDialogs.alert(
							// 		getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							// 		getLocalizedStrings().message.DOCUSIGN
							// 			.DOCUSIGN_CONNECTED_MESSAGE_1,
							// 		null
							// 	);
							// }
						} else if (value === constants.PUSH) {
							let pushIds = {};
							let pushArr = [];
							for (var i = 0; i < recordIds.length; i++) {
								pushIds = {
									id: recordIds[i],
								};
								pushArr.push(pushIds);
							}
							data = {
								selectedRecords: pushArr,
							};
							dispatch(
								getAppDrawer(
									true,
									labelName,
									value,
									data,
									constants.DETAIL_VIEW
								)
							);
							setOpenActionList(false);
						} else if (
							objectName === constants.CONTACTS_OBJECT &&
							value === constants.EDIT
						) {
							let params = {};
							params.is_linked_contact = true;
							params.parent_object = "accounts";
							params.object = "contacts";
							params.record_id = recordIds[0];
							let promise = Promise.resolve(isLinkedRecord(params));
							if (isValidParam(promise)) {
								promise.then((response) => {
									if (isValidParam(response) && response.is_linked) {
										let linkDetails = response.link_details;
										data.parent_object = constants.ACCOUNTS_OBJECT;
										data.parent_record_id = linkDetails.id;
									}
									dispatch(
										getAppDrawer(
											true,
											labelName,
											value,
											data,
											constants.DETAIL_VIEW
										)
									);
								});
								setOpenActionList(false);
							}
						} else if (value === constants.CONVERT) {
							let minimizeList = customDrawer.minimizeList;
							if (customDrawer.isOpen === false) {
								customDrawer.minimizeList.Convert = false;
							}
							if (
								!minimizeList.hasOwnProperty(constants.CONVERT) ||
								(minimizeList.hasOwnProperty(constants.CONVERT) &&
									!minimizeList.Convert)
							) {
								let style = {};
								style.width = "40%";
								dispatch(
									getAppCustomDrawer(
										true,
										labelName,
										value,
										style,
										data,
										null,
										true,
										constants.DETAIL_VIEW
									)
								);
								setOpenActionList(false);
							} else {
								dispatch(
									showCustomSnackBar(
										getLocalizedStrings().message.ACCOUNTS
											.ALERT_MULTI_DRAWER_OPEN,
										styles.snackbarBodyStyleError,
										styles.snackBarStyleTop
									)
								);
							}
						} else {
							dispatch(
								getAppDrawer(
									true,
									labelName,
									value,
									data,
									constants.DETAIL_VIEW
								)
							);
						}
					}
				}
				if (isRedirect) {
					if (value === constants.PRINT) {
						/** print */
						let homeId = constants.MODULE_ID_SALES;
						if (app.selectedModuleName === constants.MODULE_SALES) {
							homeId = constants.MODULE_ID_SALES;
						} else if (app.selectedModuleName === constants.MODULE_OPERATIONS) {
							homeId = constants.MODULE_ID_OPERATIONS;
						}
						setOpenActionList(false);

						let redirectUrl = constants.snapshotUrl;
						let tableId = OBJECT_TABLEID_MAP[objectName];
						redirectUrl += "&token=" + localStorage.getItem("token");
						redirectUrl += "&tableId=" + tableId;
						redirectUrl += "&recordId=" + recordIds[0];
						redirectUrl += "&homeId=" + homeId;
						redirectUrl += "&callingfor=printpreview";
						redirectUrl += "&isInXpress=" + false;
						window.open(
							redirectUrl,
							"Print_preview",
							"scrollbars=1,width=800,height=500,location=no"
						);
					} else if (value === constants.SEARCH_KB) {
						let kbUrl = app.me.kb_link;
						let synopsis = detailViewRecord.synopsis;
						var protocol = window.location.protocol;
						if (synopsis !== null && synopsis !== "") {
							kbUrl =
								protocol +
								"//" +
								kbUrl +
								"/Search/Do/" +
								encodeURIComponent(synopsis);
						} else {
							kbUrl = protocol + "//" + kbUrl;
						}
						window.open(kbUrl);
					}
				}
			} else {
				setOpenActionList(false);
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					permissionMessage,
					null
				);
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> action()':" + error
			);
		}
	};

	const login = (projectId) => {
		let myclient_id = projectId
		if (tabInfo?.callFrom === "task_listview") {
			let info = tabInfo
			if(queueList.data?.openedRecordInQueueId !== undefined && queueList.data?.openedRecordInQueueId !== null){
				info.openedRecordInQueueId = queueList.data?.openedRecordInQueueId;
			}
			updateTabInfo(info)
		}
		let promise = franchiseLogin(myclient_id);
		if (isValidParam(promise)) {
			promise.then(response => {
				if (isValidParam(response)) {
					if (getBooleanParam(response.is_franchise_login)) {
						let previousFRLoginDetails = localStorage.getItem('franchiseAppLoginDetails')
						previousFRLoginDetails = previousFRLoginDetails ? JSON.parse(previousFRLoginDetails) : [];
						const { me: { id, background, industry_id, industry_type, is_franchisor, is_franchisee, email, projectId }, selectedModuleItems, selectedModuleName, selectedModuleInfo } = app;
						const { rememberTab, tabs } = tab;
						const currentUrl = window.location.href;
						const franchiseAppLoginDetails = {}
						franchiseAppLoginDetails.appData = { id: id, background: background, industryId: industry_id, industryType: industry_type, isFranchisor: is_franchisor, is_franchisee: is_franchisee, email: email, projectId: projectId }
						franchiseAppLoginDetails.selectionDetails = { selectedModuleItems: selectedModuleItems, selectedModuleName: selectedModuleName }
						if (isValidParam(selectedModuleInfo)) {
							franchiseAppLoginDetails.selectionDetails = { ...franchiseAppLoginDetails.selectionDetails, selectedModuleInfo: selectedModuleInfo }
						}
						franchiseAppLoginDetails.tabDetails = { listrememberedTab: rememberTab, listselectedTab: tabs }
						franchiseAppLoginDetails.returnUrl = currentUrl
						franchiseAppLoginDetails.token = response.token
						franchiseAppLoginDetails.callFrom = constants.DETAIL
						franchiseAppLoginDetails.shouldReturnToInitialPos_ = `shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`
						if (isArray(previousFRLoginDetails)) {
							previousFRLoginDetails.push(franchiseAppLoginDetails)
						}
						localStorage.setItem('token', response.token);
						localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(previousFRLoginDetails))
						localStorage.setItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`, true)
						localStorage.setItem('is_franchisor_token', true);
						if (constants.APP_URL === "") {
							window.location.replace("/");
						} else {
							window.location.replace(constants.APP_URL);
						}
					} else {
						if (response.data?.is_subscription_overdue) {
							localStorage.removeItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`)
							let alertDialogMessage = getLocalizedStrings().message.UNITS.SUBSCRIPTION_NOT_VALID;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
						}
					}
				}
			});
		}
	};


	const processUpdateCoupons = (params, msg, recordId) => {
		try {
			var promise = Promise.resolve(updateCouponsStatus(params, recordId));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response)) {
						if (response.status === 0) {
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							refreshDeatilsView();
							setListViewMounted(false, object);
						}
					}
				});
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> processUpdateCoupons()':" + error
			);
		}
	};

	const pushToQuickBooks = (selectedIds) => {
		try {
			let params = {};
			params.recordIds = selectedIds;
			let url = endPoints.PRODUCTS.PUSH_TO_QB;
			let promise = Promise.resolve(HTTPClient.post(url, params));
			promise.then((response) => {
				if (isValidParam(response) && response.status === 0) {
					dispatch(
						showCustomSnackBar(
							getLocalizedStrings().message.PRODUCTS.ADDED_TO_QUICKBOOK,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
					dispatch(refreshDetailViewData(true));
				} else {
					if (response.status === -1) {
						let msgArr = [];
						let msg = "";
						if (response.hasOwnProperty("data")) {
							if (response.data.hasOwnProperty("failedCreateProductList")) {
								let arrValue = getArrayParam(
									response.data.failedCreateProductList
								);
								if (arrValue.length > 0) {
									arrValue.forEach((val) => {
										msg = (
											<span>
												Product <b>{val}</b> couldnot be added to Quickbooks.
												<br />
											</span>
										);
										msgArr.push(msg);
									});
								}
							}

							if (response.data.hasOwnProperty("failedUpdateProductList")) {
								let arrValue = getArrayParam(
									response.data.failedUpdateProductList
								);
								if (arrValue.length > 0) {
									arrValue.forEach((val) => {
										msg = (
											<span>
												Product <b>{val}</b> couldnot be updated in Quickbooks.
												<br />
											</span>
										);
										msgArr.push(msg);
									});
								}
							}

							let alertMsg = (
								<div
									style={{
										overflow: "auto",
										maxHeight: "400px",
										fontSize: "16px",
									}}
								>
									{msgArr}
								</div>
							);

							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								alertMsg,
								null
							);
						}
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js ---> pushToQuickBooks() '" + error
			);
		}
	};

	const processAddQB = (selectedIds) => {
		try {
			let is_qbsetup = getBooleanParam(app.me.is_qbsetup);
			if (!is_qbsetup) {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					getLocalizedStrings().message.QUICKBOOKS.COONECT_QB_MSG,
					processAddQBOnConfirm.bind(),
					null
				);
			} else {
				pushToQuickBooks(selectedIds);
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> processAddQB()':" + error
			);
		}
	};

	const processAddQBOnConfirm = () => {
		dispatch(
			getAppDrawer(
				true,
				getLocalizedStrings().label.MY_INTEGRATION.MY_INTEGRATIONS,
				constants.MY_INTEGRATIONS,
				null,
				constants.PRODUCTS_OBJECT
			)
		);
	};

	const queueActions = (detailViewId, value) => {
		let isAction = true;
		//; Not sure from where edit coming from
		try {
			if (
				(object === constants.TASKS || object === constants.APPOINTMENTS) &&
				getBooleanParam(isEdit)
			) {
				let msg = getLocalizedStrings().message.COMMON.WITHOUT_SAVE_MESSAGE;
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					msg,
					() => queueActionsOnConfirm(detailViewId, value),
					null
				);
			} else {
				if (value === constants.PREVIOUS || value === constants.NEXT) {
					processQueue(detailViewId, value);
				} else if (value === constants.REMOVE) {
					removeFromQueue(detailViewId, value);
				} else if (value === constants.CLOSE) {
					closeActiveTab();
				}
			}
		} catch (error) {
		}
	};

	const queueActionsOnConfirm = (detailViewId, value) => {
		if (value === constants.PREVIOUS || value === constants.NEXT) {
			processQueue(detailViewId, value);
		} else if (value === constants.REMOVE) {
			removeFromQueue(detailViewId, value);
		} else if (value === constants.CLOSE) {
			closeActiveTab();
		}
	};

	const processQueue = (detailViewId, action) => {
		let prevObj = null;
		let nextRecordIndex = 0;
		try {
			let data = getObjectParam(queueList.data);
			let queueListNew = getArrayParam(data.records);
			// let detailViewId = detailView.record.id;
			let recordId = detailViewId[0];
			let queueObjInner = queueListNew?.filter((obj) => {
				return obj.id === recordId;
			});
			// let final=queueListNew.map((obj,i)=>{
			// 	 if(obj.idx !==i){
			//       return obj.idx=i;
			// 	 }
			// })
			let index =
				isValidParam(queueObjInner) && queueObjInner.length > 0
					? queueObjInner[0].idx
					: -1;
			let objectName = getStringParam(object);
			if (index >= 0 && index < queueListNew.length) {
				if (action === constants.PREVIOUS) {
					nextRecordIndex = index - 1;
				} else if (action === constants.NEXT) {
					nextRecordIndex = index + 1;
				}

				if (index === queueListNew.length - 1) {
					/**
					 *  get the data for for next limit ,
					 * get the start_indext from redux and check  and then call api to get the data
					 */
				}

				if (
					nextRecordIndex === queueListNew.length &&
					data.call_from.toLowerCase() === constants.LIST_VIEW.toLocaleLowerCase
				) {
					// Fetch the next page Records
					// start_index":40,
					nextRecordIndex = 0;
				}

				prevObj = queueListNew[nextRecordIndex];
				let preId =
					isValidParam(prevObj) && prevObj !== null
						? getIntParam(prevObj.id)
						: 0;
				if (preId > 0) {
					let total_count = getIntParam(data.total_count);
					let label = getStringParam(prevObj.title);
					queueList.data['openedRecordInQueueId'] = preId;
					queueList.data['openedRecordInQueueIndex'] = nextRecordIndex;
					if (
						objectName === constants.TASKS ||
						objectName === constants.APPOINTMENTS
					) {
						sfForm.reminder = [];
					}

					openDetailviewQueueTab(
						objectName,
						label,
						preId,
						nextRecordIndex,
						total_count
					);
					
				}
				
		if(object == 'appointments'){
			dispatch(refreshDetailViewListViewData(true,detailViewId));
				let urlParams = {
				 object: 'appointments',
				 recordId: preId
			 }
			 dispatch(getDetailviewData(urlParams));
			 refreshDeatilsView();
		} 
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> processQueue()':" + error
			);
		}
	};

	const removeFromQueue = (detailViewId, action) => {
		let nextRecordIndex = 0;
		let newIdx = 0;
		if (detailViewId > 0) {
			let tempQueList = queueList;
			let data = getObjectParam(queueList.data);
			let _queueList = getArrayParam(data.records);
			let recordId = detailViewId[0];
			let queueObj = _queueList.filter((obj) => { return obj.id == recordId });
			let index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;

			let objectName = getStringParam(object);

			if (queueList.data.object == 'tasks' && object == 'accounts' && index == -1) {
				recordId = queueList.data.openedRecordInQueueId;
				queueObj = _queueList.filter((obj) => { return obj.id == recordId });
				index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
				objectName = 'tasks';
			}

			if (action == constants.DELETE && _queueList.length <= 1) {
				let activeTab = tabManagerActiveTab();
				if (isValidParam(activeTab)) {
					closeTab(activeTab.item, activeTab.index);
				}
			} else {
				if (index >= 0) {
					if (index + 1 < _queueList.length) {
						nextRecordIndex = index + 1;
						newIdx = index;
						// open next records for detailview
					} else {
						nextRecordIndex = index - 1;
						newIdx = nextRecordIndex;
						// get the last record  for detailview 
					}

					let prevObj = _queueList[nextRecordIndex];
					let preId = isValidParam(prevObj) && prevObj != null ? getIntParam(prevObj.id) : 0;
					if (preId > 0) {
						let total_count = getIntParam(data.total_count);
						total_count = total_count - 1;
						removeRecordFromRedux(objectName, recordId, total_count);
						if (queueList.data.object == 'tasks' && object == 'accounts') {

							let deleteLinkInfo = _queueList.filter((obj) => { return obj.id == recordId });
							let _linkInfo = deleteLinkInfo[0].linkInfo
							removeAcoountRecordInQueueFromRedux(objectName, recordId, total_count, _linkInfo);
							if (prevObj.linkInfo == _linkInfo) {
								let _nextrecordIdx = _queueList.findIndex((f) => {
									if (f.linkInfo != _linkInfo) {
										return f.idx;
									}
								})
								newIdx = _queueList.findIndex((f) => {
									return f.linkInfo == _linkInfo
								})
								newIdx = newIdx;
								prevObj = _queueList[_nextrecordIdx];
								preId = isValidParam(prevObj) && prevObj != null ? getIntParam(prevObj.id) : 0;
							}
						}

						let label = getStringParam(prevObj.title);
						tempQueList.data['openedRecordInQueueId'] = preId;
						tempQueList.data['openedRecordInQueueIndex'] = newIdx;
						tempQueList.data['openedRecordInQueuelabel'] = label;
						if (objectName == constants.TASKS) {

							let linkInfo = prevObj.linkInfo;
							if (linkInfo != null && linkInfo != undefined) {
								let linkInfoArr = linkInfo.split(',');
								let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[tempQueList?.data?.selecteddrpdwnObj]);

								linkInfoArr.map((item, i) => {
									let ObjRecordArr = item.split('_');
									let tableId = getIntParam(ObjRecordArr[0].trim());
									if (selectedObjId == tableId) {
										preId = getIntParam(ObjRecordArr[1]);
										objectName = tempQueList?.data?.selecteddrpdwnObj;
									}

								});
							}
						}

						openDetailviewQueueTab(objectName, label, preId, newIdx, total_count);

						dispatch(refreshDetailViewListViewData(true, recordId));
						let urlParams = {
							object: objectName,
							recordId: preId
						}
						dispatch(getDetailviewData(urlParams));
						refreshDeatilsView(action);
					}
				}
			}

		}
	}

	const closeActiveTab = () => {
		try {
			let activeTab = tabManagerActiveTab();
			if (isValidParam(activeTab)) {
				closeTab(activeTab.item, activeTab.index);
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> closeActiveTab()':" + error
			);
		}
	};

	const generateActions = () => {
		let actionListMultiLingual = {
			Edit: getLocalizedStrings().label.COMMON.EDIT,
			Download: getLocalizedStrings().label.COMMON.DOWNLOAD,
			Share: getLocalizedStrings().label.COMMON.SHARE,
			Connect: getLocalizedStrings().label.COMMON.CONNECT,
			Disconnect: getLocalizedStrings().label.COMMON.DISCONNECT,
			Assign: getLocalizedStrings().label.COMMON.ASSIGN,
			Delete: getLocalizedStrings().label.COMMON.DELETE,
			Map: getLocalizedStrings().label.COMMON.MAP,
			Push: getLocalizedStrings().label.COMMON.PUSH,
			Copy: getLocalizedStrings().label.COMMON.COPY,
			"To XMS": getLocalizedStrings().label.COMMON.TO_XMS,
			"See Field History": getLocalizedStrings().label.COMMON.AUDIT,
			Print: getLocalizedStrings().label.COMMON.PRINT,
			"Convert to Accounts":
				getLocalizedStrings().label.COMMON.CONVERT_TO_ACCOUNTS,
			Unsubscribe: getLocalizedStrings().label.COMMON.UNSUBSCRIBE,
			DocuSign: getLocalizedStrings().label.COMMON.DOCUSIGN,
			"Enable Email Sending": getLocalizedStrings().label.COMMON.ENABLE_MAILING,
			"Disable Email Sending":
				getLocalizedStrings().label.COMMON.DISABLE_MAILING,
			Close: getLocalizedStrings().label.COMMON.CLOSE,
			Cancel: getLocalizedStrings().label.COMMON.CANCEL,
			"Generate Invoice":
				getLocalizedStrings().label.COMMON["Generate Invoice"],
			"Add Payment": getLocalizedStrings().label.COMMON["Add Payment"],
			"Open Audit Form": getLocalizedStrings().label.COMMON.OPEN_AUDIT_FORM,
			Login: getLocalizedStrings().label.LOGIN.LOG_IN,
            "Share with": getLocalizedStrings().label.COMMON.SHARE_WITH_UNITS,

		};
		let actionsVal = [];
		let isAuditTrail = false;
		let tableId = 0;
		let arrActions = headerProps.actions;
		let client_Id = detailViewRecord.myclient_Id;
		let toXMS = detailView.data.hasOwnProperty("isToXMS")
			? detailView.data.isToXMS
			: false;
		tableId = OBJECT_TABLEID_MAP[object];
		if (tableId > 0) {
			tableId = tableId.toString();
		}

		let auditTrailTableIds = app.me.audit_trail_table_ids;
		if (auditTrailTableIds !== null && auditTrailTableIds.length > 0) {
			var tableIdsArr = auditTrailTableIds.split(",");
			if (tableIdsArr !== null && tableIdsArr.length > 0) {
				for (var i = 0; i < tableIdsArr.length; i++) {
					var objTableId = tableIdsArr[i];
					if (objTableId === tableId) {
						isAuditTrail = true;
						break;
					}
				}
			}
		}
		if (!isAuditTrail && isValidParam(arrActions)) {
			arrActions = arrActions?.filter((f) => {
				return f !== constants.AUDIT;
			});
		}

		let isClientConn = 0;
		isClientConn = app.me.module_setup_list?.filter((f) => {
			return f === constants.MS_CLIENT_CONNECT;
		});
		if (isValidParam(isClientConn)) {
			if (isClientConn[0] === constants.MS_CLIENT_CONNECT) {
				if (client_Id > 0) {
					arrActions = arrActions?.filter((f) => {
						return f !== constants.CONNECT;
					});
				} else if (isValidParam(arrActions)) {
					arrActions = arrActions?.filter((f) => {
						return f !== constants.DISCONNECT;
					});
				}
			} else {
				arrActions = arrActions?.filter((f) => {
					return f !== constants.CONNECT && f !== constants.DISCONNECT;
				});
			}
		} else {
			arrActions = arrActions?.filter((f) => {
				return f !== constants.CONNECT && f !== constants.DISCONNECT;
			});
		}

		// if(isValidParam(app.me.is_docusign) && !app.me.is_docusign){
		//     arrActions = arrActions.filter(f => { return f !== constants.DOCUSIGN });
		// }

		if (object === constants.ACCOUNTS_OBJECT && isValidParam(arrActions)) {
			let copyExist = arrActions?.filter((f) => {
				return f === constants.COPY;
			});
			let toXMSExist = arrActions?.filter((f) => {
				return f === constants.TO_XMS;
			});

			if (copyExist.length > 0 && toXMSExist.length > 0) {
				let copyindex = arrActions.findIndex((f) => f === constants.COPY);
				let xmsindex = arrActions.findIndex((f) => f === constants.TO_XMS);
				arrActions.splice(xmsindex, 1);
				arrActions.splice(copyindex + 1, 0, constants.TO_XMS);
			}
		}

		if (object === constants.SALES_LEADS_OBJECT && isValidParam(arrActions)) {
			arrActions = arrActions.filter((item) => item !== constants.PUSH);
		}
		if (object !== constants.CONTACTS_OBJECT && isValidParam(arrActions)) {
			arrActions = arrActions.filter((item) => item !== constants.SHARE_WITH_UNITS);
		}
		if (isValidParam(arrActions)) {
			map(arrActions, function (actionName, i) {
				if (
					actionName !== constants.EDIT &&
					actionName !== constants.CONVERT_TO_ACCOUNTS
				) {
					//&& actionName !== constants.DOCUSIGN

					let data = {};
					data.label = actionListMultiLingual[actionName];
					data.value = actionName;
					data.disable =
						actionName === constants.TO_XMS && toXMS ? true : false;
					actionsVal.push(data);
				}
			});
		}

		return actionsVal;
	};

	useEffect(() => {
		let newGeneratedAction = generateActions();
		setActions(newGeneratedAction);
	}, []);

	const uploadLogo = (logoName) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (!isInXpress) {
			if (
				!(
					object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT ||
					object === constants.OPPORTUNITIES_OBJECT ||
					object === constants.PETS_OBJECT
				)
			) {
				return;
			}
			if (logoName === "icon" && detailView.data.file_name !== "") {
				return;
			}
			let id = detailViewRecord.id;
			dispatch(
				getAppDialog(
					true,
					constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG,
					getLocalizedStrings().message.ATTACHMENT.SELECT_IMAGE_TO_UPLOAD,
					setLogoURL,
					{ id: id, object: object },
					null
				)
			);
		} else {
			let permissionMessage =
				getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				permissionMessage,
				null
			);
		}
	};

	const setLogoURL = (imgUrl) => {
		imgUrl = getStringParam(imgUrl);
		if (imgUrl !== "") {
			detailView.data.file_name = imgUrl;
		}
	};

	const getActions = () => {
		let actionsHTML = [];
		if (object === constants.COUPONS_OBJECT) {
			let buttonProps = { style: styles.secondaryButton };
			let status = isValidParam(detailViewRecord.t_status)
				? getStringParam(detailViewRecord.t_status)
				: "";
			if (status === constants.INACTIVE) {
				//buttonProps.label = getLocalizedStrings().label.COMMON.ACTIVATE;
				buttonProps.onClick = () => {
					handleAction(constants.ACTIVE);
				};
				actionsHTML.push(
					<Button {...buttonProps}>
						{" "}
						{getLocalizedStrings().label.COMMON.ACTIVATE}
					</Button>
				);
			} else if (status === constants.ACTIVE) {
				//buttonProps.label = getLocalizedStrings().label.COMMON.DEACTIVATE;
				buttonProps.onClick = () => {
					handleAction(constants.INACTIVE);
				};
				actionsHTML.push(
					<Button {...buttonProps}>
						{" "}
						{getLocalizedStrings().label.COMMON.DEACTIVATE}{" "}
					</Button>
				);
			}

			buttonProps = { style: styles.secondaryButton };
			//buttonProps.label = getLocalizedStrings().label.COMMON.DELETE;
			buttonProps.onClick = () => {
				handleAction(constants.DELETE);
			};
			actionsHTML.push(
				<Button {...buttonProps}>
					{" "}
					{getLocalizedStrings().label.COMMON.DELETE}{" "}
				</Button>
			);
		} else if (
			object === constants.CASES_OBJECT &&
			app.selectedModuleName === constants.MODULE_HUB
		) {
			let logedInUSerName = getStringParam(app.me.name);
			let record = getObjectParam(detailView.record);
			let submitter = getStringParam(record.custom_field1);
			let status = getStringParam(record.t_status);

			if (logedInUSerName === submitter && status !== "Close") {
				let buttonProps = { style: styles.primaryButton };
				//buttonProps.label = getLocalizedStrings().label.COMMON.CLOSE_CASE;
				buttonProps.onClick = () => {
					handleAction(constants.HUB_CASE_CLOSE);
				};
				actionsHTML.push(
					<Button {...buttonProps}>
						{" "}
						{getLocalizedStrings().label.COMMON.CLOSE_CASE}{" "}
					</Button>
				);
			}
		} else if (object === constants.PRODUCTS_OBJECT) {
			let xeroIntegration = app.me.hasOwnProperty("xero_integration")
				? app.me.xero_integration
				: null;
			if (
				(xeroIntegration !== null && !xeroIntegration.is_xero) ||
				app.me.is_qbsetup
			) {
				let buttonProps = { style: styles.primaryButton };
				buttonProps.onClick = () => {
					handleAction(constants.ADD_QUICKBOOKS);
				};
				actionsHTML.push(
					<Button {...buttonProps}>
						{" "}
						{getLocalizedStrings().label.COMMON.PUSH_TO_QB}{" "}
					</Button>
				);
			}
		}
		return actionsHTML;
	};

	const getKanbanToggle = () => {
		let kanban = null;
		let activeTabInfo = getObjectParam(getActiveTabInfo());
		let currentView = null;
		if (object === constants.PROJECTS_OBJECT) {
			let selectedView = getObjectParam(
				JSON.parse(getCookie("view_details_" + app.me.projectId))
			);
			if (selectedView.hasOwnProperty(object)) {
				let obj = getObjectParam(selectedView[object]);
				if (obj.hasOwnProperty("defaultView")) {
					currentView = obj.defaultView.toLowerCase();
					// defaultView = (obj.defaultView === 'Timeline'? 3 : (obj.defaultView === 'Column' ? 2 : 1));
				}
			}

			kanban = (
				<div
					style={{
						width: object === constants.PROJECTS_OBJECT ? "17%" : "12%",
						cursor: "pointer",
						paddingLeft: "0px",
						paddingRight: "0px",
						float: "right",
						marginRight: "10px",
					}}
				>
					<KanbanToggle
						currentView={currentView}
						object={object}
						isDetailView={true}
					/>
				</div>
			);
		}
		return kanban;
	};

	// edit title start

	const getTitleField = () => {
		let temp = null;
		let objectName = getStringParam(object);
		let fieldArr = null;
		let showEditIcon = getBooleanParam(showEditTitleIcon);

		let titleFieldName = headerProps.titleFieldName;
		try {
			fieldArr = detailView.fields?.filter((f) => {
				if (f.name === titleFieldName) {
					return f;
				} else if (constants.CONTACTS_OBJECT === objectName && f.name === "last_name") {
					return f;
				}
			});

			if (objectName !== constants.ROYALTY_PLANS_OBJECT && objectName !== constants.CONTACTS_OBJECT && isValidParam(fieldArr) && getArrayParam(fieldArr).length > 0) {
				let fieldObj = {};
				let record = detailView.data.record;
				fieldArr.map((f, i) => {
					temp = {};
					temp.id = fieldArr[i].hasOwnProperty("id") ? fieldArr[i].id : 0;
					temp.name = fieldArr[i].hasOwnProperty("name")
						? fieldArr[i].name
						: "";
					temp.value =
						getStringParam(record[f.name]).length > 0
							? getStringParam(record[f.name])
							: "";
					temp.isRequiredField = getBooleanParam(f.is_required_field);
					temp.isInlineEdit = getBooleanParam(f.is_inline_edit);
					temp.textBeforeEdit =
						getStringParam(record[f.name]).length > 0
							? getStringParam(record[f.name])
							: "";
					showEditIcon = getBooleanParam(f.is_inline_edit);
					fieldObj[f.name] = temp;
				});
				setFields(fieldObj);
				setShowEditTitleIcon(showEditIcon);
			} else if (objectName === constants.CONTACTS_OBJECT || getArrayParam(fieldArr).length === 0) {
				getTitleFieldByObject(objectName);
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> getTitleField()':" + error
			);
		}
	};

	const getTitleFieldByObject = (objName) => {
		let field = null;
		let url = endPoints.COMMON.GET_DETAILVIEW_TITLE_FIELD;
		let info = getObjectParam(getActiveTabInfo());
		let params = {};
		params.object = getStringParam(objName);
		params.title_field_name = getStringParam(headerProps.titleFieldName);
		params.id = getIntParam(detailView.data.record.id);
		if (info.hasOwnProperty("isInXpress") && info.hasOwnProperty("inXpressProjectId")) {
			params.is_inxpress = getBooleanParam(info.isInXpress);
			params.inxpress_project_id = getIntParam(info.inXpressProjectId);
		}
		if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
			params.isUnitsAccount = info.isUnitsAccount;
			params.UnitsAccountProjectId = info.UnitsAccountProjectId;
		}
		try {
			let promise = Promise.resolve(HTTPClient.get(url, params));
			promise.then((response) => {
				if (isValidParam(response) && isValidParam(response.titlefields)) {
					let fieldsArr = getArrayParam(response.titlefields);
					let fieldsObj = {};
					fieldsArr.map((f, i) => {
						let temp = {};
						temp.id = f.id;
						temp.name = f.name;
						temp.value = getStringParam(f.value).length > 0 ? getStringParam(f.value) : "";
						temp.isSystemField = getBooleanParam(f.isSystemField);
						temp.isRequiredField = getBooleanParam(f.is_required_field);
						temp.isReadOnly = getBooleanParam(f.isReadOnly);
						temp.textBeforeEdit = getStringParam(f.value).length > 0 ? getStringParam(f.value) : "";
						fieldsObj[temp.name] = temp;
					});
					if (params.object !== constants.CONTACTS_OBJECT && !isFieldValidForEdit(fieldsObj[params.title_field_name])) {
						//hasPermission
						setShowEditTitleIcon(false);
					} else if (params.object === constants.CONTACTS_OBJECT) {
						if (fieldsArr.length === 1 && !isFieldValidForEdit(fieldsObj[params.title_field_name])) {
							setShowEditTitleIcon(false);
						}
					}
					setFields(fieldsObj);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> getTileFieldByObject()':" + error
			);
		}
	};

	function setHeaderTitle(fieldName, event) {
		const value = event.target.value;
		let fieldsNew = { ...fields };
		let titlefield = isValidParam(fieldName) ? fieldsNew[getStringParam(fieldName)] : null;
		if (isValidParam(titlefield)) {
			titlefield.value = value;
			fieldsNew[fieldName] = titlefield;
		}
		setFields(fieldsNew);
	}

	const checkValidFieldUpdate = (fields) => {
		let isValid = true;
		let titleFldName = getStringParam(headerProps.titleFieldName);
		if (isValidParam(fields)) {
			let fld = fields[titleFldName];
			if (
				fld.isRequiredField &&
				getStringParam(fld.value).trim().length === 0
			) {
				isValid = false;
				//fld.value = getStringParam(fld.textBeforeEdit);
			}
			if (object === constants.CONTACTS_OBJECT) {
				let lastName = Object.keys(fields)[1];
				if (
					isValid &&
					fields[lastName].isRequiredField &&
					getStringParam(fields[lastName].value).trim().length === 0
				) {
					isValid = false;
					//fields[lastName].value = getStringParam(fields[lastName].textBeforeEdit);
				} //else if(!isValid ){
				//fields[lastName].value = getStringParam(fields[lastName].textBeforeEdit);
				//}
			}
		}
		return isValid;
	};

	const inlineUpdate = (fields) => {
		let tempField = null;
		let tempFieldsArr = [];
		try {
			let recordId = detailView.record.id;
			if (isValidParam(fields)) {
				Object.keys(fields).map((key) => {
					tempField = {};
					tempField.id = fields[key].id;
					tempField.name = fields[key].name;
					// fieldName = fields[key].name;
					tempField.value = getStringParam(fields[key].value);
					// fieldValue = getStringParam(fields[key].value);
					tempFieldsArr.push(tempField);
				});
				if (object !== "" && recordId > 0) {
					let params = {};
					params.id = recordId;
					params.fields = [];
					params.auditrail_action_type =
						constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;
					params.fields = tempFieldsArr;
					if (
						app.selectedModuleName === constants.MODULE_HUB &&
						object === constants.CASES_OBJECT
					) {
						params.call_from = constants.MODULE_HUB;
					}
					let promise = SFFormActions.saveRecordPromise(object, params);
					promise.then((response) => {
						if (
							ParameterVerifier.isValidParam(response) &&
							response.hasOwnProperty("data") &&
							response.data.hasOwnProperty("id")
						) {
							let id =
								ParameterVerifier.isValidParam(response.data.id) &&
									isArray(response.data.id) &&
									response.data.id.length > 0
									? response.data.id[0]
									: 0;
							if (id > 0) {
								dispatch(showCustomSnackBar(
									getLocalizedStrings().message.COMMON.UPDATE,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								));

								// commented to handle detailviewTitle --Lalu
								// updateHeaderTitleRecord(fieldName, fieldValue);

								// detailView.data.record[fieldName] = fieldValue;
								// detailView.data.title = fieldValue;
								// refreshView();

								dispatch(refreshDetailViewData(true));
								// refreshDetailViewListViewData(true);
							}
						}
					});
				}
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> inlineUpdate()':" + error
			);
		}
	};

	function handleShowEditTitleTextField() {
		let tabInfo = getActiveTabInfo();
		let styleError = styles.snackBarStyleTop;
		let hasPermission = getTitleEditPermission();
		let detailViewRecord = detailView.record;
		if (hasPermission || (tabInfo.isUnitsAccount && tabInfo.callFrom === "mini_edit" && app.me.is_franchisee)) {
			if (object == constants.TASKS && detailViewRecord != null && detailViewRecord.t_status != null && detailViewRecord.t_status == "Completed") {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "you cannot edit a completed task.", null);
			} else {
				if (!getBooleanParam(showEditTitleTextField)) {
					setShowEditTitleTextField(true);
					setShowEditTitleIcon(false);
				}
			}
		} else {
			styleError.left = 390;
			dispatch(
				showCustomSnackBar(
					getLocalizedStrings().message.COMMON.ACCESS_DENIED,
					styles.snackbarBodyStyleError,
					styleError
				)
			);
		}
	}

	const isFieldValidForEdit = (field) => {
		let isValid = false;
		if (
			isValidParam(field) &&
			field.hasOwnProperty("isInlineEdit") &&
			field.isInlineEdit
		) {
			isValid = true;
		} else if (isValidParam(field)) {
			if (field.hasOwnProperty("isReadOnly") && !field.isReadOnly) {
				isValid = true;
			}
		}
		return isValid;
	};

	const getTitleEditPermission = () => {
		let permit = false;
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let objectName = getStringParam(object);
		let hasPermission = hasAccessPermission(
			objectName,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_EDIT
		);
		if (hasPermission && !isInXpress && !isUnitsAccount) {
			permit = true;
		}
		return permit;
	};

	function saveTitle() {
		if ($("#titlecontainer:hover").length === 1) {
		} else {
			let doUpdate = true;
			let showTxtbox = false;
			let showEditIcon = true;

			let titleFieldName = getStringParam(headerProps.titleFieldName);
			if (isValidParam(fields) && Object.keys(fields).length > 0) {
				if (object === constants.CONTACTS_OBJECT) {
					let key2 = getStringParam(Object.keys(fields)[1]);
					if (
						getStringParam(fields[titleFieldName].value) ===
						getStringParam(fields[titleFieldName].textBeforeEdit) &&
						getStringParam(fields[key2].value) ===
						getStringParam(fields[key2].textBeforeEdit)
					) {
						doUpdate = getStringParam(fields[titleFieldName].value).length === 0 ||
							getStringParam(fields[key2].value).length === 0;
					}
				} else if (
					getStringParam(fields[titleFieldName].value) ===
					getStringParam(fields[titleFieldName].textBeforeEdit)
				) {
					doUpdate = getStringParam(fields[titleFieldName].value).length === 0;
				}
				if (doUpdate) {
					if (checkValidFieldUpdate(fields)) {
						if (
							object === constants.PRODUCTS_OBJECT &&
							fields.hasOwnProperty("product_name") &&
							isValidParam(fields.product_name)
						) {
							checkDuplicateProduct(fields);
						} else {
							inlineUpdate(fields);

							setShowEditTitleTextField(showTxtbox);
							setShowEditTitleIcon(showEditIcon);
						}
					} else {
						showTxtbox = true;
						showEditIcon = false;
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.COMMON.TITLE_FIELD_BLANK,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							)
						);
						setShowEditTitleTextField(showTxtbox);
						setShowEditTitleIcon(showEditIcon);
					}
				} else {
					setShowEditTitleTextField(showTxtbox);
					setShowEditTitleIcon(showEditIcon);
				}
			}
		}
	};

	const getTitleForContactsObject = (titleField1, titleField2) => {
		let title = null;
		if (
			getStringParam(titleField1.value).length === 0 &&
			getStringParam(titleField2.value).length === 0
		) {
			title = constants.EMPTY;
		} else if (getStringParam(titleField1.value) === 0) {
			title = getStringParam(titleField2.value);
		} else if (getStringParam(titleField2.value) === 0) {
			title = getStringParam(titleField1.value);
		} else {
			title = getStringParam(titleField1.value + " " + titleField2.value);
		}
		return title;
	};

	const getTextFieldComponent = (titlefield) => {
		let component = null;
		let minWidth = 150;
		let maxWidth = 400;
		let perCharPixel = 8;
		if (isValidParam(titlefield)) {
			let autoFocus = titlefield.name !== "last_name";
			let textSize = getIntParam(titlefield.value.length + 1) * perCharPixel;
			let widthValue = minWidth;
			if (textSize > minWidth && textSize < maxWidth) {
				widthValue = textSize;
			} else if (textSize > maxWidth) {
				widthValue = maxWidth;
			}
			let inputStyle = {};
			inputStyle.display = "block";
			inputStyle.fontSize = "18px";
			inputStyle.width = widthValue + "px";

			component = (
				<div
					style={{
						display: "inline-block",
						paddingBottom: "5px",
						paddingLeft: "15px",
						fontWeight: "normal",
						color: "#555555",
						verticalAlign: "middle",
						maxWidth: "60%",
						marginTop: "-10px",
						marginLeft: "0px",
					}}
				>
					{" "}
					<FormControl
						style={{ width: "100%" }}
						className="test"
						noValidate
						autoComplete="off"
					>
						<TextField
							variant="standard"
							id={"header_text_field_Id_" + titlefield.id}
							key={titlefield.id}
							name={titlefield.name}
							value={titlefield.value}
							onChange={(e) => setHeaderTitle(titlefield.name, e)}
							onFocus={(e) => {
								e.target.setSelectionRange(0, 0);
							}}
							style={{ ...inputStyle }}
							autoFocus={autoFocus}
							inputprops={{ maxLength: "225" }}
							onBlur={saveTitle}
							autoComplete="new-password"
							className={"sf-fields-bg"}
						/>
					</FormControl>
				</div>
			);
		}
		return component;
	};

	const getTextForReadOnlyField = (title) => {
		let component = null;
		if (isValidParam(title)) {
			let titleName = "dvw_" + title.name;
			let styleobj = {
				textOverflow: "ellipsis",
				overflow: "hidden",
				display: "inline-block",
				fontSize: "24px",
				whiteSpace: "nowrap",
				fontWeight: "normal",
				color: "#555555",
				verticalAlign: "middle",
				maxWidth: "95%",
				marginTop: "-10px",
				marginLeft: "2%",
			};
			if (title.name === "last_name") {
				styleobj.paddingLeft = "0px";
				styleobj.marginLeft = "auto";
			}
			if (getStringParam(title.value).length > 0) {
				component = (
					<div
						style={{
							display: "inline-block",
							width: "40%",
							marginLeft: "18%",
							padding: "5px",
						}}
					>
						<span
							id={{ titleName }}
							style={{ ...styleobj }}
							title={title.value}
						>
							{title.value}
						</span>
					</div>
				);
			}
		}
		return component;
	};
	//edit title ends

	const checkDuplicateProduct = (field) => {
		try {
			let productName = isValidParam(field["product_name"])
				? getStringParam(field["product_name"].value).trim().toLowerCase()
				: "";
			let paramObject = {};
			paramObject.product_name = getStringParam(productName);
			paramObject.record_id = getIntParam(detailView.record.id);
			let promise = checkDuplicateProductName(paramObject);
			promise.then((response) => {
				if (isValidParam(response)) {
					let isDuplicateProduct = getBooleanParam(response.is_duplicate);
					if (isDuplicateProduct) {
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.PRODUCTS.DUPLICATE_PRODUCT,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							)
						);

						setShowEditTitleTextField(true);
						setShowEditTitleIcon(false);
					} else {
						inlineUpdate(field);

						setShowEditTitleTextField(false);
						setShowEditTitleIcon(true);
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> checkDuplicateProduct()':" + error
			);
		}
	};

	const createJobsActionButton = () => {
		let element = [];
		try {
			let phoneSetup = getObjectParam(app.me.phone_setup);
			let list = [];
			// if (!getObjectParam(detailView.data.isAppointmentScheduled)) {
			let t_status = getObjectParam(detailView.data.record.t_status)
				? detailView.data.record.t_status
				: "New";
			if (t_status === JOB_ACTIONS.NEW) {
				list = [
					{
						name: constants.JOB_SCHEDULER,
						label: getLocalizedStrings().label.CAMPAIGNS.SCHEDULE,
					},
				];
			}
			// let list = [{ name: constants.JOB_SCHEDULER, label: getLocalizedStrings().label.CAMPAIGNS.SCHEDULE }];
			if (t_status === JOB_ACTIONS.SCHEDULED) {
				list.push({
					name: constants.JOB_RESCHEDULER,
					label: getLocalizedStrings().label.JOBS.RESCHEDULE,
				});
				let phoneTextProvider = app.me.phone_text_provider ?? constants.NONE;
				if (phoneTextProvider !== constants.NONE && phoneSetup.is_twilio_sms) {
					list.push({
						name: constants.SEND_TEXT,
						label: getLocalizedStrings().label.JOBS.OMW,
					});
				}
				list.push({
					name: JOB_ACTIONS.DONE,
					label: getLocalizedStrings().label.COMMON.DONE,
				});
			}
			if (t_status === JOB_ACTIONS.DONE || t_status === JOB_ACTIONS.SCHEDULED) {
				list.push(
					...[
						{
							name: constants.GENERATE_INVOICE,
							label: getLocalizedStrings().label.JOBS.INVOICE,
						},
						{
							name: constants.ADD_PAYMENT,
							label: getLocalizedStrings().label.JOBS.PAY,
						},
					]
				);
			}
			if (t_status === JOB_ACTIONS.INVOICED) {
				list = [
					{
						name: constants.ADD_PAYMENT,
						label: getLocalizedStrings().label.JOBS.PAY,
					},
				];
			}

			element = list.map((item) => {
				return (
					<Button
						key={`key-${item.name}`}
						onClick={() => handleAction(item.name)}
						style={{ ...styles.primaryButton, verticalAlign: "top" }}
						disabled={isLoading}
					>
						{" "}
						{item.label}{" "}
					</Button>
				);
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> createJobsActionButton()':" + error
			);
		}
		return element;
	};

	const handlePostAll=(value)=>{
			if(value === 'To Units'){
				handleAction(value)
			}else{
				handleAction(value)
			}
	}

	const createRoyaltyRunActionButton = () => {
		let element = [];
		try {
			let list = [];
			let showButton = getBooleanParam(detailView.data.show_buttons);
			let showPostButton = getBooleanParam(detailView.data.show_post_buttons);
			let showInvoiceAllButton = getBooleanParam(detailView.data.show_invoice_all_button);
			let showEmailAllButton = getBooleanParam(detailView.data.show_email_all_button);
			let showPostAllButton = getBooleanParam(detailView.data.show_post_all_button);
			let showMarkPaidAllButton = getBooleanParam(detailView.data.show_mark_paid_all_button);

			if (showButton) {
				/*list.push({
					name: ROYALTY_RUN_ACTIONS.REPORT_SALES,
					label: getLocalizedStrings().label.ROYALTY_RUN.REPORT_SALES,
				});
				list.push({
					name: ROYALTY_RUN_ACTIONS.PROCESS,
					label: getLocalizedStrings().label.ROYALTY_RUN.PROCESS,
				});*/
				list.push({
					name: ROYALTY_RUN_ACTIONS.SUBMIT,
					label: getLocalizedStrings().label.ROYALTY_RUN.SUBMIT,
				});
			}
			if (showInvoiceAllButton) {
				list.push({
					name: ROYALTY_RUN_ACTIONS.INVOICE_ALL,
					label: getLocalizedStrings().label.ROYALTY_RUN.INVOICE_ALL,
				});
			}
			if (showMarkPaidAllButton) {
				list.push({
					name: ROYALTY_RUN_ACTIONS.MARKPAID_ALL,
					label: 'Mark Paid All',
				});
			}

			if (showEmailAllButton) {
				list.push({
					name: ROYALTY_RUN_ACTIONS.EMAIL_ALL,
					label: getLocalizedStrings().label.ROYALTY_RUN.EMAIL_ALL,
				});
			}
			if (showPostAllButton) {
				list.push({
					name: ROYALTY_RUN_ACTIONS.POST_ALL,
					label: getLocalizedStrings().label.ROYALTY_RUN.POST_ALL,
					dropdownOptions: [
						{ value: 'To Units', label: getLocalizedStrings().label.COMMON.TO_UNITS},
						{ value: 'To Quickbooks', label: getLocalizedStrings().label.COMMON.TO_QUICKBOOKS},
					]
				});
			}
	
			element = list.map((item) => {
				if (item.name === ROYALTY_RUN_ACTIONS.POST_ALL && item.dropdownOptions && app.me.is_qbsetup) {
					return (
						<div key={`key-${item.name}`} >
							<PopOver
								id={"PopOver"}
								key={"PopOver"}
								buttonEndIcon={"keyboard_arrow_down"}
								buttonStyle={styles.primaryButton}
								labelposition={"before"}
								buttonLabel={ROYALTY_RUN_ACTIONS.POST_ALL}
								options={item.dropdownOptions}
								onclickAction={(v) => handlePostAll(v)}
								buttonLineOrigin={[0, 0]}
                        />
						</div>
					);
				} else {
					return (
						<Button
							key={`key-${item.name}`}
							onClick={() => handleAction(item.name)}
							style={{...styles.primaryButton, verticalAlign: "top"}}
							disabled={isLoading}
						>
							{item.label}
						</Button>
					);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> createRoyaltyRunActionButton()':" + error
			);
		}
		return element;
	};
	
	


	const createReportedSalesActionButton = () => {
		let element = [];
		try {
			let list = [];
			list.push({
				name: REPORTED_SALES_ACTIONS.UPDATE,
				label: getLocalizedStrings().label.REPORTED_SALES.UPDATE,
			});
			/*list.push({
				name: REPORTED_SALES_ACTIONS.CLOSE,
				label: getLocalizedStrings().label.REPORTED_SALES.CLOSE,
			});*/
			element = list.map((item) => {
				return (
					<Button
						key={`key-${item.name}`}
						onClick={() => handleAction(item.name)}
						style={{ ...styles.primaryButton, verticalAlign: "top" }}
						disabled={isLoading}
					>
						{" "}
						{item.label}{" "}
					</Button>
				);
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> createReportedSalesActionButton()':" + error
			);
		}
		return element;
	};

	const createTasksActionButton = () => {
		let element = [];
		try {
			let list = [];
			// let list = [{ name: constants.JOB_SCHEDULER, label: getLocalizedStrings().label.CAMPAIGNS.SCHEDULE }];
			list = [{ name: constants.COMPLETE, label: "Complete" }];
			list.push({ name: constants.COMPLETE_NEW, label: "Complete & New" });
			list.push({ name: constants.DAY, label: "+ Day" });
			list.push({ name: constants.WEEK, label: "+ Week" });
			list.push({ name: constants.MONTH, label: "+ Month" });
			if(detailView.data.record.t_unit_owner !== 1){
				list.push({ name: constants.DELETE, label: "Delete" });
			}

			element = list.map((item) => {
				return (
					<Button
						key={`key-${item.name}`}
						onClick={() => handleTasksAction(item.name)}
						// style={{ verticalAlign: 'top'  }}
						style={
							item.name === constants.COMPLETE
								? styles.primaryButton
								: styles.secondaryButton
						}
						disabled={isLoading}
					>
						{" "}
						{item.label}{" "}
					</Button>
				);
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> createTasksActionButton()':" + error
			);
		}
		return element;
	};

	const handleGreenlightAction = (item) => {
		if (item !== "Greenlight") {
			greenLightStatusUpdate(item);
		} else {
			getRequiredFields(item);
		}
	};

	const getRequiredFields = (item) => {
		let params = {};
		try {
			params = {
				record_id: detailView.record.id,
				table_id: OBJECT_TABLEID_MAP[object],
				is_greenlight: true,
			};
			var promise = Promise.resolve(
				HTTPClient.get(endPoints.WORKFLOWS.GET_REQUIRED_FIELDS, params)
			);
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response)) {
						let requiredFields = getArrayParam(response);
						if (requiredFields.length > 0) {
							let style = {};
							let title = "Greenlight";
							style.width = "33%";
							let recordId = getIntParam(detailView.record.id);
							let data = {};
							data.object = object;
							data.recordId = recordId;
							data.requiredFields = requiredFields;
							dispatch(
								getAppCustomDrawer(
									true,
									title,
									item,
									style,
									data,
									null,
									true,
									item
								)
							);
						} else {
							greenLightStatusUpdate(item);
						}
					}
				});
			}
		} catch (error) {
			console.error("Error in 'convert.js -> getRequiredFields()':" + error);
		}
	};

	const greenLightStatusUpdate = (value) => {
		if (value === "Resubmit") {
			value = constants.GREENLIGHT_STATUS_RESUBMITTED;
		}
		try {
			let greenLightFieldId = 0;
			let recordId = getIntParam(detailView.record.id);
			let fields = getArrayParam(sfForm.data.accounts.fields);
			let greenLightField = fields?.filter(
				(f) => f.name === constants.GREENLIGHT_STATUS_FIELD
			);
			if (greenLightField.length > 0) {
				greenLightFieldId = getIntParam(greenLightField[0].id);
			}
			let params = {};
			params.id = recordId;
			params.fields = [];
			let tempField = {};
			if (greenLightFieldId > 0) {
				tempField.id = greenLightFieldId;
			}
			tempField.name = constants.GREENLIGHT_STATUS_FIELD;
			tempField.value = value;
			params.fields.push(tempField);
			params.auditrail_action_type = constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;
			let promise = SFFormActions.saveRecordPromise(object, params);
			promise.showCustomSnackBar = showCustomSnackBar;
			promise.then((response) => {
				if (
					isValidParam(response) &&
					response.hasOwnProperty("data") &&
					response.data.hasOwnProperty("id")
				) {
					let id =
						isValidParam(response.data.id) &&
							isArray(response.data.id) &&
							response.data.id.length > 0
							? response.data.id[0]
							: 0;
					if (id > 0) {
						dispatch(refreshDetailViewData(true));
						if (
							listView.data.object.toLowerCase() ===
							constants.CONTACTS_OBJECT ||
							childListView.data.object.toLowerCase() ===
							constants.CONTACTS_OBJECT
						) {
							dispatch(refreshDetailViewListViewData(true));
						}

						openMailDrawerForDenialStage(value);
						dispatch(promise.showCustomSnackBar(
							getLocalizedStrings().message.COMMON.UPDATE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						));
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewFields.js -> greenLightStatusUpdate()':" + error
			);
		}
	};

	const openMailDrawerForDenialStage = (value) => {
		if (
			object === constants.ACCOUNTS_OBJECT &&
			value === constants.GREENLIGHT_STAGE_DENIAL_INQUIRY
		) {
			let email = constants.GREENLIGHT_TO_EMAIL;
			let recordId = getIntParam(detailView.record.id);
			let sendMailLabel =
				getLocalizedStrings().label.COMMON.EMAIL_TO + " " + email + "";
			let type = LinkToType.TYPE_EMAIL;
			let data = {};
			data.id = recordId;
			data.email = email;
			data.object = object;
			data.parentObject = getStringParam(object);
			data.type = constants.TYPE_EMAIL;
			data.stageName = constants.GREENLIGHT_STAGE_DENIAL_INQUIRY;
			dispatch(
				getAppCustomDrawer(
					true,
					sendMailLabel,
					type,
					{ width: "90%" },
					data,
					null,
					true
				)
			);
		}
	};

	const refreshUpdate = () => {
		let tabInfo = getActiveTabInfo();
		tabInfo.selectedPageNo = 1;
		let activeTab = tabManagerActiveTab();
		if (object === constants.AUDITS_OBJECT) {
			activeTab.info.startIndex = 0;
			activeTab.info.startPageNo = 1;
		}
		updateActiveTab(activeTab);
		dispatch(detailViewIsMounted(false));
		dispatch(isDetailViewSet(false));
		refreshView();
	};


	const xmsAlertDialog = (val, isSucess) => {
		let alertDialogTitle =
			getLocalizedStrings().message.COMMON.XMS_SUCCESS_ALERT_DIALOG_TITLE;
		if (!isSucess) {
			alertDialogTitle =
				getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE;
		}
		sfDialogs.alert(alertDialogTitle, val, null);
	};

	const processToXMS = (selectedId) => {
		try {
			let params = {};
			params.recordId = selectedId;
			var promise = Promise.resolve(
				HTTPClient.post(endPoints.ACCOUNTS.GET_XMS_FIELD_VALUE, params)
			);
			promise.then((response) => {
				if (response.status === 0) {
					setFieldValue(response.data.fields);
					setData(response.data);
					setErrorMsg("");
					let dialogData = {};
					dialogData.fieldValue = getArrayParam(response.data.fields);
					dialogData.data = response.data;
					dispatch(
						getAppDialog(
							true,
							constants.XMS_PUSH_DIALOG,
							"",
							xmsAlertDialog,
							dialogData,
							constants.DETAIL_VIEW
						)
					);
				} else {
					if (response.status === -1) {
						setFieldValue([]);
						setErrorMsg(response.error.message);
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE,
							response.error.message,
							null
						);
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js ---> processToXMS() '" + error
			);
		}
	};

	useEffect(() => {
		if (headerProps !== null) {
			getTitleField();
		}
	}, [headerProps.title]);

	let total_count = 0;
	let openedRecordInQueueNo;
	let isCallfromQueue = false;




	let themeColor = app.me.background;
	let previousButtonStyle = { ...styles.secondaryButton, width: '30px', minWidth: '27px', marginRight: '0px', backgroundColor: themeColor, height: '29px' };
	let nextButtonStyle = { ...styles.secondaryButton, width: '30px', minWidth: '27px', marginRight: '0px', backgroundColor: themeColor, height: '29px' };
	let removeButtonStyle = { ...styles.secondaryButton, marginRight: '0px', width: '30px', minWidth: '27px', height: '29px' }
	let nextIconStyle = { fontSize: '25px', color: 'white', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
	let prevIconStyle = { fontSize: '25px', color: 'white', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
	let removeIconStyle = { fontSize: '20px', color: '#717171', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
	let isDisabledPrevious = false;
	let isDisabledNext = false;
	let isRemoveDisable = false;
	if (tabInfo.hasOwnProperty('is_callFrom_queue') && getBooleanParam(tabInfo.is_callFrom_queue)) {
		isCallfromQueue = true;
		let recordsIndex = tabInfo.hasOwnProperty('record_index') ? getIntParam(tabInfo.record_index) : 0;
		total_count = tabInfo.hasOwnProperty('total_count') ? getIntParam(tabInfo.total_count) : 0;
		isDisabledNext = recordsIndex + 1 === total_count ? true : false;
		isDisabledPrevious = recordsIndex === 0 ? true : false;
		isRemoveDisable = recordsIndex === 0 && recordsIndex + 1 === total_count ? true : false;

		previousButtonStyle = isDisabledPrevious ? { ...styles.secondaryButton, backgroundColor: "white", marginRight: '0px', width: '30px', minWidth: '27px', height: '29px' } : previousButtonStyle;
		nextButtonStyle = isDisabledNext ? { ...styles.secondaryButton, backgroundColor: "white", width: '30px', minWidth: '27px', marginRight: '0px', height: '29px' } : nextButtonStyle;
		removeButtonStyle = isRemoveDisable ? { ...styles.secondaryButton, backgroundColor: "white", width: '30px', minWidth: '27px', marginRight: '0px', height: '29px' } : removeButtonStyle;

		nextIconStyle = isDisabledNext ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : nextIconStyle;
		prevIconStyle = isDisabledPrevious ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : prevIconStyle;
		removeIconStyle = isRemoveDisable ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : removeIconStyle;
	}
	if (isCallfromQueue && queueList != null && queueList != undefined && queueList.hasOwnProperty('data') && queueList.data != null && (queueObj != constants.TASKS)) {
		total_count = queueList.data.total_count;
		openedRecordInQueueNo = queueList.data.openedRecordInQueueIndex + 1;
	}
	let _showConvertButton, _more = false;
	if (object == constants.ACCOUNTS_OBJECT) {
		_showConvertButton = true;
		_more = true;
	}

	return (
		<div style={{ paddingLeft: "7px", height: "40px" }}>
			<div
				style={{
					paddingLeft: "0px",
					float: "left",
					width: "45%",
					// marginTop: "-3px",
					display: 'flex'
				}}
			>
				{(object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT ||
					object === constants.OPPORTUNITIES_OBJECT ||
					object === constants.PETS_OBJECT) && (
						<span
							style={{
								display: "inline-block",
								paddingLeft: "13px",
								paddingTop: detailView.data.file_name !== "" ? "" : "5px",
								paddingRight: "10px !important",
								cursor: "pointer",
							}}
						>
							{detailView.data.file_name !== "" && (
								<img
									src={detailView.data.file_name}
									width="30px"
									height="30px"
									alt="object"
									onClick={(e) => {
										uploadLogo("image");
									}}
								/>
							)}

							{detailView.data.file_name === "" && (
								<i
									className={headerProps.iconImage}
									aria-hidden="true"
									style={{ color: "#F89764" }}
									onClick={() => {
										uploadLogo("icon");
									}}
								/>
							)}
						</span>
					)}

				{!(
					object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT ||
					object === constants.OPPORTUNITIES_OBJECT ||
					object === constants.PETS_OBJECT
				) && (
						<span
							style={{
								display: "inline-block",
								paddingLeft: "13px",
								paddingTop: "5.5px",
								paddingRight: "10px !important",
							}}
						>
							<i
								className={headerProps.iconImage}
								aria-hidden="true"
								style={{ color: "#F89764" }}
							/>
						</span>
					)}

				<div id="titlecontainer" style={{ display: 'flex', maxWidth: "70%" }}>
					{showEditTitleTextField ? (
						<div
							style={{
								display: "inline-block",
								width: "auto",
								padding: "5px",
							}}
						>
							{isValidParam(titleField1) && isFieldValidForEdit(titleField1)
								? getTextFieldComponent(titleField1)
								: isValidParam(titleField1) &&
								getTextForReadOnlyField(titleField1)}
							{
								(object === constants.CONTACTS_OBJECT)
									? isValidParam(titleField2) && isFieldValidForEdit(titleField2)
										? getTextFieldComponent(titleField2)
										: isValidParam(titleField2) && getTextForReadOnlyField(titleField2)
									: null}
						</div>
					) : (
						<span
							id="detailviewHeaderId"
							style={{
								paddingLeft: "13px",
								textOverflow: "ellipsis",
								overflow: "hidden",
								display: "inline-block",
								fontSize: "24px",
								whiteSpace: "nowrap",
								fontWeight: "normal",
								color: "#555555",
								verticalAlign: "middle",
								maxWidth: "100%",
								//marginTop: "-10px",
							}}
							title={title}
						>
							{title}
						</span>
					)}
					{showEditTitleIcon && object !== constants.AUDITS_OBJECT && (
						<div
							style={{
								cursor: "pointer",
								color: "rgb(113, 113, 113)",
								//display: "inline-block",
								marginTop: "6px",
								paddingLeft: "10px",
								paddingRight: "10px",
							}}
							onClick={handleShowEditTitleTextField}
						>
							<Icon
								style={{
									fontSize: "20px",
									display: 'flex',
									alignContent: 'space-around',
									justifyContent: 'center',
									alignItems: 'stretch',
									flexWrap: 'wrap',
									flexDirection: 'row'
								}}
								title={getLocalizedStrings().label.COMMON.EDIT}
							>
								edit
							</Icon>
						</div>
					)}
				</div>

				<span
					style={{
						position: "relative",
						cursor: "pointer",
						marginTop: "14px",
						paddingLeft: "10px",
					}}
				>
					<span
						style={{
							fontSize: 12,
							fontWeight: "bold",
							color: "#039be5",
							backgroundColor: "inherit",
						}}
						children={getLocalizedStrings().label.REPORT.REFRESH}
						onClick={refreshUpdate}
					/>
				</span>
			</div>

			{/* working here */}

			{isCallfromQueue && (queueObjInner && queueObjInner !== constants.TASKS) && (
				<div style={{ display: "inline", marginTop: "1px" }}>
					<div style={{ display: "inline", paddingRight: "5px" }}>
						<Button
							disabled={isDisabledPrevious}
							title={getLocalizedStrings().label.QUEUE.PREVIOUS}
							style={previousButtonStyle}
							onClick={() => handleAction(constants.PREVIOUS)}
						>
							<i
								className="fas fa-caret-left"
								aria-hidden="true"
								style={prevIconStyle}
							></i>
						</Button>
					</div>
					<div style={{ display: "inline", paddingRight: "5px" }}>
						<Button
							disabled={isDisabledNext}
							title={getLocalizedStrings().label.QUEUE.NEXT}
							style={nextButtonStyle}
							onClick={() => handleAction(constants.NEXT)}
						>
							<i
								className="fas fa-caret-right"
								aria-hidden="true"
								style={nextIconStyle}
							></i>
						</Button>
					</div>
					<div style={{ display: "inline", paddingRight: "5px" }}>
						<Button
							disabled={isRemoveDisable}
							title={getLocalizedStrings().label.QUEUE.REMOVE}
							style={removeButtonStyle}
							onClick={() => handleAction(constants.REMOVE)}
						>
							<i
								className="fas fa-minus"
								aria-hidden="true"
								style={removeIconStyle}
							></i>
						</Button>
					</div>
					<div style={{ display: 'inline', paddingRight: '5px' }}>
						<span> ({openedRecordInQueueNo} of {total_count})</span>
					</div>
				</div>
			)}

			<div
				style={
					{
						display: "flex",
						justifyContent: "flex-end",
						float: "right",
						width: (object === constants.ROYALTY_RUN_OBJECT || object === constants.TASKS || object === constants.CASES_OBJECT) ? "" : "40%",
					}
					// { display: 'inline', float: 'right', marginTop: '1px' }
				}
			>
				{showEditButton && object === constants.TASKS && detailViewRecord.t_status != 'Completed' && (
					<div style={{ display: "inline" }}>
						<Button
							style={
								object === constants.TASKS
									? styles.primaryButton
									: styles.secondaryButton
							}
							onClick={() => handleAction(constants.EDIT)}
						>
							{getLocalizedStrings().label.COMMON.EDIT}
						</Button>
					</div>
				)}

				{isValidParam(greenlightActions) &&
					greenlightActions !== null &&
					greenlightActions.length > 0 && (
						<div
							style={{
								display: "inline",
								marginRight: "0px",
								float: "left",
							}}
						>
							{greenlightActions.map((item) => {
								return (
									<Button
										key={`key-${item}`}
										onClick={() => handleGreenlightAction(item)}
										style={{
											...styles.primaryButton,
											verticalAlign: "top",
											height: "29px",
											marginTop: "2px",
										}}
										disabled={isLoading}
									>
										{" "}
										{item}{" "}
									</Button>
								);
							})}
						</div>
					)}
				{/* (app.me.projectId == 893 || app.me.projectId == 2678 ) */}
				
				{(object == constants.ACCOUNTS_OBJECT ? _showConvertButton : showConvertButton) &&
					object !== constants.GROUP_OBJECT &&
					object !== constants.SALES_LEADS_OBJECT &&
					object !== constants.AUDITS_OBJECT &&
					object !== constants.ROYALTY_RUN_OBJECT &&
					object !== constants.REPORTED_SALES_OBJECT &&
					object !== constants.COMPLIANCE_OBJECT && (
						<div
							style={{
								display: "inline",
								paddingRight: "5px",
								marginRight: "0px",
								float: "left",
							}}
						>
							<Button
								style={{ ...styles.listViewPrimaryButton }}
								labelstyle={{ paddingLeft: "10px", paddingRight: "10px" }}
								onClick={() => handleAction(constants.CONVERT)}
							>
								{getLocalizedStrings().label.COMMON.CONVERT}
							</Button>
						</div>
					)}

				{showConvertToAccountButton && (
					<div style={{ display: "inline", paddingRight: "5px" }}>
						<Button
							style={{ ...styles.listViewPrimaryButton }}
							labelstyle={{ paddingLeft: "10px", paddingRight: "10px" }}
							onClick={() => handleAction(constants.CONVERT_TO_ACCOUNTS)}
						>
							{convertToAccountLabel}{" "}
						</Button>
					</div>
				)}

				{showPushButton && (
					<div style={{ display: "inline", paddingRight: "5px" }}>
						<Button
							style={{ ...styles.listViewPrimaryButton }}
							labelstyle={{ paddingLeft: "10px", paddingRight: "10px" }}
							onClick={() => handleAction(constants.PUSH)}
						>
							{getLocalizedStrings().label.COMMON.PUSH}
						</Button>
					</div>
				)}
				{showSearchKBButton && (
					<div style={{ display: "inline" }}>
						<Button
							style={styles.secondaryButton}
							labelstyle={{ paddingLeft: "10px", paddingRight: "10px" }}
							onClick={() => handleAction(constants.SEARCH_KB)}
						>
							{constants.SEARCH_KB}{" "}
						</Button>
					</div>
				)}
				{object === constants.JOBS_OBJECT && createJobsActionButton()}
				{object === constants.ROYALTY_RUN_OBJECT && createRoyaltyRunActionButton()}
				{/* {object === constants.REPORTED_SALES_OBJECT && createReportedSalesActionButton()} */}
				{object === constants.TASKS && detailViewRecord.t_status != 'Completed' && createTasksActionButton()}
				{showEditButton &&
					object !== constants.AUDITS_OBJECT &&
					object !== constants.ROYALTY_RUN_OBJECT &&
					object !== constants.TASKS && (
						<div style={{ display: "inline", marginTop: "2px" }}>
							<Button
								style={styles.secondaryButton}
								onClick={() => handleAction(constants.EDIT)}
							>
								{getLocalizedStrings().label.COMMON.EDIT}
							</Button>
						</div>
					)}

				{getActions()}
				<div style={{ display: "inline", marginTop: "2px" }}>
					{(object == constants.ACCOUNTS_OBJECT ? _more : showMore) && object !== constants.AUDITS_OBJECT && (
						<PopOver
							id={"modulesMoreOptions"}
							key={"modulesMoreOptions"}
							name={"modulesMoreOptions"}
							buttonEndIcon={"more_vert"}
							buttonStyle={{
								...styles.togButton,
								backgroundColor: "#ffffff",
								height: "26px",
								width: "26px",
								border: "1px solid rgb(224,224,224)",
								marginTop: "1px",
								paddingLeft: "1px",
							}}
							labelposition={"before"}
							buttonLabel={""}
							options={actions}
							onclickAction={handleAction}
							isShowIcon={true}
							title={getLocalizedStrings().label.COMMON.MORE}
							buttonLineOrigin={buttonLineOrigin}
						/>
					)}
				</div>

			</div>
			{showToggle && getKanbanToggle()}
			<div className="clearfix" />
		</div>
	);
};

export default DetailViewHeader;

function processGroupData(response) {
	var tcAlert = [],
		tcMsgAlert = "";
	var bcAlert = [],
		bcMsgAlert = "";
	var abAlert = [],
		abMsgAlert = "";
	var webFormAlert = [],
		webFormMsgAlert = "";
	var landingPageAlert = [],
		landingPageMsgAlert = "";
	var message = [];
	var alertMsg = "";

	if (response != null && response != "" && response != undefined) {
		if (response.touchmsg != null && response.touchmsg != undefined) {
			tcMsgAlert = response.touchmsg;
		}
		if (response.broadcastmsg != null && response.broadcastmsg != undefined) {
			bcMsgAlert = response.broadcastmsg;
		}
		if (response.abmsg != null && response.abmsg != undefined) {
			abMsgAlert = response.abmsg;
		}
		if (response.webFormMsg != null && response.webFormMsg != undefined) {
			webFormMsgAlert = response.webFormMsg;
		}
		if (response.landingPageMsg != null && response.landingPageMsg != undefined) {
			landingPageMsgAlert = response.landingPageMsg;
		}

		if (
			(tcMsgAlert != null && tcMsgAlert != "" && tcMsgAlert != undefined) ||
			(bcMsgAlert != null && bcMsgAlert != "" && bcMsgAlert != undefined) ||
			(abMsgAlert != null && abMsgAlert != "" && abMsgAlert != undefined) ||
			(webFormMsgAlert != null && webFormMsgAlert != "" && webFormMsgAlert != undefined) ||
			(landingPageMsgAlert != null && landingPageMsgAlert != "" && landingPageMsgAlert != undefined)
		) {
			if (tcMsgAlert != null && tcMsgAlert != "" && tcMsgAlert != undefined) {
				for (let i = 0; i < tcMsgAlert.length; i++) {
					var touchArr = tcMsgAlert[i].hasOwnProperty("touch")
						? tcMsgAlert[i]["touch"]
						: null;
					var tcGroup = tcMsgAlert[i].hasOwnProperty("tcGroup")
						? tcMsgAlert[i]["tcGroup"]
						: null;

					if (touchArr != null && touchArr.length > 0) {
						var tcName = "";
						var messageTC = "";

						for (let j = 0; j < touchArr.length; j++) {
							if (tcName === "") {
								tcName = touchArr[j];
							} else {
								tcName = tcName + ", " + touchArr[j];
							}
						}
						messageTC = (
							<span>
								<b>
									<i>{tcGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_TOUCH_CAMPAIGN}{" "}
								<br />
								<b>
									{tcName}
									<br />
									<br />
								</b>
							</span>
						);
						tcName = "";
						tcAlert.push(messageTC);
					}
				}
			}

			if (bcMsgAlert !== null && bcMsgAlert !== "") {
				for (let i = 0; i < bcMsgAlert.length; i++) {
					var broadcastArr = bcMsgAlert[i].hasOwnProperty("broadcast")
						? bcMsgAlert[i]["broadcast"]
						: null;
					var broadcastGroup = bcMsgAlert[i].hasOwnProperty("bcGroup")
						? bcMsgAlert[i]["bcGroup"]
						: null;

					if (broadcastArr !== null && broadcastArr.length > 0) {
						var bcName = "";
						var messageBC = "";

						for (let j = 0; j < broadcastArr.length; j++) {
							if (bcName === "") {
								bcName = broadcastArr[j];
							} else {
								bcName = bcName + ", " + broadcastArr[j];
							}
						}

						messageBC = (
							<span>
								<b>
									<i>{broadcastGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_BROADCAST_CAMPAIGN}{" "}
								<br />
								<b>
									{bcName}
									<br />
									<br />
								</b>
							</span>
						);
						bcName = "";

						bcAlert.push(messageBC);
					}
				}
			}

			if (abMsgAlert !== null && abMsgAlert !== "") {
				for (let i = 0; i < abMsgAlert.length; i++) {
					var abArr = abMsgAlert[i].hasOwnProperty("ab")
						? abMsgAlert[i]["ab"]
						: null;
					var abGroup = abMsgAlert[i].hasOwnProperty("abGroup")
						? abMsgAlert[i]["abGroup"]
						: null;

					if (abArr !== null && abArr.length > 0) {
						var abName = "";
						var messageAB = "";

						for (let j = 0; j < abArr.length; j++) {
							if (abName === "") {
								abName = abArr[j];
							} else {
								abName = abName + ", " + abArr[j];
							}
						}

						messageAB = (
							<span>
								<b>
									<i>{abGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_AB_CAMPAIGN} <br />
								<b>
									{abName}
									<br />
									<br />
								</b>
							</span>
						);
						abName = "";

						abAlert.push(messageAB);
					}
				}
			}

			if (webFormMsgAlert !== null && webFormMsgAlert !== "") {
				for (let i = 0; i < webFormMsgAlert.length; i++) {
					var webformArr = webFormMsgAlert[i].hasOwnProperty("webForm")
						? webFormMsgAlert[i]["webForm"]
						: null;
					var webformGroup = webFormMsgAlert[i].hasOwnProperty("webFormGroup")
						? webFormMsgAlert[i]["webFormGroup"]
						: null;

					if (webformArr !== null && webformArr.length > 0) {
						var webformName = "";
						var messageWebform = "";

						for (let j = 0; j < webformArr.length; j++) {
							if (webformName === "") {
								webformName = webformArr[j];
							} else {
								webformName = webformName + ", " + webformArr[j];
							}
						}

						messageWebform = (
							<span>
								<b>
									<i>{webformGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_WEBFORM} <br />
								<b>
									{webformName}
									<br />
									<br />
								</b>
							</span>
						);
						webformName = "";

						webFormAlert.push(messageWebform);
					}
				}
			}

			if (landingPageMsgAlert !== null && landingPageMsgAlert !== "") {
				for (let i = 0; i < landingPageMsgAlert.length; i++) {
					var landingpageArr = landingPageMsgAlert[i].hasOwnProperty(
						"landingPage"
					)
						? landingPageMsgAlert[i]["landingPage"]
						: null;
					var landingpageGroup = landingPageMsgAlert[i].hasOwnProperty(
						"landingPageGroup"
					)
						? landingPageMsgAlert[i]["landingPageGroup"]
						: null;

					if (landingpageArr !== null && landingpageArr.length > 0) {
						var landingpageName = "";
						var messageLandingpage = "";

						for (let j = 0; j < landingpageArr.length; j++) {
							if (landingpageName === "") {
								landingpageName = landingpageArr[j];
							} else {
								landingpageName = landingpageName + ", " + landingpageArr[j];
							}
						}

						messageLandingpage = (
							<span>
								<b>
									<i>{landingpageGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_LANDINGPAGE} <br />
								<b>
									{landingpageName}
									<br />
									<br />
								</b>
							</span>
						);
						landingpageName = "";
						landingPageAlert.push(messageLandingpage);
					}
				}
			}
		}

		if (tcAlert !== null) {
			message.push(tcAlert);
		}

		if (bcAlert !== null) {
			message.push(bcAlert);
		}

		if (abAlert !== null) {
			message.push(abAlert);
		}
		if (webFormAlert !== null) {
			message.push(webFormAlert);
		}

		if (landingPageAlert !== null) {
			message.push(landingPageAlert);
		}

		alertMsg = (
			<div style={{ overflow: "auto", maxHeight: "400px", fontSize: "16px" }}>
				<b>{getLocalizedStrings().message.GROUP.DELETE_MSG} :</b>
				<br />
				<br />
				{message}
			</div>
		);
		return alertMsg;
	} else {
		return alertMsg;
	}
}
