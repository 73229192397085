import React from 'react';
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../services/constants/MultiLingual';
import { getTopUpDialog } from '../../services/actions/topUpDialogActions';
import { useDispatch, useSelector } from "react-redux";

const SfTopUpDialog = () => {
  const { dialogTitle, dialogActions, data, isOpenDialog, callFrom, style } = useSelector((state) => state.topUpDialog);
  const app = useSelector((state) => state.app)
  const dispatch = useDispatch();
  let inWidth = window.innerWidth;

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      //   onClose();
    }
  };

  const handleClick = (from) => {
    dispatch(getTopUpDialog(false, null, null, null, null, null, null));
    if (from === 'deny') {
      if (dialogActions.deny !== undefined && dialogActions.deny !== null) {
        dialogActions.deny();
      }
    } else {
      if (dialogActions.accept !== undefined && dialogActions.accept !== null) {
        dialogActions.accept();
      }
    }
  };

  if (!isOpenDialog) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal" style={{ width: inWidth < 1024 ? '90%' : inWidth * 0.45 }}>
        <div className='modal-header'>
          <span className='modal-title'>
            {dialogTitle}
          </span>
          <span className='modal-close-wrapper' >
            <button className="modal-close-btn" onClick={() => handleClick('deny')}>
              &times;
            </button>
          </span>
        </div>

        <div className='modal-body'>
          <div className='modal-content'>
            <div style={{ color: 'rgb(0 0 0 / 60%)', marginLeft: '2.5%' }} dangerouslySetInnerHTML={{ __html: data.message }} />
          </div>
          <div style={{ float: 'right', paddingTop: '10px' }}>
            {callFrom === 'confirm' && <Button
              style={{
                backgroundColor: "#ffffff",
                color: "#717171",
                border: "solid 1px #e0e0e0",
                zIndex: "0",
                height: "28px",
                width: 'auto',
                lineHeight: "28px",
                marginRight: '20px'
              }}
              onClick={() => handleClick('deny')}
            >
              {getLocalizedStrings().label.COMMON.CANCEL}
            </Button>
            }
            <Button style={{
              backgroundColor: style?.backgroundColor ? style.backgroundColor : app?.me.background,
              color: "#ffffff",
              border: "solid 1px #d3d6d6",
              marginRight: "10px",
              zIndex: "0",
              height: "28px",
              //width:'20px',
              lineHeight: "28px",
            }}
              onClick={() => handleClick('accept')}
            >
              {getLocalizedStrings().label.COMMON.OK}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SfTopUpDialog;
