import React from 'react';
import { connect } from 'react-redux';
import CalendarSettings from '../components/calendarSettings';
import SendSMS from '../components/sendSMS';
import MosaicoTemplate from './MosaicoTemplate';
import BroadcastCampaignForMosico from '../containers/BroadcastCampaignForMosico';
import AutomationMosaicoTemplate from './AutomationMosaicoTemplate';
import SFForm from '../components/sfForm';
import EditMail from '../components/editMail';
import SendMail from '../components/sendMail';
import Email from '../components/email';
import { setHeader } from '../../../services/actions/headerActions';
import { getActiveTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { Typography, Icon, IconButton } from '@mui/material';
import { isValidParam, getBooleanParam, getStringParam, getArrayParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { closeWorkflowSetupDrawer, setActions } from '../../../services/actions/workflowSetupAction';
import { LinkToType } from '../../../services/constants/link';
import { getAppCustomDrawer, minimizeAppCustomDrawer } from '../../../services/actions/appContainerActions';
import SalesTemplate from './SalesTemplate';
import { constants, WORKFLOW_SETUP } from '../../../services/constants/constants';
import AgreementComponent from '../components/agreementComponent';
import AutomationAddMessage from '../components/automationAddMessage';
import MarketingTemplate from './MarketingTemplate';
import PreviewEmailTemplate from './PreviewEmailTemplate';
import BroadcastCampaign from './BroadcastCampaign';
import AgreementTemplate from './AgreementTemplate';
import News from '../components/news';
import * as sfDialogs from '../components/sfDialogs';
import ABCampaign from './ABCampaign';
import Convert from '../components/convert';
import GreenlightConvert from '../components/greenlightConvert'
import BatchConvert from '../components/batchConvert';
import { updateConvertData, updateBatchConvertData } from '../../../services/actions/convertAction'
import { detailViewIsMounted, isDetailViewSet, refreshDetailViewData } from '../../../services/actions/detailViewActions';
import RoyaltyPlanManagement from "../components/royaltyPlanManagement";
import AuditReportDetails from '../components/AuditReportDetails';
import AuditAnswers from '../components/AuditAnswers';
import CorrectiveActionReportDetails from '../components/CorrectiveActionReportDetails';
import WorkFlowSetup from '../components/WorkflowSetup';
import AuditWeaknessFailedReport from '../components/AuditWeaknessFailedReport';
import AuditChecklist from '../components/AuditChecklist';

class SFCustomDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMinimize: false,
            drawerWidth: '0%',
            dialogTitle: null,
        }

    }

    componentWillReceiveProps(nextProps) {
        let actionType = getStringParam(this.props.appContainer.customDrawerProps.actionType);
        if (actionType === LinkToType.TYPE_EMAIL) {
            if (!this.state.isMinimize) {
                if (isValidParam(nextProps) && isValidParam(nextProps.appContainer.customDrawerProps.isMinimize) && nextProps.appContainer.customDrawerProps.isMinimize) {
                    //this.state.isMinimize = true;
                    let _state = this.state;
                    _state.isMinimize = true;
                    this.setState(_state);
                }
            }
        }
    }
    getCustomDrawerComponent(object) {
        let isOpen = getBooleanParam(this.props.appContainer.customDrawerProps.isOpen);
        let actionType = getStringParam(this.props.appContainer.customDrawerProps.actionType);
        let data = getObjectParam(this.props.appContainer.customDrawerProps.data);
        object = data.hasOwnProperty('object') ? getStringParam(data.object) : object;
        let selectedRecords = getArrayParam(data.selectedRecords);
        let recordId = getIntParam(data.id);
        let activeTab = getActiveTab();
        let callFrom = isValidParam(this.props.appContainer.customDrawerProps.callFrom) ? this.props.appContainer.customDrawerProps.callFrom : null;
        let isDetailView = activeTab.type === TYPE_DETAIL_VIEW ? true : false;
        let parentDetails = {};
        parentDetails.parent_object = getStringParam(data.parent_object);
        parentDetails.parent_record_id = getIntParam(data.parent_record_id);
        if (actionType !== constants.CONVERT && actionType !== constants.GREENLIGHT && actionType !== constants.AUTOMATION_DESIGNER_AUTO_MAIL && actionType !== constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE && actionType !== constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
            actionType = actionType.toLowerCase();
        }
        if (isOpen && isValidParam(actionType) && actionType !== '') {
            switch (actionType) {
                case constants.CONVERT:
                    if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN || callFrom === constants.COLUMN_VIEW) {
                        if (selectedRecords.length === 1) {
                            return <Convert object={object} closeDrawer={this.closeActionDrawer} callFrom={callFrom} data={data} selectedRecords={selectedRecords} />;
                        } else {
                            return <BatchConvert object={object} closeDrawer={this.closeActionDrawer} callFrom={callFrom} selectedRecords={selectedRecords} data={data} />;
                        }
                    } else {
                        return <Convert object={object} closeDrawer={this.closeActionDrawer} callFrom={callFrom} data={data} />;
                    }
                case constants.GREENLIGHT:
                    return <GreenlightConvert object={object} closeDrawer={this.closeActionDrawer} callFrom={callFrom} data={data} />
                case constants.AUDIT_REPORT_DEATILS:
                    return <AuditReportDetails object={object} closeDrawer={this.closeActionDrawer} data={data} />
                case constants.CORRECTIVE_ACTIONS_REPORT_DETAILS:
                    return <CorrectiveActionReportDetails object={object} closeDrawer={this.closeActionDrawer} data={data} />
                case constants.AUDIT_WEAKNESS_FAILED_REPORT:
                    return <AuditWeaknessFailedReport object={object} closeDrawer={this.closeActionDrawer} data={data} />
                case constants.AUDIT_ANSWERS_OBJECT:
                    return <AuditAnswers object={object} data={data} closeActionDrawer={this.closeActionDrawer} />
                case LinkToType.TYPE_CALENDAR_SETTINGS:
                    return <CalendarSettings closeDrawer={this.closeActionDrawer} data={data} />
                case LinkToType.TYPE_JOB_CALENDAR_SETTINGS:
                    return <CalendarSettings closeDrawer={this.closeActionDrawer} data={data} />
                case WORKFLOW_SETUP.SETUP:
                    return <WorkFlowSetup object={object} closeDrawer={this.closeActionDrawer} data={data} />
                case LinkToType.TYPE_TASK_SETTINGS:
                    return <CalendarSettings closeDrawer={this.closeActionDrawer} data={data} />
                case LinkToType.TYPE_CLICK_TO_SMS:
                    return <SendSMS object={object} data={data} type={actionType} closeDrawer={this.closeActionDrawer} />;
                case LinkToType.TYPE_EDIT:
                    if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                        if (isValidParam(data.type) && (data.type === constants.EDITOR_NEW || data.type === constants.EDITOR_BEE)) {
                            return <BroadcastCampaignForMosico id={recordId} type={actionType} />
                        } else {
                            return <BroadcastCampaign id={recordId} type={actionType} object={object} />;
                        }
                    } else if (object === constants.SALES_TEMPLATE_OBJECT) {
                        return <SalesTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
                        if (isValidParam(data.type) && ((data.type === constants.EDITOR_NEW) || data.type === constants.EDITOR_BEE)) {
                            return <MosaicoTemplate id={recordId} type={actionType} />
                        } else {
                            return <MarketingTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                        }
                    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        return <AgreementTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} tempdata={data} />;
                    } else if (object === constants.NEWS_OBJECT) {
                        return <News object={object} id={recordId} type={actionType} data={this.props.appContainer.drawerProps.data} closeDrawer={this.closeActionDrawer} />;
                    } else if(object === constants.AUDIT_CHECKLISTS_OBJECT){
                        return <AuditChecklist object={object} id={recordId} type={actionType} data={this.props.appContainer.drawerProps.data} closeDrawer={this.closeActionDrawer}/>
                    }else if (object === constants.AB_CAMPAIGNS_OBJECT) {
                        return <ABCampaign id={recordId} type={actionType} />;
                    } else if (object === constants.ROYALTY_PLANS_OBJECT) {
                        return <RoyaltyPlanManagement object={object} id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    }
                    // else if (object === constants.WEB_FORMS) {

                    //     return <WebForm id={recordId} type={actionType} data={data} object={object} />;
                    // }
                    else {
                        return null;
                    }
                case LinkToType.TYPE_NEW:
                    if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                        if (isValidParam(data.type) && (data.type === constants.EDITOR_NEW || data.type === constants.EDITOR_BEE)) {
                            return <BroadcastCampaignForMosico id={recordId} type={actionType} />;
                        } else {
                            return <BroadcastCampaign id={recordId} type={actionType} object={object} />;
                        }
                    } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
                        return <AutomationMosaicoTemplate nodeObject={data.node_object} />
                    } else if (object === constants.NOTE_OBJECT) {
                        return <SFForm object={object} openMode={constants.SF_FORM_OPEN_MODE_CUSTOM} recordId={recordId} isDetailView={isDetailView} parentDetails={parentDetails} reminderBlock={true} callFrom={data.callFrom} />;
                    } else if (object === constants.SALES_TEMPLATE_OBJECT) {
                        return <SalesTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
                        if (isValidParam(this.props.appContainer.customDrawerProps.data.type) && (this.props.appContainer.customDrawerProps.data.type === constants.EDITOR_LEGACY || data.type === constants.EDITOR_BEE)) {
                            return <PreviewEmailTemplate object={object} data={this.props.appContainer.drawerProps.data} />
                        } else if (isValidParam(this.props.appContainer.customDrawerProps.data.type) && (this.props.appContainer.customDrawerProps.data.type === constants.EDITOR_NEW)) {
                            return <MosaicoTemplate id={recordId} type={actionType} />
                        }
                    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        return <AgreementTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} tempdata={data} />;
                    } else if (object === constants.NEWS_OBJECT) {
                        return <News object={object} id={recordId} type={actionType} data={this.props.appContainer.drawerProps.data} closeDrawer={this.closeActionDrawer} />;
                    } else if(object === constants.AUDIT_CHECKLISTS_OBJECT){
                        return <AuditChecklist object={object} id={recordId} type={actionType} data={this.props.appContainer.drawerProps.data} closeDrawer={this.closeActionDrawer}/>
                    } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
                        return <ABCampaign id={recordId} type={actionType} />;
                    } else if (object === constants.ROYALTY_PLANS_OBJECT) {
                        return <RoyaltyPlanManagement object={object} id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    }
                    // else if (object === constants.WEB_FORMS) {
                    //     return <WebForm id={recordId} type={actionType} data={data} object={object}/>;
                    // } 
                    else {
                        return null;
                    }
                    break;
                case LinkToType.TYPE_COPY:
                    if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                        if (isValidParam(data.type) && (data.type === constants.EDITOR_NEW || data.type === constants.EDITOR_BEE)) {
                            return <BroadcastCampaignForMosico id={recordId} type={actionType} />
                        } else {
                            return <BroadcastCampaign id={recordId} type={actionType} object={object} />;
                        }
                    } else if (object === constants.SALES_TEMPLATE_OBJECT) {
                        return <SalesTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
                        if (isValidParam(data.type) && ((data.type === constants.EDITOR_NEW) || data.type === constants.EDITOR_BEE)) {
                            return <MosaicoTemplate id={recordId} type={actionType} />
                        } else {
                            return <MarketingTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                        }
                    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        return <AgreementTemplate id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} tempdata={data} />;
                    } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
                        return <ABCampaign id={recordId} type={actionType} />;
                    } else if (object === constants.ROYALTY_PLANS_OBJECT) {
                        return <RoyaltyPlanManagement object={object} id={recordId} type={actionType} closeDrawer={this.closeActionDrawer} />;
                    }
                    // else if (object === constants.WEB_FORMS) {
                    //     return <WebForm id={recordId} type={actionType} data={data} object={object}/>;
                    // }
                    else {
                        return null;
                    }
                case LinkToType.TYPE_EMAIL:
                    if (data.emailType !== undefined && (data.emailType === 'DirectMail' || data.emailType === 'AutoMail')) {
                        return <EditMail id={recordId} object={object} type={actionType} data={data} closeDrawer={this.closeActionDrawer} minimizeCustomDrawer={() => this.minimizeCustomDrawer()} />
                    } else {
                        return <SendMail id={recordId} object={object} type={actionType} data={data} closeDrawer={this.closeActionDrawer} minimizeCustomDrawer={() => this.minimizeCustomDrawer()} />
                    }
                case constants.EMAIL_DETAILS:
                    return <Email parentObject={data.parentObject} type={actionType} parentRecordId={data.parentRecordId} data={data.record} callFrom={data.callFrom} />;
                case constants.AGREEMENT_OBJECT:
                    return <AgreementComponent object={object} closeDrawer={this.closeActionDrawer} data={this.props.appContainer.customDrawerProps.data} />
                case constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE:
                    return <AutomationAddMessage object={object} closeDrawer={this.closeActionDrawer} data={this.props.appContainer.customDrawerProps.data} />
                case constants.AUTOMATION_DESIGNER_AUTO_MAIL:
                    return <AutomationAddMessage object={object} closeDrawer={this.closeActionDrawer} data={this.props.appContainer.customDrawerProps.data} />
                case constants.AUTOMATION_DESIGNER_ADD_MESSAGE:
                    return <AutomationAddMessage object={constants.AUTOMATION_DESIGNER_OBJECT} closeDrawer={this.closeActionDrawer} data={this.props.appContainer.customDrawerProps.data} />
                default: return null;
            }
        } else {
            return null;
        }
    }

    closeActionDrawer = () => {
        let data = this.props.appContainer.customDrawerProps;
        let selectedRecords = getArrayParam(data.data.selectedRecords);
        let tempObject = this.props.appContainer.customDrawerProps.data.object;
        let obj = null //{};
        let minimizeList = data.minimizeList;
        let actionType = data.actionType;
        let callFrom = isValidParam(data.callFrom) ? data.callFrom : null;
        if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN || callFrom === constants.COLUMN_VIEW) {
            if (selectedRecords.length === 1) {
                this.props.updateConvertData(obj);
            } else {
                this.props.updateBatchConvertData(obj);
            }
        } else {
            this.props.updateConvertData(obj);
        }
        if (minimizeList.hasOwnProperty(actionType)) {
            minimizeList[actionType] = false;
        }
        // if(isDetailView){
        let prevScrollPosition = this.props.detailView.scrollPosition;
        this.props.detailView.prevScrollPosition = prevScrollPosition;
        if(tempObject === 'correactive_action_details'){
            this.props.setHeader(constants.TASK_REPORT, getLocalizedStrings().label.TASK_REPORT.TITLE, null, false, true);
        }
        if (data.actionType === "workflow-setup") {
            this.props.closeWorkflowSetupDrawer();
            this.props.setActions('--Add--');

        }
        this.props.sfForm.sfFromData = {};
        this.props.getAppCustomDrawer(false, null, null, null, null, null, null);
    }

    minimizeCustomDrawer = () => {
        let data = this.props.appContainer.customDrawerProps;
        let minimizeList = data.minimizeList;
        let type = data.actionType;
        minimizeList[type] = true
        let style = {};
        let labelName = this.props.appContainer.customDrawerProps.dialogName;
        let headerProps = {};
        let _state = this.state;
        _state.isMinimize = true;
        this.setState(_state);
        let prevScrollPosition = this.props.detailView.scrollPosition;
        this.props.detailView.prevScrollPosition = prevScrollPosition;
        this.props.minimizeAppCustomDrawer(true, labelName, style, { isMinimize: true }, headerProps, minimizeList);
    }

    maximizeDrawer = () => {
        let data = this.props.appContainer.customDrawerProps;
        let nonMinimizableDrawer = this.props.appContainer.drawerProps;
        if (nonMinimizableDrawer.isOpenDrawer) {
            const alertMsg = `"${nonMinimizableDrawer.actionDialogName}" is already opened, please close this drawer before maximizing.`
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

        } else {
            let minimizeList = data.minimizeList;
            let labelName = this.props.appContainer.customDrawerProps.dialogName;
            let _state = this.state;
            _state.isMinimize = false;
            this.setState(_state);
            let prevScrollPosition = this.props.detailView.scrollPosition;
            this.props.detailView.prevScrollPosition = prevScrollPosition;
            this.props.minimizeAppCustomDrawer(false, labelName, null, { isMinimize: false }, null, minimizeList)
        }
    }

    render() {
        //  console.log('render')
        let actionType = getStringParam(this.props.appContainer.customDrawerProps.actionType);
        var fullWidth = window.innerWidth;

        let callDrawer = window.innerHeight - 100;
        if (actionType === LinkToType.TYPE_EMAIL) {
            callDrawer = window.innerHeight - 50;
        }
        let drwaerBodyStyle = { height: callDrawer, overflowY: 'scroll' };
        if (actionType === LinkToType.TYPE_EMAIL) {
            drwaerBodyStyle = { height: callDrawer, overflow: 'hidden' };
        }
        var drawerWidthP = this.props.appContainer.customDrawerProps !== undefined ? this.props.appContainer.customDrawerProps.style.width : '41%';
        var dw = drawerWidthP.split("%")[0];
        var drawerWidth = fullWidth * 0.01 * parseInt(dw);
        var mdw = 17;
        let minimisedDrawerWidth = fullWidth * 0.01 * parseInt(mdw);
        let minimizable = this.props.appContainer.customDrawerProps !== undefined ? this.props.appContainer.customDrawerProps.minimizable : false;
        if (this.state.isMinimize) {
            return (
                <div class='minimized-mail-bar'>
                    <div style={{ backgroundColor: '#717171' }}>
                        <div style={{ display: 'flex' }} >
                            <div style={{ color: '#fff', paddingLeft: '20px', textAlign: 'left', height: '40px', lineHeight: '40px' }}>
                                <div style={{ display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: minimisedDrawerWidth - 70 }} title={this.props.appContainer.customDrawerProps.dialogName}>
                                    <span style={{ color: '#fff', fontSize: '20px' }}> {this.props.appContainer.customDrawerProps.dialogName}</span>
                                </div>

                            </div>

                            <div >
                                <IconButton>
                                    <i className="fas fa-window-maximize" title="Maximize"
                                        style={{ color: '#fff', fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer' }}
                                        onClick={() => this.maximizeDrawer()}></i>
                                </IconButton>

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='sf-call-drawer-backdrop' >
                    <div className='sf-call-drawer' style={{ width: drawerWidth }}>
                        {(actionType !== LinkToType.TYPE_EMAIL) &&
                            <div className='sf-call-drawer-toolbar'>
                                <div className='sf-drawer-title'>
                                    <Typography variant="h6" style={{ color: '#fff', paddingLeft: "24px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 400, fontSize: '24px', textAlign: 'left', height: '48px', lineHeight: '48px', width: this.props.appContainer.customDrawerProps.data.object === constants.NEWS_OBJECT ? '90%' : '' }}>
                                        {this.props.appContainer.customDrawerProps.dialogName}
                                    </Typography>
                                    <span className='sf-call-drawer-button-right'>

                                        {minimizable && <IconButton onClick={() => this.minimizeCustomDrawer()}
                                            title={'Minimize'}>
                                            <i className="fas fa-window-minimize" title="Minimize"
                                                style={{ fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer', color: '#fff', marginLeft: 5 }}
                                            ></i>
                                        </IconButton>}
                                        <IconButton
                                            onClick={() => this.closeActionDrawer()}
                                            title={getLocalizedStrings().label.COMMON.CLOSE}>
                                            <Icon style={{ color: '#fff', marginTop: 2 }}> close</Icon>
                                        </IconButton>
                                    </span>
                                </div>
                            </div>}

                        <div className='sf-call-drawer-body' style={drwaerBodyStyle}>
                            {this.getCustomDrawerComponent(this.props.appContainer.customDrawerProps.data.object)}
                        </div>
                    </div>
                </div>
            );
        }
    }
};


const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        appContainer: state.appContainer,
        detailView: state.detailView,
        sfForm: state.sfForm,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        minimizeAppCustomDrawer: (isMinimize, label, style, data, headerProps, minimizeList) => {
            dispatch(minimizeAppCustomDrawer(isMinimize, label, style, data, headerProps, minimizeList));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        updateConvertData: (convert) => {
            dispatch(updateConvertData(convert));
        },
        detailViewIsMounted: (isRefresh) => {
            dispatch(detailViewIsMounted(isRefresh));
        },
        isDetailViewSet: (isRefresh) => {
            dispatch(isDetailViewSet(isRefresh));
        },
        refreshDetailViewData: (isRefresh) => {
            dispatch(refreshDetailViewData(isRefresh));
        },
        updateBatchConvertData: (convert) => {
            dispatch(updateBatchConvertData(convert));
        },
        closeWorkflowSetupDrawer: () => {
            dispatch(closeWorkflowSetupDrawer());
        },
        setActions: (value) => {
            dispatch(setActions(value))
        }
    }
};
SFCustomDrawer = connect(mapStateToProps, mapDispatchToProps)(SFCustomDrawer);

export default SFCustomDrawer;
