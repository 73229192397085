import { actions } from '..//constants/actions';

const QueueInitialSate = {
    mounted: false,
}

const queueReducer = (state = QueueInitialSate, action)=>{
    switch(action.type){

        case actions.QUEUE_RECORDS_GET_PENDING:
            return {
                ...state,
                ...QueueInitialSate
            }
        case actions.QUEUE_RECORDS_GET_FULFILLED:                
                return{
                    ...state,
                    data: action.payload,
                    mounted: true,
                    call_from: action.payload.call_from,
                }    
        case actions.UPDATE_QUEUE_LIST_DATA:
            return{
                ...state,
                data:{
                    ...state.data,
                    ...action.payload
                }
                
        }            
        default:
            return state;
    }

}
export default queueReducer;

