import React, { useEffect, useState,useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {styles} from '../../../services/constants/styles';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {getAppDrawer} from '../../../services/actions/appContainerActions';
import { constants } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import {refreshDetailViewData,refreshDetailViewGroup,detailViewIsMounted,isDetailViewSet} from "../../../services/actions/detailViewActions";
import * as sfDialogs from "../components/sfDialogs";
import {endPoints} from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import {showCustomSnackBar} from "../../../services/actions/snackBarAction";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import FormGroup from '@mui/material/FormGroup';
import {
    getStringParam,
} from "../../../services/helper/parameterVerifier";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Checkbox,
    FormControlLabel,
    FormControl,
    Icon,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ShowCircularProgress from '../components/circularProgress';

const AuditAnswers = ({data,closeActionDrawer}) => {
    const dispatch = useDispatch();
    const scrollref = useRef(null);
    const inputRef = useRef(null);    
    const fileInput = useRef(null);
    const filePhoto = useRef(null);
    const app = useSelector((state)=>state.app);
    const [feedBack,setFeedBack] = useState('');
    const [correctiveActions,setCorrectiveActions] = useState('');
    const [attachFiles, setAttachFiles] = useState([]);
    const [isChecked,setisChecked] = useState(false); 
    const [photos, setPhotos] = useState([]);
    const [isOpen,setisOpen] = useState(false);
    const [selectedImage,setSelectedImage] = useState({});
    const [isMounted,setIsMounted] = useState(false);
    const [Note,setNote] = useState('');
    const [NoteList,setNoteList] = useState('');
    const [photoList,setPhotoList] = useState({});

    useEffect(()=>{
        setIsMounted(true);
        let tempData = data?.data;
        if(tempData?.photo_url?.length > 0){
            setAttachFiles(tempData.photo_url);
            setPhotos(tempData.photo_url);
        }
        if(tempData?.feedback){
            setFeedBack(tempData.feedback);
        }
        if(tempData?.corrective_action){
            setCorrectiveActions(tempData.corrective_action);
        }
        if(tempData?.note){
            setNoteList(tempData.note)
        }
        if(tempData?.corrective_action_required){
            let tempIsChecked = tempData?.corrective_action_required === 'Yes' ? true :false;
            setisChecked(tempIsChecked);
        }
        if(tempData.photo_url){
            setPhotoList(tempData.photo_url);
        }
        setIsMounted(false);
    },[])


    useEffect(() => {
        if (data.type && data.type === "Picture") {
            scrollref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [scrollref,attachFiles,photoList])

    const closeDrawer = () => {
        closeActionDrawer();
    }

    const OpenAttachmentFiles = () =>{
        if(app.me.is_franchisee){
            if (fileInput.current) {
                function onFileChange(e) {
                    const [file] = e.target.files;
                    if (file) {
                        if (getStringParam(file.type).startsWith('image/')) {
                            const reader = new FileReader();
                            reader.onload = function () {
                                let tempAttchFiles = attachFiles;
                                tempAttchFiles.push(reader?.result);
                                setAttachFiles([...tempAttchFiles]);
                            }
                            reader.onerror = function (evt) {
                                console.error("error reading file");
                            }
                            reader.readAsDataURL(file);
                            let tempPhotos = [...photos];
                            tempPhotos = [...tempPhotos ??[] ,file]
                            setPhotos(tempPhotos);
                        } else {                        
                            sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, () => {});
                        }
                    }
                    fileInput.current.removeEventListener('change', onFileChange);
                    fileInput.current.value = null;
                }
                fileInput.current.addEventListener('change', onFileChange);
                fileInput.current.click();
            }
        }
    }

    const UpdateSelectedImagefunc = (ele,idx) =>{
        if(app.me.is_franchisee && !ele.id){
            if (filePhoto.current) {
                function onFileChangeUpdate(e) {
                    const [file] = e.target.files;
                    if (file) {
                        if (getStringParam(file.type).startsWith('image/')) {
                            const reader = new FileReader();
                            reader.onload = function () {
                                if(idx >= 0){
                                    let tempAttchFiles = attachFiles;
                                    tempAttchFiles[idx] = reader?.result;
                                    setAttachFiles([...tempAttchFiles]);
                                }
                            }
                            reader.onerror = function (evt) {
                                console.error("error reading file");
                            }
                            reader.readAsDataURL(file);
                            let tempPhotos = [...photos];
                            tempPhotos[idx] =  file;
                            setPhotos(tempPhotos);
                        } else {                        
                            sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, () => {});
                        }
                    }
                    filePhoto.current.removeEventListener('change', onFileChangeUpdate);
                    filePhoto.current.value = null;
                }
                filePhoto.current.addEventListener('change', onFileChangeUpdate);
                filePhoto.current.click();
            }
        }else{
            setSelectedImage(ele);
            setisOpen(true);
        }
    }
    const UpdateAuditAnswers = () =>{
        let params = {};
        let {id} = data?.data;
        if(app.me.is_franchisee || (app.me.is_franchisee && app.me.is_franchisor)){
            params.id = id;
            params.correctiveAction = correctiveActions;
            params.feedBack = feedBack;
            params.new_note = Note;
            params.corrective_action_required = isChecked;
            let tempPhotos = [];
            photos?.map((ele)=>{
                if(!ele.id || ele.id === undefined){
                    tempPhotos.push(ele);
                }
            })
            setIsMounted(true);
            const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_ANSWERS.UPDATE_AUDIT_ANSWERS, params));
            if (promise) {
                promise.then(res => {
                    if(tempPhotos){
                        let answerMap = {};
                        const formData = new FormData();
                        let photoNames = [];
                        for (let i = 0; i < tempPhotos.length; i++) {
                            photoNames.push(tempPhotos[i].name);
                            formData.append('photo', tempPhotos[i], tempPhotos[i].name);
                        }
                        answerMap[data?.data?.id] = photoNames;
                        formData.append('answerMap',JSON.stringify(answerMap));
                        let url = endPoints.AUDIT_QUESTIONS.UPLOAD_ANSWER_PHOTO;
                        const _promise = Promise.resolve(HTTPClient.postFormData(url, formData));
                        if (_promise) {
                            _promise.then(res => {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                setIsMounted(false);
                                closeActionDrawer();
                                dispatch(detailViewIsMounted(false));
                                dispatch(isDetailViewSet(false));
                                dispatch(refreshDetailViewData(true));
                            })
                        }
                    }
                })
            }
        }else{
            params.id = id;
            params.feedBack = feedBack;
            params.new_note = Note;
            params.corrective_action_required = isChecked;
            const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_ANSWERS.UPDATE_AUDIT_ANSWERS, params));
            setIsMounted(true);
            if (promise) {
                promise.then(res => {
                   dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                   setIsMounted(false);
                    closeActionDrawer();
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(refreshDetailViewData(true));
                })
            }
        }
    }

    const handleClose = () => {
        setisOpen(false);
    };

    const handleDeleteAttachedPhoto = (ele,idx) =>{
        if(app.me.is_franchisee && !ele.id){
            let _attachedFiles = attachFiles;
            _attachedFiles.splice(idx, 1);
            setAttachFiles([..._attachedFiles]);
            let tempPhotos = [...photos];
            tempPhotos.splice(idx,1);
            setPhotos(tempPhotos);
        }
    }

    const handleInputChange = (value) =>{
        if(value){
            inputRef.current?.focus();
        }else{
            setFeedBack('');
        }
        setisChecked(value);
    }

    const setTextInputRef = (element) =>{
        inputRef.current = element;
    }

    const handleChangeNote = (event,type) =>{
        let value = event.target.value;
        if(type === 'note'){
            setNote(value);
        }else{
            setNoteList(value);
        }
    }

    const getQuestionContent = (data) => {
                let element = <div className="row">
                    <div className="col-sm-9">
                        <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                label={getLocalizedStrings().label.AUDIT_ANSWERS.QUESTION}
                                id="questionName"
                                name="questionName"
                                autoFocus={true}
                                title={data.questions}
                                value={data.questions}
                                disabled
                                autoComplete="new-password"
                                style={{zIndex: 0}}
                                margin="dense"
                                className={"sf-fields-bg"}/>
                        </FormControl>
                    </div>
                    <div className="col-sm-3">
                        <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                            <TextField
                                disabled
                                variant="outlined"
                                label={getLocalizedStrings().label.AUDIT_ANSWERS.SCORE}
                                id="score"
                                value={data.total_score}
                                name="score"
                                autoComplete="new-password"
                                style={{zIndex: 0}}
                                margin="dense"
                                className={"sf-fields-bg"}/>
                        </FormControl>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    disabled
                                    variant="outlined"
                                    label={getLocalizedStrings().label.AUDIT_ANSWERS.ANSWER}
                                    id="answer"
                                    value={data.answers}
                                    multiline
                                    rows={5}
                                    name="answer"
                                    autoComplete="new-password"
                                    style={{zIndex: 0}}
                                    margin="dense"
                                    className={"sf-fields-bg"}/>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className = "isFeedbackEditable">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox disabled={(app.me.is_franchisee) || (app.me.is_franchisor && data.isfeedback === 1)} checked={isChecked} value={isChecked} onChange={(e) => handleInputChange(e.target.checked)} style={{marginLeft:'-12px'}} />}  style={{width:'fit-content',marginLeft:'16px'}} label="Requires Corrective Action" />
                            </FormGroup>
                        </div>
                        <div className="col-sm-12">
                            <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.AUDIT_QUESTIONS.NOTE}
                                    id="answer"
                                    value={Note}
                                    onChange={(event)=>handleChangeNote(event,'note')}
                                    InputLabelProps={{
                                        sx: {
                                          color: '#454545',
                                          fontSize: "17px",
                                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "12px" }
                                        }
                                      }}
                                    inputRef={setTextInputRef}
                                    multiline
                                    rows={3}
                                    name="note"
                                    autoComplete="new-password"
                                    style={{zIndex: 0}}
                                    margin="dense"
                                    className={"sf-fields-bg"}
                                    // inputProps={{ maxLength: 20 }}
                                    />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id="answer"
                                    disabled
                                    value={NoteList}
                                    onChange={(event)=>handleChangeNote(event,'note_details')}
                                    multiline
                                    rows={5}
                                    name="NoteList"
                                    autoComplete="new-password"
                                    style={{zIndex: 0}}
                                    margin="dense"
                                    className={"sf-fields-bg"}/>
                            </FormControl>
                        </div>
                        <div>
                            <div style={{ width: '100%', display: 'inline' }}>
                                {app.me.is_franchisee && <div style={{ width: '100%', height: '50px', float: 'right' }}>
                                    <IconButton
                                        onClick={() => OpenAttachmentFiles()}
                                        title = {getLocalizedStrings().label.AUDIT_ANSWERS.INSERT_PHOTO}
                                        style={{ marginTop: '0px', float: 'right', marginRight: '12px' }}
                                    >
                                        <InsertPhotoIcon style={{ fontSize: '40px' }} />
                                    </IconButton>
                                </div>}
                                <input type='file' style={{ display: 'none', width: '0px', height: '0px' }} ref={fileInput} accept="image/*"></input>
                                <input type='file' style={{ display: 'none', width: '0px', height: '0px' }} ref={filePhoto} accept="image/*"></input>

                                <div style={{ marginTop: '14px', float: 'right', justifyContent: 'right', display: 'inline' }}>

                                    {/* tempPhotoAttachment */}
                                    <div style={{float: 'right', justifyContent: 'right', display: 'flex',width: '33.5rem', overflowWrap: 'break-word', wordWrap: 'break-word', flexWrap: 'wrap', marginLeft: attachFiles?.length > 4 ? '0%' : '15px'}}>
                                        {attachFiles?.map((ele, idx) => {
                                            return <div style={{ marginBottom: '10px' }}>
                                                <img style={{ width: '90px', height: '50px', marginRight: '16px', cursor: 'pointer', border: '1px solid black' }} src={ele.url ? ele.url : ele} onClick={() => UpdateSelectedImagefunc(ele, idx)} />
                                                {(app.me.is_franchisee && !ele.id) && <IconButton
                                                    title={getLocalizedStrings().label.COMMON.REMOVE} style={{ cursor: 'pointer' }}>
                                                    <Icon style={{
                                                        position: 'absolute',
                                                        left: '-12px',
                                                        right: 0,
                                                        top: '-47px',
                                                        bottom: 0,
                                                        color: 'red',
                                                        fontSize: '18px'
                                                    }}
                                                        onClick={() => handleDeleteAttachedPhoto(ele, idx)}
                                                    > close</Icon>
                                                </IconButton>}
                                            </div>
                                        })}
                                    </div>

                                     {/* photo attchemt */}
                                     <div className='fe_photo' style={{
                                        float: 'right',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end', // Align items to the right
                                        width: '33.5rem',
                                        overflowWrap: 'break-word',
                                        // border: '1px solid red',
                                        wordWrap: 'break-word',
                                        flexWrap: 'wrap',
                                        // marginLeft: attachFiles?.length > 4 ? '0%' : '15px'
                                    }}>
                                        <div>
                                            {Object.entries(photoList).map(([label, items]) => (
                                                <div ref={scrollref} key={label} style={{ marginBottom: '10px' }}>
                                                    {/* //remove textAlign & width to set from left */}
                                                    <div className='user_info' style={{ fontSize: '16px',textAlign: 'right',width: '100%'}}>{label}</div>   
                                                    <div style={{
                                                        display: 'flex',
                                                        overflowX: 'auto',
                                                         marginTop:'5px',
                                                         overflowWrap:'break-word',
                                                         flexWrap:'wrap',
                                                        justifyContent: 'flex-end' // Align items inside the inner container to the right
                                                    }}>
                                                        {items.map(item => (
                                                            <div key={item.id} style={{ marginRight: '10px',height:'fit-content' }}>
                                                                {item.file_name.endsWith('.pdf') ? (
                                                                    <div>
                                                                        <img
                                                                            src={item.url}
                                                                            // alt={item.file_name}
                                                                            style={{
                                                                                width: '90px',
                                                                                height: '50px',
                                                                                cursor: 'pointer',
                                                                                border: '1px solid black'
                                                                            }}
                                                                        />
                                                                        <p>{item.file_name}</p>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{height:'100%'}}>
                                                                        <img
                                                                            src={item.url}
                                                                            onClick={() => UpdateSelectedImagefunc(item, 0)}
                                                                            // alt={item.file_name}
                                                                            style={{
                                                                                width: '90px',
                                                                                height: '50px',
                                                                                cursor: 'pointer',
                                                                                border: '1px solid black'
                                                                            }}
                                                                        />
                                                                        <div title={item.file_name} style={{width:'90px',height:'100%',marginTop:'5px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{item.file_name}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        return element
    }

    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div style={{marginLeft: '27px', marginTop: '24px', marginRight: '27px', height: '100%'}}>
        { !isMounted ? <div style={{width:'100%',height:'fit-content'}}>
                {getQuestionContent(data.data)}
                <div style={{textAlign: 'right', marginTop: '10px', display:'flex',paddingBottom: '20px',float:'right'}}>
                    <Button onClick={() => UpdateAuditAnswers()}
                                style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    <Button onClick={() => closeDrawer()}
                        style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                </div>
            </div>
            :
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        }

            <div key="sign-dialog">
                <Dialog aria-labelledby="customized-dialog-title" onClose={handleClose} open={isOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <div style={{ display: 'flex' }}>
                            <div
                                className="modal-header"
                                style={{
                                    marginTop: 5,
                                    display: 'block',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                                title={getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_PHOTO}>
                                {getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_PHOTO}
                            </div>
                            <div style={{ width: '50px', float: 'right', alignItems: 'right', textAlign: 'right', alignSelf: 'right', marginLeft: '20px' }}>
                                <IconButton style={{ marginTop: '8px' }} aria-label="close" onClick={() => handleClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ marginBottom: '1rem', width: '33rem', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={selectedImage.url ? selectedImage.url : selectedImage } style={{ width: '250px', height: '250px',objectFit:'contain' }} />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default AuditAnswers;
